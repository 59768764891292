import React, { useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import {
	LoadingOverlay,
	TextInput,
	Title,
	Text,
	Notification,
	useMantineTheme,
} from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { HeroImg } from '@/components/partials/heroImg/HeroImg';
import { AppPaths } from '@/configs/appPaths';
import { SubmitStatus } from '../login/Login';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import styles from './ResetPassword.module.scss';

const initialValues = {
	email: '',
};

const baseSlug = 'resetPassword.';

export const ResetPassword: React.FC = () => {
	const { other, spacing } = useMantineTheme();

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);

	const { getErrorMessage, getContent } = useContent();

	const form = useForm({
		initialValues,
		validate: {
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
		},
	});

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.requestResetPassword(
			form.values.email
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		form.setFieldError('email', getErrorMessage(ErrorCode.USER_NOT_FOUND));
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<div className="page-wrapper-unauth">
			<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={status === SubmitStatus.LOADING} />
				<Title ta="center" order={1} mb={6}>
					{getContent(`${baseSlug}header`)}
				</Title>
				<Text
					{...other.fontSizes.xl}
					ta="center"
					color="dark.6"
					mb="xl"
					className="hide-on-mobile"
				>
					{getContent(`${baseSlug}subheader`)}
				</Text>
				{status === SubmitStatus.SUCCESS && (
					<Notification {...other.notificationProps.success} mb="lg">
						<Text {...other.fontSizes.md} color="green.6" my="sm">
							{getContent(`${baseSlug}success`)}
						</Text>
					</Notification>
				)}
				{status !== SubmitStatus.SUCCESS && (
					<>
						<TextInput
							{...form.getInputProps('email')}
							{...other.inputSizes.lg}
							required
							type="email"
							placeholder={getContent(`${baseSlug}inputEmailPlaceholder`)}
							mb="lg"
						/>
						<Button
							themes={['primary', 'arrow', 'fullWidth']}
							buttonProps={{
								type: 'submit',
								style: {
									marginBottom: spacing.lg,
								},
							}}
						>
							{getContent(`${baseSlug}submitBtn`)}
						</Button>
					</>
				)}
				<Text
					ta="center"
					color={other.textDimmed}
					{...other.fontSizes.md}
					mb="lg"
				>
					{getContent(`${baseSlug}navigateLogin`, {
						links: {
							$login: {
								to: `/${AppPaths.LOGIN}`,
								className: 'text-primary',
							},
						},
					})}
				</Text>
			</form>
			<HeroImg src={getContent(`${baseSlug}heroImg`)} />
		</div>
	);
};
