import { UUID } from '@/types/types';
import { Point } from './types/point';
import { RendererComponent } from './types/objects';
import { Vector2 } from 'three';
import {
	areVectorsParallel,
	getClosestPoint,
	rotateCornerPoints,
} from '@/utils/rendererUtils';

export interface SerializedConnectingEdge {
	id: UUID;
	centerPoint: Point;
	connectedTo?: UUID;
	isShortArm?: true;
}

export interface ConnectingEdgeConfig {
	id: UUID;
	component: RendererComponent;
	centerPoint: Point;
	connectedTo?: ConnectingEdge;
	isShortArm?: true;
}

export class ConnectingEdge {
	id: UUID;
	component: RendererComponent;
	centerPoint: Vector2;
	angleVector!: Vector2;
	connectedTo?: ConnectingEdge;
	isShortArm?: true;

	constructor(config: ConnectingEdgeConfig) {
		this.id = config.id;
		this.connectedTo = config.connectedTo;
		this.component = config.component;
		this.centerPoint = new Vector2(config.centerPoint.x, config.centerPoint.y);
		this.isShortArm = config.isShortArm;
		this.updateEdgeVector();
	}

	calculateDistance(otherEdge: ConnectingEdge, pos = this.centerPoint) {
		return otherEdge.centerPoint.distanceTo(pos);
	}

	getUpdatedCenterPoint(change: Vector2) {
		return this.centerPoint.clone().add(change);
	}

	handleHostRotation(
		angle: number,
		axis = this.component.pos,
		hasSquareConnector = false
	) {
		this.centerPoint = rotateCornerPoints(
			[this.centerPoint],
			angle,
			axis.x,
			axis.y
		)[0];

		this.updateEdgeVector();
	}

	conectEdges(otherEdge: ConnectingEdge) {
		this.connectedTo = otherEdge;
		otherEdge.connectedTo = this;
	}

	disconnectEdges() {
		if (this.connectedTo?.connectedTo) this.connectedTo.connectedTo = undefined;
		this.connectedTo = undefined;
	}

	canConnectToOtherEdge(other: ConnectingEdge) {
		return areVectorsParallel(this.angleVector, other.angleVector);
	}

	private updateEdgeVector() {
		const cornerPoint = getClosestPoint(
			this.centerPoint,
			...this.component.getAreaPoints()
		);

		this.angleVector = new Vector2(
			Math.abs(cornerPoint.x - this.centerPoint.x),
			Math.abs(cornerPoint.y - this.centerPoint.y)
		);
	}
}
