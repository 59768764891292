import React, { useEffect, useRef } from 'react';
import { Mesh } from '@/3d/core/types/mesh';
import {
	roomBuilder,
	roomCreator,
} from '@/components/views/creator/renderer/Renderer';
import { Box, BoxProps } from '@mantine/core';
import { RoomRenderer } from '@/3d/renderer/RoomRenderer';
import { useElementSize } from '@mantine/hooks';
import { Events } from '@/3d/core/Events';
import { RendererEvent } from '@/3d/core/enums/RendererEvent';

interface Props {
	mesh: Mesh;
	boxProps?: BoxProps;
}

export const Project3dView: React.FC<Props> = ({ mesh, boxProps }) => {
	const { ref: containerRef, width } = useElementSize();
	const canvasRef = useRef<HTMLCanvasElement>(null!);

	useEffect(() => {
		Events.getInstance().emit(RendererEvent.RESIZE_RENDERER);
	}, [width]);

	useEffect(() => {
		roomCreator.buildMeshRoom(roomBuilder, mesh);
	}, []);

	useEffect(() => {
		if (!canvasRef.current) return;
		const canvas = canvasRef.current;

		canvas.height = canvas.parentElement?.clientHeight!;
		canvas.width = canvas.parentElement?.clientWidth!;

		const room = roomBuilder.getRoom();

		const roomRenderer = new RoomRenderer(canvas, room);

		setTimeout(() => {
			window.dispatchEvent(new Event('creatorloaded'));
		}, 1000);

		return () => {
			roomRenderer.dispose();
			roomBuilder.restart();
		};
	}, []);

	return (
		<Box ref={containerRef} h="100%" w="100%" {...boxProps}>
			<Box ref={canvasRef} component="canvas"></Box>
		</Box>
	);
};
