import { MeshManager } from './MeshManager';
import {
	MaterialFactory,
	MaterialType,
	concreteMaterial,
} from './MaterialFactory';
import { ObjectTypeName } from '../core/types/objects';
import { ViewType } from '@/types/creator';
import { Wall } from '../core/Wall';
import { Room } from '../core/Room';
import * as THREE from 'three';

export const WALL_COLOR = 0xb9b1a8,
	WALL_SIDE_COLOR = 0xf0f2f4,
	WALL_COLOR_DRAGGING = 0x07353f,
	WALL_COLOR_HOVER = 0x007f6d,
	LINE_OPTIONS = {
		color: 0x2d2d2d,
		linewidth: 0.0008,
		polygonOffset: true,
		polygonOffsetFactor: -1,
		polygonOffsetUnits: 1,
	};

export enum WallMaterialPosition {
	TOP = 'top',
	INSIDE = 'inside',
}

export class WallManager extends MeshManager {
	private wallMeshes = new Map<Wall, THREE.Mesh>();
	private materials = {
		normal: {
			top: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_COLOR,
				side: THREE.DoubleSide,
				polygonOffset: true,
				polygonOffsetFactor: 1,
				polygonOffsetUnits: 1,
			}),
			normal: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_SIDE_COLOR,
				side: THREE.DoubleSide,
			}),
			external: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_SIDE_COLOR,
				transparent: true,
				depthWrite: true,
				opacity: 0.7,
				side: THREE.DoubleSide,
			}),
			internal: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_SIDE_COLOR,
			}),
			side: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_SIDE_COLOR,
				transparent: true,
				depthWrite: true,
				opacity: 0.35,
				side: THREE.DoubleSide,
			}),
		},
		hover: {
			top: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_COLOR_HOVER,
				side: THREE.DoubleSide,
			}),
		},
		dragging: {
			top: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: WALL_COLOR_DRAGGING,
				side: THREE.DoubleSide,
			}),
		},
		realistic: {
			internal: concreteMaterial.clone(),
		},
	};

	constructor(
		protected override room: Room,
		protected override meshGroup: THREE.Group
	) {
		super(room, meshGroup);
	}

	update() {
		this.removeUnusedMeshes(
			this.wallMeshes,
			new Set(this.room.walls),
			ObjectTypeName.WALL
		);

		for (const wall of this.room.walls) {
			if (!this.wallMeshes.has(wall)) {
				this.createWallMesh(wall);
			} else {
				this.updateWallMesh(wall);
			}
		}
	}

	private getMaterials(wall: Wall) {
		const topMaterial = wall.isDragging
			? this.materials.dragging.top
			: wall.mouseOver
			? this.materials.hover.top
			: this.materials.normal.top;

		const sideMaterial = this.materials.normal.side,
			externalMaterial = this.materials.normal.external,
			internal =
				this.room.viewType === ViewType.FIRST_PERSON
					? this.materials.realistic.internal
					: this.materials.normal.internal;

		return [
			sideMaterial,
			sideMaterial,
			topMaterial,
			this.materials.normal.normal,
			externalMaterial,
			internal,
		];
	}

	private createWallMesh(wall: Wall) {
		const { p1, p2 } = wall.getLinePoints();
		const wallIndex = this.room.walls.indexOf(wall);

		const wallThickness = wall.width;
		const wallLength = wall.length - wallThickness;
		const wallHeight = this.room.height;

		const wallGeometry = new THREE.BoxGeometry(
			wallLength,
			wallHeight,
			wallThickness
		);

		const materials = this.getMaterials(wall);

		const wallMesh = new THREE.Mesh(wallGeometry, materials);
		wallMesh.userData.wallId = wall.id;

		wallMesh.position.set(
			(p1.x + p2.x) / 2,
			this.room.height / 2,
			(p1.y + p2.y) / 2
		);
		wallMesh.rotation.y = -wall.angle * (Math.PI / 180);

		this.createWallEdges(wall);
		this.wallMeshes.set(wall, wallMesh);

		this.meshGroup.add(wallMesh);
	}

	private updateWallGeometry(w: Wall, wallMesh: THREE.Mesh) {
		const wallLength = w.length;
		const wallThickness = w.width;
		const wallHeight = this.room.height;

		const newWallGeometry = new THREE.BoxGeometry(
			wallLength - wallThickness,
			wallHeight,
			wallThickness
		);

		wallMesh.geometry.dispose();

		wallMesh.geometry = newWallGeometry;
	}

	private updateWallMesh(wall: Wall) {
		const wallMesh = this.wallMeshes.get(wall);

		if (!wallMesh) return;

		this.updateWallGeometry(wall, wallMesh);

		const { p1, p2 } = wall.getLinePoints();

		wallMesh.position.set(
			(p1.x + p2.x) / 2,
			this.room.height / 2,
			(p1.y + p2.y) / 2
		);

		const materials = this.getMaterials(wall);
		wallMesh.material = materials;

		this.createWallEdges(wall);
	}

	private createWallEdges(wall: Wall) {
		const [p1, p2, p3, p4] = wall.getAreaPoints(wall.length - wall.width);
		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.WALL}-${wall.id}-1`,
			startVector.set(p1.x, this.room.height, p1.y),
			endVector.set(p2.x, this.room.height, p2.y),
			LINE_OPTIONS
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.WALL}-${wall.id}-2`,
			startVector.set(p3.x, this.room.height, p3.y),
			endVector.set(p4.x, this.room.height, p4.y),
			LINE_OPTIONS
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.WALL}-${wall.id}-3`,
			startVector.set(p1.x, 0, p1.y),
			endVector.set(p2.x, 0, p2.y),
			LINE_OPTIONS
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.WALL}-${wall.id}-4`,
			startVector.set(p3.x, 0, p3.y),
			endVector.set(p4.x, 0, p4.y),
			LINE_OPTIONS
		);
	}
}
