import { FONT_LINE_HEIGHT } from '../renderer/LabelManager';
import { MouseOver } from './interfaces/MouseOver';
import { Line } from './Line';
import { Wall } from './Wall';
import { Vector2 } from 'three';

export type LabelConfig = Wall;

export class Label implements MouseOver {
	id = crypto.randomUUID();
	wall!: Wall;
	pos!: Vector2;
	length!: number;
	mouseOver = false;
	windowPosition?: Vector2;
	width = FONT_LINE_HEIGHT;
	selected = false;

	constructor(config: LabelConfig) {
		this.wall = config;
		this.length = 0;
	}

	getLinePoints(length: number) {
		const halfLength = length / 2;
		const angle = (this.wall.angle * Math.PI) / 180;

		const x1 = this.pos.x + Math.cos(angle) * halfLength;
		const y1 = this.pos.y + Math.sin(angle) * halfLength;
		const x2 = this.pos.x - Math.cos(angle) * halfLength;
		const y2 = this.pos.y - Math.sin(angle) * halfLength;

		return new Line(new Vector2(x1, y1), new Vector2(x2, y2));
	}
}
