import React from 'react';
import { useContent } from '@/providers/ContentProvider';
import { Language } from '@/types/types';
import { NativeSelect, Select, rem } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import APP_CONFIG from '@/configs/appConfig';
import LANGUAGES from '@/configs/languages';
import styles from '../Navbar.module.scss';

const data = Object.entries(LANGUAGES).map(([lang, { displayCode }]) => ({
	value: lang,
	label: displayCode,
}));

export const LanguageSelect: React.FC = () => {
	const { language, setLanguage } = useContent();

	const mobileBreakpoint = useMediaQuery(
		`(max-width: ${rem(APP_CONFIG.BREAKPOINTS.MD)} )`
	);

	return mobileBreakpoint ? (
		<div className={styles.langContainer}>
			<div className={styles.nativeSelectWrapper}>
				<NativeSelect
					size="lg"
					data={data}
					classNames={{
						input: styles.nativeSelectInput,
						rightSection: styles.selectRightSection,
					}}
					onChange={(e) => setLanguage(e.currentTarget.value as Language)}
					value={language}
				/>
			</div>
		</div>
	) : (
		<Select
			value={language}
			data={data}
			onChange={setLanguage}
			classNames={{
				input: styles.selectInput,
				rightSection: styles.selectRightSection,
				dropdown: styles.selectDropdown,
			}}
		/>
	);
};
