import { Lamp } from '@/3d/core/Lamp';
import { Connector } from '@/3d/core/Connector';
import { ConnectorType } from '@/api/ComponentApi';
import { RendererEvent } from './enums/RendererEvent';
import { Events } from './Events';
import { Core } from './Core';
import { RendererComponent } from './types/objects';
import { ConnectingEdge } from './ConnectingEdge';
import { Vector2 } from 'three';
import {
	areVectorsParallel,
	extrudePointFromLine,
	getClosestPoint,
} from '@/utils/rendererUtils';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import soundConnect from '@/assets/sound/objectConnect.mp3';
import soundDisconnect from '@/assets/sound/objectDisconnect.wav';

const connectSoundEffect = new Audio(soundConnect);
const disconnectSoundEffect = new Audio(soundDisconnect);

function playConnectionSoundEffect() {
	connectSoundEffect.currentTime = 0;
	connectSoundEffect.play();
}
function playDisconnectSoundEffect() {
	if (import.meta.env.PROD) return;

	disconnectSoundEffect.currentTime = 0;
	disconnectSoundEffect.play();
}

export class ObjectConnector {
	constructor(private core: Core) {}

	private setGroupIdForMatchComponents(
		componentA: RendererComponent,
		componentB: RendererComponent,
		automaticConnector?: Connector
	) {
		if (componentA.groupId && componentB.groupId) {
			for (const object of this.core
				.getRendererComponents()
				.filter((obj) => obj.isSameGroup(componentB!))) {
				object.groupId = componentA.groupId;
			}
		} else if (componentA.groupId) {
			componentB.groupId = componentA.groupId;
			if (automaticConnector) automaticConnector.groupId = componentA.groupId;
		} else if (componentB.groupId) {
			componentA.groupId = componentB.groupId;
			if (automaticConnector) automaticConnector.groupId = componentB.groupId;
		} else {
			const groupId = crypto.randomUUID();
			componentA.groupId = groupId;
			componentB.groupId = groupId;
			if (automaticConnector) automaticConnector.groupId = groupId;
		}
	}

	private disconnectLamp(lamp: Lamp) {
		playDisconnectSoundEffect();

		for (const edge of lamp.connectionEdges) {
			edge.disconnectEdges();
		}

		const oldConnectors = lamp.connectors;

		for (const connector of lamp.connectors) {
			connector.disconnectLamp(lamp);

			if (connector.isAutomatic && connector.lamps.length < 2) {
				connector.dispose();
				Events.getInstance().emit(RendererEvent.UPDATE_OBSERVABLE_AREA);
			}
		}

		if (
			oldConnectors.filter((con) => !!con.groupId).length === 2 &&
			!oldConnectors[0].checkIfConnectorsInSameGroup(oldConnectors[1])
		) {
			this.core.unvisitObjects();
			oldConnectors[0].setNewGroupId();
			this.core.unvisitObjects();
		}
	}

	private detectLampConnections(
		draggedLamp: Lamp,
		connectors: Connector[],
		posChange: Vector2,
		secondCheck = false
	) {
		let connectorMatch = null;
		let connectorMatchEdge = null;
		let draggedLampEdge = null;

		for (const connector of connectors) {
			if (connectorMatch) break;

			if (connector.isSkew() !== draggedLamp.isSkew()) continue;

			if (draggedLamp.seriesId !== connector.seriesId) continue;

			for (const lampEdge of draggedLamp.connectionEdges) {
				if (connectorMatch) break;

				for (const connectorEdge of connector.connectionEdges) {
					if (!lampEdge.canConnectToOtherEdge(connectorEdge)) continue;

					const dist = lampEdge.calculateDistance(
						connectorEdge,
						lampEdge.getUpdatedCenterPoint(posChange)
					);

					let threshold: number = RENDERER_CONFIG.OBJECT_CONNECT_THRESHOLD;
					if (connector.connectorType === ConnectorType.SQUARE)
						threshold = RENDERER_CONFIG.OBJECT_CONNECT_THRESHOLD / 2;
					if (secondCheck) threshold = 1;

					if (dist < threshold) {
						connectorMatch = connector;
						draggedLampEdge = lampEdge;
						connectorMatchEdge = connectorEdge;
						break;
					}
				}
			}
		}

		if (!connectorMatch || !draggedLampEdge || !connectorMatchEdge)
			return false;
		if (draggedLampEdge.connectedTo || connectorMatchEdge.connectedTo)
			return false;
		if (
			draggedLampEdge.calculateDistance(connectorMatchEdge) >
			draggedLampEdge.centerPoint.distanceTo(connectorMatch.pos)
		)
			return false;
		if (
			connectorMatchEdge.centerPoint.distanceTo(draggedLamp.pos) <
			draggedLamp.length / 2
		)
			return false;

		if (!secondCheck) playConnectionSoundEffect();

		connectorMatch.connectLamp(draggedLamp);
		draggedLampEdge.conectEdges(connectorMatchEdge);

		if (!secondCheck) {
			const distConnectorCenterToMatch = connectorMatchEdge.isShortArm
				? connectorMatch.length / 2
				: connectorMatch.length - connectorMatch.width / 2;

			const newConnectorCenter = extrudePointFromLine(
				draggedLamp.pos,
				draggedLampEdge.centerPoint,
				distConnectorCenterToMatch
			);

			const posChange = newConnectorCenter.clone().sub(connectorMatch.pos);

			connectorMatch.pos = newConnectorCenter;
			connectorMatch.updateGroupPosition(
				posChange,
				this.core.getComponentsGroup(connectorMatch)
			);
		}

		this.setGroupIdForMatchComponents(draggedLamp, connectorMatch);

		return true;
	}

	private detectLampDisconections(draggedLamp: Lamp, posChange: Vector2) {
		if (!draggedLamp.connectors.length) return false;

		for (const edge of draggedLamp.connectionEdges) {
			if (!edge.connectedTo) continue;

			const dist = edge.calculateDistance(
				edge.connectedTo,
				edge.getUpdatedCenterPoint(posChange)
			);

			if (RENDERER_CONFIG.OBJECT_DISCONNECT_THRESHOLD < dist) {
				this.disconnectLamp(draggedLamp);
				return true;
			}
		}
		return false;
	}

	private handleAutomaticSquareConnections(
		draggedLamp: Lamp,
		lamps: Lamp[],
		posChange: Vector2
	) {
		const automaticConnector = draggedLamp.automaticConnector;
		if (!automaticConnector || draggedLamp.isShadow) return false;

		const draggedLampEdges = draggedLamp.connectionEdges;

		let minDistance = Infinity;
		let lampMatch = null;
		let draggedEdge = null;
		let matchEdge = null;

		for (const otherLamp of lamps.filter((lamp) => lamp !== draggedLamp)) {
			if (
				!otherLamp.automaticConnector ||
				otherLamp.seriesId !== draggedLamp.seriesId
			)
				continue;

			if (draggedLamp.angle % 90 !== otherLamp.angle % 90) continue;

			const otherLampEdges = otherLamp.connectionEdges;

			for (const draggedLampEdge of draggedLampEdges) {
				for (const otherLampEdge of otherLampEdges) {
					const dist = draggedLampEdge.calculateDistance(
						otherLampEdge,
						draggedLampEdge.getUpdatedCenterPoint(posChange)
					);

					if (dist < minDistance) {
						minDistance = dist;
						lampMatch = otherLamp;
						draggedEdge = draggedLampEdge;
						matchEdge = otherLampEdge;
					}
				}
			}
		}

		if (!lampMatch || !draggedEdge || !matchEdge) return false;

		if (RENDERER_CONFIG.OBJECT_CONNECT_THRESHOLD > minDistance) {
			if (draggedEdge.connectedTo || matchEdge.connectedTo) return false;
			if (lampMatch.pos.distanceTo(draggedLamp.pos) < lampMatch.length / 2)
				return false;

			playConnectionSoundEffect();

			const conHalfLength = lampMatch.automaticConnector!.length / 2,
				areLampsParallel = draggedLamp.angle === lampMatch.angle;

			const connectorPos = extrudePointFromLine(
				draggedLamp.pos,
				draggedEdge.centerPoint,
				conHalfLength
			);

			const newConnector = new Connector({
				...draggedLamp.automaticConnector!,
				id: crypto.randomUUID(),
				angle: lampMatch.angle,
				lamps: [],
				groupId: draggedLamp.groupId,
				pos: connectorPos,
				isAutomatic: true,
			});

			const connectorEdgeDraggedCenter = getClosestPoint(
				draggedEdge.centerPoint,
				...newConnector.connectionEdges.map((edge) => edge.centerPoint)
			);

			const newLampMatchPos = new Vector2();
			let connectorEdgeMatch: ConnectingEdge;

			if (areLampsParallel) {
				connectorEdgeMatch = newConnector.connectionEdges.find((edge) =>
					edge.centerPoint.equals(
						extrudePointFromLine(
							draggedLamp.pos,
							draggedEdge.centerPoint,
							lampMatch.automaticConnector!.length
						)
					)
				)!;

				newLampMatchPos.copy(
					extrudePointFromLine(
						draggedLamp.pos,
						connectorEdgeMatch.centerPoint,
						lampMatch.length / 2
					)
				);
			} else {
				const connectorEdgeMatchPos = getClosestPoint(
					matchEdge.centerPoint,
					...newConnector.connectionEdges
						.filter(
							(edge) =>
								!edge.connectedTo &&
								!areVectorsParallel(edge.angleVector, draggedEdge.angleVector)
						)
						.map((edge) => edge.centerPoint)
				);

				connectorEdgeMatch = newConnector.connectionEdges.find((edge) =>
					edge.centerPoint.equals(connectorEdgeMatchPos)
				)!;

				newLampMatchPos.copy(
					extrudePointFromLine(
						newConnector.pos,
						connectorEdgeMatch.centerPoint,
						lampMatch.length / 2
					)
				);
			}

			matchEdge.conectEdges(connectorEdgeMatch);
			const connectorEdgeDragged = newConnector.connectionEdges.find((edge) =>
				edge.centerPoint.equals(connectorEdgeDraggedCenter)
			)!;
			draggedEdge.conectEdges(connectorEdgeDragged);

			const posChange = newLampMatchPos.clone().sub(lampMatch.pos);
			lampMatch.pos = newLampMatchPos;
			lampMatch.updateGroupPosition(
				posChange,
				this.core.getComponentsGroup(lampMatch)
			);

			this.setGroupIdForMatchComponents(draggedLamp, lampMatch, newConnector);

			newConnector.connectLamp(draggedLamp);
			newConnector.connectLamp(lampMatch);

			return newConnector;
		}

		return false;
	}

	detectComponentsConnections(
		draggedGroup: RendererComponent[],
		posChange: Vector2
	) {
		let isConnection = false;

		for (const object of draggedGroup) {
			if (object instanceof Connector) {
				if (this.detectConnectorConnections(object, posChange))
					isConnection = true;
			}
			if (object instanceof Lamp) {
				const automaticConnector = this.detectLampAutomaticConnections(
					object,
					this.core.lamps,
					posChange,
					true
				);
				if (automaticConnector) {
					this.core.connectors.push(automaticConnector);
					Events.getInstance().emit(RendererEvent.UPDATE_OBSERVABLE_AREA);
					isConnection = true;
					continue;
				}

				if (this.handleLampConnecting(object, posChange, false))
					isConnection = true;
			}
		}

		return isConnection;
	}

	handleLampConnecting(
		draggedLamp: Lamp,
		posChange: Vector2,
		checkDisconnnections = true
	) {
		if (draggedLamp.isShadow) return false;

		const connectors = this.core.connectors.filter(
			(connector) => !draggedLamp.isSameGroup(connector)
		);

		if (checkDisconnnections && draggedLamp.connectors.length)
			this.detectLampDisconections(draggedLamp, posChange);

		const isConnection = this.detectLampConnections(
			draggedLamp,
			connectors,
			posChange
		);

		if (isConnection)
			this.detectLampConnections(draggedLamp, connectors, posChange, true);

		return isConnection;
	}

	detectConnectorConnections(
		draggedConnector: Connector,
		posChange: Vector2,
		secondCheck = false
	) {
		if (draggedConnector.isShadow) return false;

		const lamps = this.core.lamps.filter(
			(lamp) => !draggedConnector.isSameGroup(lamp)
		);

		const connectorEdges = draggedConnector.connectionEdges;

		let minDistance = Infinity;
		let lampMatch = null;
		let lampMatchEdge = null;
		let draggedConnectorEdge = null;

		for (const lamp of lamps) {
			if (lamp.isSkew() !== draggedConnector.isSkew()) continue;

			const lampEdges = lamp.connectionEdges;

			for (const connectorEdge of connectorEdges) {
				for (const lampEdge of lampEdges) {
					if (!connectorEdge.canConnectToOtherEdge(lampEdge)) continue;

					const dist = lampEdge.calculateDistance(
						lampEdge,
						connectorEdge.getUpdatedCenterPoint(posChange)
					);

					if (dist < minDistance) {
						minDistance = dist;
						lampMatch = lamp;
						lampMatchEdge = lampEdge;
						draggedConnectorEdge = connectorEdge;
					}
				}
			}
		}

		if (!lampMatch || !lampMatchEdge || !draggedConnectorEdge) return false;

		if (RENDERER_CONFIG.OBJECT_CONNECT_THRESHOLD > minDistance) {
			if (lampMatchEdge.connectedTo || draggedConnectorEdge.connectedTo)
				return false;
			if (draggedConnector.seriesId !== lampMatch.seriesId) return false;
			if (lampMatch.pos.distanceTo(draggedConnector.pos) < lampMatch.length / 2)
				return false;

			playConnectionSoundEffect();

			if (!secondCheck) {
				const newLampCenter = extrudePointFromLine(
					draggedConnector.pos,
					draggedConnectorEdge.centerPoint,
					lampMatch.length / 2
				);

				const pChange = newLampCenter.clone().sub(lampMatch.pos);
				lampMatch.pos = newLampCenter;
				lampMatch.updateGroupPosition(
					pChange,
					this.core.getComponentsGroup(lampMatch)
				);
			}

			this.setGroupIdForMatchComponents(draggedConnector, lampMatch);

			draggedConnector.connectLamp(lampMatch);
			lampMatchEdge.conectEdges(draggedConnectorEdge);

			this.detectConnectorConnections(
				draggedConnector,
				draggedConnector.pos,
				true
			);

			return true;
		}

		return false;
	}

	detectLampAutomaticConnections(
		draggedLamp: Lamp,
		lamps: Lamp[],
		posChange: Vector2,
		secondCheck = false
	) {
		if (!secondCheck && draggedLamp.connectors.length) return false;
		const automaticConnector = draggedLamp.automaticConnector;
		if (!automaticConnector || draggedLamp.isShadow) return false;

		if (automaticConnector.connectorType === ConnectorType.SQUARE)
			return this.handleAutomaticSquareConnections(
				draggedLamp,
				lamps,
				posChange
			);

		const draggedLampEdges = draggedLamp.connectionEdges;

		let minDistance = Infinity;
		let lampMatch: Lamp | null = null;
		let draggedEdge = null;
		let matchEdge = null;

		for (const otherLamp of lamps.filter((lamp) => lamp !== draggedLamp)) {
			if (
				!otherLamp.automaticConnector ||
				otherLamp.seriesId !== draggedLamp.seriesId
			)
				continue;

			if (
				automaticConnector.connectorType === ConnectorType.I &&
				draggedLamp.angle !== otherLamp.angle
			)
				continue;

			const otherLampEdges = otherLamp.connectionEdges;

			for (const draggedLampEdge of draggedLampEdges) {
				for (const otherLampEdge of otherLampEdges) {
					if (!draggedLampEdge.canConnectToOtherEdge(otherLampEdge)) continue;

					const dist = draggedLampEdge.calculateDistance(
						otherLampEdge,
						draggedLampEdge.getUpdatedCenterPoint(posChange)
					);

					if (dist < minDistance) {
						minDistance = dist;
						lampMatch = otherLamp;
						draggedEdge = draggedLampEdge;
						matchEdge = otherLampEdge;
					}
				}
			}
		}

		if (!lampMatch || !draggedEdge || !matchEdge) return false;

		if (RENDERER_CONFIG.OBJECT_CONNECT_THRESHOLD > minDistance) {
			if (draggedEdge.connectedTo || matchEdge.connectedTo) return false;
			if (lampMatch.pos.distanceTo(draggedLamp.pos) < lampMatch.length / 2)
				return false;
			playConnectionSoundEffect();

			const conHalfLength = lampMatch.automaticConnector!.length / 2;

			const connectorPos = extrudePointFromLine(
				draggedLamp.pos,
				draggedEdge.centerPoint,
				conHalfLength
			);

			const newConnector = new Connector({
				...draggedLamp.automaticConnector!,
				id: crypto.randomUUID(),
				angle: lampMatch.angle,
				lamps: [],
				groupId: draggedLamp.groupId,
				pos: connectorPos,
				isAutomatic: true,
			});

			const connectorEdgeDraggedCenter = getClosestPoint(
				draggedEdge.centerPoint,
				...newConnector.connectionEdges.map((edge) => edge.centerPoint)
			);
			const connectorEdgeDragged = newConnector.connectionEdges.find((edge) => {
				return (
					edge.centerPoint.x === connectorEdgeDraggedCenter.x &&
					edge.centerPoint.y === connectorEdgeDraggedCenter.y
				);
			})!;
			draggedEdge.conectEdges(connectorEdgeDragged);

			const connectorEdgeMatchCenter = getClosestPoint(
				matchEdge.centerPoint,
				...newConnector.connectionEdges
					.filter((edge) => !edge.connectedTo)
					.map((edge) => edge.centerPoint)
			);
			const connectorEdgeMatch = newConnector.connectionEdges.find((edge) => {
				return (
					edge.centerPoint.x === connectorEdgeMatchCenter.x &&
					edge.centerPoint.y === connectorEdgeMatchCenter.y
				);
			})!;
			matchEdge.conectEdges(connectorEdgeMatch);

			const newLampMatchPos = extrudePointFromLine(
				newConnector.pos,
				connectorEdgeMatch.centerPoint,
				lampMatch.length / 2
			);
			const posChange = newLampMatchPos.clone().sub(lampMatch.pos);
			lampMatch.pos = newLampMatchPos;
			lampMatch.updateGroupPosition(
				posChange,
				this.core.getComponentsGroup(lampMatch)
			);

			this.setGroupIdForMatchComponents(draggedLamp, lampMatch, newConnector);

			newConnector.connectLamp(draggedLamp);
			newConnector.connectLamp(lampMatch);
			return newConnector;
		}

		return false;
	}
}
