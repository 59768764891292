import { RoomBuilder } from './RoomBuilder';
import { Wall } from './Wall';
import { Label } from './Label';
import { Node } from './Node';
import { Mesh } from './types/mesh';
import { NodeDirection } from './enums/NodeDirection';
import { Lamp } from './Lamp';
import { Connector } from '@/3d/core/Connector';
import { UUID } from '@/types/types';
import { ConnectingEdge } from './ConnectingEdge';
import { ApplyEventHandlers, Emit } from './Events';
import { RendererEvent } from './enums/RendererEvent';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import {
	ChargerObject,
	ConnectorObject,
	LampObject,
	NodeObject,
	ObjectTypeName,
	WallObject,
} from './types/objects';
import { Charger } from './Charger';

@ApplyEventHandlers
export class Creator {
	@Emit(RendererEvent.RESTART_VIEW)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	@Emit(RendererEvent.UPDATE_RENDERER)
	buildMeshRoom(builder: RoomBuilder, mesh: Mesh) {
		roomBuilder.clearRoom();

		const walls: Wall[] = [];
		const nodes: Node[] = [];
		const labels: Label[] = [];
		const lamps: Lamp[] = [];
		const connectors: Connector[] = [];
		const chargers: Charger[] = [];

		const lampsConnections: Map<Lamp, UUID[]> = new Map();
		const connectorsConnections: Map<Connector, UUID[]> = new Map();
		const chargersConnections: Map<Charger, UUID> = new Map();

		const edgeConnections: Map<ConnectingEdge, UUID> = new Map();
		const edges: ConnectingEdge[] = [];

		for (let index = 0; index < mesh.mesh.length; index++) {
			const currObject = mesh.mesh[index];

			switch (currObject.object) {
				case ObjectTypeName.WALL:
					const wall = handleWallObject(currObject, walls, nodes);
					handleLabelObject(wall, labels);
					break;
				case ObjectTypeName.NODE:
					handleNodeObject(
						currObject,
						walls,
						nodes,
						mesh.mesh.filter((m) =>
							[ObjectTypeName.WALL, ObjectTypeName.NODE].includes(m.object)
						).length,
						index
					);
					break;
				case ObjectTypeName.LAMP:
					currObject.param.automaticConnector =
						mesh.automaticConnectors[
							currObject.param.automaticConnector as UUID
						];

					handleLampObject(
						currObject,
						lamps,
						lampsConnections,
						edgeConnections,
						edges
					);
					break;
				case ObjectTypeName.CONNECTOR:
					handleConnectorObject(
						currObject,
						connectors,
						connectorsConnections,
						edgeConnections,
						edges
					);
					break;
				case ObjectTypeName.CHARGER:
					handleChargerObject(currObject, chargers, chargersConnections);
					break;
				default:
					break;
			}
		}

		for (const lamp of lamps) {
			const connections = lampsConnections.get(lamp);
			if (!connections) continue;

			lamp.connectors = connections.map(
				(id) => connectors.find((c) => c.id === id)!
			);
		}

		for (const connector of connectors) {
			const connections = connectorsConnections.get(connector);
			if (!connections) continue;

			connector.lamps = connections.map(
				(id) => lamps.find((c) => c.id === id)!
			);
		}

		for (const edge of edges) {
			const connection = edgeConnections.get(edge);
			if (!connection) continue;

			edge.connectedTo = edges.find((e) => e.id === connection)!;
		}

		chargersConnections.forEach((componentId, charger) => {
			const component = [...lamps, ...connectors].find(
				(comp) => comp.id === componentId
			);

			if (component) charger.poweredComponent = component;
		});

		builder.addWalls(walls);
		builder.addLabels(labels);
		builder.addNodes(nodes);
		builder.addLamps(lamps);
		builder.addConnectors(connectors);
		builder.addChargers(chargers);
		builder.updateRoom({ ...mesh.config, meshType: mesh.type });
	}
}

function handleWallObject(
	currObject: WallObject,
	walls: Wall[],
	nodes: Node[]
) {
	const wall = new Wall(currObject.param);
	walls.push(wall);

	const node = nodes[nodes.length - 1];

	if (node && node.wallA && !node.wallB) {
		node.connectWall(wall, NodeDirection.End);
	}

	return wall;
}

function handleLampObject(
	currObject: LampObject,
	lamps: Lamp[],
	lampsConnections: Map<Lamp, UUID[]>,
	edgeConnections: Map<ConnectingEdge, UUID>,
	edges: ConnectingEdge[]
) {
	const lamp = new Lamp(currObject.param);

	if (currObject.param.edges)
		for (const edgeConf of currObject.param.edges) {
			const edge = new ConnectingEdge({
				component: lamp,
				id: edgeConf.id,
				centerPoint: edgeConf.centerPoint,
			});
			edges.push(edge);

			if (edgeConf.connectedTo) edgeConnections.set(edge, edgeConf.connectedTo);

			lamp.connectionEdges.push(edge);
		}

	lamps.push(lamp);
	lampsConnections.set(lamp, currObject.param.connectors);
}

function handleConnectorObject(
	currObject: ConnectorObject,
	connectors: Connector[],
	connectorsLamps: Map<Connector, UUID[]>,
	edgeConnections: Map<ConnectingEdge, UUID>,
	edges: ConnectingEdge[]
) {
	const connector = new Connector(currObject.param);

	if (currObject.param.edges)
		for (const edgeConf of currObject.param.edges) {
			const edge = new ConnectingEdge({
				component: connector,
				id: edgeConf.id,
				centerPoint: edgeConf.centerPoint,
				isShortArm: edgeConf.isShortArm,
			});
			edges.push(edge);

			if (edgeConf.connectedTo) edgeConnections.set(edge, edgeConf.connectedTo);

			connector.connectionEdges.push(edge);
		}

	connectors.push(connector);
	connectorsLamps.set(connector, currObject.param.lamps);
}

function handleChargerObject(
	currObject: ChargerObject,
	chargers: Charger[],
	chargerConnections: Map<Charger, UUID>
) {
	const charger = new Charger(currObject.param);
	if (currObject.param.poweredComponent)
		chargerConnections.set(charger, currObject.param.poweredComponent);

	chargers.push(charger);
}

function handleLabelObject(wall: Wall, labels: Label[]) {
	const label = new Label(wall);
	label.id = crypto.randomUUID();
	labels.push(label);
}

function handleNodeObject(
	currObject: NodeObject,
	walls: Wall[],
	nodes: Node[],
	wallsAndNodesAmount: number,
	index: number
) {
	const node = new Node(currObject.param);

	const wall = walls[walls.length - 1];

	if (wall && !node.wallA) {
		node.connectWall(wall, NodeDirection.Start);
	}

	if (index === wallsAndNodesAmount - 1) {
		const wall = walls[0];
		node.connectWall(wall, NodeDirection.End);
	}

	nodes.push(node);
}
