import React from 'react';
import { NavLinkProps, NavLink as RRNavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavLink.module.scss';

interface Props extends NavLinkProps {
	className?: string;
}

export const NavLink: React.FC<
	Props & React.RefAttributes<HTMLAnchorElement>
> = (props) => {
	return (
		<RRNavLink
			{...props}
			className={({ isActive }) =>
				classNames(props.className, styles.link, { [styles.active]: isActive })
			}
		>
			{props.children}
		</RRNavLink>
	);
};
