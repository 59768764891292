import { Room } from '../core/Room';
import { Lamp } from '../core/Lamp';
import { MeshManager } from './MeshManager';
import { MaterialFactory, MaterialType } from './MaterialFactory';
import {
	extrudePointFromLine,
	getFarthesPoint,
	getRendererComponentYPos,
} from '@/utils/rendererUtils';
import { HELPER_LINE_COLOR, HELPER_LINE_WIDTH } from '../core/HelperLine';
import {
	ObjectTypeName,
	RendererComponentMontageType,
} from '../core/types/objects';
import { ViewType } from '@/types/creator';
import {
	BoxGeometry,
	CanvasTexture,
	Color,
	DoubleSide,
	FrontSide,
	Group,
	Mesh,
	MeshBasicMaterial,
	SRGBColorSpace,
	Vector3,
} from 'three';
import { Loader } from './Loader';
import { UUID } from '@/types/types';

const LAMP_COLOR = 0x006557;
const AREA_HOVER_COLOR = 0x07353f;
const LAMP_REALISTIC_COLOR = 0xe3e8ef;
const SLIGN_OPTIONS = {
	color: 0x697586,
	linewidth: 0.001,
};
const LINE_OPTIONS = {
	color: 0xffffff,
	linewidth: 0.002,
	dashed: false,
};
const LINE_DASHED_OPTIONS = {
	dashed: true,
	dashSize: 5,
	gapSize: 5,
};
const LAMP_SELECTED_LINE_COLOR = 0xffcd4c;
const LAMP_SHADOW_LINE_COLOR = 0x07353f;

export class LampManager extends MeshManager {
	private lampMeshes: Map<Lamp, Mesh> = new Map();
	private materials = {
		normal: {
			bottom: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: LAMP_COLOR,
				side: DoubleSide,
			}),
			side: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				opacity: 0.7,
				color: LAMP_COLOR,
				transparent: true,
				side: DoubleSide,
			}),
		},
		hover: {
			bottom: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: AREA_HOVER_COLOR,
				side: DoubleSide,
			}),
		},
		realistic: {
			side: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: LAMP_REALISTIC_COLOR,
				side: FrontSide,
			}),
			withTexture: new Map<UUID, MeshBasicMaterial>(),
		},
	};

	constructor(
		protected override room: Room,
		protected override meshGroup: Group
	) {
		super(room, meshGroup);
	}

	update() {
		this.removeUnusedMeshes(
			this.lampMeshes,
			new Set(this.room.lamps),
			ObjectTypeName.LAMP
		);

		for (const lamp of this.room.lamps) {
			if (!lamp.needsUpdate) continue;

			lamp.needsUpdate = false;

			const mesh = this.lampMeshes.get(lamp);

			if (!mesh) {
				this.createMesh(lamp);
				continue;
			}

			if (lamp.hasSizeChanged) {
				this.lampMeshes.delete(lamp);

				this.removeMesh(mesh);
				this.createMesh(lamp);
				lamp.hasSizeChanged = false;
				return;
			}

			this.updateMesh(lamp, mesh);
		}
	}

	private createLineHelpers(lamp: Lamp) {
		if (lamp.selected || this.room.viewType !== ViewType.RECTANGULAR) return;

		const startVector = new Vector3(),
			endVector = new Vector3();

		for (let idx = 0; idx < lamp.lineHelpers.length; idx++) {
			const helper = lamp.lineHelpers[idx];
			const y = this.room.componentsSlingLevel + lamp.height;

			const options = {
				linewidth: HELPER_LINE_WIDTH,
				color: !!helper.overlapingHelper ? HELPER_LINE_COLOR : 0xffffff,
				transparent: true,
				opacity: Number(!!helper.overlapingHelper),
			};

			const start = helper.endpoints.start;
			const end = helper.overlapingHelper
				? getFarthesPoint(
						start,
						helper.overlapingHelper.endpoints.start,
						helper.overlapingHelper.endpoints.end
				  )
				: helper.endpoints.end;

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.LAMP}-${lamp.id}-helper-${idx}`,
				startVector.set(start.x, y, start.y),
				endVector.set(end.x, y, end.y),
				options
			);
		}
	}

	private getMaterials(lamp: Lamp) {
		const isShadowMaterial =
			lamp.isAreaHover ||
			lamp.isDragging ||
			lamp.isSummaryHover ||
			lamp.isShadow;

		let bottomMaterial = this.materials.normal.bottom;
		if (isShadowMaterial) bottomMaterial = this.materials.hover.bottom;

		let sideMaterial = this.materials.normal.side;
		if (isShadowMaterial) sideMaterial = this.materials.hover.bottom;

		return [
			sideMaterial,
			sideMaterial,
			sideMaterial,
			bottomMaterial,
			sideMaterial,
			sideMaterial,
		];
	}

	private createMesh(lamp: Lamp) {
		const { p1, p2 } = lamp.getLinePoints();

		const { width, height, length } = lamp;

		const lampGeometry = new BoxGeometry(length, height, width);

		const materials = this.getMaterials(lamp);

		const lampMesh = new Mesh(lampGeometry, materials);

		lampMesh.position.set(
			(p1.x + p2.x) / 2,
			this.room.componentsSlingLevel + 0.5 * lamp.height,
			(p1.y + p2.y) / 2
		);
		lampMesh.rotation.y = -lamp.angle * (Math.PI / 180);

		this.createLineHelpers(lamp);
		this.lampMeshes.set(lamp, lampMesh);
		this.meshGroup.add(lampMesh);
	}

	private updateMesh(lamp: Lamp, lampMesh: Mesh) {
		this.room.viewType === ViewType.FIRST_PERSON
			? this.updateMeshRealistic(lamp, lampMesh)
			: this.updateMeshSimplified(lamp, lampMesh);
	}

	private updateMeshSimplified(lamp: Lamp, lampMesh: Mesh) {
		const materials = this.getMaterials(lamp);

		lampMesh.material = materials;

		const { p1, p2 } = lamp.getLinePoints();

		lampMesh.position.set(
			(p1.x + p2.x) / 2,
			this.room.componentsSlingLevel + 0.5 * lamp.height,
			(p1.y + p2.y) / 2
		);
		lampMesh.rotation.y = -lamp.angle * (Math.PI / 180);

		this.removeEdges(lamp);

		if (this.room.viewType === ViewType.ISOMETRIC || lamp.selected)
			this.createEdges(lamp);
		this.createLineHelpers(lamp);
	}

	private updateMeshRealistic(lamp: Lamp, lampMesh: Mesh) {
		const sideMaterial = this.materials.realistic.side;

		lampMesh.material = [
			sideMaterial,
			sideMaterial,
			sideMaterial,
			sideMaterial,
			sideMaterial,
			sideMaterial,
		];

		if (lamp.modelTexture) {
			Loader.loadTexture(lamp.modelTexture, (texture) => {
				const loadedMaterial = this.materials.realistic.withTexture.get(
					lamp.componentItemId
				);

				if (loadedMaterial) {
					(lampMesh.material as MeshBasicMaterial[])[3] = loadedMaterial;
					return;
				}

				const canvas = document.createElement('canvas');
				const context = canvas.getContext('2d')!;

				const padding = texture.image.height / 6;
				const repeat = Math.round(lamp.length / 2.5);

				canvas.width = texture.image.width * repeat + 2 * padding;
				canvas.height = texture.image.height + 2 * padding;
				const imageRatio = texture.image.width / texture.image.height;

				context.fillStyle = lamp.modelColor;
				context.fillRect(0, 0, canvas.width, canvas.height);
				const imageWidth = (canvas.width - 2 * padding) / repeat;
				const imageHeight = imageWidth / imageRatio;

				for (let i = 0; i < repeat; i++) {
					context.drawImage(
						texture.image,
						padding + i * imageWidth,
						padding,
						imageWidth,
						imageHeight
					);
				}

				const newTexture = new CanvasTexture(canvas);
				newTexture.colorSpace = SRGBColorSpace;

				const bottomMaterial = new MeshBasicMaterial({
					map: newTexture,
				});

				(lampMesh.material as MeshBasicMaterial[])[3] = bottomMaterial;
				this.materials.realistic.withTexture.set(
					lamp.componentItemId,
					bottomMaterial
				);
			});
		} else {
			const color = new Color(lamp.modelColor);

			const bottomMaterial =
				this.materials.realistic.side.clone() as MeshBasicMaterial;
			bottomMaterial.color.set(color);

			lampMesh.material[3] = bottomMaterial;
		}

		lampMesh.position.y = getRendererComponentYPos(lamp, this.room);

		this.removeEdges(lamp);

		if (lamp.montageType === RendererComponentMontageType.SLIGN)
			this.createSligns(lamp);
	}

	private removeEdges(lamp: Lamp) {
		this.lineManager.removeLines(`${ObjectTypeName.LAMP}-${lamp.id}`);
	}

	private createEdges(lamp: Lamp) {
		const { height, length } = lamp;

		const [p1, p2, p3, p4] = lamp.getAreaPoints(length);

		const opts =
			lamp.selected || lamp.isShadow
				? {
						...LINE_OPTIONS,
						...LINE_DASHED_OPTIONS,
						userData: { animated: true },
						color: lamp.isShadow
							? LAMP_SHADOW_LINE_COLOR
							: LAMP_SELECTED_LINE_COLOR,
				  }
				: LINE_OPTIONS;

		const startVector = new Vector3(),
			endVector = new Vector3();

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-1-1`,
			startVector.set(p1.x, this.room.componentsSlingLevel, p1.y),
			endVector.set(p4.x, this.room.componentsSlingLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-1-2`,
			startVector.set(p1.x, this.room.componentsSlingLevel + height, p1.y),
			endVector.set(p1.x, this.room.componentsSlingLevel, p1.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-1-3`,
			startVector.set(p4.x, this.room.componentsSlingLevel + height, p4.y),
			endVector.set(p4.x, this.room.componentsSlingLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-top-1`,
			startVector.set(p4.x, this.room.componentsSlingLevel + height, p4.y),
			endVector.set(p1.x, this.room.componentsSlingLevel + height, p1.y),
			opts
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-2-1`,
			startVector.set(p2.x, this.room.componentsSlingLevel, p2.y),
			endVector.set(p3.x, this.room.componentsSlingLevel, p3.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-2-2`,
			startVector.set(p2.x, this.room.componentsSlingLevel + height, p2.y),
			endVector.set(p2.x, this.room.componentsSlingLevel, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-side-2-3`,
			startVector.set(p3.x, this.room.componentsSlingLevel + height, p3.y),
			endVector.set(p3.x, this.room.componentsSlingLevel, p3.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-top-2`,
			startVector.set(p2.x, this.room.componentsSlingLevel + height, p2.y),
			endVector.set(p3.x, this.room.componentsSlingLevel + height, p3.y),
			opts
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-bottom1`,
			startVector.set(p1.x, this.room.componentsSlingLevel, p1.y),
			endVector.set(p2.x, this.room.componentsSlingLevel, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-bottom2`,
			startVector.set(p3.x, this.room.componentsSlingLevel, p3.y),
			endVector.set(p4.x, this.room.componentsSlingLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-top3`,
			startVector.set(p1.x, this.room.componentsSlingLevel + height, p1.y),
			endVector.set(p2.x, this.room.componentsSlingLevel + height, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.LAMP}-${lamp.id}-top4`,
			startVector.set(p3.x, this.room.componentsSlingLevel + height, p3.y),
			endVector.set(p4.x, this.room.componentsSlingLevel + height, p4.y),
			opts
		);
	}

	private createSligns(lamp: Lamp) {
		const { p1, p2 } = lamp.getLinePoints();

		[p1, p2].forEach((point, idx) => {
			const { x, y } = extrudePointFromLine(lamp.pos, point, -lamp.width);
			const slignVector = new Vector3(
					x,
					this.room.componentsSlingLevel + lamp.height,
					y
				),
				ceilingVecor = new Vector3(x, this.room.height, y);

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.LAMP}-${lamp.id}-slign-${idx}`,
				slignVector,
				ceilingVecor,
				SLIGN_OPTIONS
			);
		});
	}
}
