import React from 'react';
import { useMantineTheme, Flex, Divider, Text } from '@mantine/core';
import { useContent } from '@/providers/ContentProvider';
import { isDefaultLanguage, removeWhiteSpaces } from '@/utils/utilities';
import PhoneIcon from '@/assets/icons/phone.svg?react';
import MailIcon from '@/assets/icons/mail.svg?react';

const baseSlug = 'contactBoxes.';

export const ContactBoxes: React.FC = () => {
	const { getContent, language } = useContent();
	const { other } = useMantineTheme();

	return (
		<>
			<Flex mb="lg" gap="md" wrap="wrap">
				<a
					href={`tel:${removeWhiteSpaces(
						getContent(`${baseSlug}phoneNumberPolish`)
					)}`}
					className="decoration-none"
				>
					<Flex gap="sm" align="center">
						<Flex miw={48} h={48} align="center" justify="center" bg="green.0">
							<PhoneIcon />
						</Flex>
						<Text {...other.fontSizes.lg} fw={other.fontWeights.bold}>
							{getContent(`${baseSlug}phoneNumberPolish`)}
						</Text>
					</Flex>
				</a>
				<a href="mailto:kontakt@lenalight.pl" className="decoration-none">
					<Flex gap="sm" align="center">
						<Flex miw={48} h={48} align="center" justify="center" bg="green.0">
							<MailIcon />
						</Flex>
						<Text {...other.fontSizes.lg} fw={other.fontWeights.bold}>
							{getContent(`${baseSlug}emailPolish`)}
						</Text>
					</Flex>
				</a>
			</Flex>
			{!isDefaultLanguage(language) && (
				<>
					<Divider orientation="horizontal" mb="lg" />
					<Text
						tt="uppercase"
						{...other.fontSizes.xs}
						fw={other.fontWeights.bold}
						color={other.textDimmed}
						mb="sm"
					>
						{getContent(`${baseSlug}labelForeign`)}
					</Text>
					<Flex mb="lg" justify="space-between">
						<a
							href={`tel:${removeWhiteSpaces(
								getContent(`${baseSlug}phoneNumberForeign`)
							)}`}
							className="decoration-none"
						>
							<Flex gap="sm" align="center">
								<Flex
									miw={48}
									h={48}
									align="center"
									justify="center"
									bg="gray.3"
								>
									<PhoneIcon className="icon-white900" />
								</Flex>
								<Text {...other.fontSizes.lg} fw={other.fontWeights.bold}>
									{getContent(`${baseSlug}phoneNumberForeign`)}
								</Text>
							</Flex>
						</a>
						<a href="mailto:kontakt@lenalight.pl" className="decoration-none">
							<Flex gap="sm" align="center">
								<Flex
									miw={48}
									h={48}
									align="center"
									justify="center"
									bg="gray.3"
								>
									<MailIcon className="icon-white900" />
								</Flex>
								<Text {...other.fontSizes.lg} fw={other.fontWeights.bold}>
									{getContent(`${baseSlug}emailForeign`)}
								</Text>
							</Flex>
						</a>
					</Flex>
				</>
			)}
		</>
	);
};
