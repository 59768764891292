import {
	useMantineTheme,
	TextInput,
	Textarea,
	Checkbox,
	Drawer,
	Title,
	Text,
	LoadingOverlay,
	Image,
	Notification,
	Anchor,
	Flex,
	Center,
	Tooltip,
} from '@mantine/core';
import { useForm, isEmail } from '@mantine/form';
import { useAuth } from '@/providers/AuthProvider';
import { Button } from '../button/Button';
import { useMutation } from '@tanstack/react-query';
import { CreatorProject } from '@/types/creator';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import { AppPaths } from '@/configs/appPaths';
import { useClipboard } from '@mantine/hooks';
import CopyIcon from '@/assets/icons/copy.svg?react';
import ProjectApi, { Project } from '@/api/ProjectApi';

const baseSlug = 'shareProjectDrawer.';

interface Props {
	project: Project | CreatorProject | null;
	opened: boolean;
	close: () => void;
}

export const ShareProjectDrawer: React.FC<Props> = ({
	project,
	opened,
	close,
}) => {
	const { getErrorMessage, getContent, getMutationErrorMessage } = useContent();

	const { user } = useAuth();

	const { other, primaryColor, primaryShade } = useMantineTheme();

	const form = useForm({
		initialValues: {
			email: '',
			message: '',
			selfSend: false,
		},
		validate: {
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
		},
	});

	const shareMutation = useMutation({ mutationFn: ProjectApi.shareProject });

	const handleSubmit = () => {
		shareMutation.mutate({
			id: project!.id!,
			...form.values,
		});
	};

	const handleClose = () => {
		form.reset();
		shareMutation.reset();
		close();
	};

	const clipboard = useClipboard();

	return (
		<Drawer
			opened={opened}
			onClose={handleClose}
			title={
				shareMutation.isSuccess
					? getContent(`${baseSlug}title`)
					: getContent(`${baseSlug}titleSuccess`)
			}
		>
			<LoadingOverlay visible={shareMutation.isPending} />
			{!!shareMutation.error && (
				<Notification {...other.notificationProps.danger} mb="lg">
					<Text {...other.fontSizes.md} color="red.5">
						{getMutationErrorMessage(shareMutation.error)}
					</Text>
				</Notification>
			)}
			{!shareMutation.isSuccess && (
				<Title order={3} mb="sm">
					{getContent(`${baseSlug}subheader`)}
				</Title>
			)}
			<Text {...other.fontSizes.md} color={other.textDimmed} mb="lg">
				{shareMutation.isSuccess
					? getContent(`${baseSlug}infoSuccess`)
					: getContent(`${baseSlug}info`)}
			</Text>
			<Flex mb="lg" gap="md" align="center">
				<Tooltip
					opened={clipboard.copied}
					label={getContent(`${baseSlug}copySuccess`)}
				>
					<button
						type="button"
						onClick={() =>
							clipboard.copy(
								`${import.meta.env.VITE_APP_URL}${AppPaths.PROJECTS}/${
									project?.id
								}`
							)
						}
					>
						<Center>
							<CopyIcon
								width="1.5rem"
								height="1.5rem"
								cursor="pointer"
								className="svg-path-primary"
							/>
						</Center>
					</button>
				</Tooltip>
				<Anchor
					{...other.fontSizes.md}
					target="_blank"
					href={`${import.meta.env.VITE_APP_URL}${AppPaths.PROJECTS}/${
						project?.id
					}`}
					color={`${primaryColor}.${primaryShade}`}
					td="underline"
				>
					{`${import.meta.env.VITE_APP_URL}${AppPaths.PROJECTS}/${project?.id}`}
				</Anchor>
			</Flex>
			{!shareMutation.isSuccess && (
				<form onSubmit={form.onSubmit(handleSubmit)}>
					<TextInput
						{...form.getInputProps('email')}
						{...other.inputSizes.md}
						required
						type="email"
						placeholder={getContent(`${baseSlug}inputEmailPlaceholder`)}
						mb="lg"
					/>
					<Textarea
						{...form.getInputProps('message')}
						{...other.inputSizes.md}
						placeholder={getContent(`${baseSlug}inputMessagePlaceholder`)}
						mb="lg"
					/>
					{!!user && (
						<Checkbox
							{...form.getInputProps('selfSend')}
							label={getContent(`${baseSlug}checkboxSendSelf`)}
							mb="lg"
						/>
					)}
					<Button
						themes={['primary', 'arrow', 'fullWidth']}
						buttonProps={{
							type: 'submit',
						}}
					>
						{getContent(`${baseSlug}submitBtn`)}
					</Button>
				</form>
			)}
			{shareMutation.isSuccess && (
				<Image src={getContent(`${baseSlug}imgSuccess`)} />
			)}
		</Drawer>
	);
};
