import { ApplyEventHandlers, Emit, Events, On } from './Events';
import { NativeMouseEvent } from '../renderer/MouseHandler';
import { Connector, ConnectorConfig } from '@/3d/core/Connector';
import { RendererEvent } from './enums/RendererEvent';
import {
	isHorizontal,
	isRendererComponent,
	roundToHalf,
} from '@/utils/rendererUtils';
import { Lamp, LampConfig } from './Lamp';
import { Core } from './Core';
import { Charger } from './Charger';
import { Wall } from './Wall';
import { UUID, MouseButtons } from '@/types/types';
import { groupBy } from 'lodash';
import { LineHelper } from './HelperLine';
import { Label } from './Label';
import { RemoveObjectsPayload } from './ObjectRemover';
import { Node } from './Node';
import { NodeDirection } from './enums/NodeDirection';
import { ResizeDirection } from './enums/ResizeDirection';
import { Clonable } from './interfaces/Clonable';
import { MouseOver } from './interfaces/MouseOver';
import { RendererComponent, RendererObject } from './types/objects';
import { Point } from './types/point';
import { Draggable } from './interfaces/Draggable';
import { ViewType } from '@/types/creator';
import { Vector2 } from 'three';
import RENDERER_CONFIG from '@/configs/rendererConfig';

@ApplyEventHandlers
export class Room extends Core {
	constructor() {
		super();
	}

	@On(RendererEvent.ADD_LAMP)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onAddLamp(config: LampConfig) {
		if (!config.automaticConnector) {
			const automaticConnector = this.lamps.find(
				(lamp) => lamp.componentItemId === config.componentItemId
			)?.automaticConnector;

			if (automaticConnector) Object.assign(config, { automaticConnector });
		}
		this.takeSnapshot();

		const cf = {
			...config,
			connectors: [],
		};

		const roomDimensions = this.getDimensions();
		const angle =
			Math.round(roomDimensions.width) >= Math.round(roomDimensions.height)
				? 90
				: 0;
		cf.pos ??= this.getCentroid();
		cf.angle ??= angle;

		const lamp = new Lamp(cf);

		this.lamps.push(lamp);
		this.collisionDetector.addCollidable(lamp);
	}

	@On(RendererEvent.ADD_CONNECTOR)
	@Emit(RendererEvent.UPDATE_RENDERER)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	protected onAddConnector(config: ConnectorConfig) {
		this.takeSnapshot();

		const cf = {
			...config,
			lamps: [],
		};

		const roomDimensions = this.getDimensions();
		const angle =
			Math.round(roomDimensions.width) >= Math.round(roomDimensions.height)
				? 90
				: 0;

		cf.pos ??= this.getCentroid();
		cf.angle ??= angle;

		this.clickOutside();

		const connector = new Connector(cf);
		this.connectors.push(connector);
		this.collisionDetector.addCollidable(connector);
	}

	@On(RendererEvent.ADD_CHARGER)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	protected onAddCharger() {
		this.takeSnapshot();
		const charger = new Charger({
			id: crypto.randomUUID(),
			pos: this.sceneMouse,
		});

		this.chargers.push(charger);
		this.detectChargerConnections();

		this.clickOutside();
	}

	@On(RendererEvent.WALL_DRAGGING)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onWallDragging(
		wall: Wall,
		mouse: Point,
		mouseEvent: NativeMouseEvent
	) {
		if (!wall.isDragging) return;

		const angle = (wall.angle * Math.PI) / 180;
		const perpendicularAngle = angle + Math.PI / 2;

		const dx = mouse.x - wall.pos.x;
		const dy = mouse.y - wall.pos.y;

		const perpDx = Math.cos(perpendicularAngle);
		const perpDy = Math.sin(perpendicularAngle);

		const dotProduct = dx * perpDx + dy * perpDy;
		const draggingDirection = dotProduct >= 0 ? 1 : -1;

		const projectionLength = Math.abs(dotProduct) * draggingDirection;

		const projectionChange = Math.abs(projectionLength);

		const lastPos = {
			x: wall.pos.x,
			y: wall.pos.y,
		};

		wall.pos.x += projectionLength * perpDx;
		wall.pos.y += projectionLength * perpDy;

		if (this.isAnyObjectOutsideRoom()) {
			wall.pos.x = lastPos.x;
			wall.pos.y = lastPos.y;
			return;
		}

		if (!wall.resizeAdjacentWalls(projectionChange, draggingDirection)) {
			wall.pos.x = lastPos.x;
			wall.pos.y = lastPos.y;
		} else {
			this.floorNeedsUpdate = true;
		}
	}

	@On(RendererEvent.LAMP_DRAGGING)
	protected onLampDragging(
		lamp: Lamp,
		mouse: Vector2,
		mouseEvent: NativeMouseEvent
	) {
		if (!lamp.isDragging) return;

		const gridSize = RENDERER_CONFIG.GRID_SIZE,
			minDist = RENDERER_CONFIG.MIN_DIST_GRID;

		const { width, length } = lamp;

		const updatedPos = mouse.clone(),
			updatedMouse = mouse.clone();

		if (lamp.centerClickDiff) {
			updatedMouse.add(lamp.centerClickDiff);
		} else if (lamp.clickPosition) {
			const centerClickDiff = lamp.pos.clone().sub(lamp.clickPosition);

			lamp.centerClickDiff = centerClickDiff;

			updatedMouse.add(centerClickDiff);
		}

		if (mouseEvent.shiftKey) {
			updatedPos.set(
				roundToHalf(updatedMouse.x / gridSize) * gridSize +
					(((!isHorizontal(lamp.angle) ? width : length) / 2) % gridSize),
				roundToHalf(updatedMouse.y / gridSize) * gridSize +
					(((isHorizontal(lamp.angle) ? width : length) / 2) % gridSize)
			);
		} else {
			updatedPos.set(
				roundToHalf(updatedMouse.x / minDist) * minDist,
				roundToHalf(updatedMouse.y / minDist) * minDist
			);
		}

		if (updatedPos.equals(lamp.pos)) return;

		const lastPos = lamp.pos.clone();
		const posChange = updatedPos.clone().sub(lastPos);

		const isGroupDragging =
			!!lamp.groupId && this.selectedGroup === lamp.groupId;

		if (isGroupDragging) {
			const group = this.getComponentsGroup(lamp);

			this.handleGroupDragging(posChange, group);

			const isConnection = this.objectConnector.detectComponentsConnections(
				group,
				posChange
			);

			if (isConnection) this.selectGroup(lamp.groupId!);

			this.updateRenderer();
			return;
		}

		lamp.needsUpdate = true;

		const isInside = this.isObjectInsideRoom(lamp, updatedPos);

		if (!isInside) {
			const pos = lamp.getLastValidPosition(this.getPolygon(), updatedPos);
			if (!pos) return;

			lamp.pos = pos;
			this.updateRenderer();
			return;
		}

		if (!lamp.connectors.length) lamp.pos = updatedPos;

		const automaticConnector =
			this.objectConnector.detectLampAutomaticConnections(
				lamp,
				this.lamps,
				posChange
			);

		if (automaticConnector) {
			this.connectors.push(automaticConnector);

			const secondConnector =
				this.objectConnector.detectLampAutomaticConnections(
					lamp,
					this.lamps,
					posChange,
					true
				);
			if (secondConnector) this.connectors.push(secondConnector);

			Events.getInstance().emit(RendererEvent.UPDATE_OBSERVABLE_AREA);
			this.onMouseUp(lamp);
			return;
		}

		const isConnection = this.objectConnector.handleLampConnecting(
			lamp,
			posChange
		);

		if (isConnection) {
			this.onMouseUp(lamp);
			return;
		}

		const isColliding = lamp.connectors.length
			? false
			: this.collisionDetector.isColliding(lamp, updatedPos);

		if (isColliding && !!lamp.lastPosition) {
			lamp.pos = lamp.lastPosition;
			this.updateRenderer();
			return;
		}

		lamp.lastPosition = lastPos.clone();

		this.detectChargerConnections();
		this.renderLineHelpers(lamp);
	}

	@On(RendererEvent.CONNECTOR_DRAGGING)
	protected onConnectorDragging(
		connector: Connector,
		mouse: Vector2,
		mouseEvent: NativeMouseEvent
	) {
		if (!connector.isDragging) return;

		const gridSize = RENDERER_CONFIG.GRID_SIZE;
		const { width, length } = connector;

		const halfSize = (isHorizontal(connector.angle) ? length : width) / 2;
		const gridOffset = halfSize % gridSize;

		const minDist = RENDERER_CONFIG.MIN_DIST_GRID;

		const updatedMouse = mouse.clone(),
			updatedPos = mouse.clone();

		if (connector.centerClickDiff) {
			updatedMouse.add(connector.centerClickDiff);
		} else if (connector.clickPosition) {
			const centerClickDiff = connector.pos
				.clone()
				.sub(connector.clickPosition);

			connector.centerClickDiff = centerClickDiff;

			updatedMouse.add(centerClickDiff);
		}

		updatedPos.set(
			mouseEvent.shiftKey
				? roundToHalf(updatedMouse.x / gridSize) * gridSize + gridOffset
				: roundToHalf(updatedMouse.x / minDist) * minDist,
			mouseEvent.shiftKey
				? roundToHalf(updatedMouse.y / gridSize) * gridSize + gridOffset
				: roundToHalf(updatedMouse.y / minDist) * minDist
		);

		if (updatedPos.equals(connector.pos)) return;

		connector.needsUpdate = true;

		const isInside = this.isObjectInsideRoom(connector, updatedPos);

		const groupObjects = this.getRendererComponents().filter((obj) =>
			connector.isSameGroup(obj)
		);

		if (!isInside) {
			const pos = connector.getLastValidPosition(this.getPolygon(), updatedPos);

			if (!pos) return;

			const oldPos = connector.pos.clone();

			connector.pos = pos;

			const posChange = connector.pos.clone().sub(oldPos);

			connector.updateGroupPosition(posChange, groupObjects);

			this.updateRenderer();
			return;
		}

		const lastPos = connector.pos.clone();
		connector.pos = updatedPos;
		const posChange = updatedPos.clone().sub(lastPos);
		connector.updateGroupPosition(posChange, groupObjects);

		const isConnection = this.objectConnector.detectComponentsConnections(
			this.getComponentsGroup(connector),
			posChange
		);

		if (isConnection) {
			this.updateRenderer();
			return;
		}

		const isColliding = this.collisionDetector.isColliding(
			connector,
			updatedPos
		);

		if (isColliding && !!connector.lastPosition) {
			posChange.copy(connector.lastPosition.clone().sub(connector.pos));

			connector.updateGroupPosition(posChange, groupObjects);

			connector.pos = connector.lastPosition;
		} else {
			connector.lastPosition = lastPos.clone();
		}

		this.detectChargerConnections();
		this.renderLineHelpers(connector);
	}

	@On(RendererEvent.CHARGER_DRAGGING)
	protected onChargerDragging(
		charger: Charger,
		mouse: Point,
		mouseEvent: NativeMouseEvent
	) {
		// if (!charger.isDragging) return;
		// const gridSize = RENDERER_CONFIG.GRID_SIZE,
		// 	minDist = RENDERER_CONFIG.MIN_DIST_GRID;
		// const { width, length } = charger;
		// const updatedPos = {
		// 	...mouse,
		// };
		// const updatedMouse = {
		// 	...mouse,
		// };
		// if (charger.centerClickDiff) {
		// 	updatedMouse.x += charger.centerClickDiff.x;
		// 	updatedMouse.y += charger.centerClickDiff.y;
		// } else if (charger.clickPosition) {
		// 	const centerClickDiff = {
		// 		x: charger.pos.x - charger.clickPosition.x,
		// 		y: charger.pos.y - charger.clickPosition.y,
		// 	};
		// 	charger.centerClickDiff = centerClickDiff;
		// 	updatedMouse.x += centerClickDiff.x;
		// 	updatedMouse.y += centerClickDiff.y;
		// }
		// if (mouseEvent.shiftKey) {
		// 	updatedPos.x =
		// 		roundToHalf(updatedMouse.x / gridSize) * gridSize +
		// 		(((!isHorizontal(charger.angle) ? width : length) / 2) % gridSize);
		// 	updatedPos.y =
		// 		roundToHalf(updatedMouse.y / gridSize) * gridSize +
		// 		(((isHorizontal(charger.angle) ? width : length) / 2) % gridSize);
		// } else {
		// 	updatedPos.x = roundToHalf(updatedMouse.x / minDist) * minDist;
		// 	updatedPos.y = roundToHalf(updatedMouse.y / minDist) * minDist;
		// }
		// if (updatedPos.x === charger.pos.x && updatedPos.y === charger.pos.y)
		// 	return;
		// charger.needsUpdate = true;
		// const isInside = this.isObjectInsideRoom(charger, updatedPos);
		// if (!isInside) {
		// 	const pos = charger.getLastValidPosition(this.getPolygon(), updatedPos);
		// 	if (!pos) return;
		// 	charger.pos = pos;
		// 	this.updateRenderer();
		// 	return;
		// }
		// const lastPos = { ...charger.pos };
		// charger.pos = updatedPos;
		// this.detectChargerConnections();
		// charger.lastPosition = new THREE.Vector2(lastPos.x, lastPos.y);
		// this.updateRenderer();
	}

	@Emit(RendererEvent.UPDATE_RENDERER)
	protected renderLineHelpers(obj: RendererComponent) {
		const otherHelpers = this.getRendererComponents()
			.filter((otherObj) => otherObj !== obj)
			.reduce<LineHelper[]>((acc, curr) => {
				return acc.concat(curr.lineHelpers);
			}, []);

		for (const helper of obj.lineHelpers) {
			helper.overlapDetector(otherHelpers);
		}
	}

	@On(RendererEvent.SUMMARY_COMPONENT_ROW_MOUSER)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected handleSummaryHover(componentItemId?: UUID) {
		for (const object of this.getRendererComponents()) {
			if (object.isSummaryHover) {
				object.isSummaryHover = false;
				object.needsUpdate = true;
			}
			if (object.componentItemId === componentItemId) {
				object.isSummaryHover = true;
				object.needsUpdate = true;
			}
		}
	}

	@On(RendererEvent.COPY_OBJECTS)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected copyObjects(objects: Clonable[]) {
		this.clickOutside();
		this.objectDuplicator.duplicateOjects(objects);
	}

	@On(RendererEvent.REMOVE_OBJECTS)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected removeObjects(payload: RemoveObjectsPayload) {
		this.objectRemover.removeObjects(payload);
		document.body.style.cursor = 'initial';
	}

	@On(RendererEvent.SELECT_ALL_OBJECTS)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected selectAllObjects() {
		for (const obj of this.getRendererObjects()) {
			obj.selected = true;
			obj.windowPosition = this.calculatePositionRelativeToWindow(obj.pos);
		}
	}

	@On(RendererEvent.ROTATE_OBJECTS)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected rotateObjects(ids: string[]) {
		this.takeSnapshot();

		if (ids.length === 1) {
			const lamp = this.lamps.find((l) => l.id === ids[0]);
			const connector = this.connectors.find((c) => c.id === ids[0]);
			const object = lamp || connector;

			if (!object) return;

			const objectsGroup = this.getRendererComponents().filter(
				(obj) => object.isSameGroup(obj) || obj === object
			);

			for (const obj of objectsGroup) {
				obj.rotate(object.pos);
			}

			return;
		}

		const lamps = this.lamps.filter((l) => ids.includes(l.id));
		const connectors = this.connectors.filter((c) => ids.includes(c.id));

		const objects = [...lamps, ...connectors];

		for (const object of objects.filter((obj) => !obj.groupId)) {
			object.rotate();
		}

		const grouped = groupBy(
			objects.filter((obj) => !!obj.groupId),
			(obj) => obj.groupId
		);

		for (const [groupId, group] of Object.entries(grouped)) {
			for (const object of this.getRendererComponents()) {
				if (object.groupId === groupId) object.rotate(group[0].pos);
			}
		}
	}

	@On(RendererEvent.SELECT_DRAG)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected selectDrag(start: Vector2, end: Vector2) {
		const topLeft = new Vector2(
			Math.min(start.x, end.x),
			Math.min(start.y, end.y)
		);
		const topRight = new Vector2(
			Math.max(start.x, end.x),
			Math.min(start.y, end.y)
		);
		const bottomLeft = new Vector2(
			Math.min(start.x, end.x),
			Math.max(start.y, end.y)
		);
		const bottomRight = new Vector2(
			Math.max(start.x, end.x),
			Math.max(start.y, end.y)
		);

		this.areaSelection.verticies = [topLeft, topRight, bottomRight, bottomLeft];
		this.areaSelection.needsUpdate = true;

		for (const obj of this.getRendererObjects()) {
			if (!obj.isAreaHover) continue;
			obj.isAreaHover = false;
			obj.needsUpdate = true;
		}
		for (const obj of this.getObjectsInSelectionArea()) {
			obj.isAreaHover = true;
			obj.needsUpdate = true;
		}
	}

	@On(RendererEvent.SELECT_DRAG_END)
	protected selectDragEnd() {
		const hoveredComponents = this.getRendererComponents().filter(
			(obj) => obj.isAreaHover
		);

		const isWholeGroupHovered =
			this.checkIfWholeGroupSelected(hoveredComponents);

		if (isWholeGroupHovered) this.selectedGroup = hoveredComponents[0].groupId!;

		for (const obj of this.getRendererObjects()) {
			if (!obj.isAreaHover) continue;

			obj.windowPosition = this.calculatePositionRelativeToWindow(obj.pos);

			obj.isAreaHover = false;
			obj.selected = true;
		}

		this.areaSelection.verticies = [];
		this.updateRenderer();
		this.areaSelection.needsUpdate = false;
	}

	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	private mergeNode(node: Node) {
		const indexOfNode = this.nodes.indexOf(node);
		if (indexOfNode === -1) return;

		const nearestNode = this.findNearestNodeWithSameAngle(node, indexOfNode);

		if (!nearestNode) return;

		const { wallA: nodeAWallA, wallB: nodeAWallB } = node.getConnectedWalls();
		const { wallA: nodeBWallA, wallB: nodeBWallB } =
			nearestNode.getConnectedWalls();

		let sharedWall: Wall;
		let nodeARemainingWall;
		let nodeBRemainingWall;

		if (nodeAWallA === nodeBWallA || nodeAWallA === nodeBWallB) {
			sharedWall = nodeAWallA;
			nodeARemainingWall = nodeAWallB;
			nodeBRemainingWall = nodeAWallA === nodeBWallA ? nodeBWallB : nodeBWallA;
		} else {
			sharedWall = nodeAWallB;
			nodeARemainingWall = nodeAWallA;
			nodeBRemainingWall = nodeAWallB === nodeBWallA ? nodeBWallB : nodeBWallA;
		}

		nodeARemainingWall.removeNode(node);
		nodeARemainingWall.removeNode(nearestNode);
		nodeBRemainingWall.removeNode(node);
		nodeBRemainingWall.removeNode(nearestNode);

		this.collisionDetector.removeCollidable(sharedWall);
		const oldLabel = this.labels.find((label) => label.wall === sharedWall);
		this.labels.splice(this.labels.indexOf(oldLabel!), 1);
		this.walls.splice(this.walls.indexOf(sharedWall), 1);

		const newNodeAngle = node.angle === 135 ? 90 : 270;

		const jointNode = new Node({ angle: newNodeAngle });

		let isForwardDirection;
		const nIdx = this.nodes.indexOf(nearestNode);
		if (nIdx > indexOfNode) {
			isForwardDirection =
				nIdx - indexOfNode < indexOfNode + this.nodes.length - nIdx;
		} else {
			isForwardDirection =
				indexOfNode - nIdx > nIdx + this.nodes.length - indexOfNode;
		}

		const wallLengthChange = Math.sqrt(
			(sharedWall.length * sharedWall.length) / 2
		);

		if (isForwardDirection) {
			jointNode.connectWall(nodeARemainingWall, NodeDirection.Start);
			jointNode.connectWall(nodeBRemainingWall, NodeDirection.End);
		} else {
			jointNode.connectWall(nodeARemainingWall, NodeDirection.End);
			jointNode.connectWall(nodeBRemainingWall, NodeDirection.Start);
		}

		if (isForwardDirection) {
			nodeARemainingWall.resizeWall(wallLengthChange, ResizeDirection.End);
			nodeBRemainingWall.resizeWall(wallLengthChange, ResizeDirection.Start);
		} else {
			nodeARemainingWall.resizeWall(wallLengthChange, ResizeDirection.Start);
			nodeBRemainingWall.resizeWall(wallLengthChange, ResizeDirection.End);
		}

		this.nodes.splice(this.nodes.indexOf(node), 1);
		this.nodes.splice(this.nodes.indexOf(nearestNode), 1, jointNode);
	}

	@On(RendererEvent.MOUSE_LEAVE_RENDERER)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected mouseLeaveLeaveRenderer() {
		if (this.viewType !== ViewType.RECTANGULAR) return;

		document.body.style.cursor = 'initial';
		for (const obj of [
			...this.walls,
			...this.labels,
			...this.lamps,
			...this.connectors,
			...this.nodes,
		]) {
			obj.mouseOver = false;
		}
	}

	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	private splitNode(node: Node) {
		const { wallA, wallB } = node.getConnectedWalls();

		if (
			wallA.length < RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH ||
			wallB.length < RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH
		)
			return;

		wallA.removeNode(node);
		wallB.removeNode(node);

		const lengthA = wallA.length;
		const lengthB = wallB.length;

		const halfShortestLength = Math.min(lengthA, lengthB) / 2;

		const newNodeAngle = node.angle === 90 ? 135 : 225;

		const newNodeA = new Node({ angle: newNodeAngle });
		const newNodeB = new Node({ angle: newNodeAngle });

		const newWallAngle = node.getNewWallAngle(wallA.angle);

		wallA.resizeWall(-halfShortestLength, ResizeDirection.End);
		wallB.resizeWall(-halfShortestLength, ResizeDirection.Start);

		const wallALine = wallA.getLinePoints();
		const wallBLine = wallB.getLinePoints();

		const midPoint = wallALine.findMidpoint(wallBLine);

		const centerX = midPoint.x;
		const centerY = midPoint.y;

		const newWall = new Wall({
			pos: { x: centerX, y: centerY },
			length: Math.sqrt(halfShortestLength * halfShortestLength * 2),
			angle: newWallAngle,
		});
		const label = new Label(newWall);
		label.id = crypto.randomUUID();
		this.labels.push(label);

		newNodeA.connectWall(wallA, NodeDirection.Start);
		newNodeA.connectWall(newWall, NodeDirection.End);

		newNodeB.connectWall(wallB, NodeDirection.End);
		newNodeB.connectWall(newWall, NodeDirection.Start);

		const oldNodeIndex = this.nodes.indexOf(node);
		this.nodes.splice(oldNodeIndex, 1, newNodeA, newNodeB);

		const wallAIndex = this.walls.indexOf(wallA);
		this.walls.splice(wallAIndex + 1, 0, newWall);
	}

	@On(RendererEvent.OBJECT_TRANSFERING)
	@Emit(RendererEvent.UPDATE_RENDERER)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	protected onObjectDragged(config: LampConfig | ConnectorConfig) {
		let cf = {
			...config,
			pos: { x: -1000, y: -1000 },
			angle: 90,
			isShadow: true,
			isDragging: true,
			mouseOver: true,
		};

		if ('connectorType' in config) {
			const connector = new Connector({ ...cf, lamps: [] } as ConnectorConfig);
			this.connectors.push(connector);
			this.collisionDetector.addCollidable(connector);
			return;
		}

		const lamp = new Lamp({ ...cf, connectors: [] });

		this.lamps.push(lamp);
		this.collisionDetector.addCollidable(lamp);
	}

	@On(RendererEvent.DRAGGED_LEFT)
	@Emit(RendererEvent.UPDATE_RENDERER)
	@Emit(RendererEvent.UPDATE_OBSERVABLE_AREA)
	protected onDraggedLeave() {
		const draggedList = this.getRendererComponents().filter(
			(obj) => obj.isShadow
		);

		draggedList.forEach((obj) => {
			this.collisionDetector.removeCollidable(obj);
		});

		this.lamps = this.lamps.filter((lamp) => !lamp.isShadow);
		this.connectors = this.connectors.filter(
			(connector) => !connector.isShadow
		);
	}

	@On(RendererEvent.MOUSE_ENTER)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onMouseEnter(obj: MouseOver, mouseEvent: NativeMouseEvent) {
		obj.mouseOver = true;

		if (obj instanceof Node) {
			const { wallA, wallB } = obj.getConnectedWalls();

			if (
				wallA.length < RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH ||
				wallB.length < RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH
			) {
				return;
			}

			if (obj.angle !== 90 && obj.angle !== 270) {
				const indexOfNode = this.nodes.indexOf(obj);
				if (indexOfNode === -1) return;

				const nearestNode = this.findNearestNodeWithSameAngle(obj, indexOfNode);

				if (!nearestNode) return;

				nearestNode.mouseOver = true;
			}
		}
	}

	@On(RendererEvent.MOUSE_LEAVE)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onMouseLeave(obj: MouseOver) {
		document.body.style.cursor = 'default';
		obj.mouseOver = false;

		if (obj instanceof Node) {
			if (obj.angle !== 90 && obj.angle !== 270) {
				const indexOfNode = this.nodes.indexOf(obj);
				if (indexOfNode === -1) return;

				const nearestNode = this.findNearestNodeWithSameAngle(obj, indexOfNode);

				if (!nearestNode) return;

				nearestNode.mouseOver = false;
			}
		}
	}

	@On(RendererEvent.DRAG_TO_SCENE_END)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected dragToSceneEnd() {
		for (const object of this.getRendererComponents()) {
			object.needsUpdate = object.isShadow;
			object.isShadow = false;
			object.isDragging = false;
		}
	}

	@On(RendererEvent.MOUSE_UP)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onMouseUp(obj: Draggable) {
		obj.isDragging = false;
		if ('needsUpdate' in obj) obj.needsUpdate = true;

		for (const obj of this.getRendererObjects()) {
			obj.clickPosition = undefined;
			obj.centerClickDiff = undefined;

			if ('lineHelpers' in obj)
				for (const helperLine of obj.lineHelpers) {
					helperLine.overlapingHelper = null;
				}
		}
	}

	@On(RendererEvent.MOUSE_DOWN)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onMouseDown(
		obj: RendererObject,
		mouseEvent: NativeMouseEvent,
		mouse: Vector2
	) {
		const isObjRendererComponent = isRendererComponent(obj);

		if (!(isObjRendererComponent && obj.groupId === this.selectedGroup))
			this.clickOutside();

		this.takeSnapshot();

		if (obj instanceof Label) {
			obj.selected = true;
			obj.windowPosition = this.calculatePositionRelativeToWindow(obj.pos);
			return;
		}

		const isRendererItem =
			obj instanceof Lamp || obj instanceof Connector || obj instanceof Charger;

		if (isRendererItem && mouseEvent.button === MouseButtons.RIGHT) {
			obj.selected = true;
			obj.windowPosition = this.calculatePositionRelativeToWindow(obj.pos);
			return;
		}

		if (isObjRendererComponent) {
			obj.clickPosition = mouse;

			const otherHelpers = this.getRendererComponents()
				.filter((otherLamp) => otherLamp !== obj)
				.reduce<LineHelper[]>((acc, curr) => {
					return acc.concat(curr.lineHelpers);
				}, []);

			for (const helper of obj.lineHelpers) {
				helper.overlapDetector(otherHelpers);
			}
		}

		if ('isDragging' in obj) {
			obj.isDragging = true;
			return;
		}

		if (obj instanceof Node) {
			if ([90, 270].includes(obj.angle)) {
				this.splitNode(obj);
			} else {
				this.mergeNode(obj);
			}
			this.floorNeedsUpdate = true;
		}
	}

	@On(RendererEvent.CLICK_ON_EMPTY_SPACE)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected onEmptySpaceClick() {
		this.clickOutside();
	}

	@On(RendererEvent.SELECT_WHOLE_GROUP)
	@Emit(RendererEvent.UPDATE_RENDERER)
	protected selectGroup(groupId: UUID) {
		this.selectedGroup = groupId;

		const group = this.getRendererComponents().filter(
			(c) => c.groupId === groupId
		);

		for (const obj of group) {
			obj.selected = true;
			obj.needsUpdate = true;
			obj.windowPosition = this.calculatePositionRelativeToWindow(obj.pos);
		}
	}
}
