import {
	useContext,
	ReactNode,
	useLayoutEffect,
	createContext,
	useState,
} from 'react';
import { useLocalStorage, useSessionStorage } from '@mantine/hooks';
import { User } from '@/types/user';
import {
	AvailableSocials,
	LoginValues,
	SubmitStatus,
} from '@/components/views/unauthenticated/login/Login';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorCodes, isAppError } from '@/utils/utilities';
import { ErrorCode } from './ContentProvider';
import { AxiosError } from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import httpClient from '@/api/httpClient';
import Cookies from 'js-cookie';

type LoginResponse =
	| { status: SubmitStatus.SUCCESS }
	| { status: SubmitStatus.ERROR; stack: (ErrorCode | string)[] };

interface TokenPayload extends JwtPayload {
	user: User;
}

interface Context {
	user: User | null;
	changeUser: (newUser?: User | null) => void;
	upatedUser: (data: Partial<User>) => void;
	login: (values: LoginValues) => Promise<LoginResponse>;
	loginWithSocials: (values: {
		idToken: string;
		remember: boolean;
		type: AvailableSocials;
	}) => Promise<LoginResponse>;
	logout: () => void;
}

const AuthContext = createContext<Context>(null!);

export const useAuth = () => useContext(AuthContext);

const STORAGE_KEY = 'refreshToken';

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const queryClient = useQueryClient();

	const [user, setUser] = useLocalStorage<User | null>({
		key: 'user',
		defaultValue: null,
		getInitialValueInEffect: false,
	});

	const [sessionRefreshToken, setSessionRefreshToken, clearSessionToken] =
		useSessionStorage<string | undefined>({
			key: STORAGE_KEY,
			defaultValue: Cookies.get(STORAGE_KEY),
			getInitialValueInEffect: false,
		});

	const [refreshTimeout, setRefreshTimeout] = useState<number>();

	useLayoutEffect(() => {
		loginWithResfreshToken(sessionRefreshToken);
	}, []);

	const loginWithResfreshToken = async (refresh?: string) => {
		if (!refresh) {
			logout();
			return;
		}

		try {
			const response = await UnauthenticatedApi.refreshToken(refresh);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			const remember = !!Cookies.get(STORAGE_KEY);

			setAuth(decoded, accessToken, refreshToken, remember);
		} catch (error: any) {
			console.error(error);
			if (error.code !== AxiosError.ECONNABORTED) {
				setUser(null);
				eradicateRefreshToken();
			}
		}
	};

	const refreshTokenWhenExpire = (token: TokenPayload, refresh: string) => {
		const tokenLifeSpan = token.iat && token.exp ? token.exp - token.iat : 0;
		// Refresh 5 sec before token expire
		const refreshTimeout = (tokenLifeSpan - 5) * 1000;

		const id = setTimeout(() => {
			loginWithResfreshToken(refresh);
		}, refreshTimeout);

		setRefreshTimeout(id);
	};

	const login: Context['login'] = async ({ email, password, remember }) => {
		try {
			const response = await UnauthenticatedApi.login({ email, password });

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);
			setAuth(decoded, accessToken, refreshToken, remember);

			return { status: SubmitStatus.SUCCESS };
		} catch (error) {
			const stack = isAppError(error) ? error.stack : [ErrorCode.GENERIC];
			return { stack, status: SubmitStatus.ERROR };
		}
	};

	const loginWithSocials: Context['loginWithSocials'] = async ({
		idToken,
		remember,
		type,
	}) => {
		try {
			const response =
				type === 'google'
					? await UnauthenticatedApi.loginWithGoogle(idToken)
					: await UnauthenticatedApi.loginWithFacebook(idToken);

			const { accessToken, refreshToken } = response.data;

			const decoded = jwtDecode<TokenPayload>(accessToken);

			setAuth(decoded, accessToken, refreshToken, remember);

			return { status: SubmitStatus.SUCCESS };
		} catch (error) {
			const message = getErrorCodes(error);
			return { stack: message, status: SubmitStatus.ERROR };
		}
	};

	const logout: Context['logout'] = () => {
		queryClient.cancelQueries();
		eradicateRefreshToken();
		httpClient.defaults.headers.common['Authorization'] = '';
		setUser(null);
		queryClient.clear();
		clearTimeout(refreshTimeout);
	};

	const eradicateRefreshToken = () => {
		clearSessionToken();
		Cookies.remove(STORAGE_KEY);
	};

	const saveRefreshToken = (refreshToken: string) => {
		setSessionRefreshToken(refreshToken);
		Cookies.set(STORAGE_KEY, refreshToken, {
			secure: true,
			expires: 360,
		});
	};

	const setAuth = (
		decoded: TokenPayload,
		accessToken: string,
		refreshToken: string,
		remember = true
	) => {
		const { user } = decoded;

		if (user.userType !== 'user') throw new Error('Admins not allowed');

		setAuthHeader(accessToken);

		if (remember) saveRefreshToken(refreshToken);
		else setSessionRefreshToken(refreshToken);

		refreshTokenWhenExpire(decoded, refreshToken);

		setUser(user);
	};

	const changeUser: Context['changeUser'] = (newUser = null) =>
		setUser(newUser);

	const upatedUser: Context['upatedUser'] = (data) =>
		setUser((prev) => (prev ? { ...prev, ...data } : null));

	return (
		<AuthContext.Provider
			value={{
				user,
				changeUser,
				upatedUser,
				login,
				loginWithSocials,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

function setAuthHeader(token: string) {
	httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
