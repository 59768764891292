import React, { useEffect } from 'react';
import { useAuth } from '@/providers/AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from '@/components/partials/loading/Loading';
import { useMutation } from '@tanstack/react-query';
import UserApi from '@/api/UserApi';

export const EmailConfirmation: React.FC = () => {
	const { user, upatedUser } = useAuth();

	const token = useParams().token as string;

	const navigate = useNavigate();

	const confirmMutation = useMutation({
		mutationFn: UserApi.confirmEmailChange,
		onSuccess: () => {
			if (user) upatedUser({ email: user.pendingEmail as string });
			navigate('/');
		},
	});

	useEffect(() => {
		confirmMutation.mutate(token);
	}, []);

	return <Loading />;
};
