import {
	LineMaterial,
	LineMaterialParameters,
} from 'three/examples/jsm/lines/LineMaterial';
import { Loader } from './Loader';
import {
	Material,
	MaterialParameters,
	MeshBasicMaterial,
	MeshBasicMaterialParameters,
	MeshStandardMaterial,
	MeshStandardMaterialParameters,
	RepeatWrapping,
	ShaderMaterial,
	ShaderMaterialParameters,
	Vector2,
} from 'three';

import metal from '@/assets/textures/concrete-metallic.png';
import albedo from '@/assets/textures/concrete-albedo.png';
import normal from '@/assets/textures/concrete-normal.png';
import roughness from '@/assets/textures/concrete-roughness.png';

const metalnessMap = Loader.loadTexture(metal),
	map = Loader.loadTexture(albedo),
	normalMap = Loader.loadTexture(normal),
	roughnessMap = Loader.loadTexture(roughness);

for (const m of [metalnessMap, map, normalMap, roughnessMap]) {
	m.wrapS = RepeatWrapping;
	m.wrapT = RepeatWrapping;
	m.repeat.set(4, 4);
}

export const concreteMaterial = new MeshStandardMaterial({
	metalnessMap,
	map,
	normalMap,
	roughnessMap,
	polygonOffset: true,
	polygonOffsetFactor: 1,
	polygonOffsetUnits: 1,
});

export enum MaterialType {
	MESH_BASIC = 'MeshBasicMaterial',
	MESH_STANDARD = 'MeshStandardMaterial',
	SHADER = 'ShaderMaterial',
	LINE = 'LineMaterial2',
}

export class MaterialFactory {
	static getMaterial(
		type: MaterialType,
		options:
			| MaterialParameters
			| ShaderMaterialParameters
			| MeshBasicMaterialParameters
			| MeshStandardMaterialParameters
			| LineMaterialParameters
	): Material {
		let material: Material;

		switch (type) {
			case MaterialType.MESH_BASIC:
				material = new MeshBasicMaterial(options);
				break;
			case MaterialType.MESH_STANDARD:
				material = new MeshStandardMaterial(options);
				break;
			case MaterialType.SHADER:
				material = new ShaderMaterial(options);
				break;
			case MaterialType.LINE:
				material = new LineMaterial(options as LineMaterialParameters);
				break;
			default:
				throw new Error(`Unknown material type: ${type}`);
		}

		return material;
	}

	static getTextureMaterial(
		path: string,
		tiling: Vector2,
		options?: MeshStandardMaterialParameters
	) {
		const map = Loader.loadTexture(path);

		map.wrapS = RepeatWrapping;
		map.wrapT = RepeatWrapping;
		map.repeat = tiling;

		const material = new MeshStandardMaterial({
			map,
			...options,
		});

		return material;
	}
}
