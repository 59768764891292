import { Room } from '../core/Room';
import {
	BackSide,
	BufferGeometry,
	Group,
	Mesh,
	MeshBasicMaterial,
	Vector3,
} from 'three';

export class SelectionAreaRenderer {
	private room: Room;
	private meshGroup: Group;
	private material = new MeshBasicMaterial({
		color: 0x007f6d,
		opacity: 0.2,
		transparent: true,
		side: BackSide,
	});

	constructor(room: Room, meshGroup: Group) {
		this.room = room;
		this.meshGroup = meshGroup;
	}

	drawSelectionArea() {
		if (!this.room.areaSelection.needsUpdate) return;

		for (const child of this.meshGroup.children) {
			if (child.name === 'selectionArea') {
				this.meshGroup.remove(child);
				(child as Mesh).geometry.dispose();
			}
		}

		const vertices: Vector3[] = [];
		const indices: number[] = [];

		for (
			let index = 0;
			index < this.room.areaSelection.verticies.length;
			index++
		) {
			const point = this.room.areaSelection.verticies[index];

			vertices.push(new Vector3(point.x, 300, point.y));
			if (index < this.room.areaSelection.verticies.length - 2) {
				indices.push(0, index + 1, index + 2);
			}
		}

		const geometry = new BufferGeometry().setFromPoints(vertices);
		geometry.setIndex(indices);

		const selectionAreaMesh = new Mesh(geometry, this.material);
		selectionAreaMesh.name = 'selectionArea';
		this.meshGroup.add(selectionAreaMesh);
	}
}
