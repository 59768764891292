import React from 'react';
import { Loader } from './partials/Loader';
import styles from './Loading.module.scss';

interface Props {
	fullHeight?: boolean;
}

export const Loading: React.FC<Props> = ({ fullHeight }) => {
	return (
		<div
			className={styles.container}
			style={fullHeight ? { height: '100vh' } : {}}
		>
			<Loader />
		</div>
	);
};
