import React, { MouseEventHandler } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Anchor, Divider, Flex, Title, useMantineTheme } from '@mantine/core';
import { NavLink } from '../navlink/NavLink';
import { LanguageSelect } from './partials/LanguageSelect';
import { HamburgerMenu } from './partials/HamburgerMenu';
import { useAuth } from '@/providers/AuthProvider';
import { AppPaths } from '@/configs/appPaths';
import { useModals } from '@mantine/modals';
import { Button } from '../button/Button';
import { useContent } from '@/providers/ContentProvider';
import Logo from '@/assets/images/logo.svg?react';
import APP_CONFIG from '@/configs/appConfig';
import styles from './Navbar.module.scss';

const baseSlug = 'navbar.';

export const Navbar: React.FC = () => {
	const { user, logout } = useAuth();

	const { getContent } = useContent();

	const { pathname } = useLocation();

	const { other } = useMantineTheme();

	const navigate = useNavigate();

	const handleLogout: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();
		logout();
		navigate(`/${AppPaths.LOGIN}`);
	};

	const isCreator = pathname.includes(AppPaths.CREATOR);

	const modals = useModals();

	const openConfirmationModal: MouseEventHandler<HTMLAnchorElement> = (e) => {
		e.preventDefault();

		const handleClick = () => {
			const click = new MouseEvent('click');
			e.target.dispatchEvent(click);
		};

		const modal = modals.openModal({
			size: APP_CONFIG.MODAL_WIDTH,
			children: (
				<>
					<Title order={2} ta="center" mb="lg">
						{getContent(`creator.exitModalHeader`)}
					</Title>
					<Flex gap="lg" className={styles.modalButtons}>
						<Button
							size="sm"
							themes={['primary', 'fullWidth']}
							buttonProps={{
								type: 'button',
								onClick: () => modals.closeModal(modal),
							}}
						>
							{getContent(`creator.exitModalCancel`)}
						</Button>
						<Button
							size="sm"
							themes={['secondary', 'fullWidth']}
							buttonProps={{
								type: 'button',
								onClick: handleClick,
							}}
						>
							{getContent(`creator.exitModalConfirm`)}
						</Button>
					</Flex>
				</>
			),
		});
	};

	return (
		<nav id="navbar" className={styles.navbar}>
			<div className={styles.left}>
				<Link className={styles.logoConainer} to="/">
					<Logo />
				</Link>
				<Divider className={styles.homePageAnchor} orientation="vertical" />
				<Anchor
					{...other.fontSizes.lg}
					className={styles.homePageAnchor}
					href="https://lenalighting.pl/"
					color={other.textDimmed}
					onClick={isCreator ? openConfirmationModal : undefined}
				>
					{getContent(`${baseSlug}homePage`)}
				</Anchor>
			</div>
			<div className={styles.right}>
				{user && (
					<NavLink to={AppPaths.PROJECTS} end>
						{getContent(`${baseSlug}projects`)}
					</NavLink>
				)}
				{user && (
					<NavLink to={AppPaths.ACCOUNT}>
						{getContent(`${baseSlug}account`)}
					</NavLink>
				)}
				{user && (
					<NavLink to={`${AppPaths.CREATOR}/${AppPaths.LAYOUT}`}>
						{getContent(`${baseSlug}creator`)}
					</NavLink>
				)}
				<NavLink to={AppPaths.CONTACT}>
					{getContent(`${baseSlug}contact`)}
				</NavLink>
				{!user && (
					<NavLink to={AppPaths.LOGIN}>
						{getContent(`${baseSlug}login`)}
					</NavLink>
				)}
				{user && (
					<NavLink onClick={handleLogout} to="">
						{getContent(`${baseSlug}logout`)}
					</NavLink>
				)}
				<div className={styles.selectContainer}>
					<Divider orientation="vertical" />
					<LanguageSelect />
				</div>
			</div>
			<HamburgerMenu baseSlug={baseSlug} />
		</nav>
	);
};
