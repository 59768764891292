import React from 'react';
import { useMantineTheme, Divider, Text } from '@mantine/core';
import styles from './PhonePrefix.module.scss';

interface Props {
	prefix?: string;
}

export const PhonePrefix: React.FC<Props> = ({ prefix }) => {
	const theme = useMantineTheme();

	return (
		<div className={styles.container}>
			<Text color="dark.6" {...theme.other.fontSizes.lg}>
				{prefix}
			</Text>
			<Divider orientation="vertical" />
		</div>
	);
};
