import { Wall, WallConfig } from '../Wall';
import { Label, LabelConfig } from '../Label';
import { NodeConfig, Node } from '../Node';
import { Lamp, LampConfig } from '../Lamp';
import { Connector, ConnectorConfig } from '../Connector';
import { Charger, ChargerConfig } from '../Charger';

export enum ObjectTypeName {
	WALL = 'wall',
	LABEL = 'label',
	NODE = 'node',
	LAMP = 'lamp',
	CONNECTOR = 'connector',
	CHARGER = 'charger',
}

export type WallObject = {
	object: ObjectTypeName.WALL;
	param: WallConfig;
};

export type LabelObject = {
	object: ObjectTypeName.LABEL;
	param: LabelConfig;
};

export type NodeObject = {
	object: ObjectTypeName.NODE;
	param: NodeConfig;
};

export type LampObject = {
	object: ObjectTypeName.LAMP;
	param: LampConfig;
};

export type ConnectorObject = {
	object: ObjectTypeName.CONNECTOR;
	param: ConnectorConfig;
};

export type ChargerObject = {
	object: ObjectTypeName.CHARGER;
	param: ChargerConfig;
};

export type MeshObject =
	| WallObject
	| LabelObject
	| NodeObject
	| LampObject
	| ConnectorObject
	| ChargerObject;

export type RendererObject = Wall | Label | Node | Lamp | Connector | Charger;

export type RendererComponent = Lamp | Connector;
export type RendererItem = RendererComponent | Charger;

export enum RendererComponentMontageType {
	SLIGN,
	CEILING,
}
