import React from 'react';
import { useWindowScroll } from '@mantine/hooks';
import { Affix, Transition } from '@mantine/core';
import { Button } from '../button/Button';
import ArrowUpIcon from '@/assets/icons/arrowUp.svg?react';
import styles from './ScrollTop.module.scss';

export const ScrollTop: React.FC = () => {
	const [scroll, scrollTo] = useWindowScroll();

	const scrollToTop = () => scrollTo({ y: 0 });

	return (
		<Affix position={{ bottom: 20, right: 20 }} zIndex={50}>
			<Transition transition="slide-up" mounted={scroll.y > 600}>
				{(transitionStyles) => (
					<div style={transitionStyles}>
						<Button
							themes={['primary', 'iconOnly']}
							size="sm"
							buttonProps={{
								className: styles.button,
								onClick: scrollToTop,
							}}
						>
							<ArrowUpIcon />
						</Button>
					</div>
				)}
			</Transition>
		</Affix>
	);
};
