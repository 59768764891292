import { Room } from '../core/Room';
import { MeshManager } from './MeshManager';
import { Connector } from '@/3d/core/Connector';
import { ConnectorType } from '@/api/ComponentApi';
import {
	getFarthesPoint,
	getRendererComponentYPos,
} from '@/utils/rendererUtils';
import { HELPER_LINE_COLOR, HELPER_LINE_WIDTH } from '../core/HelperLine';
import { ObjectTypeName } from '../core/types/objects';
import { ViewType } from '@/types/creator';
import { Loader } from './Loader';
import { MaterialFactory, MaterialType } from './MaterialFactory';
import textureHoveredPng from '@/assets/textures/connectorHovered.png';
import texturePng from '@/assets/textures/connector.png';
import * as THREE from 'three';

const LINE_OPTIONS = {
	color: 0xabb1ba,
	linewidth: 0.001,
	dashed: false,
};
const LINE_DASHED_OPTIONS = {
	dashed: true,
	dashSize: 5,
	gapSize: 5,
};
const CONNECTOR_SELECTED_LINE_COLOR = 0xffcd4c;
const CONNECTOR_SHADOW_LINE_COLOR = 0x07353f;

const texture = Loader.loadTexture(texturePng);
texture.repeat.set(0.3, 0.3);
texture.needsUpdate = true;
const textureHovered = Loader.loadTexture(textureHoveredPng);
textureHovered.repeat.set(0.3, 0.3);
textureHovered.needsUpdate = true;

const CONNECTOR_COLOR = 0xf2f5f8;

export class ConnectorManager extends MeshManager {
	private connectorMeshes: Map<Connector, THREE.Mesh[]> = new Map();
	private materials = {
		normal: {
			side: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: CONNECTOR_COLOR,
				side: THREE.DoubleSide,
				map: texture,
			}),
		},
		hover: {
			side: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				side: THREE.DoubleSide,
				map: textureHovered,
			}),
		},
	};

	constructor(
		protected override room: Room,
		protected override meshGroup: THREE.Group
	) {
		super(room, meshGroup);
	}

	update() {
		this.removeUnusedMeshes(
			this.connectorMeshes,
			new Set(this.room.connectors),
			ObjectTypeName.CONNECTOR
		);

		for (const connector of this.room.connectors) {
			if (!connector.needsUpdate) continue;
			connector.needsUpdate = false;

			const mesh = this.connectorMeshes.get(connector);

			if (!mesh) {
				this.createMesh(connector);
			} else {
				this.updateMesh(connector, mesh);
			}
		}
	}

	private getMaterial(connector: Connector) {
		const isHoverMaterial =
			connector.isAreaHover || connector.isDragging || connector.isSummaryHover;

		const material = isHoverMaterial
			? this.materials.hover.side
			: this.materials.normal.side;

		return material;
	}

	private createLineHelpersMeshes(connector: Connector) {
		if (connector.selected || this.room.viewType !== ViewType.RECTANGULAR) {
			this.lineManager.removeLines(
				`${ObjectTypeName.CONNECTOR}-${connector.id}-helper`
			);
			return;
		}

		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		for (let idx = 0; idx < connector.lineHelpers.length; idx++) {
			const helper = connector.lineHelpers[idx];
			const y = this.room.componentsSlingLevel + connector.height;

			const options = {
				color: !!helper.overlapingHelper ? HELPER_LINE_COLOR : 0xffffff,
				transparent: true,
				opacity: Number(!!helper.overlapingHelper),
				linewidth: HELPER_LINE_WIDTH,
			};

			const start = helper.endpoints.start;
			const end = helper.overlapingHelper
				? getFarthesPoint(
						start,
						helper.overlapingHelper.endpoints.start,
						helper.overlapingHelper.endpoints.end
				  )
				: helper.endpoints.end;

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.CONNECTOR}-${connector.id}-helper-${idx}`,
				startVector.set(start.x, y, start.y),
				endVector.set(end.x, y, end.y),
				options
			);
		}
	}

	private createMesh(connector: Connector) {
		const points = connector.getAreaPoints();

		const shapePoints: THREE.Vector3[] = [];

		for (const p of points) {
			shapePoints.push(
				new THREE.Vector3(
					p.x,
					this.room.componentsSlingLevel + connector.height,
					p.y
				)
			);
		}

		for (const p of points) {
			shapePoints.push(
				new THREE.Vector3(p.x, this.room.componentsSlingLevel, p.y)
			);
		}

		const material = this.getMaterial(connector);

		const geometryTop = new THREE.BufferGeometry().setFromPoints(shapePoints);
		const geometrySide = new THREE.BufferGeometry().setFromPoints(shapePoints);
		const geometryBottom = new THREE.BufferGeometry().setFromPoints(
			shapePoints
		);

		const [indices, indicesSides, indicensBottom] = this.getIndices(connector);

		let xValues = shapePoints.map((v) => v.x);
		let zValues = shapePoints.map((v) => v.z);

		let minX = Math.min(...xValues);
		let maxX = Math.max(...xValues);
		let minZ = Math.min(...zValues);
		let maxZ = Math.max(...zValues);

		let uvs = shapePoints
			.map((v) => {
				return new THREE.Vector2(
					(v.x - minX) / (maxX - minX),
					(v.z - minZ) / (maxZ - minZ)
				);
			})
			.reduce<number[]>((acc, v) => {
				acc.push(v.x, v.y);
				return acc;
			}, []);

		geometryTop.setIndex(indices);
		geometryBottom.setIndex(indicensBottom);
		geometrySide.setIndex(indicesSides);

		geometryTop.setAttribute(
			'uv',
			new THREE.Float32BufferAttribute(uvs.flat(), 2)
		);
		geometryBottom.setAttribute(
			'uv',
			new THREE.Float32BufferAttribute(uvs.flat(), 2)
		);
		geometrySide.setAttribute(
			'uv',
			new THREE.Float32BufferAttribute(uvs.flat(), 2)
		);
		const meshTop = new THREE.Mesh(geometryTop, material);
		const meshBottom = new THREE.Mesh(geometryBottom, material);
		const meshSides = new THREE.Mesh(geometrySide, material);

		this.meshGroup.add(meshTop);
		this.meshGroup.add(meshBottom);
		this.meshGroup.add(meshSides);
		this.connectorMeshes.set(connector, [meshTop, meshSides, meshBottom]);
		this.createConnectorEdges(connector, shapePoints);
		this.createLineHelpersMeshes(connector);
	}

	private createConnectorEdges(
		connector: Connector,
		shapePoints: THREE.Vector3[]
	) {
		const opts =
			connector.selected || connector.isShadow
				? {
						...LINE_OPTIONS,
						...LINE_DASHED_OPTIONS,
						userData: { animated: true },
						color: connector.isShadow
							? CONNECTOR_SHADOW_LINE_COLOR
							: CONNECTOR_SELECTED_LINE_COLOR,
				  }
				: LINE_OPTIONS;

		const halfLength = Math.floor(shapePoints.length / 2);
		const topPoints = shapePoints.slice(0, halfLength);
		const bottomPoints = shapePoints.slice(halfLength);

		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		for (let i = 0; i < halfLength; i++) {
			let startTopPoint = topPoints[i];
			let endTopPoint = topPoints[(i + 1) % halfLength];

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.CONNECTOR}-${connector.id}-top-line-${i}`,
				startVector.set(startTopPoint.x, startTopPoint.y, startTopPoint.z),
				endVector.set(endTopPoint.x, endTopPoint.y, endTopPoint.z),
				opts
			);

			let startBottomPoint = bottomPoints[i];
			let endBottomPoint = bottomPoints[(i + 1) % halfLength];

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.CONNECTOR}-${connector.id}-bottom-line-${i}`,
				startVector.set(
					startBottomPoint.x,
					startBottomPoint.y,
					startBottomPoint.z
				),
				endVector.set(endBottomPoint.x, endBottomPoint.y, endBottomPoint.z),
				opts
			);

			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.CONNECTOR}-${connector.id}-vertical-line-${i}`,
				startVector.set(startTopPoint.x, startTopPoint.y, startTopPoint.z),
				endVector.set(
					startBottomPoint.x,
					startBottomPoint.y,
					startBottomPoint.z
				),
				opts
			);
		}
	}

	private getIndices(connector: Connector) {
		switch (connector.connectorType) {
			case ConnectorType.L:
				return [
					[0, 1, 4, 5, 0, 4, 4, 1, 2, 2, 3, 4],
					[
						0, 11, 6, 0, 5, 11, 0, 7, 1, 0, 6, 7, 1, 8, 2, 1, 7, 8, 2, 9, 3, 2,
						8, 9, 3, 9, 10, 4, 3, 10, 4, 10, 5, 5, 10, 11,
					],
					[6, 7, 10, 11, 6, 10, 10, 7, 8, 8, 9, 10],
				];
			case ConnectorType.T:
				return [
					[0, 1, 2, 0, 2, 7, 3, 6, 5, 5, 4, 3],
					[
						0, 8, 1, 8, 9, 1, 1, 9, 10, 10, 2, 1, 2, 10, 3, 3, 10, 11, 3, 11, 4,
						4, 12, 11, 4, 12, 5, 5, 13, 12, 5, 13, 6, 6, 14, 13, 6, 14, 7, 7,
						15, 14, 7, 15, 0, 0, 8, 15,
					],
					[8, 9, 10, 8, 10, 15, 11, 14, 13, 13, 12, 11],
				];
			case ConnectorType.X:
				return [
					[0, 1, 2, 0, 2, 11, 3, 4, 9, 9, 10, 3, 5, 6, 8, 8, 7, 6],
					[
						0, 1, 12, 13, 12, 1, 1, 2, 13, 13, 14, 2, 2, 3, 14, 14, 15, 3, 3, 4,
						15, 15, 16, 4, 4, 5, 16, 16, 17, 5, 5, 6, 17, 17, 18, 6, 6, 7, 18,
						18, 19, 7, 7, 8, 19, 19, 20, 8, 8, 9, 20, 20, 21, 9, 9, 10, 21, 21,
						22, 10, 10, 11, 22, 22, 23, 11, 11, 0, 23, 23, 12, 0, 12, 13, 14,
						12, 14, 23, 15, 16, 21, 21, 22, 15, 17, 18, 20, 20, 19, 18,
					],
					[
						12, 13, 14, 12, 14, 23, 15, 16, 21, 21, 22, 15, 17, 18, 20, 20, 19,
						18,
					],
				];
			case ConnectorType.I:
				return [
					[0, 1, 2, 0, 2, 3],
					[
						0, 4, 1, 1, 4, 5, 1, 5, 2, 2, 5, 6, 2, 6, 3, 3, 6, 7, 3, 7, 0, 0, 7,
						4,
					],
					[4, 5, 6, 4, 6, 7],
				];
			case ConnectorType.SQUARE:
				return [
					[0, 1, 2, 0, 2, 3],
					[
						0, 4, 1, 1, 4, 5, 1, 5, 2, 2, 5, 6, 2, 6, 3, 3, 6, 7, 3, 7, 0, 0, 7,
						4,
					],
					[4, 5, 6, 4, 6, 7],
				];
		}
	}

	private updateMesh(connector: Connector, connectorMeshes: THREE.Mesh[]) {
		this.room.viewType === ViewType.FIRST_PERSON
			? this.updateMeshRealistic(connector, connectorMeshes)
			: this.updateMeshSimplified(connector, connectorMeshes);
	}

	private updateMeshSimplified(
		connector: Connector,
		connectorMeshes: THREE.Mesh[]
	) {
		const points = connector.getAreaPoints();
		const shapePoints: THREE.Vector3[] = [];

		for (const point of points) {
			shapePoints.push(
				new THREE.Vector3(
					point.x,
					this.room.componentsSlingLevel + connector.height,
					point.y
				)
			);
		}
		for (const point of points) {
			shapePoints.push(
				new THREE.Vector3(point.x, this.room.componentsSlingLevel, point.y)
			);
		}

		const material = this.getMaterial(connector);

		for (let index = 0; index < connectorMeshes.length; index++) {
			const mesh = connectorMeshes[index];

			const newGeometry = new THREE.BufferGeometry().setFromPoints(shapePoints);
			const [indices, indicesSides, indicesBottom] = this.getIndices(connector);

			let xValues = shapePoints.map((v) => v.x);
			let zValues = shapePoints.map((v) => v.z);

			let minX = Math.min(...xValues);
			let maxX = Math.max(...xValues);
			let minZ = Math.min(...zValues);
			let maxZ = Math.max(...zValues);

			let uvs = shapePoints
				.map((v) => {
					return new THREE.Vector2(
						(v.x - minX) / (maxX - minX),
						(v.z - minZ) / (maxZ - minZ)
					);
				})
				.reduce<number[]>((acc, v) => {
					acc.push(v.x, v.y);
					return acc;
				}, []);

			newGeometry.setIndex(
				index === 0 ? indices : index === 1 ? indicesBottom : indicesSides
			);
			newGeometry.setAttribute(
				'uv',
				new THREE.Float32BufferAttribute(uvs.flat(), 2)
			);

			mesh.geometry.dispose();
			mesh.geometry = newGeometry;
			mesh.material = material;
			mesh.position.set(0, 0, 0);
		}

		this.createConnectorEdges(connector, shapePoints);
		this.createLineHelpersMeshes(connector);
	}

	private updateMeshRealistic(
		connector: Connector,
		connectorMeshes: THREE.Mesh[]
	) {
		const points = connector.getAreaPoints();
		const shapePoints: THREE.Vector3[] = [];
		const halfHeight = connector.height / 2;

		const posYBottom =
				getRendererComponentYPos(connector, this.room) - halfHeight,
			posYTop = getRendererComponentYPos(connector, this.room) + halfHeight;

		for (const point of points) {
			shapePoints.push(new THREE.Vector3(point.x, posYBottom, point.y));
		}
		for (const point of points) {
			shapePoints.push(new THREE.Vector3(point.x, posYTop, point.y));
		}

		const material = this.getMaterial(connector);

		connectorMeshes.forEach((mesh, index) => {
			const newGeometry = new THREE.BufferGeometry().setFromPoints(shapePoints);
			const [indices, indicesSides, indicesBottom] = this.getIndices(connector);

			const xValues = shapePoints.map((v) => v.x);
			const zValues = shapePoints.map((v) => v.z);

			const minX = Math.min(...xValues);
			const maxX = Math.max(...xValues);
			const minZ = Math.min(...zValues);
			const maxZ = Math.max(...zValues);

			const uvs = shapePoints
				.map((v) => {
					return new THREE.Vector2(
						(v.x - minX) / (maxX - minX),
						(v.z - minZ) / (maxZ - minZ)
					);
				})
				.reduce<number[]>((acc, v) => {
					acc.push(v.x, v.y);
					return acc;
				}, []);

			newGeometry.setIndex(
				index === 0 ? indices : index === 1 ? indicesBottom : indicesSides
			);
			newGeometry.setAttribute(
				'uv',
				new THREE.Float32BufferAttribute(uvs.flat(), 2)
			);

			mesh.geometry.dispose();
			mesh.geometry = newGeometry;
			mesh.material = material;
			mesh.position.set(0, 0, 0);
		});

		this.createConnectorEdges(connector, shapePoints);
		this.createLineHelpersMeshes(connector);
	}
}
