import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import { useAuth } from '@/providers/AuthProvider';
import {
	LoadingOverlay,
	TextInput,
	Title,
	Text,
	useMantineTheme,
	PasswordInput,
	Checkbox,
	Divider,
	Notification,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSearchParams } from 'react-router-dom';
import { HeroImg } from '@/components/partials/heroImg/HeroImg';
import { AppPaths } from '@/configs/appPaths';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import { isApiError } from '@/utils/utilities';
import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import FacebookIcon from '@/assets/icons/facebook.svg?react';
import GoogleIcon from '@/assets/icons/google.svg?react';
import styles from './Login.module.scss';

export interface LoginValues {
	email: string;
	password: string;
	remember?: boolean;
}

export enum SubmitStatus {
	THROTTLE,
	LOADING,
	ERROR,
	SUCCESS,
}

const initialValues = {
	email: '',
	password: '',
	remember: true,
};

export type AvailableSocials = 'google' | 'facebook';

const baseSlug = 'login.';

export const Login: React.FC = () => {
	const { login: facebookLogin } = useLogin();

	const { getErrorMessage, getContent } = useContent();

	const { other, spacing } = useMantineTheme();
	const { login, loginWithSocials } = useAuth();

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [resultMessage, setResultMessage] = useState('');

	const [searchParams, setSearchParams] = useSearchParams();

	const form = useForm({
		initialValues,
	});

	const handleSocialsLoginSuccess = async (
		idToken: string,
		type: AvailableSocials
	) => {
		const result = await loginWithSocials({
			idToken,
			remember: form.values.remember,
			type,
		});

		if (result.status === SubmitStatus.ERROR) {
			setStatus(SubmitStatus.ERROR);
			setResultMessage(getErrorMessage(result.stack));
			return;
		}

		setStatus(SubmitStatus.THROTTLE);
	};

	const loginGoogle = useGoogleLogin({
		onSuccess: ({ access_token }) =>
			handleSocialsLoginSuccess(access_token, 'google'),
		onError: () => {
			setStatus(SubmitStatus.ERROR);
			setResultMessage(getErrorMessage(ErrorCode.GENERIC));
		},
		onNonOAuthError: () => {
			setStatus(SubmitStatus.THROTTLE);
		},
	});

	const handleGoogleLogin = () => {
		setStatus(SubmitStatus.LOADING);
		loginGoogle();
	};

	const handleFacebookLogin = async () => {
		setStatus(SubmitStatus.LOADING);

		try {
			const response = await facebookLogin({
				scope: 'email',
			});

			handleSocialsLoginSuccess(response.authResponse.accessToken, 'facebook');
		} catch (_) {
			setStatus(SubmitStatus.THROTTLE);
		}
	};

	useEffect(() => {
		const token = searchParams.get('token');
		setSearchParams({});

		if (!token) return;

		(async () => {
			setStatus(SubmitStatus.LOADING);
			const response = await UnauthenticatedApi.verifyEmailToken(token);

			const isError = isApiError(response);

			if (isError) {
				setStatus(SubmitStatus.ERROR);
				setResultMessage(getErrorMessage(response.message));
			} else {
				setStatus(SubmitStatus.SUCCESS);
				setResultMessage(getContent(`${baseSlug}verifyEmailSuccess`));
			}
		})();
	}, []);

	const handleSubmit = async (loginValues: LoginValues) => {
		setStatus(SubmitStatus.LOADING);
		setResultMessage('');

		const result = await login(loginValues);
		form.reset();

		if (result.status === SubmitStatus.ERROR) {
			setStatus(SubmitStatus.ERROR);
			setResultMessage(getErrorMessage(result.stack));
			return;
		}

		setStatus(SubmitStatus.THROTTLE);
	};

	return (
		<div className="page-wrapper-unauth">
			<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={status === SubmitStatus.LOADING} />
				<Title ta="center" order={1} mb={6}>
					{getContent(`${baseSlug}header`)}
				</Title>
				<Text
					{...other.fontSizes.xl}
					ta="center"
					color={other.textDimmed}
					mb="xl"
				>
					{getContent(`${baseSlug}subheader`)}
				</Text>
				{status === SubmitStatus.ERROR && (
					<Notification {...other.notificationProps.danger} mb="lg">
						<Text {...other.fontSizes.md} color="red.5">
							{resultMessage}
						</Text>
					</Notification>
				)}
				{status === SubmitStatus.SUCCESS && (
					<Notification {...other.notificationProps.success} mb="lg">
						<Text {...other.fontSizes.md} color="green.6">
							{resultMessage}
						</Text>
					</Notification>
				)}
				<TextInput
					{...form.getInputProps('email')}
					{...other.inputSizes.lg}
					required
					type="email"
					placeholder={getContent(`${baseSlug}inputEmailPlaceholder`)}
					mb="lg"
				/>
				<PasswordInput
					{...form.getInputProps('password')}
					{...other.inputSizes.lg}
					required
					placeholder={getContent(`${baseSlug}inputPasswordPlaceholder`)}
					mb="lg"
				/>
				<Checkbox
					{...form.getInputProps('remember', { type: 'checkbox' })}
					label={getContent(`${baseSlug}checkboxRemember`)}
					mb="lg"
				/>
				<Button
					themes={['primary', 'arrow', 'fullWidth']}
					buttonProps={{
						type: 'submit',
						style: {
							marginBottom: spacing.lg,
						},
					}}
				>
					{getContent(`${baseSlug}submitBtn`)}
				</Button>
				<Text
					ta="center"
					color={other.textDimmed}
					mb="lg"
					{...other.fontSizes.md}
				>
					{getContent(`${baseSlug}navigateReset`, {
						links: {
							$resetPassword: {
								to: `/${AppPaths.RESET_PASSWORD}`,
								className: 'text-primary',
							},
						},
					})}
				</Text>
				<Divider
					mb="lg"
					labelPosition="center"
					label={getContent(`${baseSlug}divider`)}
				/>
				<Button
					themes={['secondary', 'fullWidth', 'icon']}
					buttonProps={{
						type: 'button',
						style: {
							marginBottom: spacing.md,
						},
						onClick: handleGoogleLogin,
					}}
				>
					<GoogleIcon />
					{getContent('googleLoginBtn')}
				</Button>
				<Button
					themes={['secondary', 'fullWidth', 'icon']}
					buttonProps={{
						type: 'button',
						style: {
							marginBottom: spacing.lg,
						},
						onClick: handleFacebookLogin,
					}}
				>
					<FacebookIcon />
					{getContent('facebookLoginBtn')}
				</Button>
				<Text
					{...other.fontSizes.md}
					ta="center"
					color={other.textDimmed}
					mb="lg"
				>
					{getContent(`${baseSlug}navigateRegister`, {
						links: {
							$register: {
								to: `/${AppPaths.REGISTER}`,
								className: 'text-primary',
							},
						},
					})}
				</Text>
			</form>
			<HeroImg src={getContent(`${baseSlug}heroImg`)} />
		</div>
	);
};
