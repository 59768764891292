import { Room } from './Room';
import { Wall } from './Wall';
import { Node } from './Node';
import { Label } from './Label';
import { Lamp } from './Lamp';
import { Connector } from '@/3d/core/Connector';
import { Charger } from './Charger';
import { roomHistory } from '@/components/views/creator/renderer/Renderer';

export class RoomBuilder {
	private room!: Room;

	addWall(wall: Wall) {
		this.room.walls.push(wall);
	}

	addNode(node: Node) {
		this.room.nodes.push(node);
	}

	addLabel(label: Label) {
		this.room.labels.push(label);
	}

	addLamp(lamp: Lamp) {
		this.room.lamps.push(lamp);
		this.room.collisionDetector.addCollidable(lamp);
	}

	addConnector(connector: Connector) {
		this.room.connectors.push(connector);
		this.room.collisionDetector.addCollidable(connector);
	}

	addCharger(charger: Charger) {
		this.room.chargers.push(charger);
	}

	addWalls(walls: Wall[]) {
		walls.forEach((w) => this.addWall(w));
	}

	addNodes(nodes: Node[]) {
		nodes.forEach((n) => this.addNode(n));
	}

	addLabels(labels: Label[]) {
		labels.forEach((l) => this.addLabel(l));
	}

	addLamps(lamps: Lamp[]) {
		lamps.forEach((l) => this.addLamp(l));
	}

	addConnectors(connectors: Connector[]) {
		connectors.forEach((c) => this.addConnector(c));
	}

	addChargers(chargers: Charger[]) {
		chargers.forEach((c) => this.addCharger(c));
	}

	updateRoom(payload: Partial<Room>) {
		Object.assign(this.room, payload);
	}

	clearRoom() {
		this.room.clear();
	}

	restart() {
		this.room = new Room();
		roomHistory.clear();
	}

	getRoom() {
		return this.room;
	}
}
