import { RendererObject, ObjectTypeName } from '../core/types/objects';
import { LineManager } from './LineManager';
import { Room } from '../core/Room';
import { UUID } from '@/types/types';
import * as THREE from 'three';

export class MeshManager {
	protected lineManager: LineManager;

	constructor(protected room: Room, protected meshGroup: THREE.Group) {
		this.lineManager = new LineManager(meshGroup);
	}

	protected removeUnusedLines(currentKeys: Set<UUID>, prefix: ObjectTypeName) {
		for (const key of this.lineManager.lines.keys()) {
			if (!key.startsWith(prefix)) continue;

			const id = key.split('-').splice(1, 5).join('-') as UUID;

			if (currentKeys.has(id)) continue;

			this.lineManager.removeLine(key);
		}
	}

	protected removeMesh(mesh: THREE.Mesh) {
		this.meshGroup.remove(mesh);
		mesh.geometry.dispose();
	}

	protected removeUnusedMeshes(
		meshes: Map<RendererObject, THREE.Mesh | THREE.Mesh[]>,
		currentElements: Set<RendererObject>,
		prefix: ObjectTypeName
	) {
		for (const [element, mesh] of meshes) {
			if (!currentElements.has(element)) {
				if (mesh instanceof Array) {
					for (const m of mesh) {
						this.removeMesh(m);
					}
				} else {
					this.removeMesh(mesh);
				}
				meshes.delete(element);
			}
		}

		const currentObjects = new Set(
			Array.from(meshes).map(([object]) => object.id)
		);

		this.removeUnusedLines(currentObjects, prefix);
	}
}
