import {
	Vector2,
	FrontSide,
	Mesh,
	GridHelper,
	PlaneGeometry,
	MeshBasicMaterial,
	DoubleSide,
	Shape,
	ShapeGeometry,
	BackSide,
	AlwaysStencilFunc,
	KeepStencilOp,
	ReplaceStencilOp,
	Color,
	NotEqualStencilFunc,
	LineBasicMaterial,
	Group,
	SRGBColorSpace,
} from 'three';
import { disposeMaterials } from '@/utils/rendererUtils';
import { Room } from '../core/Room';
import { MaterialFactory, MaterialType } from './MaterialFactory';
import { WALL_SIDE_COLOR } from './WallManager';
import { Loader } from './Loader';
import { MeshType } from '../core/enums/MeshType';
import { ViewType } from '@/types/creator';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import ceilingSrc from '@/assets/textures/ceiling.jpg';

import vertexShader from './shaders/floor/vertex.vert';
import fragmentShader from './shaders/floor/fragment.frag';

const {
	FLOOR_Y_POS,
	FLOOR_LINES_Y_POSITION,
	FLOOR_LINES_LARGE_Y_POSITION,
	FLOOR_GRID_SIZE,
} = RENDERER_CONFIG;

const ceilingMaterial = MaterialFactory.getTextureMaterial(
	ceilingSrc,
	new Vector2(0.01, 0.01),
	{
		side: FrontSide,
		color: WALL_SIDE_COLOR,
		userData: {
			type: 'ceiling',
		},
		polygonOffset: true,
		polygonOffsetFactor: 1,
		polygonOffsetUnits: 1,
	}
);

export class FloorManager {
	private innerFloorMesh?: Mesh;
	private ceilingMesh?: Mesh;
	private customFloor?: Mesh;
	private largeGrid?: GridHelper;
	private smallGrid?: GridHelper;

	constructor(private room: Room, private meshGroup: Group) {}

	update() {
		if (!this.room.floorNeedsUpdate) return;

		this.disposeOldMesh();

		this.createInnerFloor();
		this.createCeiling();

		if (this.room.customFloorSrc && !this.customFloor)
			this.loadCustomFloor(this.room.customFloorSrc);

		this.room.floorNeedsUpdate = false;
	}

	loadCustomFloor(src: string) {
		this.disposeCustomFloor();

		const image = new Image();

		image.onload = () => {
			const width = image.naturalWidth;
			const height = image.naturalHeight;

			Loader.loadTexture(src, (texture) => {
				texture.colorSpace = SRGBColorSpace;

				const geometry = new PlaneGeometry(width, height);

				geometry.rotateX(Math.PI * -0.5);

				const material = new MeshBasicMaterial({
					map: texture,
					side: DoubleSide,
				});

				const mesh = new Mesh(geometry, material);
				mesh.position.set(0, 1, 0);

				this.customFloor = mesh;

				this.meshGroup.add(this.customFloor);

				this.room.customFloorSrc = src;
			});
		};

		image.src = src;
	}

	private getRoomGeometry() {
		const nodes = this.room.nodes;

		const plainShape = new Shape();

		for (let i = 0; i < nodes.length; i++) {
			const p1 = nodes[i].getInnerIntersection();
			if (!p1) continue;

			if (!i) {
				plainShape.moveTo(p1.x, p1.y);
			} else {
				plainShape.lineTo(p1.x, p1.y);
			}
		}

		const geometry = new ShapeGeometry(plainShape);
		geometry.rotateX(Math.PI * 0.5);

		return geometry;
	}

	private createCeiling() {
		if (this.room.viewType !== ViewType.FIRST_PERSON) return;

		const geometry = this.getRoomGeometry();

		const mesh = new Mesh(geometry, ceilingMaterial);
		mesh.position.set(0, this.room.height, 0);

		this.ceilingMesh = mesh;
		this.meshGroup.add(this.ceilingMesh);
	}

	private createInnerFloor() {
		if (this.room.meshType === MeshType.OPENED) return;

		const geometry = this.getRoomGeometry();

		const material = MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
			color: 0xffffff,
			side: BackSide,
			stencilWrite: true,
			stencilFunc: AlwaysStencilFunc,
			stencilRef: 1,
			stencilFuncMask: 0xff,
			stencilFail: KeepStencilOp,
			stencilZFail: KeepStencilOp,
			stencilZPass: ReplaceStencilOp,
		});

		const mesh = new Mesh(geometry, material);
		mesh.position.set(0, FLOOR_Y_POS, 0);

		this.innerFloorMesh = mesh;
		this.meshGroup.add(this.innerFloorMesh);

		const largeGrid = this.drawFloorGrid(
			FLOOR_GRID_SIZE,
			100,
			0xe3e8ef,
			FLOOR_LINES_LARGE_Y_POSITION
		);
		const smallGrid = this.drawFloorGrid(
			FLOOR_GRID_SIZE,
			500,
			0xeef2f6,
			FLOOR_LINES_Y_POSITION
		);

		this.largeGrid = largeGrid;
		this.smallGrid = smallGrid;
	}

	private disposeCustomFloor() {
		this.room.customFloorSrc = undefined;

		if (!this.customFloor) return;

		this.meshGroup.remove(this.customFloor);
		this.customFloor.geometry.dispose();
		disposeMaterials(this.customFloor);

		this.customFloor = undefined;
	}

	private disposeOldMesh() {
		if (this.innerFloorMesh) {
			this.meshGroup.remove(this.innerFloorMesh);
			this.innerFloorMesh.geometry.dispose();
			this.innerFloorMesh = undefined;
		}
		if (this.ceilingMesh) {
			this.meshGroup.remove(this.ceilingMesh);
			this.ceilingMesh.geometry.dispose();
			disposeMaterials(this.ceilingMesh);
			this.ceilingMesh = undefined;
		}
		if (this.room.meshType !== MeshType.OPENED) this.disposeCustomFloor();
		if (this.smallGrid) {
			this.meshGroup.remove(this.smallGrid);
			this.smallGrid.geometry.dispose();
			this.smallGrid = undefined;
		}
		if (this.largeGrid) {
			this.meshGroup.remove(this.largeGrid);
			this.largeGrid.geometry.dispose();
			this.largeGrid = undefined;
		}
	}

	private drawFloorGrid(
		gridSize: number,
		gridDivisions: number,
		gridColor: number,
		heightOffset: number
	) {
		const grid = new GridHelper(gridSize, gridDivisions, gridColor, gridColor);
		grid.position.set(0, heightOffset, 0);

		const gridStencilMaterial = MaterialFactory.getMaterial(
			MaterialType.SHADER,
			{
				userData: {
					type: 'grid',
				},
				uniforms: {
					baseColor: { value: new Color(gridColor) },
					maskColor: { value: new Color(gridColor) },
				},

				vertexShader: `
				varying vec2 vUv;

				void main() {
					vUv = uv;
					gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
				}`,
				fragmentShader: `
				uniform vec3 baseColor;
				uniform vec3 maskColor;
				varying vec2 vUv;

				void main() {
					float mask = mod(floor(vUv.x) + floor(vUv.y), 2.0);
					gl_FragColor = vec4(mix(baseColor, maskColor, mask), 1.0);
				}`,
				stencilWrite: true,
				stencilFunc: NotEqualStencilFunc,
				stencilRef: 0,
				stencilFuncMask: 0xff,
				stencilFail: ReplaceStencilOp,
				stencilZFail: ReplaceStencilOp,
				stencilZPass: ReplaceStencilOp,
			}
		) as LineBasicMaterial;

		grid.material = gridStencilMaterial;

		this.meshGroup.add(grid);

		return grid;
	}
}
