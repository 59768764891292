import { Line } from './Line';
import { MouseOver } from './interfaces/MouseOver';
import { Selectable } from './interfaces/Selectable';
import { Point, Polygon } from './types/point';
import { UUID } from '@/types/types';
import {
	ChargerObject,
	ObjectTypeName,
	RendererComponent,
} from './types/objects';
import { Draggable } from './interfaces/Draggable';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import { isPointInsidePolygon } from '@/utils/rendererUtils';
import { pick } from 'lodash';
import polygonOverlap from 'polygon-overlap';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import * as THREE from 'three';
import { Vector2 } from 'three';

const { CHARGER_DIMENSIONS } = RENDERER_CONFIG;

export interface ChargerConfig {
	id: UUID;
	pos: Point;
	poweredComponent?: UUID;
}

export class Charger implements MouseOver, Draggable, Selectable {
	id: UUID;
	private _pos!: Vector2;
	angle: number;
	windowPosition?: Point;
	lastPosition!: Vector2;
	length: number = CHARGER_DIMENSIONS.length;
	height: number = CHARGER_DIMENSIONS.height;
	width: number = CHARGER_DIMENSIONS.width;
	poweredComponent: RendererComponent | null;
	clickPosition?: Point;
	centerClickDiff?: Point;
	private _selected = false;
	isAreaHover = false;
	mouseOver = false;
	visited = false;
	isDragging = false;
	needsUpdate = true;

	constructor(config: ChargerConfig) {
		this.id = config.id;
		this._pos = new Vector2(config.pos.x, config.pos.y);
		this.angle = 0;
		this.poweredComponent = null;
	}

	get selected() {
		return this._selected;
	}

	set selected(selected: boolean) {
		this._selected = selected;
		this.needsUpdate = true;
	}

	get pos() {
		return this._pos;
	}

	set pos(pos: Vector2) {
		this.needsUpdate = true;
		this._pos = pos;
	}

	isSkew() {
		return !!(this.angle % 90);
	}

	getPolygon(): Polygon {
		return this.getAreaPoints(this.length).map((corner) => [
			corner.x,
			corner.y,
		]);
	}

	isInsideSelect(polygon: Polygon) {
		const corners = this.getAreaPoints(this.length);

		for (const corner of Object.values(corners)) {
			if (isPointInsidePolygon(corner, polygon)) {
				return true;
			}
		}

		return polygonOverlap(polygon, this.getPolygon());
	}

	isChargerInsideRoom(polygon: Polygon, pos?: Vector2) {
		const corners = this.getAreaPoints(this.length, pos);

		const n = polygon.length;
		let p1x = polygon[0][0],
			p1y = polygon[0][1];

		for (const corner of Object.values(corners)) {
			let inside = false;
			let xIntercept = 0;
			let onEdge = false;

			for (let i = 1; i <= n; i++) {
				const p2x = polygon[i % n][0],
					p2y = polygon[i % n][1];

				if (corner.y > Math.min(p1y, p2y)) {
					if (corner.y <= Math.max(p1y, p2y)) {
						if (corner.x <= Math.max(p1x, p2x)) {
							if (p1y !== p2y) {
								xIntercept =
									((corner.y - p1y) * (p2x - p1x)) / (p2y - p1y) + p1x;
							}

							if (p1x === p2x || corner.x <= xIntercept) {
								inside = !inside;
							}
						}
					}
				}

				const closestPoint = this.getClosestPointOnEdge(
					[p1x, p1y],
					[p2x, p2y],
					corner
				);
				const distance = Math.sqrt(
					(closestPoint.x - corner.x) ** 2 + (closestPoint.y - corner.y) ** 2
				);
				if (distance < Number.EPSILON) {
					onEdge = true;
					break;
				}

				p1x = p2x;
				p1y = p2y;
			}

			if (!inside && !onEdge) return false;
		}

		return true;
	}

	getClosestPointOnEdge(p1: number[], p2: number[], corner: Vector2) {
		const x1 = p1[0],
			y1 = p1[1],
			x2 = p2[0],
			y2 = p2[1],
			x3 = corner.x,
			y3 = corner.y;
		const dx = x2 - x1,
			dy = y2 - y1;
		const t = ((x3 - x1) * dx + (y3 - y1) * dy) / (dx * dx + dy * dy);
		const clampedT = Math.max(0, Math.min(1, t));

		return new Vector2(x1 + clampedT * dx, y1 + clampedT * dy);
	}

	getLastValidPosition(polygon: Polygon, pos: Vector2) {
		const corners = this.getAreaPoints(this.length, pos);
		const n = polygon.length;
		const room = roomBuilder.getRoom();

		let minDx = 0;
		let minDy = 0;
		let maxDx = 0;
		let maxDy = 0;
		const epsilon = 5;

		for (const corner of Object.values(corners)) {
			let p1x = polygon[0][0];
			let p1y = polygon[0][1];

			let dx = 0,
				dy = 0;
			let minDistance = Infinity;

			for (let i = 1; i <= n; i++) {
				const p2x = polygon[i % n][0];
				const p2y = polygon[i % n][1];

				const closestPoint = this.getClosestPointOnEdge(
					[p1x, p1y],
					[p2x, p2y],
					corner
				);
				const distance = Math.sqrt(
					(closestPoint.x - corner.x) ** 2 + (closestPoint.y - corner.y) ** 2
				);

				if (distance < minDistance) {
					minDistance = distance;

					const edgeLength = Math.sqrt((p2x - p1x) ** 2 + (p2y - p1y) ** 2);
					const normal = {
						x: (p2y - p1y) / edgeLength,
						y: -(p2x - p1x) / edgeLength,
					};

					const adjustedClosestPoint = {
						x: closestPoint.x + epsilon * normal.x,
						y: closestPoint.y + epsilon * normal.y,
					};

					dx = corner.x - adjustedClosestPoint.x;
					dy = corner.y - adjustedClosestPoint.y;
				}

				p1x = p2x;
				p1y = p2y;
			}

			minDx = Math.min(minDx, dx);
			minDy = Math.min(minDy, dy);
			maxDx = Math.max(maxDx, dx);
			maxDy = Math.max(maxDy, dy);
		}

		const newPosition = pos
			.clone()
			.sub(new Vector2(minDx < 0 ? minDx : maxDx, minDy < 0 ? minDy : maxDy));

		if (room.isObjectInsideRoom(this, newPosition)) {
			return newPosition;
		} else {
			return false;
		}
	}

	getLinePoints(length: number = this.length, pos?: Vector2) {
		const halfLength = length / 2;
		const angle = (this.angle * Math.PI) / 180;

		const position = pos || this.pos;

		const x1 = position.x + Math.cos(angle) * halfLength;
		const y1 = position.y + Math.sin(angle) * halfLength;
		const x2 = position.x - Math.cos(angle) * halfLength;
		const y2 = position.y - Math.sin(angle) * halfLength;

		return new Line(new Vector2(x1, y1), new Vector2(x2, y2));
	}

	getAreaPoints(length = this.length, pos?: Vector2) {
		const linePoints = this.getLinePoints(length, pos);

		const halfWidth = this.width / 2;
		const angle = (this.angle * Math.PI) / 180;

		const offset = new Vector2(
			Math.sin(angle) * halfWidth,
			Math.cos(angle) * halfWidth
		);

		let p1: Vector2, p2: Vector2, p3: Vector2, p4: Vector2;

		if (this.isSkew()) {
			const skewOffset = new Vector2();

			if (this.angle > 0 && this.angle < 90) {
				skewOffset.set(-Math.abs(offset.x), Math.abs(offset.y));
			} else if (this.angle > 180 && this.angle < 270) {
				skewOffset.set(Math.abs(offset.x), -Math.abs(offset.y));
			} else {
				skewOffset.set(Math.abs(offset.x), Math.abs(offset.y));
			}

			p1 = linePoints.p1.clone().sub(skewOffset);
			p2 = linePoints.p2.clone().sub(skewOffset);
			p3 = linePoints.p2.clone().add(skewOffset);
			p4 = linePoints.p1.clone().add(skewOffset);
		} else {
			p1 = linePoints.p1.clone().sub(offset);
			p2 = linePoints.p2.clone().sub(offset);
			p3 = linePoints.p2.clone().add(offset);
			p4 = linePoints.p1.clone().add(offset);
		}

		return [p1, p2, p3, p4];
	}

	dispose() {
		const room = roomBuilder.getRoom();
		room.chargers = room.chargers.filter((ch) => ch.id !== this.id);
	}

	save(): ChargerObject {
		return {
			object: ObjectTypeName.CHARGER,
			param: {
				...pick(this, ['id', 'pos']),
				poweredComponent: this.poweredComponent?.id,
			},
		};
	}
}
