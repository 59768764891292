import { Node } from './Node';
import { Line } from './Line';
import { Point } from './types/point';
import { Draggable } from './interfaces/Draggable';
import { MouseOver } from './interfaces/MouseOver';
import { ResizeDirection } from './enums/ResizeDirection';
import { Emit } from './Events';
import { CollisionObject } from '@/3d/core/CollisionDetector';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import { ObjectTypeName, WallObject } from './types/objects';
import { UUID } from '@/types/types';
import { RendererEvent } from './enums/RendererEvent';
import { Vector2 } from 'three';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export type WallConfig = {
	pos: Point;
	length: number;
	angle: number;
};

export enum MoveDirection {
	INSIDE,
	OUTSIDE,
}

export enum TraverseDirection {
	FORWARD,
	BACKWARD,
}

export class Wall implements Draggable, MouseOver {
	id: UUID = crypto.randomUUID();
	length!: number;
	nodes: Node[] = [];
	pos!: Vector2;
	angle = 0;
	width = RENDERER_CONFIG.WALL_THICKNESS;
	mouseOver = false;
	isDragging = false;
	minWallLength = RENDERER_CONFIG.WALL_MIN_LENGTH;
	maxWallLength = RENDERER_CONFIG.WALL_MAX_LENGTH;
	lastPosition!: Vector2;

	constructor(config: WallConfig) {
		this.pos = new Vector2(config.pos.x, config.pos.y);
		this.length = config.length;
		this.angle = config.angle;
	}

	pushNode(node: Node) {
		this.nodes.push(node);
	}

	removeNode(node: Node) {
		this.nodes = this.nodes.filter((n) => n !== node);
	}

	getLinePoints(length: number = this.length, pos: Vector2 = this.pos) {
		const halfLength = length / 2;
		const angle = (this.angle * Math.PI) / 180;

		const x1 = pos.x + Math.cos(angle) * halfLength;
		const y1 = pos.y + Math.sin(angle) * halfLength;
		const x2 = pos.x - Math.cos(angle) * halfLength;
		const y2 = pos.y - Math.sin(angle) * halfLength;

		return new Line(new Vector2(x1, y1), new Vector2(x2, y2));
	}

	isSkew() {
		return this.angle % 90 !== 0;
	}

	getAdjacentWalls(): {
		adjacentWallA: Wall | null;
		adjacentWallB: Wall | null;
	} {
		const adjacentWalls: {
			adjacentWallA: Wall | null;
			adjacentWallB: Wall | null;
		} = { adjacentWallA: null, adjacentWallB: null };

		if (!this.nodes.length) return adjacentWalls;

		for (const node of this.nodes) {
			const connectedWalls = node.getConnectedWalls();

			if (connectedWalls.wallA === this) {
				adjacentWalls.adjacentWallA = connectedWalls.wallB;
			} else {
				adjacentWalls.adjacentWallB = connectedWalls.wallA;
			}
		}

		return adjacentWalls;
	}

	@Emit(RendererEvent.UPDATE_RENDERER)
	resizeThisWall(newLength: number): boolean {
		const oldLength = this.length;

		const moveDirection =
			newLength < this.length ? MoveDirection.INSIDE : MoveDirection.OUTSIDE;
		const moveAmount = Math.abs(newLength - this.length);

		const { adjacentWallA, adjacentWallB } = this.getAdjacentWalls();

		let resizeDirection = ResizeDirection.Center;
		let traverseDirection = TraverseDirection.BACKWARD;

		const { hops: hopsA } = this.getHops(TraverseDirection.FORWARD);
		const { hops: hopsB } = this.getHops(TraverseDirection.BACKWARD);

		const backActions = [];

		let resultA = true;
		let resultB = true;

		if (
			hopsA > hopsB ||
			(hopsA === hopsB &&
				adjacentWallA?.getNextWall(TraverseDirection.FORWARD) ===
					adjacentWallB?.getNextWall(TraverseDirection.BACKWARD))
		) {
			const isNodeObtuse =
				(this.getCommonNode(adjacentWallB!)?.angle || 0) > 180;
			adjacentWallB?.moveByDirection(
				isNodeObtuse ? -moveAmount : moveAmount,
				moveDirection,
				this
			);
			resizeDirection = ResizeDirection.Start;
			traverseDirection = TraverseDirection.BACKWARD;

			backActions.push(() =>
				adjacentWallB?.moveByDirection(
					isNodeObtuse ? -moveAmount : -moveAmount,
					moveDirection,
					this
				)
			);
		} else if (hopsB > hopsA) {
			const isNodeObtuse =
				(this.getCommonNode(adjacentWallA!)?.angle || 0) > 180;
			adjacentWallA?.moveByDirection(
				isNodeObtuse ? -moveAmount : moveAmount,
				moveDirection,
				this
			);
			backActions.push(() =>
				adjacentWallA?.moveByDirection(
					isNodeObtuse ? moveAmount : -moveAmount,
					moveDirection,
					this
				)
			);

			resizeDirection = ResizeDirection.End;
			traverseDirection = TraverseDirection.FORWARD;
		} else if (
			hopsA === hopsB &&
			adjacentWallA?.getNextWall(TraverseDirection.FORWARD) !==
				adjacentWallB?.getNextWall(TraverseDirection.BACKWARD)
		) {
			adjacentWallA?.moveByDirection(moveAmount / 2, moveDirection, this);
			backActions.push(() =>
				adjacentWallA?.moveByDirection(-(moveAmount / 2), moveDirection, this)
			);

			resultA = this.resizePerpendicularWallInDirection(
				moveAmount / 2,
				moveDirection,
				ResizeDirection.End,
				TraverseDirection.FORWARD,
				false
			);

			if (resultA) {
				backActions.push(() => {
					this.resizePerpendicularWallInDirection(
						-(moveAmount / 2),
						moveDirection,
						ResizeDirection.End,
						TraverseDirection.FORWARD,
						false
					);
				});
			}

			adjacentWallB?.moveByDirection(moveAmount / 2, moveDirection, this);

			backActions.push(() =>
				adjacentWallB?.moveByDirection(-(moveAmount / 2), moveDirection, this)
			);

			resultB = this.resizePerpendicularWallInDirection(
				moveAmount / 2,
				moveDirection,
				ResizeDirection.Start,
				TraverseDirection.BACKWARD,
				false
			);

			if (resultB) {
				backActions.push(() => {
					this.resizePerpendicularWallInDirection(
						-(moveAmount / 2),
						moveDirection,
						ResizeDirection.Start,
						TraverseDirection.BACKWARD,
						false
					);
				});
			}
		}

		let result = true;

		if (
			hopsA !== hopsB ||
			(hopsA === hopsB &&
				adjacentWallA?.getNextWall(TraverseDirection.FORWARD) ===
					adjacentWallB?.getNextWall(TraverseDirection.BACKWARD))
		) {
			result = this.resizePerpendicularWallInDirection(
				moveAmount,
				moveDirection,
				resizeDirection,
				traverseDirection,
				false
			);
		}

		if (!result || !resultA || !resultB) {
			backActions.forEach((action) => action());
			return false;
		} else {
			backActions.push(() => {
				this.resizePerpendicularWallInDirection(
					-moveAmount,
					moveDirection,
					resizeDirection,
					traverseDirection,
					false
				);
			});
		}

		const room = roomBuilder.getRoom();

		if (this.length + moveAmount <= 0) {
			backActions.forEach((action) => action());
			return false;
		}

		if (
			hopsA !== hopsB ||
			(hopsA === hopsB &&
				adjacentWallA?.getNextWall(TraverseDirection.FORWARD) ===
					adjacentWallB?.getNextWall(TraverseDirection.BACKWARD))
		) {
			this.resizeWall(
				moveAmount,
				moveDirection === MoveDirection.OUTSIDE
					? resizeDirection
					: -resizeDirection
			);
		}

		this.length = newLength;

		if (room.isAnyObjectOutsideRoom()) return this.resizeThisWall(oldLength);

		return true;
	}

	getHops(
		traverseDirection: TraverseDirection,
		originWall: Wall = this
	): { hops: number; wall: Wall | null } {
		let hops = 0;
		let nextWall = this.getNextWall(traverseDirection);

		if (nextWall) {
			if (nextWall.isSkew()) {
				hops = hops + 2;
			} else {
				hops++;
			}
			if (this.getCommonNode(nextWall!)!.angle > 180) hops++;
			if (nextWall.isPerpendicular(originWall))
				return {
					hops,
					wall: nextWall,
				};

			hops += nextWall.getHops(traverseDirection, originWall).hops;
			nextWall = nextWall.getHops(traverseDirection, originWall).wall;
		}

		return { hops, wall: nextWall };
	}

	resizePerpendicularWallInDirection(
		projectionLength: number,
		moveDirection: MoveDirection,
		direction: ResizeDirection,
		traverseDirection: TraverseDirection,
		sizeCheck: boolean = true
	) {
		let nextWall = this.getNextWall(traverseDirection);
		const nodes = [this.getCommonNode(nextWall!)];

		if (nextWall?.isSkew()) return false;

		while (nextWall && !this.isPerpendicular(nextWall)) {
			const next = nextWall.getNextWall(traverseDirection);

			nodes.push(nextWall.getCommonNode(next!));

			nextWall = next;
		}

		if (nextWall) {
			const outsideNodes = nodes.filter((n) => n && n.angle > 180);

			let moveAmount = projectionLength;
			if (moveDirection === MoveDirection.INSIDE) moveAmount = -moveAmount;

			if (outsideNodes.length >= 1) moveAmount = -moveAmount;

			if (nextWall.length + moveAmount <= this.minWallLength) {
				return false;
			} else {
				nextWall.resizeWall(moveAmount, -direction, false, sizeCheck);
				return true;
			}
		}

		return true;
	}

	getMaxLineLength() {
		return 0;
	}

	getMinLineLength() {
		return 0;
	}

	getCommonNode(wall: Wall): Node | null {
		const nodes = this.nodes.filter((n) => n.isWallConnected(wall));

		return nodes.length ? nodes[0] : null;
	}

	isPerpendicular(wall: Wall): boolean {
		const diff = Math.abs(this.angle - wall.angle);
		return diff === 0 || diff === 180;
	}

	getNextWall(direction: TraverseDirection) {
		const { adjacentWallA, adjacentWallB } = this.getAdjacentWalls();

		if (direction === TraverseDirection.FORWARD) return adjacentWallA;

		return adjacentWallB;
	}

	moveByDirection(
		change: number,
		direction: MoveDirection = MoveDirection.INSIDE,
		wall = this
	) {
		const angleInRadians = (this.angle * Math.PI) / 180;
		const { adjacentWallA, adjacentWallB } = this.getAdjacentWalls();

		const changeFactor = Math.abs(Math.cos(angleInRadians));
		const changeValue = this.isSkew() ? change * changeFactor : change;
		const sign = direction === MoveDirection.INSIDE ? 1 : -1;
		const xChange = sign * changeValue * Math.sin(angleInRadians);
		const yChange = -sign * changeValue * Math.cos(angleInRadians);

		if (this.isSkew()) {
			this.length += (sign * change) / changeFactor;
			this.propagateSkewResize(change, direction, wall);
		} else {
			[adjacentWallA, adjacentWallB].forEach((adjWall) => {
				if (adjWall && adjWall !== wall && adjWall.isSkew()) {
					adjWall.pos.x += xChange;
					adjWall.pos.y += yChange;
				}
			});
		}

		this.pos.x += xChange;
		this.pos.y += yChange;
	}

	propagateSkewResize(change: number, direction: MoveDirection, wall = this) {
		const { adjacentWallA, adjacentWallB } = this.getAdjacentWalls();

		const changeValue = direction === MoveDirection.OUTSIDE ? change : -change;

		if (adjacentWallA && adjacentWallA !== wall)
			adjacentWallA.resizeWall(changeValue, ResizeDirection.Start);

		if (adjacentWallB && adjacentWallB !== wall)
			adjacentWallB.resizeWall(changeValue, ResizeDirection.End);
	}

	resizeAdjacentWalls(projectionLength: number, draggingDirection: number) {
		const { adjacentWallA, adjacentWallB } = this.getAdjacentWalls();

		const angle = (this.angle * Math.PI) / 180;
		const draggingDirectionWalls = [];

		let change = projectionLength;
		const actions = [];

		if (this.isSkew()) {
			const factor = Math.abs(Math.cos(angle));
			change = projectionLength / factor;

			let draggingDirectionSkew = -1;

			if (this.nodes[0].angle > 180) draggingDirectionSkew *= -1;

			if (
				!this.canResize(
					projectionLength * 2 * draggingDirection * draggingDirectionSkew
				)
			)
				return false;

			actions.push({
				fn: this.resizeWall,
				args: [
					projectionLength * 2 * draggingDirection * draggingDirectionSkew,
					ResizeDirection.Center,
				],
			});
		}

		let draggingWallChange = 0;
		let nodeOutside = false;
		let resizeDirection = ResizeDirection.Center;

		if (adjacentWallA) {
			const nodeA = this.getJointNode(adjacentWallA);
			if (!nodeA) return;

			const directionA = this.calculateResizingDirection(nodeA.angle);
			nodeOutside = directionA === -1;

			const changeA = adjacentWallA.isSkew()
				? change / Math.abs(Math.cos((adjacentWallA.angle * Math.PI) / 180))
				: change;

			if (!adjacentWallA.canResize(changeA * draggingDirection * directionA))
				return false;

			actions.push({
				fn: adjacentWallA.resizeWall.bind(adjacentWallA),
				args: [changeA * draggingDirection * directionA, ResizeDirection.Start],
			});

			draggingWallChange += adjacentWallA.isSkew() ? change : 0;
			draggingDirectionWalls.push(adjacentWallA?.isSkew() ? directionA : 1);

			if (adjacentWallA.isSkew()) {
				resizeDirection = ResizeDirection.End;
			}
		}

		if (adjacentWallB) {
			const nodeB = this.getJointNode(adjacentWallB);
			if (!nodeB) return;

			const directionB = this.calculateResizingDirection(nodeB.angle);
			nodeOutside = nodeOutside || directionB === -1;

			const changeB = adjacentWallB.isSkew()
				? change / Math.abs(Math.cos((adjacentWallB.angle * Math.PI) / 180))
				: change;

			if (!adjacentWallB.canResize(changeB * draggingDirection * directionB))
				return false;

			actions.push({
				fn: adjacentWallB.resizeWall.bind(adjacentWallB),
				args: [changeB * draggingDirection * directionB, ResizeDirection.End],
			});

			draggingWallChange += adjacentWallB.isSkew() ? change : 0;
			draggingDirectionWalls.push(adjacentWallB?.isSkew() ? directionB : 1);

			if (adjacentWallB.isSkew()) {
				resizeDirection = ResizeDirection.Start;
			}
		}

		let draggingWallDirection = draggingDirectionWalls.reduce(
			(a, b) => a * b,
			-1
		);

		if (
			adjacentWallA &&
			adjacentWallB &&
			adjacentWallA.isSkew() &&
			adjacentWallB.isSkew()
		) {
			resizeDirection = ResizeDirection.Center;
			if (nodeOutside) {
				draggingWallDirection =
					draggingDirectionWalls[0] === 1 && draggingDirectionWalls[1] === -1
						? -1
						: 1;
			}
		}

		if (
			!this.canResize(
				draggingWallChange * draggingDirection * draggingWallDirection
			)
		)
			return false;

		actions.forEach((action) => action.fn.apply(this, action.args as any));

		return this.resizeWall(
			draggingWallChange * draggingDirection * draggingWallDirection,
			resizeDirection,
			resizeDirection === ResizeDirection.Center && nodeOutside
		);
	}

	getJointNode(wall: Wall) {
		for (const node of this.nodes) {
			if (node.isWallConnected(wall) && node.isWallConnected(this)) {
				return node;
			}
		}
		return null;
	}

	calculateResizingDirection(nodeAngle: number) {
		return nodeAngle > 180 ? -1 : 1;
	}

	canResize(lengthChange: number) {
		const newLength = this.length + lengthChange;

		if (this.length > this.maxWallLength) return newLength <= this.length;

		return newLength >= this.minWallLength && newLength <= this.maxWallLength;
	}

	adjustLengthChange(lengthChange: number, sizeCheck: boolean = true) {
		const newLength = this.length + lengthChange;
		if (!sizeCheck) return newLength;

		if (newLength > this.maxWallLength && this.length < this.maxWallLength)
			return this.maxWallLength - this.length;

		if (newLength < this.minWallLength) return this.minWallLength - this.length;

		return newLength;
	}

	resizeWall(
		lengthChange: number,
		direction: ResizeDirection,
		move = false,
		sizeCheck = true
	) {
		const change = this.adjustLengthChange(lengthChange, sizeCheck);

		const angle = (this.angle * Math.PI) / 180;

		const centerShift = {
			x: (lengthChange / 2) * Math.cos(angle),
			y: (lengthChange / 2) * Math.sin(angle),
		};

		if (!move) {
			this.length = change;
			this.pos.x += centerShift.x * direction;
			this.pos.y += centerShift.y * direction;
		} else {
			this.pos.x += centerShift.x;
			this.pos.y += centerShift.y;
		}

		return true;
	}

	getAreaPoints(length = this.length, pos = this.pos) {
		const linePoints = this.getLinePoints(length, pos);

		const halfWidth = this.width / 2;
		const angle = (this.angle * Math.PI) / 180;

		const offset = new Vector2(
			Math.sin(angle) * halfWidth,
			Math.cos(angle) * halfWidth
		);

		let p1: Vector2, p2: Vector2, p3: Vector2, p4: Vector2;

		if (this.isSkew()) {
			const skewOffset = new Vector2();

			if (this.angle > 0 && this.angle < 90) {
				skewOffset.set(-Math.abs(offset.x), Math.abs(offset.y));
			} else if (this.angle > 180 && this.angle < 270) {
				skewOffset.set(Math.abs(offset.x), -Math.abs(offset.y));
			} else {
				skewOffset.set(Math.abs(offset.x), Math.abs(offset.y));
			}

			p1 = linePoints.p1.clone().sub(skewOffset);
			p2 = linePoints.p2.clone().sub(skewOffset);
			p3 = linePoints.p2.clone().add(skewOffset);
			p4 = linePoints.p1.clone().add(skewOffset);
		} else {
			p1 = linePoints.p1.clone().sub(offset);
			p2 = linePoints.p2.clone().sub(offset);
			p3 = linePoints.p2.clone().add(offset);
			p4 = linePoints.p1.clone().add(offset);
		}

		return [p1, p2, p3, p4];
	}

	getCollisionBox(pos = this.pos) {
		const [p1, p2, p3, p4] = this.getAreaPoints(this.length, pos);

		return [
			new CollisionObject([
				new Vector2(p1.x, p1.y),
				new Vector2(p2.x, p2.y),
				new Vector2(p3.x, p3.y),
				new Vector2(p4.x, p4.y),
			]),
		];
	}

	save(): WallObject {
		return {
			object: ObjectTypeName.WALL,
			param: {
				pos: { x: this.pos.x, y: this.pos.y },
				length: this.length,
				angle: this.angle,
			},
		};
	}
}
