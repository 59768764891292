import React from 'react';
import { Image } from '@mantine/core';
import styles from './HeroImg.module.scss';

interface Props {
	src: string;
}

export const HeroImg: React.FC<Props> = ({ src }) => {
	return (
		<Image
			src={src}
			width="auto"
			alt="Landing Page"
			classNames={{ root: styles.root, image: styles.image }}
		/>
	);
};
