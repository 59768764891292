import { getCopiedObjectPos } from '@/utils/rendererUtils';
import { CollisionDetector } from './CollisionDetector';
import { Connector } from './Connector';
import { ConnectingEdge } from './ConnectingEdge';
import { groupBy } from 'lodash';
import { Lamp } from './Lamp';
import { UUID } from '@/types/types';
import { Clonable } from './interfaces/Clonable';
import { Core } from './Core';

export class ObjectDuplicator {
	constructor(
		private core: Core,
		private collisionDetector: CollisionDetector
	) {}

	duplicateOjects(objects: Clonable[]) {
		const grouped = groupBy(objects, (object) => object.groupId);

		for (const groupId in grouped) {
			const group = grouped[groupId];

			if (groupId === 'undefined' || group.length === 1) {
				for (const object of group) {
					const newObject = object.clone();

					newObject.pos = getCopiedObjectPos(
						newObject.pos,
						newObject.width,
						newObject.angle
					);
					this.collisionDetector.addCollidable(newObject);

					if (newObject instanceof Lamp) {
						this.core.lamps.push(newObject);
					} else {
						this.core.connectors.push(newObject);
					}
				}

				continue;
			}

			const { lamps, connectors } = this.duplicateGroup(group);
			for (const object of [...lamps, ...connectors]) {
				this.collisionDetector.addCollidable(object);
			}

			this.core.lamps.push(...lamps);
			this.core.connectors.push(...connectors);
		}
	}

	private duplicateGroup(objects: Clonable[]) {
		const newGroupId = crypto.randomUUID();

		const lamps: Lamp[] = [];
		const connectors: Connector[] = [];

		const lampConnections = new Map<UUID, Lamp[]>();
		const edgeConnections = new Map<UUID, ConnectingEdge>();

		for (const object of objects.filter((obj) => obj instanceof Lamp)) {
			const lamp = object as Lamp;
			const newLamp = lamp.clone();
			newLamp.groupId = newGroupId;

			for (const edge of lamp.connectionEdges) {
				const newEdge = new ConnectingEdge({
					component: newLamp,
					id: crypto.randomUUID(),
					centerPoint: edge.centerPoint,
				});

				if (edge.connectedTo) edgeConnections.set(edge.connectedTo.id, newEdge);

				newLamp.connectionEdges.push(newEdge);
			}

			lamps.push(newLamp);

			for (const oldConnectorId of lamp.connectors.map(
				(connector) => connector.id
			)) {
				const connections = lampConnections.get(oldConnectorId);
				if (connections) connections.push(newLamp);
				else lampConnections.set(oldConnectorId, [newLamp]);
			}
		}

		for (const object of objects.filter((obj) => obj instanceof Connector)) {
			const connector = object as unknown as Connector;
			const newConnector = connector.clone();
			newConnector.groupId = newGroupId;

			const lamps = lampConnections.get(connector.id);
			if (lamps)
				for (const lamp of lamps) {
					newConnector.lamps.push(lamp);
					lamp.connectors.push(newConnector);
				}

			for (const edge of connector.connectionEdges) {
				const newEdge = new ConnectingEdge({
					component: newConnector,
					id: crypto.randomUUID(),
					centerPoint: edge.centerPoint,
					isShortArm: edge.isShortArm,
				});

				const connection = edgeConnections.get(edge.id);
				if (connection) newEdge.conectEdges(connection);

				newConnector.connectionEdges.push(newEdge);
			}

			connectors.push(newConnector);
		}

		const newPos = getCopiedObjectPos(
			connectors[0].pos,
			connectors[0].width,
			connectors[0].angle
		);
		const posChange = newPos.clone().sub(connectors[0].pos);
		connectors[0].updateGroupPosition(posChange, [...connectors, ...lamps]);
		connectors[0].pos = newPos;

		return { lamps, connectors };
	}
}
