import {
	useMantineTheme,
	TextInput,
	Drawer,
	Title,
	Text,
	Image,
	LoadingOverlay,
} from '@mantine/core';
import { ContactBoxes } from '../contactBoxes/ContactBoxes';
import { useForm, isEmail } from '@mantine/form';
import { Button } from '../button/Button';
import { useAuth } from '@/providers/AuthProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '@/configs/queryKeys';
import { CreatorProject } from '@/types/creator';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import ProjectApi, { Project, ProjectStatus } from '@/api/ProjectApi';

const baseSlug = 'sendProjectDrawer.';

interface Props {
	project: Project | CreatorProject | null;
	opened: boolean;
	close: () => void;
	submitSideEffect?: (project: Project) => void;
	closeButtonText?: string;
}

export const SendProjectDrawer: React.FC<Props> = ({
	project,
	opened,
	close,
	submitSideEffect,
	closeButtonText,
}) => {
	const queryClient = useQueryClient();

	const { other } = useMantineTheme();

	const { getErrorMessage, getContent } = useContent();

	const user = useAuth().user;

	const form = useForm({
		initialValues: {
			email: user?.email || '',
			message: '',
			selfSend: false,
		},
		validate: {
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
		},
	});

	const sendMutation = useMutation({
		mutationFn: ProjectApi.update,
		onSuccess: (proj) => {
			queryClient.invalidateQueries({ queryKey: [QueryKey.projects] });
			if (submitSideEffect) submitSideEffect(proj);
		},
	});

	const handleClose = () => {
		close();
		form.reset();
		sendMutation.reset();
	};

	const handleSubmit = () => {
		sendMutation.mutate({
			id: project!.id,
			contactEmail: form.values.email,
			status: ProjectStatus.SENT,
		});
	};

	return (
		<Drawer
			opened={opened}
			onClose={handleClose}
			title={
				sendMutation.isSuccess
					? getContent(`${baseSlug}title`)
					: getContent(`${baseSlug}titleSuccess`)
			}
		>
			<LoadingOverlay visible={sendMutation.isPending} />
			{!sendMutation.isSuccess && (
				<Title order={3} mb="sm">
					{getContent(`${baseSlug}subheader`)}
				</Title>
			)}
			{sendMutation.isSuccess && (
				<Image
					src={getContent(`${baseSlug}imgSuccess`)}
					alt="callcenter"
					mb="lg"
				/>
			)}
			{!sendMutation.isSuccess && (
				<Text {...other.fontSizes.md} color={other.textDimmed} mb="lg">
					{getContent(`${baseSlug}info`)}
				</Text>
			)}
			{sendMutation.isSuccess && (
				<>
					<Text {...other.fontSizes.md} color={other.textDimmed} mb="lg">
						{getContent(`${baseSlug}infoSuccess`)}
					</Text>
					<ContactBoxes />
					{!!user && (
						<Button
							themes={['secondary', 'fullWidth']}
							buttonProps={{ onClick: close }}
						>
							{closeButtonText || getContent(`${baseSlug}closeBtn`)}
						</Button>
					)}
				</>
			)}
			{!sendMutation.isSuccess && (
				<form onSubmit={form.onSubmit(handleSubmit)}>
					<TextInput
						{...form.getInputProps('email')}
						{...other.inputSizes.md}
						required
						type="email"
						placeholder={getContent(`${baseSlug}inputEmailPlaceholder`)}
						mb="lg"
						label={user ? getContent(`${baseSlug}inputEmailLabel`) : undefined}
					/>
					<Button
						themes={['primary', 'arrow', 'fullWidth']}
						buttonProps={{
							type: 'submit',
						}}
					>
						{getContent(`${baseSlug}submitBtn`)}
					</Button>
				</form>
			)}
		</Drawer>
	);
};
