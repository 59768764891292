import { Room } from '../core/Room';
import { MeshManager } from './MeshManager';
import { MaterialFactory, MaterialType } from './MaterialFactory';
import { ObjectTypeName } from '../core/types/objects';
import { Charger } from '../core/Charger';
import texturePng from '@/assets/textures/lightning.png';
import CREATOR_CONFIG from '@/configs/creatorConfig';
import * as THREE from 'three';

const CHARGER_LEVEL_OFFSET = 10;
const CHARGER_COLOR = 0x121926;

const loader = new THREE.TextureLoader();
const texture = loader.load(texturePng);
texture.needsUpdate = true;
const textureMaterial = new THREE.MeshBasicMaterial({
	map: texture,
	side: 0,
});

const LINE_OPTIONS = {
	color: CHARGER_COLOR,
	linewidth: 0.002,
};
const LINE_DASHED_OPTIONS = {
	dashed: true,
	dashSize: 5,
	gapSize: 5,
};
const LAMP_SELECTED_LINE_COLOR = 0xffcd4c;

export class ChargerManager extends MeshManager {
	private chargerMeshes: Map<Charger, THREE.Mesh> = new Map();

	constructor(
		protected override room: Room,
		protected override meshGroup: THREE.Group,
		protected montageLevel: number = CREATOR_CONFIG.INITIAL_ROOM_HEIGHT +
			CHARGER_LEVEL_OFFSET
	) {
		super(room, meshGroup);
	}

	setLevel(level: number) {
		this.montageLevel = level + CHARGER_LEVEL_OFFSET;
	}

	update() {
		this.removeUnusedMeshes(
			this.chargerMeshes,
			new Set(this.room.chargers),
			ObjectTypeName.CHARGER
		);

		for (const charger of this.room.chargers) {
			if (!charger.needsUpdate) continue;

			charger.needsUpdate = false;

			const mesh = this.chargerMeshes.get(charger);

			if (!mesh) {
				this.createMesh(charger);
				continue;
			}

			this.updateMesh(charger);
		}
	}

	private createEdges(charger: Charger) {
		const { height, length } = charger;

		const [p1, p2, p3, p4] = charger.getAreaPoints(length);

		const opts = charger.selected
			? {
					...LINE_OPTIONS,
					...LINE_DASHED_OPTIONS,
					userData: { animated: true },
					color: LAMP_SELECTED_LINE_COLOR,
			  }
			: LINE_OPTIONS;

		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-1-1`,
			startVector.set(p1.x, this.montageLevel, p1.y),
			endVector.set(p4.x, this.montageLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-1-2`,
			startVector.set(p1.x, this.montageLevel + height, p1.y),
			endVector.set(p1.x, this.montageLevel, p1.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-1-3`,
			startVector.set(p4.x, this.montageLevel + height, p4.y),
			endVector.set(p4.x, this.montageLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-top-1`,
			startVector.set(p4.x, this.montageLevel + height, p4.y),
			endVector.set(p1.x, this.montageLevel + height, p1.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-2-1`,
			startVector.set(p2.x, this.montageLevel, p2.y),
			endVector.set(p3.x, this.montageLevel, p3.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-2-2`,
			startVector.set(p2.x, this.montageLevel + height, p2.y),
			endVector.set(p2.x, this.montageLevel, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-side-2-3`,
			startVector.set(p3.x, this.montageLevel + height, p3.y),
			endVector.set(p3.x, this.montageLevel, p3.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-top-2`,
			startVector.set(p2.x, this.montageLevel + height, p2.y),
			endVector.set(p3.x, this.montageLevel + height, p3.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-bottom1`,
			startVector.set(p1.x, this.montageLevel, p1.y),
			endVector.set(p2.x, this.montageLevel, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-bottom2`,
			startVector.set(p3.x, this.montageLevel, p3.y),
			endVector.set(p4.x, this.montageLevel, p4.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-top3`,
			startVector.set(p1.x, this.montageLevel + height, p1.y),
			endVector.set(p2.x, this.montageLevel + height, p2.y),
			opts
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.CHARGER}-${charger.id}-top4`,
			startVector.set(p3.x, this.montageLevel + height, p3.y),
			endVector.set(p4.x, this.montageLevel + height, p4.y),
			opts
		);
	}

	private createMesh(charger: Charger) {
		const { p1, p2 } = charger.getLinePoints();

		const { width, height, length } = charger;

		const chargerGeometry = new THREE.BoxGeometry(length, height, width);
		const material = MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
			userData: {
				type: `${ObjectTypeName.CHARGER}-${charger.id}`,
			},
			color: CHARGER_COLOR,
		});

		const chargerMesh = new THREE.Mesh(chargerGeometry, [
			material,
			material,
			charger.poweredComponent ? textureMaterial : material,
			material,
			material,
			material,
		]);
		chargerMesh.position.set(
			(p1.x + p2.x) / 2,
			this.montageLevel + 0.5 * height,
			(p1.y + p2.y) / 2
		);
		chargerMesh.rotation.y = -charger.angle * (Math.PI / 180);

		this.createEdges(charger);
		this.chargerMeshes.set(charger, chargerMesh);
		this.meshGroup.add(chargerMesh);
	}

	private updateMesh(charger: Charger) {
		const chargerMesh = this.chargerMeshes.get(charger);
		if (!chargerMesh) return;

		//@ts-ignore
		chargerMesh.material[2] = charger.poweredComponent
			? textureMaterial
			: //@ts-ignore
			  chargerMesh.material[0];

		const { p1, p2 } = charger.getLinePoints();

		chargerMesh.position.set(
			(p1.x + p2.x) / 2,
			this.montageLevel + 0.5 * charger.height,
			(p1.y + p2.y) / 2
		);
		chargerMesh.rotation.y = -charger.angle * (Math.PI / 180);

		this.createEdges(charger);
	}
}
