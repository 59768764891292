import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	CreatorProject,
	CreatorRenderer,
	ViewType,
	CreatorOptions,
	CreatorComponentItem,
	SelectedComponent,
	RoomShape,
	PrefedinedRoom,
	SelectedCharger,
} from '@/types/creator';
import {
	getComponentItemMontageType,
	getComponentObjectDimensions,
	getFileSrc,
} from '@/utils/utilities';
import { RootState } from '@/store/index';
import { Events } from '@/3d/core/Events';
import { Project, ProjectStatus } from '@/api/ProjectApi';
import { StoreKey } from '@/configs/storeKeys';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import { ComponentItem } from '@/api/ComponentItemApi';
import { ComponentType } from '@/api/ComponentApi';
import { omit, pick } from 'lodash';
import { UUID } from '@/types/types';
import { LampConfig } from '@/3d/core/Lamp';
import { ObjectTypeName } from '@/3d/core/types/objects';
import { RendererEvent } from '@/3d/core/enums/RendererEvent';
import { getCopiedObjectPos } from '@/utils/rendererUtils';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import CREATOR_CONFIG from '@/configs/creatorConfig';

export interface CreatorSliceState {
	project: CreatorProject;
	renderer: CreatorRenderer;
	options: CreatorOptions;
}

const initialState: CreatorSliceState = {
	project: {
		name: '',
		projectIndex: 0,
		netPrice: 0,
		grossPrice: 0,
		euroNetPrice: 0,
		euroGrossPrice: 0,
		status: ProjectStatus.NOT_SENT,
		isFavourite: false,
		updatedAt: '',
		createdAt: '',
		user: null,
		groups: [],
		accessories: [],
		creatorComponentItems: [],
		projectComponentItems: [],
		componentItemsCount: 0,
		options: '',
	},
	renderer: {
		initialized: false,
		viewType: ViewType.RECTANGULAR,
		zoomLevel: RENDERER_CONFIG.CAMERA.ZOOM.MD,
		canUndo: false,
		mesh: null,
	},
	options: {
		currentStep: 0,
		refresher: 0,
		roomShape: RoomShape.SQUARE,
		selectedPredefined: null,
		selectedComponentCategory: null,
		selectedComponent: null,
		draggedComponentItem: null,
		selectedLabel: null,
		hoveredObject: null,
		selectedCreatorComponentItems: [],
		selectedChargers: [],
		roomHeight: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
		lightsLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		isLampDetailsOpened: false,
		shareDrawerOpened: false,
		sendDrawerOpened: false,
		saveDrawerOpened: false,
	},
};

export const creatorSlice = createSlice({
	name: StoreKey.CREATOR,
	initialState,
	reducers: {
		loadProject: (state, { payload }: PayloadAction<Project>) => {
			try {
				const newState = JSON.parse(payload.options) as CreatorSliceState;

				Object.assign(newState.project, omit(payload, ['options']));

				const rendererObjects = newState.renderer
					.mesh!.mesh.filter((m) =>
						[ObjectTypeName.LAMP, ObjectTypeName.CONNECTOR].includes(m.object)
					)
					.map(
						(item) =>
							pick(item.param, [
								'id',
								'componentItemId',
							]) as CreatorComponentItem
					);

				Object.assign(state, newState);

				state.project.creatorComponentItems = rendererObjects;

				state.options.isProjectLoading = true;
			} catch (error) {
				Object.assign(state.project, payload);
			}
		},
		updateCreator: (
			state,
			{ payload }: PayloadAction<Partial<CreatorSliceState>>
		) => {
			Object.assign(state, payload);
		},
		updateProject: (
			state,
			{ payload }: PayloadAction<Partial<CreatorProject>>
		) => {
			Object.assign(state.project, payload);
		},
		updateProjectFromApiData: (state, { payload }: PayloadAction<Project>) => {
			Object.assign(
				state.project,
				pick(payload, [
					'id',
					'user',
					'netPrice',
					'euroNetPrice',
					'updatedAt',
					'createdAt',
					'projectComponentItems',
					'accessories',
				])
			);
		},
		updateRenderer: (
			state,
			{ payload }: PayloadAction<Partial<CreatorRenderer>>
		) => {
			Object.assign(state.renderer, payload);
		},
		updateOptions: (
			state,
			{ payload }: PayloadAction<Partial<CreatorOptions>>
		) => {
			Object.assign(state.options, payload);
		},
		setRoomHeights: (
			state,
			{
				payload,
			}: PayloadAction<{
				key: keyof Pick<CreatorOptions, 'roomHeight' | 'lightsLevel'>;
				value: number;
			}>
		) => {
			switch (payload.key) {
				case 'roomHeight':
					state.options.roomHeight = payload.value;

					Events.getInstance().emit(
						RendererEvent.CHANGE_ROOM_HEIGHT,
						payload.value
					);
					if (
						state.options.lightsLevel + CREATOR_CONFIG.MIN_LIGHTS_OFFSET >
						payload.value
					) {
						state.options.lightsLevel =
							payload.value - CREATOR_CONFIG.MIN_LIGHTS_OFFSET;

						Events.getInstance().emit(
							RendererEvent.CHANGE_COMPONENTS_LEVEL,
							payload.value - CREATOR_CONFIG.MIN_LIGHTS_OFFSET
						);
					}
					break;
				case 'lightsLevel':
					state.options.lightsLevel = payload.value;
					Events.getInstance().emit(
						RendererEvent.CHANGE_COMPONENTS_LEVEL,
						payload.value
					);
					break;
			}
		},
		changeViewType: (state, { payload }: PayloadAction<ViewType>) => {
			if (payload === state.renderer.viewType) return;

			state.renderer.viewType = payload;
			state.options.selectedLabel = null;
			state.options.selectedCreatorComponentItems = [];
			Events.getInstance().emit(RendererEvent.CHANGE_VIEW_TYPE, {
				viewType: payload,
			});
		},
		toggleSelected: (
			state,
			{
				payload,
			}: PayloadAction<{
				label: CreatorOptions['selectedLabel'];
				components: SelectedComponent[];
				chargers: SelectedCharger[];
			}>
		) => {
			if (state.options.selectedLabel !== payload.label)
				state.options.selectedLabel = payload.label;

			state.options.selectedCreatorComponentItems = payload.components;
			state.options.selectedChargers = payload.chargers;
		},
		addCreatorComponentItem: (
			state,
			{
				payload,
			}: PayloadAction<{
				componentItem: ComponentItem;
				automaticConnector?: ComponentItem;
				origin?: SelectedComponent;
			}>
		) => {
			const { componentItem, automaticConnector } = payload;

			const montageType = getComponentItemMontageType(componentItem);

			const creatorComponentItem = {
				id: crypto.randomUUID(),
				componentItemId: componentItem.id,
			};

			state.project.creatorComponentItems.push(creatorComponentItem);

			const config: Partial<LampConfig> = {
				...creatorComponentItem,
				// @ts-ignore
				connectorType: componentItem.component.connectorType,
				seriesId: componentItem.component.componentCategory.id,
				montageType,
				...getComponentObjectDimensions(componentItem),
				modelColor: componentItem.modelColor,
			};

			if (componentItem.modelTexture)
				config.modelTexture = getFileSrc(componentItem.modelTexture.path);

			if (payload.origin) {
				config.angle = payload.origin.angle;
				config.pos = getCopiedObjectPos(
					payload.origin.pos,
					config.width!,
					payload.origin.angle
				);
			}

			state.options.refresher++;

			if (automaticConnector) {
				(config as Partial<LampConfig>).automaticConnector = {
					montageType,
					componentItemId: automaticConnector.id,
					seriesId: automaticConnector.component.componentCategory.id,
					connectorType: automaticConnector.component.connectorType!,
					...getComponentObjectDimensions(automaticConnector),
				};

				Events.getInstance().emit(RendererEvent.ADD_LAMP, config);

				return;
			}

			Events.getInstance().emit(
				componentItem.component.type === ComponentType.LAMP
					? RendererEvent.ADD_LAMP
					: RendererEvent.ADD_CONNECTOR,
				config
			);
		},
		addCharger: () => {
			Events.getInstance().emit(RendererEvent.ADD_CHARGER);
		},
		refreshProjectComponentItems: (state) => {
			state.options.refresher++;
		},
		copySelectedItems: (state) => {
			const room = roomBuilder.getRoom();

			const rendererItems = room
				.getRendererComponents()
				.filter((obj) =>
					state.options.selectedCreatorComponentItems
						.filter((item) => !item.copyDisabled)
						.some((item) => item.id === obj.id)
				);

			Events.getInstance().emit(RendererEvent.COPY_OBJECTS, rendererItems);
		},
		removeSelectedCreatorComponentItems: (state) => {
			const selectedComponents = state.options.selectedCreatorComponentItems;
			const selectedChargers = state.options.selectedChargers;

			Events.getInstance().emit(RendererEvent.REMOVE_OBJECTS, {
				selectedComponents,
				selectedChargers,
			});
		},
		updateCreatorComponentItem: (
			state,
			{ payload }: PayloadAction<{ id: UUID; componentItem: ComponentItem }>
		) => {
			state.project.creatorComponentItems =
				state.project.creatorComponentItems.map((item) =>
					item.id === payload.id
						? { ...item, componentItemId: payload.componentItem.id }
						: item
				);

			const lamp = roomBuilder
				.getRoom()
				.lamps.find((lamp) => lamp.id === payload.id);

			if (!lamp) return;

			Object.assign(lamp, {
				...getComponentObjectDimensions(payload.componentItem),
				componentItemId: payload.componentItem.id,
				hasSizeChanged: true,
				needsUpdate: true,
				connectionEdges: [],
			});
			lamp.createOrUpdateConnectionEdges();

			state.options.selectedCreatorComponentItems[0].componentItemId =
				payload.componentItem.id;

			state.options.refresher++;
			Events.getInstance().emit(RendererEvent.UPDATE_RENDERER);
		},
		changeRoomShape: (state, { payload }: PayloadAction<RoomShape>) => {
			if (payload === state.options.roomShape) return;

			state.project.creatorComponentItems = [];

			state.options.refresher++;

			state.renderer.zoomLevel = RENDERER_CONFIG.CAMERA.ZOOM.MD;
			state.renderer.viewType = ViewType.RECTANGULAR;

			state.options.selectedPredefined = null;
			state.options.roomShape = payload;
		},
		loadPredefinedRoom: (state, { payload }: PayloadAction<PrefedinedRoom>) => {
			if (payload.id === state.options.selectedPredefined?.id) return;

			const creatorComponentItems = payload.mesh.mesh
				.filter((m) =>
					[ObjectTypeName.LAMP, ObjectTypeName.CONNECTOR].includes(m.object)
				)
				.map(
					(item) =>
						pick(item.param, ['id', 'componentItemId']) as CreatorComponentItem
				);

			state.project.creatorComponentItems = creatorComponentItems;

			state.options.selectedPredefined = payload;
			state.options.roomShape = null;
			state.options.refresher++;
		},
		resetCreator: (
			state,
			{
				payload,
			}: PayloadAction<
				{ id?: UUID; updatedAt?: string; name?: string } | undefined
			>
		) => {
			Object.assign(state, structuredClone(initialState));

			if (payload?.id) {
				state.project.id = payload.id;
				state.options.roomShape = null;
			}
			if (payload?.name) state.project.name = payload.name;
			if (payload?.updatedAt) state.project.updatedAt = payload.updatedAt;
		},
	},
});

export const {
	loadProject,
	updateCreator,
	updateProject,
	updateRenderer,
	updateProjectFromApiData,
	updateOptions,
	setRoomHeights,
	changeViewType,
	toggleSelected,
	addCreatorComponentItem,
	refreshProjectComponentItems,
	copySelectedItems,
	addCharger,
	removeSelectedCreatorComponentItems,
	updateCreatorComponentItem,
	changeRoomShape,
	loadPredefinedRoom,
	resetCreator,
} = creatorSlice.actions;

export const selectCreatorState = (state: RootState) => state.creator;
export default creatorSlice.reducer;
