import React, { useEffect } from 'react';
import { Burger, Divider, Drawer } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@/providers/AuthProvider';
import { useContent } from '@/providers/ContentProvider';
import { User } from '@/types/user';
import { LanguageSelect } from './LanguageSelect';
import { NavLink } from '../../navlink/NavLink';
import { AppPaths } from '@/configs/appPaths';
import styles from '../Navbar.module.scss';

interface Props {
	baseSlug: string;
}

export const HamburgerMenu: React.FC<Props> = ({ baseSlug }) => {
	const { getContent } = useContent();

	const [opened, { toggle, close }] = useDisclosure(false);

	const { pathname } = useLocation();
	useEffect(() => {
		close();
	}, [pathname]);

	const { logout } = useAuth();
	const user = useAuth().user as User;

	return (
		<div className={styles.mobileMenuContainer}>
			<Burger
				opened={opened}
				onClick={toggle}
				aria-label={opened ? 'Close navigation' : 'Open navigation'}
			/>
			<Drawer
				opened={opened}
				onClose={close}
				classNames={{ header: 'd-none' }}
				size="100%"
			>
				<nav className={styles.mobileMenu}>
					{user && (
						<NavLink className={styles.item} to={AppPaths.PROJECTS} end>
							{getContent(`${baseSlug}projects`)}
						</NavLink>
					)}
					{user && (
						<NavLink className={styles.item} to={AppPaths.ACCOUNT}>
							{getContent(`${baseSlug}account`)}
						</NavLink>
					)}
					{user && (
						<NavLink
							className={styles.item}
							to={`${AppPaths.CREATOR}/${AppPaths.LAYOUT}`}
						>
							{getContent(`${baseSlug}creator`)}
						</NavLink>
					)}
					<NavLink className={styles.item} to={AppPaths.CONTACT}>
						{getContent(`${baseSlug}contact`)}
					</NavLink>
					{user && <Divider w="100%" />}
					{!user && (
						<NavLink className={styles.item} to={AppPaths.LOGIN}>
							{getContent(`${baseSlug}login`)}
						</NavLink>
					)}
					{user && (
						<button
							onClick={logout}
							type="button"
							className={styles.logoutButton}
						>
							{getContent(`${baseSlug}logout`)}
						</button>
					)}
					<LanguageSelect />
				</nav>
			</Drawer>
		</div>
	);
};
