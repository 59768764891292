import { ReactNode, Suspense } from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store/index';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from './MantineProvider';
import { QueryProvider } from './QueryProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { FacebookProvider } from 'react-facebook';
import { AuthProvider } from './AuthProvider';
import { ContentProvider } from './ContentProvider';
import { DatesProvider } from './DatesProvider';
import { Loading } from '@/components/partials/loading/Loading';

export const AppProviders = ({ children }: { children: ReactNode }) => {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<MantineProvider>
					<QueryProvider>
						<GoogleOAuthProvider
							clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}
						>
							<FacebookProvider appId={import.meta.env.VITE_FACEBOOK_APP_ID}>
								<AuthProvider>
									<ContentProvider>
										<DatesProvider>
											<Suspense fallback={<Loading fullHeight />}>
												{children}
											</Suspense>
										</DatesProvider>
									</ContentProvider>
								</AuthProvider>
							</FacebookProvider>
						</GoogleOAuthProvider>
					</QueryProvider>
				</MantineProvider>
			</BrowserRouter>
		</Provider>
	);
};
