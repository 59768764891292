import { MouseEvents } from '../core/MouseEvents';
import { debounce } from 'lodash';
import { Vector2, OrthographicCamera, Vector3 } from 'three';

export type NativeMouseEvent = MouseEvent | DragEvent;

export class MouseHandler {
	private mousePosition = new Vector2();
	private clientMousePosition = new Vector2();
	private boundOnMouseMove!: (event: MouseEvent) => void;
	private boundOnDragOver!: (event: DragEvent) => void;
	private boundOnDrop!: (event: DragEvent) => void;
	private boundOnMouseUp!: (event: MouseEvent) => void;
	private boundOnMouseDown!: (event: MouseEvent) => void;

	constructor(
		private container: HTMLCanvasElement,
		private camera: OrthographicCamera,
		private mouseEvents: MouseEvents
	) {
		this.boundOnMouseMove = this.onMouseMove.bind(this);
		this.boundOnDragOver = this.onMouseMove.bind(this);
		this.boundOnDrop = this.onDragEnd.bind(this);
		this.boundOnMouseUp = this.onMouseUp.bind(this);
		this.boundOnMouseDown = this.onMouseDown.bind(this);
		this.container.addEventListener('mousemove', this.boundOnMouseMove);
		this.container.addEventListener(
			'dragover',
			debounce(this.boundOnDragOver, 0.02)
		);
		this.container.addEventListener('drop', this.boundOnDrop);
		this.container.addEventListener('mouseup', this.boundOnMouseUp);
		this.container.addEventListener('mousedown', this.boundOnMouseDown);
	}

	private onMouseUp(event: NativeMouseEvent) {
		this.mouseEvents.mouseUp(event);
	}

	private onMouseDown(event: NativeMouseEvent) {
		this.mouseEvents.mouseDown(this.getMousePositionInScene(), event);
	}

	private onDragEnd(event: NativeMouseEvent) {
		this.mouseEvents.mouseUp(event);
	}

	private getMousePositionInScene() {
		const vector = new Vector3();

		vector.set(this.mousePosition.x, this.mousePosition.y, 0);
		vector.unproject(this.camera);

		return new Vector2(vector.x, vector.z);
	}

	private onMouseMove(event: NativeMouseEvent) {
		const { clientX, clientY } = event;
		const eventPos = new Vector2(clientX, clientY);

		if (this.clientMousePosition.equals(eventPos)) return;

		this.clientMousePosition = eventPos;

		const rect = this.container.getBoundingClientRect();
		const x = ((clientX - rect.left) / this.container.clientWidth) * 2 - 1;
		const y = -((clientY - rect.top) / this.container.clientHeight) * 2 + 1;

		this.mousePosition.set(x, y);

		this.mouseEvents.mouseMove(this.getMousePositionInScene(), event);
	}

	dispose() {
		this.container.removeEventListener('mousemove', this.boundOnMouseMove);
		this.container.removeEventListener('dragover', this.boundOnDragOver);
		this.container.removeEventListener('drop', this.boundOnDrop);
		this.container.removeEventListener('mousedown', this.boundOnMouseDown);
		this.container.removeEventListener('mouseup', this.boundOnMouseUp);
	}
}
