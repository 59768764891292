import React, { FormEventHandler, useMemo, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import {
	LoadingOverlay,
	TextInput,
	Title,
	Text,
	useMantineTheme,
	PasswordInput,
	Divider,
	Select,
	Checkbox,
	Notification,
} from '@mantine/core';
import { PhonePrefix } from '@/components/partials/phonePrefix/PhonePrefix';
import { isEmail, useForm } from '@mantine/form';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import { HeroImg } from '@/components/partials/heroImg/HeroImg';
import { AppPaths } from '@/configs/appPaths';
import { User } from '@/types/user';
import { uniqBy } from 'lodash';
import {
	isApiError,
	validatePassword,
	validatePhoneNumber,
} from '@/utils/utilities';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import CountryList from 'country-list-with-dial-code-and-flag';
import FacebookIcon from '@/assets/icons/facebook.svg?react';
import GoogleIcon from '@/assets/icons/google.svg?react';
import styles from './Register.module.scss';

export type RegisterValues = Pick<
	User,
	| 'email'
	| 'country'
	| 'phoneNumber'
	| 'contactPerson'
	| 'password'
	| 'terms'
	| 'privacy'
	| 'marketing'
> & {
	repeatPassword: string;
};

const initialValues: RegisterValues = {
	email: '',
	country: 'PL|+48',
	phoneNumber: '',
	contactPerson: '',
	password: '',
	repeatPassword: '',
	terms: false,
	privacy: false,
	marketing: false,
};

const baseSlug = 'register.';

export const Register: React.FC = () => {
	const { getErrorMessage, getContent, language } = useContent();

	const { other } = useMantineTheme();

	const [step, setStep] = useState<1 | 2 | 3>(1);

	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues,
		validate: {
			phoneNumber: (value) =>
				value && !validatePhoneNumber(value)
					? getErrorMessage(ErrorCode.INVALID_PHONE_NUMBER)
					: null,
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
			password: (value, values) => {
				if (value !== values.repeatPassword)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
			repeatPassword: (value, values) => {
				if (value !== values.password)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
		},
	});

	const handleSubmitStep1: FormEventHandler<HTMLFormElement> = async (e) => {
		e.preventDefault();
		setLoading(true);
		const isEmailAvailable = await UnauthenticatedApi.checkIfEmailAvailable(
			form.values.email
		);
		setLoading(false);

		if (isEmailAvailable) {
			setStep(2);
			return;
		}

		form.reset();
		form.setFieldError(
			'email',
			getErrorMessage([ErrorCode.USER_ALREADY_EXISTS])
		);
	};

	const handleSubmit = async () => {
		const [country, prefix] = form.values.country.split('|');

		const processed = {
			...form.values,
			phoneNumber: `${prefix} ${form.values.phoneNumber}`,
			country,
		};

		setLoading(true);
		const response = await UnauthenticatedApi.register(processed);
		form.reset();
		setLoading(false);

		const isError = isApiError(response);

		if (isError)
			return form.setFieldError('email', getErrorMessage(response.message));

		setStep(3);
	};

	const countries = useMemo(
		() =>
			uniqBy(CountryList.getAll(), (country) => country.code).map(
				({ code, dial_code }) => {
					const regionNames = new Intl.DisplayNames([language.toLowerCase()], {
						type: 'region',
					});

					return {
						label: regionNames.of(code),
						value: `${code}|${dial_code}`,
					};
				}
			),
		[language]
	);

	return (
		<div className="page-wrapper-unauth">
			<form
				className={styles.form}
				onSubmit={step === 1 ? handleSubmitStep1 : form.onSubmit(handleSubmit)}
			>
				<LoadingOverlay visible={loading} />
				<Title ta="center" order={1} mb={6}>
					{getContent(`${baseSlug}header`)}
				</Title>
				<Text
					ta="center"
					color="dark.6"
					mb="xl"
					className="hide-on-mobile"
					{...other.fontSizes.xl}
				>
					{getContent(`${baseSlug}subheader`)}
				</Text>
				{step === 3 && (
					<Notification {...other.notificationProps.success} mb="lg">
						<Text {...other.fontSizes.md} color="green.6">
							{getContent(`${baseSlug}notificationSuccess`)}
						</Text>
					</Notification>
				)}
				{step !== 3 && (
					<TextInput
						{...form.getInputProps('email')}
						{...other.inputSizes.lg}
						required
						type="email"
						mb="lg"
						placeholder={getContent(`${baseSlug}inputPlaceholderEmail`)}
					/>
				)}
				{step === 2 && (
					<>
						<Select
							{...form.getInputProps('country')}
							{...other.inputSizes.lg}
							searchable
							required
							data={countries}
							mb="lg"
						/>
						<TextInput
							{...form.getInputProps('phoneNumber')}
							{...other.inputSizes.lg}
							type="tel"
							icon={<PhonePrefix prefix={form.values.country.split('|')[1]} />}
							iconWidth={74}
							mb="lg"
							placeholder={getContent(`${baseSlug}inputPlaceholderPhoneNumber`)}
						/>
						<TextInput
							{...form.getInputProps('contactPerson')}
							{...other.inputSizes.lg}
							required
							type="text"
							mb="lg"
							placeholder={getContent(
								`${baseSlug}inputPlaceholderContactPerson`
							)}
						/>
						<PasswordInput
							{...form.getInputProps('password')}
							{...other.inputSizes.lg}
							required
							mb="lg"
							placeholder={getContent(`${baseSlug}inputPlaceholderPassword`)}
						/>
						<PasswordInput
							{...form.getInputProps('repeatPassword')}
							{...other.inputSizes.lg}
							required
							mb="lg"
							placeholder={getContent(
								`${baseSlug}inputPlaceholderRepeatPassword`
							)}
						/>
						<Checkbox
							{...form.getInputProps('terms', { type: 'checkbox' })}
							required
							mb={12}
							label={getContent(`${baseSlug}checkboxTerms`)}
						/>
						<Checkbox
							{...form.getInputProps('privacy', { type: 'checkbox' })}
							required
							mb={12}
							label={getContent(`${baseSlug}checkboxPrivacy`)}
						/>
						<Checkbox
							{...form.getInputProps('marketing', { type: 'checkbox' })}
							mb="lg"
							label={getContent(`${baseSlug}checkboxMarketing`)}
						/>
					</>
				)}
				{step !== 3 && (
					<Button
						themes={['primary', 'arrow', 'fullWidth']}
						buttonProps={{
							type: 'submit',
							mb: 'lg',
						}}
					>
						{step === 1
							? getContent(`${baseSlug}submitEmailBtn`)
							: getContent(`${baseSlug}submitBtn`)}
					</Button>
				)}
				{step === 1 && (
					<>
						<Divider
							mb="lg"
							labelPosition="center"
							label={getContent(`${baseSlug}divider`)}
						/>
						<Button
							themes={['secondary', 'fullWidth', 'icon']}
							buttonProps={{
								type: 'button',
								mb: 'md',
							}}
						>
							<GoogleIcon />
							{getContent('googleLoginBtn')}
						</Button>
						<Button
							themes={['secondary', 'fullWidth', 'icon']}
							buttonProps={{
								type: 'button',
								mb: 'lg',
							}}
						>
							<FacebookIcon />
							{getContent('facebookLoginBtn')}
						</Button>
					</>
				)}
				<Text
					ta="center"
					color={other.textDimmed}
					{...other.fontSizes.md}
					mb="lg"
				>
					{getContent(`${baseSlug}navigateLogin`, {
						links: {
							$login: {
								to: `/${AppPaths.LOGIN}`,
								className: 'text-primary',
							},
						},
					})}
				</Text>
			</form>
			<HeroImg src={getContent(`${baseSlug}heroImg`)} />
		</div>
	);
};
