import React, { lazy, useLayoutEffect } from 'react';
import { UnauthenticatedApp } from './UnauthenticatedApp';
import { checkIfScreenZoomedIn } from '@/utils/utilities';
import { useAuth } from '@/providers/AuthProvider';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { ErrorPage } from '@/components/views/authenticated/errorPage/ErrorPage';
import { useOs, useWindowEvent } from '@mantine/hooks';
import { Loading } from '@/components/partials/loading/Loading';
import { Navbar } from '../partials/navbar/Navbar';
import { ScrollTop } from '../partials/scrollTop/ScrollTop';
import { setErrorCode } from '@/store/slices/appSlice';
import { useModals } from '@mantine/modals';
import { useContent } from '@/providers/ContentProvider';
import { Language } from '@/types/types';
import httpClient from '@/api/httpClient';
import LANGUAGES from '@/configs/languages';

const AuthenticatedApp = lazy(() => import('./AuthenticatedApp'));

export const App: React.FC = () => {
	const dispatch = useTypedDispatch();

	const { pathname } = useLocation();
	const { setLanguage } = useContent();
	const { user } = useAuth();
	const [searchParams] = useSearchParams();
	const modals = useModals();
	const os = useOs();

	useWindowEvent('resize', () =>
		document.documentElement.classList.toggle(
			'window-scaled-down',
			checkIfScreenZoomedIn() && ['windows', 'macos'].includes(os)
		)
	);

	useLayoutEffect(() => {
		document.documentElement.classList.toggle(
			'window-scaled-down',
			checkIfScreenZoomedIn() && ['windows', 'macos'].includes(os)
		);
	}, [os]);

	useLayoutEffect(() => {
		window.scrollTo(0, 0);
		modals.closeAll();
		dispatch(setErrorCode(''));
	}, [pathname]);

	useLayoutEffect(() => {
		let lang = searchParams.get('lang');
		if (!lang) return;

		lang = lang.toUpperCase();
		lang = lang === 'EN' ? 'GB' : lang;

		if (!!LANGUAGES[lang as Language]) setLanguage(lang as Language);
	}, []);

	const { errorCode, loading } = useTypedSelector((state) => state.app);

	if (user && !httpClient.defaults.headers.common.Authorization)
		return <Loading fullHeight />;

	return (
		<>
			{!loading && <Navbar />}
			<div className="page-wrapper">
				{getAppContent(loading, errorCode, !!user)}
				<ScrollTop />
			</div>
		</>
	);
};

const getAppContent = (
	loading: boolean,
	errorCode: string,
	isUser: boolean
) => {
	if (loading) return <Loading fullHeight />;
	if (errorCode) return <ErrorPage httpCode={errorCode} />;
	if (isUser) return <AuthenticatedApp />;
	return <UnauthenticatedApp />;
};
