import { Line } from '@/3d/renderer/LineManager';
import { Point, Polygon } from '@/3d/core/types/point';
import {
	RendererComponent,
	RendererComponentMontageType,
	RendererObject,
} from '@/3d/core/types/objects';
import { Connector } from '@/3d/core/Connector';
import { Lamp } from '@/3d/core/Lamp';
import { Room } from '@/3d/core/Room';
import { Mesh, MeshBasicMaterial, Vector2 } from 'three';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export function isHorizontal(angle: number) {
	return [0, 180].includes(angle);
}

export function isVertical(angle: number) {
	return [90, 270].includes(angle);
}

export function getRotatedAngle(angle: number, isLamp = false) {
	let result = (angle + RENDERER_CONFIG.ROTATE_TICK) % 360;
	if (result >= 180 && isLamp) result = result % 180;

	return result;
}

export function disposeMaterials(obj: Mesh | Line) {
	if (obj.material instanceof Array) {
		for (const mat of obj.material) {
			mat.dispose();
		}
	} else {
		if ((obj.material as MeshBasicMaterial).map)
			(obj.material as MeshBasicMaterial).map?.dispose();

		obj.material.dispose();
	}
}

export function calculateCentroid(vertices: Point[]) {
	let sumX = 0;
	let sumY = 0;

	for (let i = 0; i < vertices.length; i++) {
		const currentVertex = vertices[i];
		const nextVertex = vertices[(i + 1) % vertices.length];

		const crossProduct =
			currentVertex.x * nextVertex.y - nextVertex.x * currentVertex.y;

		sumX += (currentVertex.x + nextVertex.x) * crossProduct;
		sumY += (currentVertex.y + nextVertex.y) * crossProduct;
	}

	const area = calculateArea(vertices);

	const centroidX = sumX / (6 * area);
	const centroidY = sumY / (6 * area);

	return { x: centroidX, y: centroidY };
}

function calculateArea(vertices: Point[]): number {
	let sum = 0;

	for (let i = 0; i < vertices.length; i++) {
		const currentVertex = vertices[i];
		const nextVertex = vertices[(i + 1) % vertices.length];

		const crossProduct =
			currentVertex.x * nextVertex.y - nextVertex.x * currentVertex.y;

		sum += crossProduct;
	}

	const area = sum / 2;

	return area;
}

export function calculatePolygonDimensions(vertices: Point[]): {
	width: number;
	height: number;
} {
	let minX = Number.POSITIVE_INFINITY;
	let maxX = Number.NEGATIVE_INFINITY;
	let minY = Number.POSITIVE_INFINITY;
	let maxY = Number.NEGATIVE_INFINITY;

	for (let i = 0; i < vertices.length; i++) {
		const vertex = vertices[i];

		minX = Math.min(minX, vertex.x);
		maxX = Math.max(maxX, vertex.x);

		minY = Math.min(minY, vertex.y);
		maxY = Math.max(maxY, vertex.y);
	}

	const width = maxX - minX;
	const height = maxY - minY;

	return { width, height };
}

export function getAngleToXAxis(point1: Point, point2: Point) {
	const deltaX = point2.x - point1.x;
	const deltaY = point2.y - point1.y;

	const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);

	return Math.round(angle);
}

export function isPointOnLine(line: [Point, Point], point: Point) {
	const xChange = line[1].x - line[0].x;

	if (!xChange) return roundToHalf(point.x) === roundToHalf(line[0].x);

	const m = (line[1].y - line[0].y) / xChange;
	const c = line[0].y - m * line[0].x;

	return roundToHalf(point.y) === roundToHalf(m * point.x + c);
}

export function getFarthesPoint(basePoint: Vector2, ...points: Vector2[]) {
	let farthestPoint = points[0];
	let largestDistance = basePoint.distanceTo(farthestPoint);

	for (let i = 0; i < points.length; i++) {
		const point = points[i];
		const dist = basePoint.distanceTo(point);
		if (dist > largestDistance) {
			farthestPoint = point;
			largestDistance = dist;
		}
	}

	return farthestPoint;
}

export function getClosestPoint(basePoint: Vector2, ...points: Vector2[]) {
	let closestPoint = points[0];
	let closestDistance = basePoint.distanceTo(closestPoint);

	for (let i = 0; i < points.length; i++) {
		const point = points[i];
		const dist = basePoint.distanceTo(point);
		if (dist < closestDistance) {
			closestPoint = point;
			closestDistance = dist;
		}
	}

	return closestPoint;
}

export function getAllowedLampAngles(angle: number) {
	if (isHorizontal(angle)) return [0, 180];
	if (isVertical(angle)) return [90, 270];
	return [angle % 90];
}

export function getAngleBetweenTwoPoints(point1: Vector2, point2: Vector2) {
	const diff = point2.clone().sub(point1);

	return Math.atan2(diff.y, diff.x);
}

export function extrudePointFromLine(
	basePoint: Vector2,
	edgePoint: Vector2,
	distFromEdgePoint: number
) {
	const angle = getAngleBetweenTwoPoints(basePoint, edgePoint);

	const x = edgePoint.x + Math.cos(angle) * distFromEdgePoint;
	const y = edgePoint.y + Math.sin(angle) * distFromEdgePoint;

	return new Vector2(x, y);
}

export function rotateCornerPoints(
	cornerPoints: Vector2[],
	angle: number,
	centerX: number,
	centerY: number
) {
	const radAngle = (angle * Math.PI) / 180;
	const rotatedPoints = cornerPoints.map((vec) => {
		const x = vec.x - centerX;
		const y = vec.y - centerY;

		const newX = x * Math.cos(radAngle) - y * Math.sin(radAngle);
		const newY = x * Math.sin(radAngle) + y * Math.cos(radAngle);

		return new Vector2(newX + centerX, newY + centerY);
	});

	return rotatedPoints;
}

export function getCopiedObjectPos(
	originPos: Vector2,
	width: number,
	angle: number
) {
	const copiedPos = originPos.clone();

	if ([0, 180].includes(angle)) {
		copiedPos.y += width + 5;
		return copiedPos;
	}
	if ([90, 270].includes(angle)) {
		copiedPos.x += width + 5;
		return copiedPos;
	}

	const offset = new Vector2(5 + width, angle > 90 ? 5 + width : -5 - width);

	copiedPos.add(offset);
	return copiedPos;
}

export function getZoomLevelFromSurfaceArea(
	area: number,
	isForScreenshot = false
) {
	const zoomLevels = isForScreenshot
		? RENDERER_CONFIG.CAMERA.ZOOM_SCRENSHOT
		: RENDERER_CONFIG.CAMERA.ZOOM;

	if (area >= RENDERER_CONFIG.CAMERA.SURFACE_AREA_BREAKPOINT.XL)
		return zoomLevels.XL;
	if (area >= RENDERER_CONFIG.CAMERA.SURFACE_AREA_BREAKPOINT.LG)
		return zoomLevels.LG;
	if (area >= RENDERER_CONFIG.CAMERA.SURFACE_AREA_BREAKPOINT.MD)
		return zoomLevels.MD;
	if (area >= RENDERER_CONFIG.CAMERA.SURFACE_AREA_BREAKPOINT.SM)
		return zoomLevels.SM;
	return zoomLevels.XS;
}

export function isPointInsidePolygon(point: Point, polygon: Polygon) {
	const x = point.x;
	const y = point.y;
	const n = polygon.length;
	let inside = false;

	let p1x = polygon[0][0];
	let p1y = polygon[0][1];
	for (let i = 1; i <= n; ++i) {
		const p2x = polygon[i % n][0];
		const p2y = polygon[i % n][1];
		if (y > Math.min(p1y, p2y)) {
			if (y <= Math.max(p1y, p2y)) {
				if (x <= Math.max(p1x, p2x)) {
					if (p1y !== p2y) {
						const xIntersection = ((y - p1y) * (p2x - p1x)) / (p2y - p1y) + p1x;
						if (p1x === p2x || x <= xIntersection) {
							inside = !inside;
						}
					}
				}
			}
		}
		p1x = p2x;
		p1y = p2y;
	}

	return inside;
}

export function isRendererComponent(
	obj: RendererObject
): obj is RendererComponent {
	return obj instanceof Lamp || obj instanceof Connector;
}

export function getRendererComponentYPos(obj: RendererComponent, room: Room) {
	return obj.montageType === RendererComponentMontageType.SLIGN
		? room.componentsSlingLevel + 0.5 * obj.height
		: room.height - 0.5 * obj.height;
}

export function roundToHalf(number: number) {
	return Math.round(number * 2) / 2;
}

export function areVectorsParallel(
	vector1: Vector2,
	vector2: Vector2,
	tolerance: number = 1
) {
	const dotProduct = vector1.dot(vector2);
	const lengthProduct = vector1.length() * vector2.length();
	const areParallel = Math.abs(dotProduct - lengthProduct) < tolerance;

	return areParallel;
}
