import { QueryKey } from '@/configs/queryKeys';
import {
	Attachment,
	UUID,
	Language,
	ApiPaginatedResponse,
	ApiQueryOptions,
	CustomFilters,
} from '@/types/types';
import { QueryFunctionContext } from '@tanstack/react-query';
import { omitBy, trim } from 'lodash';
import EntityApi from './EntityApi';
import httpClient from './httpClient';
import axios from 'axios';

export enum ContentType {
	TEXT,
	IMAGE,
	ELEMENT,
}

export interface Content {
	id: UUID;
	slug: string;
	type: ContentType;
	translations: {
		[key in Language]: {
			content: string;
			image: Pick<Attachment, 'id' | 'path'>;
		};
	};
}

class ContentApi extends EntityApi<Content> {
	constructor() {
		super(QueryKey.content);
	}

	override async getAll({
		queryKey,
	}: QueryFunctionContext<
		[
			QueryKey,
			Partial<
				Pick<ApiQueryOptions<Content>, 'search' | 'searchFields' | 'sort'>
			>?,
			CustomFilters?
		]
	>) {
		const params = omitBy(
			{
				search: trim(queryKey[1]?.search),
				searchFields: queryKey[1]?.searchFields?.join(','),
				[`order[${String(queryKey[1]?.sort?.key)}]`]: queryKey[1]?.sort?.order,
				...queryKey[2],
			},
			(value) => !value
		);
		Object.assign(params, { pagination: false, ...queryKey[2] });

		const client = axios.create({
			...httpClient.defaults,
			baseURL: 'https://api.lenalightingsa.pl/',
		});

		const response = await client.get<ApiPaginatedResponse<Content>>(
			queryKey[0],
			{
				params,
			}
		);
		return response.data.data;
	}
}

export default new ContentApi();
