import { Snapshot } from './Snapshot';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export class History {
	snapshots: Snapshot[] = [];

	add(snapshot: Snapshot) {
		if (this.snapshots.length === RENDERER_CONFIG.HISTORY_MAXIMUM_SNAPSHOTS)
			this.snapshots.shift();

		this.snapshots.push(snapshot);
	}

	undo() {
		if (!this.canUndo()) return null;

		const snapshot = this.snapshots.pop();
		return snapshot;
	}

	canUndo() {
		return !!this.snapshots.length;
	}

	clear() {
		this.snapshots = [];
	}
}
