import React, { ReactNode } from 'react';
import {
	Box,
	Tooltip,
	TooltipProps,
	useMantineTheme,
	Text,
	Flex,
	BoxProps,
} from '@mantine/core';
import { useContent } from '@/providers/ContentProvider';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { nextTutorialCurrentStep } from '@/store/slices/tutorialSlice';
import APP_CONFIG from '@/configs/appConfig';
import classNames from 'classnames';
import styles from './TutorialItem.module.scss';

interface Props {
	children: ReactNode;
	tutorialStepNumber: number;
	boxProps?: BoxProps;
	innerBoxProps?: BoxProps;
	tooltipProps?: Omit<Partial<TooltipProps>, 'label'>;
}

const baseSlug = 'tutorialStep.';

export const TutorialItem: React.FC<Props> = ({
	children,
	tutorialStepNumber,
	boxProps,
	innerBoxProps,
	tooltipProps,
}) => {
	const dispatch = useTypedDispatch();

	const { getContent } = useContent();
	const { other, black } = useMantineTheme();

	const { isTutorialOpened, tutorialSteps, currentTutorialStep } =
		useTypedSelector((app) => app.tutorial);

	const handleNext = () => {
		dispatch(nextTutorialCurrentStep());
	};

	const isActive =
		isTutorialOpened && currentTutorialStep === tutorialStepNumber;
	const step = tutorialSteps[tutorialStepNumber];

	return (
		<Tooltip
			radius={80}
			offset={10}
			{...tooltipProps}
			withArrow={false}
			withinPortal
			label={
				<Flex align="center" gap="sm">
					<Flex
						bg="dark.0"
						miw={22}
						h={22}
						className={styles.decor}
						justify="center"
						align="center"
					>
						<Text color={black} fw={other.fontWeights.bold} lh={1}>
							{tutorialStepNumber + 1}
						</Text>
					</Flex>
					<Text {...other.fontSizes.md} color="dark.0">
						{getContent(`${baseSlug}${step.slug}`)}
					</Text>
				</Flex>
			}
			opened={isActive}
			zIndex={APP_CONFIG.Z_INDEX.TUTORIAL_ITEM}
			classNames={{
				tooltip: styles.tooltip,
			}}
			maw={800}
		>
			<Box
				{...boxProps}
				onClick={handleNext}
				className={classNames(
					{
						[styles.activeStep]: isActive,
					},
					boxProps?.className
				)}
			>
				<Box
					{...innerBoxProps}
					className={classNames(
						{
							[styles.eventsWrapper]: isActive,
						},
						innerBoxProps?.className
					)}
				>
					{children}
				</Box>
			</Box>
		</Tooltip>
	);
};
