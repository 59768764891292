import { Line } from './Line';
import { Draggable } from './interfaces/Draggable';
import { MouseOver } from './interfaces/MouseOver';
import { Point, Polygon } from './types/point';
import { UUID } from '@/types/types';
import { Collidable } from '@/3d/core/interfaces/Collidable';
import { CollisionObject } from '@/3d/core/CollisionDetector';
import { AutomaticConnector, Connector } from '@/3d/core/Connector';
import { Selectable } from './interfaces/Selectable';
import { LineHelped } from './interfaces/LineAssisted';
import { LineHelper } from './HelperLine';
import {
	LampObject,
	ObjectTypeName,
	RendererComponent,
	RendererComponentMontageType,
} from './types/objects';
import { Connectable } from './interfaces/Connectable';
import { ConnectingEdge, SerializedConnectingEdge } from './ConnectingEdge';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import {
	getAngleToXAxis,
	getRotatedAngle,
	isPointInsidePolygon,
	rotateCornerPoints,
} from '@/utils/rendererUtils';
import { pick } from 'lodash';
import { Clonable } from './interfaces/Clonable';
import { Vector2 } from 'three';
import polygonOverlap from 'polygon-overlap';
import RENDERER_CONFIG from '@/configs/rendererConfig';

const { GENERIC_OBJECT_DIMENSIONS } = RENDERER_CONFIG;

const copyParams = [
	'componentItemId',
	'seriesId',
	'pos',
	'angle',
	'length',
	'width',
	'height',
	'montageType',
	'modelColor',
	'modelTexture',
] as const;

export interface LampConfig {
	id: UUID;
	componentItemId: UUID;
	seriesId: UUID;
	pos: Point;
	angle: number;
	length: number;
	width: number;
	height: number;
	connectors: UUID[];
	montageType?: RendererComponentMontageType;
	mouseOver?: boolean;
	isDragging?: boolean;
	isShadow?: boolean;
	groupId?: UUID;
	edges?: SerializedConnectingEdge[];
	automaticConnector?: AutomaticConnector | UUID;
	modelColor?: string;
	modelTexture?: string;
}

export class Lamp
	implements
		Draggable,
		MouseOver,
		Collidable,
		LineHelped,
		Connectable,
		Selectable,
		Clonable<Lamp>
{
	id: UUID;
	componentItemId: UUID;
	seriesId: UUID;
	private _pos!: Vector2;
	angle: number;
	windowPosition?: Vector2;
	groupId?: UUID;
	lastPosition!: Vector2;
	length: number = GENERIC_OBJECT_DIMENSIONS.length;
	height: number = GENERIC_OBJECT_DIMENSIONS.height;
	width: number = GENERIC_OBJECT_DIMENSIONS.width;
	connectors: Connector[] = [];
	lineHelpers: LineHelper[] = [];
	connectionEdges: ConnectingEdge[] = [];
	montageType: RendererComponentMontageType;
	automaticConnector?: AutomaticConnector;
	clickPosition?: Vector2;
	centerClickDiff?: Vector2;
	hasSizeChanged = false;
	isShadow = false;
	isDragging = false;
	private _selected = false;
	isAreaHover = false;
	mouseOver = false;
	isSummaryHover = false;
	visited = false;
	needsUpdate = true;
	modelColor: string;
	modelTexture?: string;

	constructor(config: LampConfig) {
		this.id = config.id;
		this.componentItemId = config.componentItemId;
		this.seriesId = config.seriesId;
		this._pos = new Vector2(config.pos.x, config.pos.y);
		this.angle = config.angle;
		this.length = config.length;
		this.groupId = config.groupId;
		this.width = config.width;
		this.height = config.height;
		this.isDragging = !!config.isDragging;
		this.isShadow = !!config.isShadow;
		this.mouseOver = !!config.mouseOver;
		this.montageType = config.montageType || RendererComponentMontageType.SLIGN;
		this.modelColor =
			config.modelColor || RENDERER_CONFIG.LAMP_DEFAULT_BOTTOM_COLOR;
		this.modelTexture = config.modelTexture;
		if (typeof config.automaticConnector !== 'string')
			this.automaticConnector = config.automaticConnector;

		this.createOrUpdateLineHelpers();

		if (!config.edges) this.createOrUpdateConnectionEdges();
	}

	isSkew() {
		return !!(this.angle % 90);
	}

	get selected() {
		return this._selected;
	}

	set selected(selected: boolean) {
		this._selected = selected;
		this.needsUpdate = true;
	}

	get pos() {
		return this._pos;
	}

	set pos(pos: Vector2) {
		this.needsUpdate = true;

		const posChange = pos.clone();
		posChange.sub(this.pos);

		this._pos = pos;
		this.createOrUpdateLineHelpers();
		this.createOrUpdateConnectionEdges({ posChange });
	}

	isSameGroup(other: Collidable | Connectable) {
		return !!this.groupId && !!other.groupId && this.groupId === other.groupId;
	}

	updateGroupPosition(posChange: Vector2, groupObjects: RendererComponent[]) {
		for (const object of groupObjects.filter((o) => o.id !== this.id)) {
			object.pos = object.pos.clone().add(posChange);
		}
	}

	rotate(axis = this.pos) {
		this.needsUpdate = true;

		this.angle = getRotatedAngle(this.angle, true);

		this._pos = rotateCornerPoints(
			[this.pos],
			RENDERER_CONFIG.ROTATE_TICK,
			axis.x,
			axis.y
		)[0];

		this.createOrUpdateConnectionEdges({ rotatationAxis: axis });
		this.createOrUpdateLineHelpers();
	}

	getPolygon(): Polygon {
		return this.getAreaPoints(this.length).map((corner) => [
			corner.x,
			corner.y,
		]);
	}

	isInsideSelect(polygon: Polygon) {
		const corners = this.getAreaPoints(this.length);

		for (const corner of Object.values(corners)) {
			if (isPointInsidePolygon(corner, polygon)) {
				return true;
			}
		}

		return polygonOverlap(polygon, this.getPolygon());
	}

	createOrUpdateLineHelpers() {
		const vertices = this.getAreaPoints();
		const isObjectConnected = !!this.connectors.length;
		const objectGroupId = this.groupId;

		for (let idx = 0; idx < vertices.length; idx++) {
			const point = vertices[idx];
			const nextPoint = vertices[(idx + 1) % vertices.length];

			const angle = getAngleToXAxis(point, nextPoint);

			const endpoints = {
				start: point,
				end: nextPoint,
			};

			if (!!this.lineHelpers[idx]) {
				const helper = this.lineHelpers[idx];
				Object.assign(helper, {
					angle,
					endpoints,
					isObjectConnected,
					objectGroupId,
				});
				continue;
			}

			this.lineHelpers.push(new LineHelper(angle, endpoints, objectGroupId));
		}
	}

	createOrUpdateConnectionEdges({
		posChange,
		rotatationAxis,
	}: {
		posChange?: Vector2;
		rotatationAxis?: Vector2;
	} = {}) {
		if (!this.connectionEdges.length) {
			this.createConnectionEdges();
			return;
		}

		if (rotatationAxis)
			for (const edge of this.connectionEdges) {
				edge.handleHostRotation(RENDERER_CONFIG.ROTATE_TICK, rotatationAxis);
			}

		if (posChange)
			for (const edge of this.connectionEdges) {
				edge.centerPoint = edge.getUpdatedCenterPoint(posChange);
			}
	}

	private createConnectionEdges() {
		const lampLine = this.getLinePoints(),
			points = [lampLine.p1, lampLine.p2];

		for (const point of points) {
			this.connectionEdges.push(
				new ConnectingEdge({
					id: crypto.randomUUID(),
					component: this,
					centerPoint: point,
				})
			);
		}
	}

	checkIfLampsInSameGroup(other: Lamp) {
		this.visited = true;
		if (!this.groupId || !other.groupId) return false;

		for (const connecotr of this.connectors) {
			if (connecotr.visited) continue;
			connecotr.visited = true;

			for (const lamp of connecotr.lamps) {
				if (lamp.visited) continue;
				lamp.visited = true;

				if (lamp === other) return true;

				if (lamp.checkIfLampsInSameGroup(other)) return true;
			}
		}

		return false;
	}

	setNewGroupId(id = crypto.randomUUID()) {
		if (this.visited) return;
		this.visited = true;
		this.groupId = id;

		for (const connector of this.connectors) {
			if (connector.visited) continue;
			connector.visited = true;
			connector.groupId = id;

			for (const lamp of connector.lamps) {
				if (lamp.visited) continue;

				lamp.setNewGroupId(id);
			}
		}
	}

	getClosestPointOnEdge(p1: number[], p2: number[], corner: Vector2) {
		const x1 = p1[0],
			y1 = p1[1],
			x2 = p2[0],
			y2 = p2[1],
			x3 = corner.x,
			y3 = corner.y;
		const dx = x2 - x1,
			dy = y2 - y1;
		const t = ((x3 - x1) * dx + (y3 - y1) * dy) / (dx * dx + dy * dy);
		const clampedT = Math.max(0, Math.min(1, t));

		return new Vector2(x1 + clampedT * dx, y1 + clampedT * dy);
	}

	getLastValidPosition(polygon: Polygon, pos: Vector2) {
		const corners = this.getAreaPoints(this.length, pos);
		const n = polygon.length;
		const room = roomBuilder.getRoom();

		let minDx = 0;
		let minDy = 0;
		let maxDx = 0;
		let maxDy = 0;
		const epsilon = 5;

		for (const corner of Object.values(corners)) {
			let p1x = polygon[0][0];
			let p1y = polygon[0][1];

			let dx = 0,
				dy = 0;
			let minDistance = Infinity;

			for (let i = 1; i <= n; i++) {
				const p2x = polygon[i % n][0];
				const p2y = polygon[i % n][1];

				const closestPoint = this.getClosestPointOnEdge(
					[p1x, p1y],
					[p2x, p2y],
					corner
				);
				const distance = Math.sqrt(
					(closestPoint.x - corner.x) ** 2 + (closestPoint.y - corner.y) ** 2
				);

				if (distance < minDistance) {
					minDistance = distance;

					const edgeLength = Math.sqrt((p2x - p1x) ** 2 + (p2y - p1y) ** 2);
					const normal = {
						x: (p2y - p1y) / edgeLength,
						y: -(p2x - p1x) / edgeLength,
					};

					const adjustedClosestPoint = {
						x: closestPoint.x + epsilon * normal.x,
						y: closestPoint.y + epsilon * normal.y,
					};

					dx = corner.x - adjustedClosestPoint.x;
					dy = corner.y - adjustedClosestPoint.y;
				}

				p1x = p2x;
				p1y = p2y;
			}

			minDx = Math.min(minDx, dx);
			minDy = Math.min(minDy, dy);
			maxDx = Math.max(maxDx, dx);
			maxDy = Math.max(maxDy, dy);
		}

		const newPosition = pos
			.clone()
			.sub(new Vector2(minDx < 0 ? minDx : maxDx, minDy < 0 ? minDy : maxDy));

		if (room.isObjectInsideRoom(this, newPosition)) {
			return newPosition;
		} else {
			return false;
		}
	}

	getLinePoints(length: number = this.length, pos?: Vector2) {
		const halfLength = length / 2;
		const angle = (this.angle * Math.PI) / 180;

		const position = pos || this.pos;

		const x1 = position.x + Math.cos(angle) * halfLength;
		const y1 = position.y + Math.sin(angle) * halfLength;
		const x2 = position.x - Math.cos(angle) * halfLength;
		const y2 = position.y - Math.sin(angle) * halfLength;

		return new Line(new Vector2(x1, y1), new Vector2(x2, y2));
	}

	getAreaPoints(length = this.length, pos?: Vector2) {
		const linePoints = this.getLinePoints(length, pos);

		const halfWidth = this.width / 2;
		const angle = (this.angle * Math.PI) / 180;

		const offset = new Vector2(
			Math.sin(angle) * halfWidth,
			Math.cos(angle) * halfWidth
		);

		let p1: Vector2, p2: Vector2, p3: Vector2, p4: Vector2;

		if (this.isSkew()) {
			const skewOffset = new Vector2();

			if (this.angle > 0 && this.angle < 90) {
				skewOffset.set(-Math.abs(offset.x), Math.abs(offset.y));
			} else if (this.angle > 180 && this.angle < 270) {
				skewOffset.set(Math.abs(offset.x), -Math.abs(offset.y));
			} else {
				skewOffset.set(Math.abs(offset.x), Math.abs(offset.y));
			}

			p1 = linePoints.p1.clone().sub(skewOffset);
			p2 = linePoints.p2.clone().sub(skewOffset);
			p3 = linePoints.p2.clone().add(skewOffset);
			p4 = linePoints.p1.clone().add(skewOffset);
		} else {
			p1 = linePoints.p1.clone().sub(offset);
			p2 = linePoints.p2.clone().sub(offset);
			p3 = linePoints.p2.clone().add(offset);
			p4 = linePoints.p1.clone().add(offset);
		}

		return [p1, p2, p3, p4];
	}

	getCollisionBox(pos = this.pos) {
		const [p1, p2, p3, p4] = this.getAreaPoints(this.length, pos);
		return [
			new CollisionObject([
				new Vector2(p1.x, p1.y),
				new Vector2(p2.x, p2.y),
				new Vector2(p3.x, p3.y),
				new Vector2(p4.x, p4.y),
			]),
		];
	}

	disconectConnector(connector: Connector) {
		this.connectors = this.connectors.filter((c) => c.id !== connector.id);
		for (const edge of this.connectionEdges) {
			if (edge.connectedTo?.component.id === connector.id)
				edge.connectedTo = undefined;
		}
	}

	dispose() {
		const room = roomBuilder.getRoom();
		room.lamps = room.lamps.filter((l) => l.id !== this.id);

		for (const edge of this.connectionEdges) {
			edge.disconnectEdges();
		}

		for (const connector of this.connectors) {
			connector.lamps = connector.lamps.filter((l) => l.id !== this.id);
			if (!connector.lamps.length) connector.groupId = undefined;

			if (connector.isAutomatic && connector.lamps.length < 2)
				connector.dispose();
		}
	}

	save(): LampObject {
		return {
			object: ObjectTypeName.LAMP,
			param: {
				...pick(this, copyParams),
				id: this.id,
				groupId: this.groupId,
				connectors: this.connectors.map((c) => c.id),
				automaticConnector: this.automaticConnector?.componentItemId,
				edges: this.connectionEdges.map((edge) => ({
					id: edge.id,
					centerPoint: edge.centerPoint,
					connectedTo: edge.connectedTo?.id,
				})),
			} as LampConfig,
		};
	}

	clone() {
		const params = {
			id: crypto.randomUUID(),
			...pick(this, copyParams),
			automaticConnector: this.automaticConnector,
			connectors: [],
			edges: [],
			isShadow: false,
			mouseOver: false,
			isDragging: false,
		} as LampConfig;

		const lamp = new Lamp(params);

		return lamp;
	}
}
