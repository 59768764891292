import {
	ApiError,
	AppError,
	Currency,
	Language,
	StringSignature,
} from '@/types/types';
import { formatDistance } from 'date-fns';
import { validatePolish } from 'validate-polish';
import { flatten, groupBy } from 'lodash';
import { ComponentItem } from '@/api/ComponentItemApi';
import { MantineTheme } from '@mantine/core';
import { ErrorCode } from '@/providers/ContentProvider';
import { isAxiosError } from 'axios';
import { ComponentType, ConnectorType } from '@/api/ComponentApi';
import { ParameterCategoryType } from '@/api/ParameterCategoryApi';
import { Project, ProjectComponentItem, ProjectStatus } from '@/api/ProjectApi';
import { DateValue } from '@mantine/dates';
import { roundToHalf } from './rendererUtils';
import {
	ComponentItemsChanges,
	CreatorComponentItem,
	ViewType,
} from '@/types/creator';
import { Room } from '@/3d/core/Room';
import { CreatorSliceState } from '@/store/slices/creatorSlice';
import {
	RendererComponent,
	RendererComponentMontageType,
} from '@/3d/core/types/objects';
import { ParameterPick } from '@/components/views/creator/partials/componentParameters/ComponentParameters';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import CREATOR_CONFIG from '@/configs/creatorConfig';
import APP_CONFIG from '@/configs/appConfig';
import moment from 'moment';

export function formatDateForInput(date: moment.MomentInput, format?: string) {
	return moment(date, format).format('YYYY-MM-DD');
}

export function formatDateForPresentation(date: moment.MomentInput) {
	if (!date) return '-';
	return moment(date).format('DD.MM.YYYY');
}

export function formatTimeForPresentation(
	date: moment.MomentInput | string = new Date()
) {
	return moment(date).format('HH:mm');
}

const PASSWORD_REGEX = /^(?=\D*\d)[^ ]{6,}$/;

/**
 *
 * @param password one number and at least 8
 * @returns
 */
export function validatePassword(password: string) {
	return PASSWORD_REGEX.test(password);
}

const PHONE_REGEX =
	/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;

/**
 *
 * @param phoneNumber validate international
 * @returns
 */
export function validatePhoneNumber(phoneNumber: string) {
	return PHONE_REGEX.test(phoneNumber) && phoneNumber.length >= 9;
}

/**
 *
 * @param accountNumber validate polish
 * @returns
 */
export function validateAccountNumber(accountNumber: string) {
	const trimmed = accountNumber.trim();
	return /\d{26}/.test(trimmed);
}

/**
 *
 * @param postalCode validate polish
 * @returns
 */
export function validatePostalCode(postalCode: string) {
	const trimmed = postalCode.trim();
	return /^[0-9]{2}-[0-9]{3}/.test(trimmed);
}

export function validatePesel(pesel: string) {
	const trimmed = pesel.trim();
	return validatePolish.pesel(trimmed);
}

/**
 *
 * @param vatNumber validate polish
 * @returns
 */
export function validateVatNumber(vatNumber: string) {
	const trimmed = vatNumber.trim();
	return validatePolish.nip(trimmed);
}

export function removeWhiteSpaces(string: string) {
	return string.replace(/\s/g, '');
}

export function isValidDateObj(date: moment.MomentInput) {
	return moment(date).isValid();
}

export function incrementDate(date: moment.MomentInput) {
	return moment(date).add(1, 'day').toDate();
}

export function randomDate(start: Date, end: Date) {
	return new Date(
		start.getTime() + Math.random() * (end.getTime() - start.getTime())
	);
}

export function sortByDate(
	dateA: string | Date,
	dateB: string | Date,
	order: 'asc' | 'desc' = 'asc'
) {
	const first = moment(dateA).valueOf(),
		second = moment(dateB).valueOf();

	return order === 'asc' ? first - second : second - first;
}

export function stripHTML(string: string) {
	return string.replace(/(<([^>]+)>)/gi, '');
}

export function handleUnload(e: BeforeUnloadEvent) {
	e.preventDefault();
	e.returnValue = '';
}

export const ID_REGEX =
	/[a-f0-9]{8}-?[a-f0-9]{4}-?[a-f0-9]{4}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/g;

export function getIdsFromText(text: string) {
	return [...new Set(flatten([...text.matchAll(ID_REGEX)]))];
}

export function fileTypeToHeader(fileType: 'csv' | 'xlsx' | 'pdf') {
	switch (fileType) {
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'csv':
			return 'text/csv';
		case 'pdf':
			return 'application/pdf';
		default:
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	}
}

export function getContent(conent: StringSignature, slug: string) {
	return conent[slug];
}

export function formatDateFromNow(date: Date, locale: Locale) {
	return formatDistance(date, new Date(), {
		locale,
		includeSeconds: true,
		addSuffix: true,
	});
}

export function projectStatusColor(
	theme: MantineTheme,
	status = ProjectStatus.NOT_SENT
) {
	const { colors } = theme;

	switch (status) {
		case ProjectStatus.NOT_SENT:
			return colors.red[1];
		case ProjectStatus.SENT:
			return colors.green[0];
		case ProjectStatus.ARCHIVED:
			return colors.gray[3];
	}
}

export const formatPrice = (value: number, opts?: Intl.NumberFormatOptions) => {
	const lang = opts?.currency === Currency.EUR ? 'en' : 'pl';

	const formatter = new Intl.NumberFormat(lang, {
		style: 'currency',
		currency: Currency.PLN,
		currencyDisplay: 'code',
		maximumFractionDigits: !value ? 0 : undefined,
		...opts,
	});

	return formatter.format(value);
};

export function checkIfScreenZoomedIn() {
	return window.devicePixelRatio >= 1.2;
}

export const isApiError = (error: unknown): error is ApiError => {
	return (
		!!(error as AppError).status &&
		!!(error as AppError).stack &&
		!!(error as ApiError).message
	);
};

export const isAppError = (error: unknown): error is AppError => {
	return !!(error as AppError).status && !!(error as AppError).stack;
};

export const getErrorCodes = (error: unknown) => {
	if (isAxiosError<ApiError>(error) && error.response)
		return Array.isArray(error.response.data.message)
			? error.response.data.message
			: [error.response.data.message];

	return [ErrorCode.GENERIC];
};

export const errorCodesToMessage = (errorCodes: ErrorCode[]) =>
	errorCodes.map((code) => `errors.${code}`).join(', ');

export function getFileSrc(path?: string) {
	return `${import.meta.env.VITE_API_URL_UPLOADS}${path}`;
}

export function getComponentTypeSlug(componentType: ComponentType) {
	switch (componentType) {
		case ComponentType.LAMP:
			return 'creator.componentType.lamp';
		case ComponentType.CONNECTOR:
			return 'creator.componentType.connector';
		case ComponentType.ACCESSORY:
			return 'creator.componentType.accessory';
		case ComponentType.SLING:
			return 'creator.componentType.sling';
	}
}

export function formatDateForApi(date: DateValue) {
	if (!date) return null;

	return moment(date).format('YYYY-MM-DD');
}

export function creatorStateToJson(room: Room, creator: CreatorSliceState) {
	const state: any = structuredClone(creator);

	state.renderer.mesh = room.save();

	state.project.creatorComponentItems = [];
	state.project.projectComponentItems = [];
	state.project.accessories = [];

	delete state.project.user;
	delete state.project.previewImage;
	state.options.draggedComponentItem = null;
	state.options.selectedComponent = null;
	state.options.selectedComponentCategory = null;
	state.options.selectedCreatorComponentItems = [];
	state.options.currentStep = 0;
	state.options.refresher = 0;
	state.project.options = '';
	state.renderer.zoomLevel = RENDERER_CONFIG.CAMERA.ZOOM.MD;
	state.renderer.canUndo = false;
	state.renderer.viewType = ViewType.RECTANGULAR;

	const json = JSON.stringify(state);

	return json;
}

export function calculateLightSummary(
	projectComponentItems: ProjectComponentItem[],
	area: number,
	roomHeight: number
) {
	let fluxSum = 0;
	let lampsLengthSum = 0;
	let fluxPerLampMeter = 0;
	let powerSum = 0;
	let intensity = 0;

	for (const projectComponentItem of projectComponentItems) {
		lampsLengthSum +=
			(projectComponentItem.componentItemData.length! *
				projectComponentItem.quantity) /
			100;

		const fluxParam = projectComponentItem.componentItemData.parameters.find(
			(p) => p.parameterCategory.type === ParameterCategoryType.FLUX
		);

		if (fluxParam)
			fluxSum += Number(fluxParam.value) * projectComponentItem.quantity;

		const powerParam = projectComponentItem.componentItemData.parameters.find(
			(p) => p.parameterCategory.type === ParameterCategoryType.POWER
		);
		if (powerParam)
			powerSum += Number(powerParam.value) * projectComponentItem.quantity;
	}

	fluxPerLampMeter = fluxSum / (lampsLengthSum || 1);

	intensity =
		area && roomHeight
			? (fluxSum / 1900) *
			  390.36 *
			  Math.pow((area / 10_000) * roomHeight, -0.578)
			: 0;

	return { fluxSum, fluxPerLampMeter, powerSum, intensity };
}

export function getComponentObjectDimensions(componentItem: ComponentItem) {
	return {
		length: componentItem.length
			? roundToHalf(componentItem.length / 10 / RENDERER_CONFIG.MIN_DIST_GRID) *
			  RENDERER_CONFIG.MIN_DIST_GRID
			: RENDERER_CONFIG.GENERIC_OBJECT_DIMENSIONS.length,
		width: componentItem.width
			? roundToHalf(
					(componentItem.width * 2) / 10 / RENDERER_CONFIG.MIN_DIST_GRID
			  ) * RENDERER_CONFIG.MIN_DIST_GRID
			: RENDERER_CONFIG.GENERIC_OBJECT_DIMENSIONS.width,
		height: componentItem.thickness
			? componentItem.thickness / 10
			: RENDERER_CONFIG.GENERIC_OBJECT_DIMENSIONS.height,
	};
}

export function getClosestNumber(number: number, array: number[]) {
	return array.reduce(
		(prev, curr) =>
			Math.abs(curr - number) < Math.abs(prev - number) ? curr : prev,
		0
	);
}

export function isDefaultLanguage(language: Language): language is 'PL' {
	return language === APP_CONFIG.DEFAULT_LANGUAGE;
}

export function getParameterCategoryName(
	translatedName: string,
	unit?: string
) {
	let name = translatedName;
	if (unit) name += ` [${unit}]`;
	return name;
}

export function isAutomaticConnector(connectorType: ConnectorType | null) {
	if (!connectorType) return false;
	return [ConnectorType.I, ConnectorType.SQUARE].includes(connectorType);
}

export function natSort(nameA = '', nameB = '', order: 'asc' | 'desc' = 'asc') {
	let a: string | number = Number(nameA),
		b: string | number = Number(nameB);

	if (isNaN(a) || isNaN(b)) {
		return order === 'asc'
			? nameA.localeCompare(nameB)
			: nameB.localeCompare(nameA);
	}

	return order === 'asc' ? a - b : b - a;
}

export function inputWidthValue(value: string) {
	return `${value.length + 3}ch`;
}

export function getProjectObjectGroups(rendererObjects: RendererComponent[]) {
	const groups: Project['groups'] = [];
	const groupedObjects = groupBy(rendererObjects, (obj) => obj.groupId);

	for (const groupId in groupedObjects) {
		const group = groupedObjects[groupId];

		if (groupId === 'undefined')
			for (const object of group) {
				groups.push([object.componentItemId]);
			}
		else groups.push(group.map((obj) => obj.componentItemId));
	}

	return groups;
}

export function getProjectComponentItemsChanges(
	creatorComponentItem: CreatorComponentItem[]
) {
	const payload: Record<string, CreatorComponentItem[] | number> = groupBy(
		creatorComponentItem,
		(item) => item.componentItemId
	);

	for (const entry in payload) {
		const ele = payload[entry];
		if (Array.isArray(ele)) payload[entry] = ele.length;
	}

	return payload as ComponentItemsChanges;
}

export function calculateColsAmount(
	paramValueLength: number,
	parametersAmmount: number
) {
	if (paramValueLength >= 20 || parametersAmmount <= 5) return 1;
	if (paramValueLength >= 10) return 2;
	return 3;
}

export function getEmptyParameterPick(): ParameterPick {
	return {
		id: '',
		value: '',
		translations: {
			GB: {
				value: '',
				description: '',
			},
			DE: {
				value: '',
				description: '',
			},
			FR: {
				value: '',
				description: '',
			},
			CZ: {
				value: '',
				description: '',
			},
		},
	};
}

export function isSafari() {
	return /Safari/.test(navigator.userAgent);
}

export function getComponentItemMontageType(componentItem: ComponentItem) {
	const montageTypeParam = componentItem.parameters.find(
		(param) =>
			param.parameterCategory.type === ParameterCategoryType.ATTACHMENT_METHOD
	);

	const montageType =
		montageTypeParam?.value === CREATOR_CONFIG.PARAM_SLIGN_NAME
			? RendererComponentMontageType.SLIGN
			: RendererComponentMontageType.CEILING;

	return montageType;
}
