import { Wall } from './Wall';
import { MouseOver } from './interfaces/MouseOver';
import { NodeDirection } from './enums/NodeDirection';
import { NodeObject, ObjectTypeName } from './types/objects';
import { UUID } from '@/types/types';
import RENDERER_CONFIG from '@/configs/rendererConfig';

export type NodeConfig = {
	angle: number;
};

export class Node implements MouseOver {
	id: UUID = crypto.randomUUID();
	wallA!: Wall;
	wallB!: Wall;
	angle = 90;
	mouseOver = false;

	constructor(config: NodeConfig) {
		this.angle = config.angle;
	}

	getNewWallAngle(angleA: number) {
		if (angleA === 90 && this.angle === 90) {
			return 45;
		} else if (angleA === 90 && this.angle === 270) {
			return 135;
		} else if (angleA === 0 && this.angle === 90) {
			return 315;
		} else if (angleA === 0 && this.angle === 270) {
			return 45;
		} else if (angleA === 180 && this.angle === 90) {
			return 135;
		} else if (angleA === 180 && this.angle === 270) {
			return 225;
		} else if (angleA === 270 && this.angle === 90) {
			return 225;
		} else if (angleA === 270 && this.angle === 270) {
			return 315;
		} else {
			return 0;
		}
	}

	connectWall(wall: Wall, direction: NodeDirection) {
		switch (direction) {
			case NodeDirection.Start:
				this.wallA = wall;
				break;
			case NodeDirection.End:
				this.wallB = wall;
				break;
		}

		wall.pushNode(this);
	}

	getConnectedWalls() {
		return {
			wallA: this.wallA,
			wallB: this.wallB,
		};
	}

	isWallConnected(wall: Wall) {
		return wall === this.wallA || wall === this.wallB;
	}

	getWallBetween(node: Node) {
		const { wallA, wallB } = this.getConnectedWalls();
		const { wallA: nodeWallA, wallB: nodeWallB } = node.getConnectedWalls();

		if (wallA === nodeWallA || wallA === nodeWallB) {
			return wallA;
		} else if (wallB === nodeWallA || wallB === nodeWallB) {
			return wallB;
		}
	}

	areWallsBetweenSkew(node: Node) {
		const wall = this.getWallBetween(node);
		return wall ? wall.isSkew() : false;
	}

	getSquareLinePoints(thickness = 0) {
		const halfSide = thickness / 2;

		const intersection = this.getIntersection();

		if (intersection === null) {
			return null;
		}

		const points = [
			{ x: intersection.x - halfSide, y: intersection.y - halfSide },
			{ x: intersection.x + halfSide, y: intersection.y - halfSide },
			{ x: intersection.x + halfSide, y: intersection.y + halfSide },
			{ x: intersection.x - halfSide, y: intersection.y + halfSide },
		];

		return points;
	}

	getSquareCornerPoints(thickness = 0) {
		const points = this.getSquareLinePoints(thickness);
		if (points === null) {
			return [];
		}
		return points.reverse();
	}

	getRotatedSquareCornerPoints(thickness = 0) {
		const cornerPoints = this.getSquareCornerPoints(thickness);
		const intersection = this.getIntersection();
		if (!intersection) return [];

		const { x: centerX, y: centerY } = intersection;

		const angle = this.getRotateAngle();

		return this.rotateCornerPoints(cornerPoints, angle, centerX, centerY);
	}

	getRotateAngle() {
		return this.angle === 90
			? this.wallA.angle
			: (this.angle + this.wallA.angle) % 360;
	}

	rotateCornerPoints(
		cornerPoints: Array<{ x: number; y: number }>,
		angle: number,
		centerX: number,
		centerY: number
	) {
		const radAngle = (angle * Math.PI) / 180;
		const rotatedPoints = cornerPoints.map((point) => {
			const x = point.x - centerX;
			const y = point.y - centerY;

			const newX = x * Math.cos(radAngle) - y * Math.sin(radAngle);
			const newY = x * Math.sin(radAngle) + y * Math.cos(radAngle);

			return { x: newX + centerX, y: newY + centerY };
		});

		return rotatedPoints;
	}

	getRotatedSkewShape(thickness = 0) {
		const skewShape = this.getSkewShape(thickness);
		const intersection = this.getIntersection();
		if (intersection === null) {
			return [];
		}

		const { x: centerX, y: centerY } = intersection;

		const angle = this.getRotateAngle() + (this.angle === 135 ? 90 : -135);

		return this.rotateCornerPoints(skewShape, angle, centerX, centerY);
	}

	getSkewShape(thickness = 0) {
		const intersection = this.getIntersection();
		if (!intersection) return [];

		const halfThickness = thickness / 2;

		const { x: centerX, y: centerY } = intersection;

		const p1 = { x: centerX - halfThickness, y: centerY + halfThickness };
		const p2 = { x: centerX + halfThickness, y: centerY + halfThickness };

		const l1 = Math.sqrt(
			Math.pow(halfThickness, 2) + Math.pow(halfThickness, 2)
		);

		const p3 = {
			x: p2.x,
			y: p2.y - (halfThickness + 1) / 2,
		};

		const p4 = {
			x: centerX + l1,
			y: centerY,
		};
		const p5 = {
			x: centerX,
			y: centerY - l1,
		};

		const p6 = {
			x: p1.x,
			y: p1.y - halfThickness - 2,
		};

		return [p1, p2, p3, p4, p5, p6];
	}

	getIntersection() {
		const { x: x1, y: y1 } = this.wallA.pos;
		const angle1 = this.wallA.angle;

		const { x: x2, y: y2 } = this.wallB.pos;
		const angle2 = this.wallB.angle;

		const radAngle1 = (angle1 * Math.PI) / 180;
		const radAngle2 = (angle2 * Math.PI) / 180;

		const dx1 = Math.cos(radAngle1);
		const dy1 = Math.sin(radAngle1);
		const dx2 = -Math.cos(radAngle2);
		const dy2 = -Math.sin(radAngle2);

		const det = dx1 * dy2 - dy1 * dx2;

		if (Math.abs(det) < 1e-9) return null;

		const t = ((x2 - x1) * dy2 - (y2 - y1) * dx2) / det;

		return {
			x: x1 + t * dx1,
			y: y1 + t * dy1,
		};
	}

	getInnerIntersection() {
		const point = this.getIntersection();
		if (!point) return null;

		const offset: number = RENDERER_CONFIG.WALL_THICKNESS / 2;

		if (this.angle === 90)
			switch (this.wallB.angle) {
				case 0:
					point.x += offset;
					point.y -= offset;
					break;
				case 90:
					point.x += offset;
					point.y += offset;
					break;
				case 180:
					point.x -= offset;
					point.y += offset;
					break;
				case 270:
					point.x -= offset;
					point.y -= offset;
					break;
			}

		if (this.angle === 135)
			switch (this.wallB.angle) {
				case 0:
					point.x += offset / 2;
					point.y -= offset;
					break;
				case 45:
					point.x += offset;
					point.y -= offset / 2;
					break;
				case 90:
					point.x += offset;
					point.y += offset / 2;
					break;
				case 135:
					point.x += offset / 2;
					point.y += offset;
					break;
				case 180:
					point.x -= offset / 2;
					point.y += offset;
					break;
				case 225:
					point.x -= offset;
					point.y += offset / 2;
					break;
				case 270:
					point.x -= offset;
					point.y -= offset / 2;
					break;
				case 315:
					point.x -= offset / 2;
					point.y -= offset;
					break;
			}

		if (this.angle === 225)
			switch (this.wallB.angle) {
				case 0:
					point.x += offset;
					point.y -= offset;
					break;
				case 45:
					point.x += offset / 2;
					point.y -= offset;
					break;
				case 90:
					point.x += offset;
					point.y -= offset / 2;
					break;
				case 225:
					point.x -= offset / 2;
					point.y += offset;
					break;
				case 270:
					point.x -= offset;
					point.y += offset / 2;
					break;
			}

		if (this.angle === 270)
			switch (this.wallB.angle) {
				case 0:
					point.x += offset;
					point.y -= offset;
					break;
				case 90:
					point.x += offset;
					point.y -= offset;
					break;
				case 180:
					point.x -= offset;
					point.y += offset;
					break;
				case 270:
					point.x -= offset;
					point.y += offset;
					break;
			}

		return point;
	}

	setAngle(angle: number) {
		this.angle = angle;
	}

	save(): NodeObject {
		return {
			object: ObjectTypeName.NODE,
			param: {
				angle: this.angle,
			},
		};
	}
}
