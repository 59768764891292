import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	TableOptions,
	useReactTable,
} from '@tanstack/react-table';
import { Optional } from 'utility-types';
import { Pagination, Select, Skeleton, Title } from '@mantine/core';
import APP_CONFIG from '@/configs/appConfig';
import classNames from 'classnames';
import ArrowDownIcon from '@/assets/icons/arrowDown.svg?react';
import ArrowUpIcon from '@/assets/icons/arrowUp.svg?react';
import styles from './Table.module.scss';

interface Props<TData> {
	reactTableProps: Optional<TableOptions<TData>, 'getCoreRowModel'>;
	withFooter?: boolean;
	loading?: boolean;
	emptyView?: JSX.Element;
	handleRowSelect?: (selected: TData[]) => void;
	pageSizeSelectSuffix?: string;
	pagination?: boolean;
}

export const ClientTable = <TData extends {}>({
	reactTableProps,
	withFooter,
	loading,
	emptyView,
	handleRowSelect,
	pageSizeSelectSuffix = 'wyników na stronę',
	pagination = true,
}: Props<TData>) => {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [rowSelection, setRowSelection] = useState({});

	const table = useReactTable({
		...reactTableProps,
		state: {
			sorting,
			rowSelection,
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: reactTableProps.getCoreRowModel || getCoreRowModel(),
		autoResetPageIndex: false,
	});

	useEffect(() => {
		if (!handleRowSelect) return;

		handleRowSelect(
			table.getSelectedRowModel().rows.map((row) => row.original)
		);
	}, [table.getSelectedRowModel()]);

	const skeletonView = useMemo(
		() =>
			new Array(table.getRowModel().rows.length).fill(null).map((_, index) => (
				<tr key={index}>
					<td colSpan={table.getHeaderGroups().length}>
						<Skeleton height={18} radius="lg" />
					</td>
				</tr>
			)),
		[]
	);

	if (!reactTableProps.data.length && !loading)
		return (
			emptyView || (
				<Title order={2} my={32}>
					Ta Lista jest pusta!
				</Title>
			)
		);

	return (
		<>
			<div className={styles.tableContainer}>
				<table className="table">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									if (!header.column.getCanSort())
										return (
											<th
												key={header.id}
												style={header.column.columnDef.meta?.style}
											>
												{!header.isPlaceholder &&
													flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
											</th>
										);

									const sort = header.column.getIsSorted();

									return (
										<th key={header.id}>
											{!header.isPlaceholder && (
												<button
													type="button"
													className={styles.sortedHeader}
													onClick={header.column.getToggleSortingHandler()}
													style={header.column.columnDef.meta?.style}
												>
													{flexRender(
														header.column.columnDef.header,
														header.getContext()
													)}
													<div
														className={classNames(styles.sortIcons, {
															[styles.sortIconsAsc]: sort === 'asc',
															[styles.sortIconsDesc]: sort === 'desc',
														})}
													>
														<ArrowUpIcon />
														<ArrowDownIcon />
													</div>
												</button>
											)}
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{loading
							? skeletonView
							: table.getRowModel().rows.map((row) => (
									<tr
										key={row.id}
										className={classNames({
											[styles.selected]: row.getIsSelected(),
										})}
									>
										{row.getVisibleCells().map((cell) => (
											<td
												key={cell.id}
												style={cell.column.columnDef.meta?.style}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</td>
										))}
									</tr>
							  ))}
					</tbody>
					{withFooter && (
						<tfoot>
							{table.getFooterGroups().map((footerGroup) => (
								<tr key={footerGroup.id}>
									{footerGroup.headers.map((header) => (
										<th key={header.id}>
											{!header.isPlaceholder &&
												flexRender(
													header.column.columnDef.footer,
													header.getContext()
												)}
										</th>
									))}
								</tr>
							))}
						</tfoot>
					)}
				</table>
			</div>
			{pagination && (
				<div className={styles.paginationContainer}>
					<div className="hide-on-mobile"></div>
					<Pagination
						value={table.getState().pagination.pageIndex + 1}
						total={table.getPageCount()}
						onChange={(page) => table.setPageIndex(page - 1)}
					/>
					<Select
						value={table.getState().pagination.pageSize.toString()}
						itemComponent={SelectItem}
						data={APP_CONFIG.TABLE_PAGE_SIZES.map((size) => ({
							label: `${size} ${pageSizeSelectSuffix}`,
							value: size.toString(),
						}))}
						onChange={(val) => table.setPageSize(Number(val))}
						classNames={{
							root: styles.selectRoot,
							input: styles.selectInput,
							item: styles.selectItem,
							dropdown: styles.selectDropwdown,
						}}
					/>
				</div>
			)}
		</>
	);
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
	value: string;
	label: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
	({ value, ...others }, ref) => (
		<div ref={ref} {...others}>
			{value}
		</div>
	)
);
