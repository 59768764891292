import { Room } from '../core/Room';
import { Node } from '../core/Node';
import { MeshManager } from './MeshManager';
import {
	MaterialFactory,
	MaterialType,
	concreteMaterial,
} from './MaterialFactory';
import { ObjectTypeName } from '../core/types/objects';
import { ViewType } from '@/types/creator';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import * as THREE from 'three';

export enum NodeMaterialPosition {
	INSIDE = 'inside',
}

const canvas = document.createElement('canvas');
const context = canvas.getContext('2d')!;

canvas.width = 300;
canvas.height = 300;

context.fillStyle = '#07353f';
context.fillRect(0, 0, canvas.width, canvas.height);

const innerWidth = canvas.width / 3;

const hoverTexture = new THREE.CanvasTexture(canvas);
hoverTexture.colorSpace = THREE.SRGBColorSpace;

context.fillStyle = '#ffffff';
context.fillRect(innerWidth, innerWidth, innerWidth, innerWidth);

const NODE_COLOR = 0xb9b1a8;
const NODE_HOVER_COLOR = 0x07353f;
const NODE_OUTSIDE_HOVER_COLOR = 0xf0f2f4;
const LINE_OPTIONS = {
	color: 0x2d2d2d,
	linewidth: 0.0008,
	polygonOffset: true,
	polygonOffsetFactor: -1,
	polygonOffsetUnits: 1,
};
const MATERIAL_LINE_OFFSET_OPTIONS = {
	polygonOffset: true,
	polygonOffsetFactor: 1,
	polygonOffsetUnits: 1,
};

export class NodeManager extends MeshManager {
	private nodeMeshes = new Map<Node, THREE.Mesh | THREE.Mesh[]>();
	private materials = {
		square: {
			top: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: NODE_COLOR,
			}),
			topHover: new THREE.MeshBasicMaterial({
				map: hoverTexture,
			}),
			hidden: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				transparent: true,
				opacity: 0,
				...MATERIAL_LINE_OFFSET_OPTIONS,
			}),
			inside: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				color: NODE_OUTSIDE_HOVER_COLOR,
				...MATERIAL_LINE_OFFSET_OPTIONS,
			}),
			outside: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
				transparent: true,
				opacity: 0.5,
				color: NODE_OUTSIDE_HOVER_COLOR,
				...MATERIAL_LINE_OFFSET_OPTIONS,
			}),
		},
		realistic: {
			concrete: concreteMaterial.clone(),
			...MATERIAL_LINE_OFFSET_OPTIONS,
		},
	};

	constructor(
		protected override room: Room,
		protected override meshGroup: THREE.Group
	) {
		super(room, meshGroup);
	}

	update() {
		this.removeUnusedMeshes(
			this.nodeMeshes,
			new Set(this.room.nodes),
			ObjectTypeName.NODE
		);

		for (const node of this.room.nodes) {
			if (!this.nodeMeshes.has(node)) {
				this.createNodeMesh(node);
			} else {
				this.updateNodeMesh(node);
			}
		}
	}

	private createNodeMesh(node: Node) {
		if ([90, 270].includes(node.angle)) this.createNodeMeshSquare(node);
		else this.createNodeMeshSkew(node);

		this.createNodeEdges(node);
	}

	private getNodeSquareMaterialsBasedOnAngle(node: Node) {
		let angle = node.getRotateAngle();

		const topMaterial = node.mouseOver
			? this.materials.square.topHover
			: this.materials.square.top;

		const isInside = node.angle > 180;

		const nodeSideMaterial = isInside
			? this.materials.square.inside
			: this.materials.square.outside;

		const materials = [
			this.materials.square.hidden,
			this.materials.square.hidden,
			topMaterial,
			this.materials.square.hidden,
			this.materials.square.hidden,
			this.materials.square.hidden,
		];

		angle = isInside ? (angle + 180) % 360 : angle;

		if (angle === 0) {
			materials[0] = nodeSideMaterial;
			materials[5] = nodeSideMaterial;
		} else if (angle === 90) {
			materials[0] = nodeSideMaterial;
			materials[4] = nodeSideMaterial;
		} else if (angle === 180) {
			materials[4] = nodeSideMaterial;
			materials[1] = nodeSideMaterial;
		} else if (angle === 270) {
			materials[1] = nodeSideMaterial;
			materials[5] = nodeSideMaterial;
		}

		return materials;
	}

	private createNodeMeshSquare(node: Node) {
		const columnThickness = node.mouseOver
			? 30
			: RENDERER_CONFIG.WALL_THICKNESS;
		const columnLength = node.mouseOver ? 30 : RENDERER_CONFIG.WALL_THICKNESS;
		const columnHeight = this.room.height;

		const intersection = node.getIntersection();
		if (!intersection) return;

		const { x, y } = intersection;

		const nodeGeometry = new THREE.BoxGeometry(
			columnThickness,
			columnHeight,
			columnLength
		);

		const materials = this.getNodeSquareMaterialsBasedOnAngle(node);

		const nodeMesh = new THREE.Mesh(nodeGeometry, materials);
		nodeMesh.position.set(x, columnHeight / 2, y);

		nodeMesh.rotation.y = -node.angle * (Math.PI / 180);
		nodeMesh.userData.nodeId = node.id;

		this.nodeMeshes.set(node, nodeMesh);
		this.meshGroup.add(nodeMesh);
	}

	private createNodeMeshSkew(node: Node) {
		const points = node.getRotatedSkewShape(10.1);
		const nodeIndex = this.room.nodes.indexOf(node);
		const shapePoints: THREE.Vector3[] = [];

		for (const point of points) {
			shapePoints.push(new THREE.Vector3(point.x, this.room.height, point.y));
		}
		for (const point of points) {
			shapePoints.push(new THREE.Vector3(point.x, 0.4, point.y));
		}

		const geometryTop = new THREE.BufferGeometry().setFromPoints(shapePoints);
		const geometry1 = new THREE.BufferGeometry().setFromPoints(shapePoints);
		const geometry2 = new THREE.BufferGeometry().setFromPoints(shapePoints);

		const indices = [0, 1, 5, 1, 2, 5, 2, 3, 5, 3, 4, 5];
		const indices1 = [1, 7, 8, 1, 8, 2, 2, 8, 3, 3, 8, 9];
		const indices2 = [0, 11, 6, 0, 5, 11, 4, 11, 5, 4, 10, 11];

		geometryTop.setIndex(indices);
		geometry1.setIndex(indices1);
		geometry2.setIndex(indices2);

		const nodeMaterial = MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
			userData: {
				type: `${ObjectTypeName.NODE}-skew${nodeIndex}`,
			},
			color: node.mouseOver ? NODE_HOVER_COLOR : NODE_COLOR,
		});

		const nodeSideMaterial1 =
			this.room.viewType === ViewType.FIRST_PERSON
				? concreteMaterial.clone()
				: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
						color: NODE_OUTSIDE_HOVER_COLOR,
						transparent: true,
						opacity: node.angle > 180 ? 0.35 : 1,
						...MATERIAL_LINE_OFFSET_OPTIONS,
				  });
		nodeSideMaterial1.userData = {
			type: `${ObjectTypeName.NODE}-${nodeIndex}-${NodeMaterialPosition.INSIDE}-skew-1`,
		};

		const nodeSideMaterial2 =
			this.room.viewType === ViewType.FIRST_PERSON
				? concreteMaterial.clone()
				: MaterialFactory.getMaterial(MaterialType.MESH_BASIC, {
						transparent: true,
						opacity: node.angle > 180 ? 1 : 0.35,
						color: NODE_OUTSIDE_HOVER_COLOR,
						...MATERIAL_LINE_OFFSET_OPTIONS,
				  });
		nodeSideMaterial2.userData = {
			type: `${ObjectTypeName.NODE}-${nodeIndex}-${NodeMaterialPosition.INSIDE}-skew-2`,
		};

		const meshTop = new THREE.Mesh(geometryTop, nodeMaterial);
		const mesh1 = new THREE.Mesh(geometry1, nodeSideMaterial1);
		const mesh2 = new THREE.Mesh(geometry2, nodeSideMaterial2);

		for (const mesh of [meshTop, mesh1, mesh2]) {
			mesh.userData.nodeId = node.id;
		}

		this.meshGroup.add(meshTop);
		this.meshGroup.add(mesh1);
		this.meshGroup.add(mesh2);
		this.nodeMeshes.set(node, [meshTop, mesh1, mesh2]);
	}

	private createSkewNodeEdges(node: Node) {
		const points = node.getRotatedSkewShape(10.1);

		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-top1`,
			startVector.set(points[1].x, this.room.height, points[1].y),
			endVector.set(points[2].x, this.room.height, points[2].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-top2`,
			startVector.set(points[2].x, this.room.height, points[2].y),
			endVector.set(points[3].x, this.room.height, points[3].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-top3`,
			startVector.set(points[4].x, this.room.height, points[4].y),
			endVector.set(points[5].x, this.room.height, points[5].y),
			LINE_OPTIONS
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-top4`,
			startVector.set(points[5].x, this.room.height, points[5].y),
			endVector.set(points[0].x, this.room.height, points[0].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-bottom1`,
			startVector.set(points[1].x, 0, points[1].y),
			endVector.set(points[2].x, 0, points[2].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-bottom2`,
			startVector.set(points[2].x, 0, points[2].y),
			endVector.set(points[3].x, 0, points[3].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-bottom3`,
			startVector.set(points[4].x, 0, points[4].y),
			endVector.set(points[5].x, 0, points[5].y),
			LINE_OPTIONS
		);
		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-bottom4`,
			startVector.set(points[5].x, 0, points[5].y),
			endVector.set(points[0].x, 0, points[0].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-vert1`,
			startVector.set(points[2].x, this.room.height, points[2].y),
			endVector.set(points[2].x, 0, points[2].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-vert2`,
			startVector.set(points[5].x, this.room.height, points[5].y),
			endVector.set(points[5].x, 0, points[5].y),
			LINE_OPTIONS
		);
	}

	private createSquareNodeEdges(node: Node) {
		const points = node.getRotatedSquareCornerPoints(
			RENDERER_CONFIG.WALL_THICKNESS
		);
		const startVector = new THREE.Vector3(),
			endVector = new THREE.Vector3();

		for (let i = 0; i < points.length - 2; i++) {
			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.NODE}-${node.id}-top${i}`,
				startVector.set(points[i].x, this.room.height, points[i].y),
				endVector.set(points[i + 1].x, this.room.height, points[i + 1].y),
				LINE_OPTIONS
			);
			this.lineManager.createOrUpdateLine(
				`${ObjectTypeName.NODE}-${node.id}-bot${i}`,
				startVector.set(points[i].x, 0, points[i].y),
				endVector.set(points[i + 1].x, 0, points[i + 1].y),
				LINE_OPTIONS
			);
		}

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-bottom1`,
			startVector.set(points[1].x, this.room.height, points[1].y),
			endVector.set(points[1].x, 0, points[1].y),
			LINE_OPTIONS
		);

		this.lineManager.createOrUpdateLine(
			`${ObjectTypeName.NODE}-${node.id}-inside`,
			startVector.set(points[3].x, this.room.height, points[3].y),
			endVector.set(points[3].x, 0, points[3].y),
			LINE_OPTIONS
		);
	}

	private createNodeEdges(node: Node) {
		if ([90, 270].includes(node.angle)) this.createSquareNodeEdges(node);
		else this.createSkewNodeEdges(node);
	}

	private updateNodeMesh(node: Node) {
		const nodeMesh = this.nodeMeshes.get(node);
		const intersection = node.getIntersection();

		if (!nodeMesh || !intersection) return;

		if (Array.isArray(nodeMesh)) {
			for (const mesh of nodeMesh) {
				this.meshGroup.remove(mesh);
				mesh.geometry.dispose();
			}

			this.nodeMeshes.delete(node);
			this.createNodeMeshSkew(node);
		} else {
			nodeMesh.position.set(
				intersection.x,
				this.room.height / 2,
				intersection.y
			);

			const columnThickness = node.mouseOver
				? 30
				: RENDERER_CONFIG.WALL_THICKNESS;
			const columnLength = node.mouseOver ? 30 : RENDERER_CONFIG.WALL_THICKNESS;
			const columnHeight = this.room.height;

			let newColumnGeometry;

			newColumnGeometry = new THREE.BoxGeometry(
				columnThickness,
				columnHeight,
				columnLength
			);

			nodeMesh.material = this.getNodeSquareMaterialsBasedOnAngle(node);

			nodeMesh.geometry.dispose();
			nodeMesh.geometry = newColumnGeometry;
		}

		this.createNodeEdges(node);
	}
}
