import { ErrorCode } from '@/providers/ContentProvider';
import { HttpStatusCode } from 'axios';
import { DeepKeys } from '@tanstack/react-table';
import { EntityBase } from '@/api/EntityApi';
import LANGUAGES from '@/configs/languages';

export interface StringSignature<TData = any> {
	[key: string]: TData;
}

export type Language = keyof typeof LANGUAGES;
export type ForeignLanguage = Exclude<Language, 'PL'>;
export type Translations<TData> = Record<ForeignLanguage, TData>;

export type Order = 'asc' | 'desc';

export interface Sort<TData extends EntityBase = any> {
	key: DeepKeys<TData> | null;
	order: Order;
}

export interface ApiQueryOptions<TData extends EntityBase = any> {
	page: number;
	sizePerPage: number;
	search: string;
	searchFields: DeepKeys<TData>[];
	sort: Sort<TData>;
}

export interface ApiPaginatedResponse<TData extends {} = {}> {
	currentPage: number;
	totalPages: number;
	itemsPerPage: number;
	totalItems: number;
	data: TData[];
}

export interface ApiFormattedResponseData<TData extends {} = {}> {
	data: TData[];
	totalPages: number;
}

export type CustomFilters = Record<string, unknown>;

export interface Attachment {
	id: UUID;
	path: string;
	name: string;
	size: number;
	mimetype: string;
}

export type UUID = ReturnType<typeof crypto.randomUUID>;

export type ApiErrorMessage = ErrorCode | string | (ErrorCode | string)[];

export interface ApiError {
	error: string;
	message: ApiErrorMessage;
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
}

export enum MouseButtons {
	LEFT,
	MIDDLE,
	RIGHT,
}

export enum Currency {
	PLN = 'PLN',
	EUR = 'EUR',
}
