import { SelectedCharger, SelectedComponent } from '@/types/creator';
import { Connector } from './Connector';
import { Lamp } from './Lamp';
import { Core } from './Core';
import { Charger } from './Charger';

export type RemoveObjectsPayload = {
	selectedComponents: SelectedComponent[];
	selectedChargers: SelectedCharger[];
};

export class ObjectRemover {
	constructor(private core: Core) {}

	removeObjects(payload: RemoveObjectsPayload) {
		const components = this.core.getRendererComponents();

		for (const component of payload.selectedComponents) {
			const compoMatch = components.find((o) => o.id === component.id);
			if (!compoMatch) continue;

			this.core.collisionDetector.removeCollidable(compoMatch);

			if (compoMatch instanceof Lamp) this.removeLamp(compoMatch);
			if (compoMatch instanceof Connector) this.removeConnector(compoMatch);
		}

		for (const charger of payload.selectedChargers) {
			const chargMatch = this.core.chargers.find((o) => o.id === charger.id);
			if (!chargMatch) continue;

			this.removeCharger(chargMatch);
		}
	}

	private removeLamp(lamp: Lamp) {
		const oldConnectors = lamp.connectors;

		lamp.dispose();

		if (
			oldConnectors.filter((con) => !!con.groupId).length === 2 &&
			!oldConnectors[0].checkIfConnectorsInSameGroup(oldConnectors[1])
		) {
			this.core.unvisitObjects();
			oldConnectors[0].setNewGroupId();
			this.core.unvisitObjects();
		}
	}

	private removeConnector(connector: Connector) {
		let oldLamps = connector.lamps;

		connector.dispose();

		oldLamps = oldLamps.filter((lamp) => !!lamp.groupId);
		if (oldLamps.length < 2) return;

		const firstLamp = oldLamps[0];

		for (const lamp of oldLamps) {
			if (lamp === firstLamp) continue;

			const isSameGroup = firstLamp.checkIfLampsInSameGroup(lamp);

			this.core.unvisitObjects();

			if (isSameGroup) return;

			lamp.setNewGroupId();
			this.core.unvisitObjects();
		}
	}

	private removeCharger(charger: Charger) {
		charger.dispose();
	}
}
