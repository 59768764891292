import { QueryKey } from '@/configs/queryKeys';
import {
	ApiFormattedResponseData,
	ApiPaginatedResponse,
	ApiQueryOptions,
	UUID,
} from '@/types/types';
import { User } from '@/types/user';
import { ComponentItem } from './ComponentItemApi';
import { ComponentItemsChanges } from '@/types/creator';
import { QueryFunctionContext } from '@tanstack/react-query';
import { omit, omitBy, pick, trim } from 'lodash';
import { DatePickerValue } from '@mantine/dates';
import { formatDateForApi, incrementDate } from '@/utils/utilities';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import EntityApi from './EntityApi';
import httpClient from './httpClient';
import AppApi from './AppApi';

export enum ProjectStatus {
	NOT_SENT,
	SENT,
	ARCHIVED,
}

export interface ProjectComponentItem {
	id: UUID;
	project: Project;
	quantity: number;
	minQuantity?: number;
	netPrice: number;
	grossPrice: number;
	euroNetPrice: number;
	euroGrossPrice: number;
	componentItemData: ComponentItem;
}

export interface Project {
	id: UUID;
	name: string;
	projectIndex: number;
	contactEmail?: string;
	netPrice: number;
	grossPrice: number;
	euroNetPrice: number;
	euroGrossPrice: number;
	user: User | null;
	groups: UUID[][];
	projectComponentItems: ProjectComponentItem[];
	accessories: ProjectComponentItem[];
	componentItemsCount: number;
	status: ProjectStatus;
	isFavourite: boolean;
	updatedAt: string;
	createdAt: string;
	options: string;
}

interface ProjectQueryOptions extends ApiQueryOptions<Project> {
	status: ProjectStatus[] | null;
	isFavorite: boolean;
	dates: DatePickerValue<'range'>;
}

const PROJECT_COMPONENT_ITEM_ENDPOINT = 'project-component-items';

class ProjectApi extends EntityApi<Project> {
	constructor() {
		super(QueryKey.projects);
		this.massStatusChange = this.massStatusChange.bind(this);
		this.massDelete = this.massDelete.bind(this);
		this.shareProject = this.shareProject.bind(this);
		this.bindProjectsToUser = this.bindProjectsToUser.bind(this);
		this.getPdf = this.getPdf.bind(this);
		this.getMultiplePdf = this.getMultiplePdf.bind(this);
		this.saveScreenshot = this.saveScreenshot.bind(this);
		this.resetProject = this.resetProject.bind(this);
		this.duplicate = this.duplicate.bind(this);
		this.updateProjectComponentItems =
			this.updateProjectComponentItems.bind(this);
		this.changeAccessoryQuantity = this.changeAccessoryQuantity.bind(this);
	}

	async getFilteredPaginated({
		queryKey,
	}: QueryFunctionContext<[QueryKey, ProjectQueryOptions]>): Promise<
		ApiFormattedResponseData<Project>
	> {
		const dates = queryKey[1].dates.every((d) => !!d)
			? [
					formatDateForApi(queryKey[1].dates[0]),
					formatDateForApi(incrementDate(queryKey[1].dates[1])),
			  ]
			: [null, null];

		const params = omitBy(
			{
				page: queryKey[1].page,
				sizePerPage: queryKey[1].sizePerPage,
				[`order[${String(queryKey[1].sort.key)}]`]: queryKey[1].sort?.order,
				search: trim(queryKey[1].search),
				searchFields: queryKey[1].searchFields.join(','),
				'filter[isFavourite]': queryKey[1].isFavorite,
				'filter[status]': queryKey[1].status?.join(','),
				'dateFrom[createdAt]': dates[0],
				'dateTo[createdAt]': dates[1],
			},
			(value) => !value
		);

		const response = await httpClient.get<ApiPaginatedResponse<Project>>(
			queryKey[0],
			{
				params,
			}
		);
		return {
			data: response.data.data,
			totalPages: response.data.totalPages,
		};
	}

	async massStatusChange({
		ids,
		status,
	}: {
		ids: UUID[];
		status: ProjectStatus;
	}) {
		await Promise.all(
			ids.map((id) =>
				httpClient.patch(`${this.queryKey}/${id}`, {
					status,
				})
			)
		);
		return true;
	}

	async massDelete(ids: UUID[]) {
		await Promise.all(
			ids.map((id) => httpClient.delete(`${this.queryKey}/${id}`))
		);
		return true;
	}

	async shareProject(payload: {
		id: UUID;
		email: string;
		message?: string;
		selfSend?: boolean;
	}) {
		await httpClient.patch(
			`${this.queryKey}/${payload.id}/share`,
			omit(payload, 'id')
		);

		return true;
	}

	async removeComponentItem(removed: { id: UUID; quantity: number }[]) {
		await Promise.all(
			removed.map(({ id, quantity }) => {
				return !quantity
					? httpClient.delete(`${PROJECT_COMPONENT_ITEM_ENDPOINT}/${id}`)
					: httpClient.patch(
							`${PROJECT_COMPONENT_ITEM_ENDPOINT}/${id}/quantity`,
							{
								quantity,
							}
					  );
			})
		);

		return true;
	}

	async bindProjectsToUser({
		user,
		projects,
	}: {
		user: UUID;
		projects: UUID[];
	}) {
		await Promise.all(
			projects.map((project) =>
				httpClient.patch(`${this.queryKey}/${project}`, { user })
			)
		);
		return true;
	}

	async getPdf(id: UUID) {
		const response = await httpClient.get<Blob>(`${this.queryKey}/${id}/pdf`, {
			responseType: 'blob',
		});

		return response.data;
	}

	async getMultiplePdf(projects: Pick<Project, 'id' | 'name' | 'updatedAt'>[]) {
		const response = await Promise.all(
			projects.map(({ id }) =>
				httpClient.get<Blob>(`${this.queryKey}/${id}/pdf`, {
					responseType: 'blob',
				})
			)
		);

		return response.map((res, idx) => ({
			name: projects[idx].name,
			updatedAt: projects[idx].updatedAt,
			file: res.data,
		}));
	}

	async saveScreenshot({ id, file }: { id: UUID; file: Blob }) {
		const attachmentBody = new FormData();
		attachmentBody.append('file', file);

		const response = await httpClient.patch<Project>(
			`${this.queryKey}/${id}/add_image`,
			attachmentBody
		);

		return response.data;
	}

	async resetProject({ id, name }: { id: UUID; name: string }) {
		await httpClient.delete(`${this.queryKey}/${id}/items`);

		const response = await httpClient.patch<Project>(`${this.queryKey}/${id}`, {
			name,
			options: '',
			previewImage: null,
		});

		return response.data;
	}

	async syncLocalProject({
		queryKey,
	}: QueryFunctionContext<[QueryKey, UUID, 'update']>) {
		const response = await httpClient.get<Project>(
			`${queryKey[0]}/${queryKey[1]}`
		);

		return pick(response.data, [
			'netPrice',
			'updatedAt',
			'projectComponentItems',
			'accessories',
		]);
	}

	async duplicate(id: UUID) {
		const response = await httpClient.patch<Project>(
			`${this.queryKey}/${id}/copy`
		);

		return response.data;
	}

	async updateProjectComponentItems({
		id,
		payload,
	}: {
		id: UUID;
		payload: ComponentItemsChanges;
	}) {
		const response = await httpClient.patch<Project>(
			`${this.queryKey}/${id}/update_items`,
			payload
		);

		return response.data;
	}

	async changeAccessoryQuantity({
		project,
		payload,
	}: {
		project: UUID;
		payload: Record<UUID, number>;
	}) {
		const response = await httpClient.patch<Project>(
			`${this.queryKey}/${project}/update_accessories`,
			payload
		);

		return response.data;
	}

	async loadCustomFloor(file: File) {
		//@ts-ignore
		const isImage = IMAGE_MIME_TYPE.includes(file.type);

		if (isImage) return await AppApi.createAttachment(file);
		return await AppApi.convertFile(file);
	}
}

export default new ProjectApi();
