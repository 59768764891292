export enum RendererEvent {
	ADD_CONNECTOR = 'addConnector',
	ADD_LAMP = 'addLamp',
	ADD_CHARGER = 'addCharger',
	CENTER_CAMERA = 'centerCamera',
	CHANGE_ROOM_HEIGHT = 'changeRoomHeight',
	CHANGE_COMPONENTS_LEVEL = 'changeComponenetsLevel',
	RESIZE_RENDERER = 'resizeRenderer',
	CHANGE_VIEW_TYPE = 'changeViewType',
	CLICK_ON_EMPTY_SPACE = 'clickOnEmptySpace',
	CLICK_OUTSIDE = 'clickOutside',
	COPY_OBJECTS = 'copyObjects',
	DRAGGED_LEFT = 'draggedLeft',
	DRAG_TO_SCENE_END = 'dragToSceneEnd',
	LAMP_DRAGGING = 'lampDragging',
	CONNECTOR_DRAGGING = 'connectorDragging',
	CHARGER_DRAGGING = 'chargerDragging',
	MOUSE_DOWN = 'mouseDown',
	MOUSE_ENTER = 'mouseEnter',
	MOUSE_LEAVE = 'mouseLeave',
	MOUSE_LEAVE_RENDERER = 'mouseLeaveRenderer',
	MOUSE_UP = 'mouseUp',
	OBJECT_TRANSFERING = 'objectTransfering',
	REMOVE_OBJECTS = 'removeObjects',
	RESTART_VIEW = 'restartView',
	ROTATE_OBJECTS = 'rotateObjects',
	ROTATE_VIEW = 'rotateView',
	SELECT_ALL_OBJECTS = 'selectAllObjects',
	SELECT_DRAG = 'selectDrag',
	SELECT_DRAG_END = 'selectDragEnd',
	SUMMARY_COMPONENT_ROW_MOUSER = 'summaryComponentRowMouser',
	UPDATE_OBSERVABLE_AREA = 'updateObservableArea',
	UPDATE_RENDERER = 'updateRenderer',
	WALL_DRAGGING = 'wallDragging',
	ZOOM_CAMERA = 'zoomCamera',
	SELECT_WHOLE_GROUP = 'selectWholeGroup',
	DRAW_CUSTOM_FLOOR = 'drawCustomFloor',
}
