import { clamp } from 'lodash';
import { MouseButtons } from '@/types/types';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import * as THREE from 'three';

const GRAVITY = -9.8,
	JUMP_VELOCITY = 3;

const KEYS = {
	arrowUp: 'ArrowUp',
	arrowDown: 'ArrowDown',
	arrowLeft: 'ArrowLeft',
	arrowRight: 'ArrowRight',
	a: 'KeyA',
	s: 'KeyS',
	w: 'KeyW',
	d: 'KeyD',
	shift: 'ShiftLeft',
	space: 'Space',
} as const;

type InputControllerState = {
	leftButton: boolean;
	rightButton: boolean;
	mouseXDelta: number;
	mouseYDelta: number;
	mouseX: number;
	mouseY: number;
};

class InputController {
	target: Document;
	current: InputControllerState;
	previous: InputControllerState | null;
	keys: Record<string, boolean>;
	previousKeys: Record<string, boolean>;

	constructor() {
		this.target = document;
		this.current = {
			leftButton: false,
			rightButton: false,
			mouseXDelta: 0,
			mouseYDelta: 0,
			mouseX: 0,
			mouseY: 0,
		};
		this.previous = null;
		this.keys = {};
		this.previousKeys = {};
		this.initialize();
	}

	private initialize() {
		this.target.addEventListener('mousedown', (e) => this.onMouseDown(e));
		this.target.addEventListener('mousemove', (e) => this.onMouseMove(e));
		this.target.addEventListener('mouseup', (e) => this.onMouseUp(e));
		this.target.addEventListener('keydown', (e) => this.onKeyDown(e));
		this.target.addEventListener('keyup', (e) => this.onKeyUp(e));
	}

	private onMouseMove(e: MouseEvent) {
		this.current.mouseX = e.pageX - window.innerWidth / 2;
		this.current.mouseY = e.pageY - window.innerHeight / 2;

		if (!this.previous) this.previous = { ...this.current };

		this.current.mouseXDelta = this.current.mouseX - this.previous.mouseX;
		this.current.mouseYDelta = this.current.mouseY - this.previous.mouseY;
	}

	private onMouseDown(e: MouseEvent) {
		this.onMouseMove(e);
		switch (e.button) {
			case MouseButtons.LEFT:
				this.current.leftButton = true;
				break;
			case MouseButtons.RIGHT:
				this.current.rightButton = true;
				break;
		}
	}

	private onMouseUp(e: MouseEvent) {
		this.onMouseMove(e);

		switch (e.button) {
			case 0:
				this.current.leftButton = false;
				break;
			case 2:
				this.current.rightButton = false;
				break;
		}
	}

	private onKeyDown(e: KeyboardEvent) {
		this.keys[e.code] = true;
	}

	private onKeyUp(e: KeyboardEvent) {
		this.keys[e.code] = false;
	}

	key(key: string) {
		return !!this.keys[key];
	}

	update() {
		if (!this.previous) return;

		this.current.mouseXDelta = this.current.mouseX - this.previous.mouseX;
		this.current.mouseYDelta = this.current.mouseY - this.previous.mouseY;

		this.previous = { ...this.current };
	}

	isReady() {
		return !!this.previous;
	}
}

export class FirstPersonControls {
	private camera: THREE.PerspectiveCamera;
	private input: InputController;
	rotation: THREE.Quaternion;
	translation: THREE.Vector3;
	private phi: number;
	private phiSpeed: number;
	private theta: number;
	private thetaSpeed: number;
	private verticalVelocity: number;
	private forwardVelicityMultiplier: number;
	private sideVelocityMultiplier: number;
	private sprintVelocityMultiplier: number;
	private rotationDamping: number;
	private translationDamping: number;
	private canJump: boolean;
	private scene: THREE.Scene;

	constructor(
		camera: THREE.PerspectiveCamera,
		translation: THREE.Vector3,
		scene: THREE.Scene
	) {
		this.camera = camera;
		this.translation = translation;
		this.scene = scene;
		this.input = new InputController();
		this.rotation = RENDERER_CONFIG.CAMERA.FIRST_PERSON.INIT_QUATERNION.clone();
		this.phi = 0;
		this.theta = 0;
		this.phiSpeed = 3;
		this.thetaSpeed = 2;
		this.verticalVelocity = 0;
		this.forwardVelicityMultiplier = 125;
		this.sideVelocityMultiplier = 50;
		this.sprintVelocityMultiplier = 2;
		this.rotationDamping = 0.1;
		this.translationDamping = 0.6;
		this.canJump = true;
	}

	update(delta: number) {
		this.updateRotation();
		this.updateTranslation(delta);
		this.updateVerticalPos(delta);
		this.input.update();
		this.updateCamera();
	}

	private updateCamera() {
		const position = this.camera.position.clone();
		const quaternion = this.camera.quaternion.clone();

		this.translation.y += this.verticalVelocity;

		quaternion.slerp(this.rotation, this.rotationDamping);
		position.lerp(this.translation, this.translationDamping);

		this.camera.quaternion.copy(quaternion);
		this.camera.position.copy(position);

		const forward = new THREE.Vector3(0, 0, -1);
		forward.applyQuaternion(quaternion);

		forward.multiplyScalar(100);
		forward.add(position);

		this.camera.lookAt(forward);
	}

	private updateTranslation(delta: number) {
		const forwardVelocity =
			Number(this.isForward()) + (this.isBackward() ? -1 : 0);
		const strafeVelocity = Number(this.isLeft()) + (this.isRight() ? -1 : 0);

		const qx = new THREE.Quaternion();
		qx.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.phi);

		const sprintMultiplier = this.input.key(KEYS.shift)
			? this.sprintVelocityMultiplier
			: 1;

		const forward = new THREE.Vector3(0, 0, -1);
		forward.applyQuaternion(qx);
		forward.multiplyScalar(
			forwardVelocity *
				delta *
				this.forwardVelicityMultiplier *
				sprintMultiplier
		);

		const left = new THREE.Vector3(-1, 0, 0);
		left.applyQuaternion(qx);
		left.multiplyScalar(
			strafeVelocity * delta * this.sideVelocityMultiplier * sprintMultiplier
		);

		const translation = this.translation.clone();
		translation.add(forward);
		translation.add(left);

		if (this.detectCollision(translation)) return;

		this.translation.copy(translation);
	}

	private detectCollision(translation: THREE.Vector3) {
		const direction = new THREE.Vector3()
			.subVectors(translation, this.camera.position)
			.normalize();
		const raycaster = new THREE.Raycaster(this.camera.position, direction);
		const colidables = this.scene.children[0].children.filter(
			(child) => !!child.userData.wallId || !!child.userData.nodeId
		);

		const isColiding = raycaster
			.intersectObjects(colidables)
			.map((i) => i.distance)
			.some((dist) => dist < RENDERER_CONFIG.WALL_THICKNESS);

		return isColiding;
	}

	private updateRotation() {
		if (!this.input.current.leftButton) return;

		const xh = this.input.current.mouseXDelta / window.innerWidth;
		const yh = this.input.current.mouseYDelta / window.innerHeight;

		this.phi += -xh * this.phiSpeed;
		this.theta = clamp(
			this.theta + -yh * this.thetaSpeed,
			-Math.PI / 3,
			Math.PI / 2.25
		);

		const qx = new THREE.Quaternion();
		qx.setFromAxisAngle(new THREE.Vector3(0, 1, 0), this.phi);

		const qz = new THREE.Quaternion();
		qz.setFromAxisAngle(new THREE.Vector3(1, 0, 0), this.theta);

		const q = new THREE.Quaternion();
		q.multiply(qx);
		q.multiply(qz);

		this.rotation.copy(q);
	}

	private updateVerticalPos(delta: number) {
		this.verticalVelocity += GRAVITY * delta;

		if (this.isOnGround()) {
			this.verticalVelocity = 0;
			this.canJump = true;
		}

		if (this.canJump && this.input.key(KEYS.space)) {
			this.verticalVelocity = JUMP_VELOCITY;
			this.canJump = false;
		}
	}

	private isOnGround() {
		return this.camera.position.y <= RENDERER_CONFIG.CAMERA.FIRST_PERSON.POS_Y;
	}

	private isForward() {
		return this.input.key(KEYS.w) || this.input.key(KEYS.arrowUp);
	}

	private isBackward() {
		return this.input.key(KEYS.s) || this.input.key(KEYS.arrowDown);
	}

	private isLeft() {
		return this.input.key(KEYS.a) || this.input.key(KEYS.arrowLeft);
	}

	private isRight() {
		return this.input.key(KEYS.d) || this.input.key(KEYS.arrowRight);
	}
}
