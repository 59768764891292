import { PLFlag, GBFlag, DEFlag, FRFlag, CZFlag } from 'mantine-flagpack';
import polish from 'date-fns/locale/pl';
import english from 'date-fns/locale/en-GB';
import german from 'date-fns/locale/de';
import french from 'date-fns/locale/fr';
import czech from 'date-fns/locale/cs';

const LANGUAGES = {
	PL: {
		name: 'Polski',
		code: 'pl',
		displayCode: 'PL',
		flag: PLFlag,
		dateFnsLocale: polish,
	},
	GB: {
		name: 'English',
		code: 'en',
		displayCode: 'EN',
		flag: GBFlag,
		dateFnsLocale: english,
	},
	DE: {
		name: 'Deutsche',
		code: 'de',
		displayCode: 'DE',
		flag: DEFlag,
		dateFnsLocale: german,
	},
	FR: {
		name: 'Français',
		code: 'fr',
		displayCode: 'FR',
		flag: FRFlag,
		dateFnsLocale: french,
	},
	CZ: {
		name: 'Čeština',
		code: 'cs',
		displayCode: 'CZ',
		flag: CZFlag,
		dateFnsLocale: czech,
	},
} as const;

export default LANGUAGES;
