import React, {
	Dispatch,
	DragEventHandler,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useTypedDispatch, useTypedSelector } from '@/store/hooks';
import { Creator } from '@/3d/core/Creator';
import { RoomBuilder } from '@/3d/core/RoomBuilder';
import { MouseEvents } from '@/3d/core/MouseEvents';
import { RoomRenderer } from '@/3d/renderer/RoomRenderer';
import { MouseHandler } from '@/3d/renderer/MouseHandler';
import { History } from '@/3d/core/History/History';
import { Events } from '@/3d/core/Events';
import { Box } from '@mantine/core';
import {
	updateOptions,
	updateProject,
	updateRenderer,
	toggleSelected,
	refreshProjectComponentItems,
	removeSelectedCreatorComponentItems,
} from '@/store/slices/creatorSlice';
import { Mesh } from '@/3d/core/types/mesh';
import {
	getComponentItemMontageType,
	getComponentObjectDimensions,
	isSafari,
} from '@/utils/utilities';
import { ComponentType } from '@/api/ComponentApi';
import { ConnectorConfig } from '@/3d/core/Connector';
import { LampConfig } from '@/3d/core/Lamp';
import { useParams } from 'react-router-dom';
import { RendererEvent } from '@/3d/core/enums/RendererEvent';
import { useDebouncedValue, useWindowEvent } from '@mantine/hooks';
import { pick } from 'lodash';
import { TutorialItem } from '@/components/partials/tutorialItem/TutorialItem';
import roomShapes from '@/data/roomShapes';

export const roomHistory = new History();
export const roomCreator = new Creator();
export const roomBuilder = new RoomBuilder();
roomBuilder.restart();

interface Props {
	roomRenderer?: RoomRenderer;
	setRoomRenderer: Dispatch<SetStateAction<RoomRenderer | undefined>>;
}

export const Renderer: React.FC<Props> = ({
	roomRenderer,
	setRoomRenderer,
}) => {
	const dispatch = useTypedDispatch();

	const canvasRef = useRef<HTMLCanvasElement>(null!);

	const { project, renderer, options } = useTypedSelector((app) => app.creator);

	const { projectId } = useParams();
	const isLoading = !!projectId && !project.id;

	const buildRoom = (mesh: Mesh) => {
		roomCreator.buildMeshRoom(roomBuilder, mesh);

		dispatch(updateRenderer({ initialized: true }));
	};

	useEffect(() => {
		if (!options.isProjectLoading || !renderer.mesh) return;

		buildRoom(structuredClone(renderer.mesh));
		dispatch(updateOptions({ isProjectLoading: false }));
		dispatch(updateRenderer({ mesh: null }));
	}, [project.id]);

	useEffect(() => {
		if (options.roomShape === null || isLoading || options.isProjectLoading)
			return;
		buildRoom(structuredClone(roomShapes[options.roomShape] as Mesh));
	}, [options.roomShape]);

	useEffect(() => {
		if (!renderer.initialized) return;

		const canvas = canvasRef.current;

		const room = roomBuilder.getRoom();

		const roomRenderer = new RoomRenderer(canvas, room);
		setRoomRenderer(roomRenderer);

		const mouseEvents = new MouseEvents(room);
		const mouseHandler = new MouseHandler(
			canvas,
			roomRenderer.cameraController.mainCamera,
			mouseEvents
		);

		return () => {
			mouseHandler.dispose();
			roomRenderer.dispose();
			roomBuilder.restart();
		};
	}, [renderer.initialized]);

	useEffect(() => {
		roomBuilder.getRoom().currentStep = options.currentStep;
	}, [options.currentStep]);

	const handleDragEnter: DragEventHandler = () => {
		if (!options.draggedComponentItem) return;

		const { componentItem, automaticConnector } = options.draggedComponentItem;

		const config: Partial<ConnectorConfig> = {
			id: crypto.randomUUID(),
			componentItemId: componentItem.id,
			seriesId: componentItem.component.componentCategory.id,
			...getComponentObjectDimensions(componentItem),
		};

		if (componentItem.component.type === ComponentType.CONNECTOR)
			config.connectorType = componentItem.component.connectorType!;

		if (automaticConnector) {
			(config as Partial<LampConfig>).automaticConnector = {
				montageType: getComponentItemMontageType(automaticConnector),
				componentItemId: automaticConnector.id,
				seriesId: automaticConnector.component.componentCategory.id,
				connectorType: automaticConnector.component.connectorType!,
				...getComponentObjectDimensions(automaticConnector),
			};
		}

		Events.getInstance().emit(RendererEvent.OBJECT_TRANSFERING, config);
	};

	const handleDragOver: DragEventHandler = (e) => {
		e.preventDefault();
		e.dataTransfer.dropEffect = 'copy';
	};

	const handleDragLeave: DragEventHandler = () => {
		Events.getInstance().emit(RendererEvent.DRAGGED_LEFT);
	};

	const handleMouseLeave = () => {
		if (isSafari() || !!options.hoveredObject) return;
		Events.getInstance().emit(RendererEvent.MOUSE_LEAVE_RENDERER);
	};

	const [refresh, setRefresh] = useState(0);
	const [shortRefresh] = useDebouncedValue(refresh, 250);
	const [hoverRefresher, setHoverRefresher] = useState(0);
	const [debouncedHover] = useDebouncedValue(hoverRefresher, 1800);

	useEffect(() => {
		if (!renderer.initialized) return;

		const callback = () => setRefresh((prev) => prev + 1);

		Events.getInstance().on(RendererEvent.UPDATE_RENDERER, () => callback());
		return () => {
			Events.getInstance().remove(RendererEvent.UPDATE_RENDERER, () =>
				callback()
			);
		};
	}, [renderer.initialized]);

	const updateCreatorComponentItems = () => {
		const room = roomBuilder.getRoom();

		const roomComponents = room
			.getRendererComponents()
			.filter((obj) => !obj.isShadow);

		if (project.creatorComponentItems.length === roomComponents.length) return;

		const addedObjects = roomComponents
			.filter(
				(obj) =>
					!project.creatorComponentItems.some((ccItem) => ccItem.id === obj.id)
			)
			.map((obj) => pick(obj, ['id', 'componentItemId']));

		const removedObjects = project.creatorComponentItems.filter(
			(l) => !roomComponents.some((lamp) => lamp.id === l.id)
		);

		if (!removedObjects.length && !addedObjects.length) return;

		const updatedCreatorComponentItems = [
			...project.creatorComponentItems.filter((ccItem) =>
				removedObjects.every((lamp) => lamp.id !== ccItem.id)
			),
			...addedObjects,
		];

		dispatch(
			updateProject({
				creatorComponentItems: updatedCreatorComponentItems,
			})
		);

		dispatch(refreshProjectComponentItems());
	};

	useEffect(() => {
		if (!project.id || isLoading) return;

		const room = roomBuilder.getRoom();
		const hoveredObject = room.getHoveredObject();

		if (!hoveredObject && options.hoveredObject)
			setTimeout(() => {
				dispatch(
					updateOptions({
						hoveredObject: null,
					})
				);
			}, 250);

		if (hoveredObject) setHoverRefresher((prev) => prev + 1);

		const { label, components, chargers } = room.getSelectedObjects();

		if (
			components[0] &&
			components[0].id === options.selectedCreatorComponentItems[0]?.id
		)
			return;

		dispatch(
			toggleSelected({
				label,
				components,
				chargers,
			})
		);
	}, [refresh]);

	useEffect(() => {
		updateCreatorComponentItems();

		dispatch(
			updateRenderer({
				zoomLevel: roomRenderer?.cameraController.mainCamera.zoom,
				canUndo: roomHistory.canUndo(),
			})
		);
	}, [shortRefresh]);

	useEffect(() => {
		const room = roomBuilder.getRoom();
		const hoveredObject = room.getHoveredObject();

		dispatch(
			updateOptions({
				hoveredObject: hoveredObject
					? pick(hoveredObject, ['windowPosition', 'componentItemId'])
					: null,
			})
		);
	}, [debouncedHover]);

	useWindowEvent('keydown', ({ key }) => {
		if (key === 'Delete') dispatch(removeSelectedCreatorComponentItems());
	});

	return (
		<TutorialItem
			tutorialStepNumber={2}
			boxProps={{
				h: '100%',
				display: 'flex',
			}}
			innerBoxProps={{
				h: '100%',
				w: '100%',
				display: 'flex',
			}}
			tooltipProps={{
				offset: -100,
			}}
		>
			<Box
				ref={canvasRef}
				component="canvas"
				onDragEnter={handleDragEnter}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onContextMenu={(e) => e.preventDefault()}
				onMouseLeave={handleMouseLeave}
			></Box>
		</TutorialItem>
	);
};
