import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { EmailConfirmation } from '../views/common/EmailConfirmation';
import { Login } from '../views/unauthenticated/login/Login';
import { Register } from '@/components/views/unauthenticated/register/Register';
import { Contact } from '../views/unauthenticated/contact/Contact';
import { SetNewPassword } from '@/components/views/unauthenticated/setNewPassword/SetNewPassword';
import { ResetPassword } from '@/components/views/unauthenticated/resetPassword/ResetPassword';
import { FullScreenProjectView } from '../views/common/fullScreenProjectView/FullScreenProjectView';
import { Project } from '../views/common/project/Project';
import { AppPaths } from '@/configs/appPaths';

export const UnauthenticatedApp: React.FC = () => {
	return (
		<Routes>
			<Route
				path={AppPaths.EMAIL_CONFIRMATION}
				element={<EmailConfirmation />}
			/>
			<Route path={AppPaths.LOGIN} element={<Login />} />
			<Route path={AppPaths.REGISTER} element={<Register />} />
			<Route path={AppPaths.CONTACT} element={<Contact />} />
			<Route
				path={`${AppPaths.SET_PASSWORD}/:token`}
				element={<SetNewPassword />}
			/>
			<Route path={AppPaths.RESET_PASSWORD} element={<ResetPassword />} />
			<Route path={AppPaths.PROJECTS}>
				<Route index element={<Navigate to={`/${AppPaths.CREATOR}`} />} />
				<Route path=":projectId" element={<Project />} />
				<Route
					path=":projectId/fullscreen-view"
					element={<FullScreenProjectView />}
				/>
			</Route>

			<Route path="*" element={<Navigate to={AppPaths.LOGIN} />} />
		</Routes>
	);
};
