import {
	CanvasTexture,
	Clock,
	Color,
	FrontSide,
	Group,
	Mesh,
	MeshBasicMaterial,
	PlaneGeometry,
	RepeatWrapping,
	SRGBColorSpace,
	Scene,
	WebGLRenderer,
} from 'three';
import { ApplyEventHandlers } from '../core/Events';
import { ViewType } from '@/types/creator';
import { LightController } from './LightsController';
import { CameraController } from './CameraController';
import { Room } from '../core/Room';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import Stats from 'three/examples/jsm/libs/stats.module.js';

const clock = new Clock();

const stats = new Stats();
if (import.meta.env.DEV) document.body.appendChild(stats.dom);

@ApplyEventHandlers
export class BaseRenderer {
	private _scene!: Scene;
	protected screenshotScene!: Scene;
	protected meshGroup!: Group;
	protected screenshotRenderer?: WebGLRenderer;
	protected renderer!: WebGLRenderer;
	protected lightsController: LightController;
	room: Room;
	canvas: HTMLCanvasElement;
	cameraController: CameraController;

	constructor(canvas: HTMLCanvasElement, room: Room) {
		this.room = room;
		this.cameraController = new CameraController(this);
		this.lightsController = new LightController(this);
		this.canvas = canvas;
		this.init();
	}

	get scene() {
		return this._scene;
	}

	resizeRenderer() {
		const container = this.canvas.parentElement!,
			aside = document.getElementById('creator-aside'),
			navbar = document.getElementById('navbar'),
			creatorSteps = document.getElementById('creator-steps'),
			projectHeader = document.getElementById('project-header');

		const isCreatorView = !!creatorSteps;

		const navbarHeight = navbar ? navbar.offsetHeight : 0,
			creatorStepsHeight = creatorSteps ? creatorSteps.offsetHeight : 0,
			projectHeaderHeight = projectHeader ? projectHeader.offsetHeight : 0,
			asideWidth = aside ? aside.offsetWidth : 0;

		let height = window.innerHeight;

		if (isCreatorView) {
			height = height - navbarHeight - creatorStepsHeight;
			if (this.room.viewType !== ViewType.FIRST_PERSON)
				height -= projectHeaderHeight;
		} else {
			height = Math.min(height, container.clientHeight);
		}

		let width = window.innerWidth - asideWidth;
		width = Math.min(width, container.clientWidth);

		this.renderer.setSize(width, height);
		const aspect = width / height;

		this.cameraController.handleResize(aspect);
	}

	protected changeRoomHeight(height: number) {
		for (const obj of this.room.getRendererObjects()) {
			obj.needsUpdate = true;
		}

		this.room.height = height;
	}

	protected changeViewType(viewType: ViewType) {
		this.cameraController.changeView(viewType);
		this.resizeRenderer();

		this.room.floorNeedsUpdate = true;
	}

	protected init() {
		this._scene = new Scene();
		this.screenshotScene = new Scene();

		this.cameraController.createCamera();

		this.scene.background = new Color(RENDERER_CONFIG.SCENE.BACKGROUND);

		this.meshGroup = new Group();
		this.scene.add(this.meshGroup);

		this.createFloor();

		this.initRenderers();
		this.lightsController.initLights();
	}

	protected initRenderers() {
		this.renderer = new WebGLRenderer({
			canvas: this.canvas,
			antialias: true,
			stencil: true,
		});

		const screenshotCanvas = document.getElementById('screenshot-renderer');

		if (screenshotCanvas) {
			this.screenshotRenderer = new WebGLRenderer({
				canvas: screenshotCanvas,
				antialias: true,
				stencil: true,
			});
			this.screenshotRenderer.setSize(
				this.canvas.clientWidth * 2,
				this.canvas.clientHeight * 2
			);
		}

		this.resizeRenderer();

		this.renderer.setAnimationLoop(() => {
			if (import.meta.env.DEV) stats.begin();

			const isFirstPerson = this.room.viewType === ViewType.FIRST_PERSON;

			const camera = isFirstPerson
				? this.cameraController.firstPersonCamera
				: this.cameraController.mainCamera;

			if (isFirstPerson)
				this.cameraController.firstPersonControls.update(clock.getDelta());
			else this.cameraController.controls.update();

			this.renderer.render(this.scene, camera);

			if (import.meta.env.DEV) stats.end();
		});
	}

	protected prepareScreenshot() {
		this.screenshotScene = new Scene();
		this.screenshotScene.background = new Color(
			RENDERER_CONFIG.SCENE.BACKGROUND
		);

		this.cameraController.prepareScreenshotCamera();
	}

	private createFloor() {
		const dotRadius = 1.2,
			dotSpacing = 20,
			wrapMultiplier = RENDERER_CONFIG.FLOOR_SIDE_LENGTH / 100;

		const canvas = document.createElement('canvas');
		canvas.width = RENDERER_CONFIG.FLOOR_SIDE_LENGTH / wrapMultiplier;
		canvas.height = RENDERER_CONFIG.FLOOR_SIDE_LENGTH / wrapMultiplier;

		const context = canvas.getContext('2d')!;
		context.fillStyle = '#eef2f6';
		context.fillRect(0, 0, canvas.width, canvas.height);

		for (let x = 0; x <= canvas.width; x += dotSpacing) {
			context.fillStyle = '#b8bec6';
			for (let y = 0; y <= canvas.height; y += dotSpacing) {
				context.beginPath();
				context.arc(x, y, dotRadius, 0, 2 * Math.PI);
				context.fill();
				context.closePath();
			}
		}
		const map = new CanvasTexture(canvas);
		map.wrapT = RepeatWrapping;
		map.wrapS = RepeatWrapping;
		map.colorSpace = SRGBColorSpace;
		map.repeat.set(wrapMultiplier, wrapMultiplier);

		const material = new MeshBasicMaterial({
			map,
		});

		const floorGeometry = new PlaneGeometry(
			RENDERER_CONFIG.FLOOR_SIDE_LENGTH,
			RENDERER_CONFIG.FLOOR_SIDE_LENGTH
		);

		const floorMesh = new Mesh(floorGeometry, material);
		floorMesh.material.side = FrontSide;
		floorMesh.rotateX(-Math.PI / 2);

		floorMesh.position.y = -1;

		this.meshGroup.add(floorMesh);
	}
}
