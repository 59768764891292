import { isPointOnLine } from '@/utils/rendererUtils';
import { Vector2 } from 'three';

export const HELPER_LINE_COLOR = 0x7c94fc;
export const HELPER_LINE_WIDTH = 0.002;

export class LineHelper {
	overlapingHelper: LineHelper | null = null;

	constructor(
		public angle: number,
		public endpoints: {
			start: Vector2;
			end: Vector2;
		},
		public objectGroupId?: string
	) {}

	overlapDetector(others: LineHelper[]) {
		this.overlapingHelper = null;

		for (const helper of others) {
			helper.overlapingHelper = null;

			if (
				!!this.objectGroupId &&
				helper.objectGroupId &&
				this.objectGroupId === helper.objectGroupId
			)
				continue;

			if (
				this.endpoints.start.x === helper.endpoints.start.x &&
				this.endpoints.start.y === helper.endpoints.start.y &&
				this.endpoints.end.x === helper.endpoints.end.x &&
				this.endpoints.end.y === helper.endpoints.end.y
			)
				continue;

			let angle1 = Math.abs(this.angle);
			let angle2 = Math.abs(helper.angle);

			if (angle1 === 180) angle1 = 0;
			if (angle2 === 180) angle2 = 0;

			if (angle1 !== angle2) continue;

			if (
				isPointOnLine(
					[this.endpoints.start, this.endpoints.end],
					helper.endpoints.start
				)
			) {
				this.overlapingHelper = helper;
				helper.overlapingHelper = this;
				return;
			}
		}
	}
}
