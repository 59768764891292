import { ReactNode } from 'react';
import {
	Center,
	MantineProvider as Mantine,
	MantineTheme,
	MantineThemeOverride,
	PasswordInputProps,
	rem,
} from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Loader } from '@/components/partials/loading/partials/Loader';
import APP_CONFIG from '@/configs/appConfig';
import classNames from 'classnames';
import ArrowDownIcon from '@/assets/icons/arrowDown.svg?react';
import EyeIcon from '@/assets/icons/eye.svg?react';
import CloseIcon from '@/assets/icons/close.svg?react';
import InfoIcon from '@/assets/icons/info.svg?react';
import CheckIcon from '@/assets/icons/check.svg?react';
import styles from '../sass/mantine.module.scss';

const colors: MantineThemeOverride['colors'] = {
	gray: [
		'#fefdfd',
		'#fdfdfc',
		'#fbfafa',
		'#f1efee',
		'#d9d7d6',
		'#c1bfbe',
		'#b9b1a8',
		'#918f8f',
		'#6c6c6b',
		'#545453',
	],
	dark: [
		'#fcfcfd',
		'#f8fafc',
		'#eef2f6',
		'#e3e8ef',
		'#cdd5df',
		'#9aa4b2',
		'#697586',
		'#4b5565',
		'#364152',
		'#202939',
	],
	green: [
		'#e6f6f4',
		'#d9f2ef',
		'#b0e4dd',
		'#00a991',
		'#009883',
		'#008774',
		'#007f6d',
		'#006557',
		'#004c41',
		'#07353f',
	],
};

const components: MantineThemeOverride['components'] = {
	Pagination: {
		defaultProps: {
			withControls: true,
			spacing: 'sm',
			radius: 0,
		},
		classNames: {
			control: styles.paginationControl,
			dots: styles.paginationDots,
		},
	},
	Burger: {
		defaultProps: ({ colors, primaryColor, primaryShade }) => ({
			color: colors[primaryColor][primaryShade as number],
			size: 30,
		}),
	},
	Badge: {
		defaultProps: (theme) => ({
			radius: 0,
			h: 46,
			p: 0,
			lh: theme.other.fontSizes.sm.lh,
			fz: theme.fontSizes.sm,
			fw: theme.other.fontWeights.normal,
		}),
		classNames: {
			inner: styles.badgeInner,
		},
	},
	Modal: {
		defaultProps: {
			centered: true,
			withCloseButton: true,
			radius: 0,
			zIndex: APP_CONFIG.Z_INDEX.MODAL,
			overlayProps: {
				opacity: 0.4,
				color: 'dark',
			},
			transitionProps: {
				transition: 'slide-down',
			},
			padding: 40,
			closeButtonProps: { size: 24 },
			size: `min(95dvw, ${rem(528)})`,
		},
		classNames: {
			header: styles.modalHeader,
			body: styles.modalBody,
			close: styles.modalClose,
			title: styles.modalTitle,
		},
	},
	Button: {
		defaultProps: (theme) => ({
			radius: 0,
		}),
	},
	Menu: {
		defaultProps: {
			position: 'bottom',
			shadow: 'sm',
			radius: 0,
			transitionProps: {
				transition: 'scale',
			},
		},
		classNames: {
			dropdown: styles.menuDropdown,
			item: styles.menuItem,
			divider: styles.menuDivider,
			itemLabel: styles.menuItemLabel,
			itemIcon: styles.menuItemIcon,
		},
	},
	Collapse: {
		defaultProps: {
			animateOpacity: true,
		},
	},
	Divider: {
		defaultProps: ({ colors }) => ({
			color: colors.dark[4],
			size: 1,
			labelProps: {
				color: colors.dark[4],
			},
		}),
		classNames: {
			label: styles.dividerLabel,
		},
	},
	Select: {
		defaultProps: ({ other }) => ({
			rightSection: <ArrowDownIcon />,
			radius: 0,
			size: other.inputSizes.md,
			transitionProps: {
				duration: 100,
				transition: 'scale-y',
				timingFunction: 'ease',
			},
		}),
		classNames: {
			input: styles.selectInput,
			rightSection: styles.selectRightSection,
			item: styles.selectItem,
			dropdown: styles.selectDropdown,
			nothingFound: styles.selectNothingFound,
			disabled: styles.selectDisabled,
			invalid: styles.selectInvalid,
		},
	},
	Image: {
		defaultProps: () => ({
			radius: 0,
			fit: 'contain',
			mah: '100%',
		}),
		classNames: {
			image: classNames('fade-in', styles.imageImage),
		},
	},
	NativeSelect: {
		defaultProps: () => ({
			rightSection: <ArrowDownIcon />,
		}),
		styles: ({ colors }) => ({
			input: {
				color: colors.dark[6],
			},
		}),
		classNames: {
			input: styles.nativeSelectInput,
		},
	},
	ScrollArea: {
		defaultProps: {
			scrollbarSize: 7,
			type: 'auto',
			['data-scrollarea']: 'true',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	},
	ScrollAreaAutosize: {
		defaultProps: {
			scrollbarSize: 7,
			type: 'auto',
		},
		classNames: {
			scrollbar: styles.scrollAreaScrollbar,
			thumb: styles.scrollAreaThumb,
		},
	},
	Tooltip: {
		defaultProps: ({ colors, black }) => ({
			position: 'top',
			transitionProps: {
				transition: 'pop',
				duration: 200,
			},
			multiline: true,
			withArrow: true,
			arrowSize: 8,
			events: { hover: true, focus: true, touch: true },
			color: black,
			radius: 0,
		}),
		classNames: {
			tooltip: styles.tooltipTooltip,
		},
	},
	TooltipFloating: {
		defaultProps: ({ colors }) => ({}),
		classNames: {},
	},
	Group: {
		classNames: {
			root: styles.groupRoot,
		},
	},
	RadioGroup: {
		defaultProps: {
			size: 'xs',
		},
	},
	Radio: {
		defaultProps: ({ colors }) => ({
			size: 'xs',
		}),
		classNames: {
			root: styles.radioRoot,
			body: styles.radioBody,
			labelWrapper: styles.radioLabelWrapper,
			label: styles.radioLabel,
			inner: styles.radioInner,
			radio: styles.radioRadio,
			icon: styles.radioIcon,
		},
	},
	Anchor: {
		defaultProps: {
			underline: false,
		},
	},
	Stack: {
		classNames: {
			root: styles.stackRoot,
		},
	},
	CheckboxGroup: {
		defaultProps: {
			spacing: 'md',
		},
	},
	Checkbox: {
		classNames: {
			inner: styles.checkboxInner,
			label: styles.checkboxLabel,
		},
		defaultProps: (theme) => ({
			size: theme.fontSizes.lg,
			color: theme.colors.dark,
			radius: 0,
		}),
	},
	TextInput: {
		defaultProps: ({ other }) => ({
			radius: 0,
			size: other.inputSizes.md,
		}),
		classNames: {
			input: styles.textInputInput,
			error: styles.textInputError,
			label: styles.textInputLabel,
		},
	},
	Textarea: {
		defaultProps: () => ({
			radius: 0,
			autosize: true,
			minRows: 4,
			maxRows: 8,
		}),
		classNames: {
			input: styles.textareaInput,
		},
	},
	PasswordInput: {
		defaultProps: ({ other }): PasswordInputProps => ({
			radius: 0,
			visibilityToggleIcon: ({ reveal }) => (
				<Center
					className={classNames({
						[styles.passwordInputIconRevealed]: reveal,
					})}
				>
					<EyeIcon />
				</Center>
			),
		}),
		classNames: {
			wrapper: styles.passwordInputWrapper,
			input: styles.passwordInputInput,
			rightSection: styles.passwordInputRightSection,
			error: styles.passwordInputError,
		},
	},
	LoadingOverlay: {
		defaultProps: () => ({
			overlayOpacity: 0.5,
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
			loader: <Loader />,
		}),
	},
	Overlay: {
		defaultProps: () => ({
			zIndex: APP_CONFIG.Z_INDEX.OVERLAY,
		}),
	},
	Progress: {
		defaultProps: ({ colors }) => ({
			size: 'md',
			color: colors.green[6],
			radius: 'md',
		}),
		styles: ({ colors }) => ({
			root: {
				backgroundColor: colors.green[0],
			},
		}),
	},
	Slider: {
		defaultProps: ({ colors }) => ({
			labelTransition: 'fade',
			labelTransitionDuration: 250,
			thumbSize: 22,
			size: 6,
			label: null,
		}),
		classNames: {
			bar: styles.sliderBar,
			thumb: styles.sliderThumb,
			track: styles.sliderTrack,
			label: styles.sliderLabel,
		},
	},
	RangeSlider: {
		defaultProps: ({ colors }) => ({
			color: colors.dark[5],
			labelTransition: 'fade',
			minRange: 1,
			labelTransitionDuration: 250,
		}),
	},
	Timeline: {
		defaultProps: {
			bulletSize: 30,
			lineWidth: 3,
		},
		classNames: {
			itemTitle: styles.timelineItemTitle,
			itemBullet: styles.timelineItemBullet,
		},
	},
	Highlight: {
		defaultProps: ({ colors }) => ({
			color: colors.dark[7],
			highlightColor: colors.dark[1],
			highlightStyles: {
				fontWeight: 700,
			},
		}),
	},
	DatePicker: {
		classNames: {
			day: styles.datePickerDay,
		},
	},
	Drawer: {
		defaultProps: () => ({
			position: 'right',
			zIndex: APP_CONFIG.Z_INDEX.DRAWER,
			size: 592,
			closeButtonProps: {
				iconSize: 20,
			},
		}),
		classNames: {
			overlay: styles.drawerInner,
			inner: styles.drawerInner,
			body: styles.drawerBody,
			header: styles.drawerHeader,
			title: styles.drawerTitle,
			close: styles.drawerClose,
		},
	},
	Title: {
		defaultProps: () => ({
			lts: -0.25,
		}),
	},
	Notification: {
		defaultProps: { radius: 0, withCloseButton: false },
		styles: {
			root: {
				zIndex: APP_CONFIG.Z_INDEX.NOTIFICATION,
			},
		},
		classNames: {
			root: styles.notificationRoot,
			icon: styles.notificationIcon,
			closeButton: styles.notificationCloseButton,
		},
	},
	BackgroundImage: {
		defaultProps: () => ({
			bgr: 'no-repeat',
			bgsz: 'contain',
			h: '100%',
		}),
	},
	Accordion: {
		defaultProps: () => ({
			chevronPosition: 'left',
			chevron: <ArrowDownIcon className="icon-gray400" />,
			transitionDuration: 250,
		}),
		classNames: {
			content: styles.accordionContent,
			item: styles.accordionItem,
			control: styles.accordionControl,
			label: styles.accordionLabel,
			chevron: styles.accordionChevron,
		},
	},
	Popover: {
		defaultProps: () => ({
			shadow: 'md',
			radius: 0,
			withArrow: false,
		}),
		classNames: {
			dropdown: styles.popoverDropdown,
		},
	},
};

const fontSizes: MantineThemeOverride['fontSizes'] = {
	xs: rem(11),
	sm: rem(12),
	md: rem(14),
	lg: rem(16),
	xl: rem(18),
};

const headings: MantineThemeOverride['headings'] = {
	fontFamily: 'Inter, sans-serif',
	fontWeight: 600,
	sizes: {
		h1: {
			fontSize: rem(40),
			lineHeight: rem(54),
		},
		h2: {
			fontSize: rem(24),
			lineHeight: rem(32),
		},
		h3: {
			fontSize: rem(16),
			lineHeight: rem(22),
		},
	},
};

const breakpoints: MantineThemeOverride['breakpoints'] = {
	xs: rem(APP_CONFIG.BREAKPOINTS.XS),
	sm: rem(APP_CONFIG.BREAKPOINTS.SM),
	md: rem(APP_CONFIG.BREAKPOINTS.MD),
	lg: rem(APP_CONFIG.BREAKPOINTS.LG),
	xl: rem(APP_CONFIG.BREAKPOINTS.XL),
};

const spacing: MantineThemeOverride['spacing'] = {
	xs: rem(3),
	sm: rem(8),
	md: rem(16),
	lg: rem(24),
	xl: rem(32),
};

const globalStyles: MantineThemeOverride['globalStyles'] = (theme) => ({});

const other: MantineThemeOverride['other'] = {
	fontWeights: {
		normal: 500,
		bold: 600,
	},
	inputSizes: {
		sm: {
			size: 26,
			classNames: {
				root: styles.inputSizeMd,
			},
		},
		md: {
			size: 46,
			classNames: {
				root: styles.inputSizeMd,
			},
		},
		lg: {
			size: 56,
			classNames: {
				root: styles.inputSizeLg,
			},
		},
	},
	fontSizes: {
		xs: { size: 'xs', lh: rem(20) },
		sm: { size: 'sm', lh: rem(20) },
		md: { size: 'md', lh: rem(22) },
		lg: { size: 'lg', lh: rem(24) },
		xl: { size: 'xl', lh: rem(30) },
	},
	textDimmed: colors.dark![6],
	borderColor: colors.dark![4],
	greySmallBadgeProps: {
		radius: 80,
		h: 28,
		miw: 28,
		sx: ({ colors }: MantineTheme) => ({
			backgroundColor: colors.gray[3],
		}),
		styles: {
			inner: {
				paddingInline: `${rem(8)} !important`,
			},
		},
	},
	notificationProps: {
		info: {
			classNames: {
				root: styles.notificationInfo,
				icon: styles.notificationInfoIcon,
			},
			icon: <InfoIcon />,
		},
		danger: {
			classNames: {
				root: styles.notificationDanger,
				icon: styles.notificationDangerIcon,
			},
			icon: <CloseIcon />,
		},
		warning: {},
		success: {
			classNames: {
				root: styles.notificationSuccess,
				icon: styles.notificationSuccessIcon,
			},
			icon: <CheckIcon />,
		},
	},
};

export const MantineProvider = ({ children }: { children: ReactNode }) => {
	return (
		<Mantine
			withCSSVariables
			theme={{
				activeStyles: {},
				fontFamily: 'Inter, sans-serif',
				black: '#121926',
				lineHeight: other.fontSizes.lg.lh,
				spacing,
				other,
				cursorType: 'pointer',
				colors,
				components,
				headings,
				breakpoints,
				colorScheme: 'light',
				primaryColor: 'green',
				primaryShade: 9,
				fontSizes,
				transitionTimingFunction: 'ease-in-out',
				loader: 'oval',
				globalStyles,
			}}
		>
			<ModalsProvider>{children}</ModalsProvider>
		</Mantine>
	);
};
