import { ReactNode } from 'react';
import { DatesProvider as MantineDatesProvider } from '@mantine/dates';
import { useContent } from './ContentProvider';
import 'dayjs/locale/pl';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';

export const DatesProvider = ({ children }: { children: ReactNode }) => {
	const { language } = useContent();

	return (
		<MantineDatesProvider
			settings={{
				locale: language === 'GB' ? 'en-gb' : language.toLowerCase(),
				firstDayOfWeek: 1,
				weekendDays: [0],
			}}
		>
			{children}
		</MantineDatesProvider>
	);
};
