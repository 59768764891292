import polygonOverlap from 'polygon-overlap';
import { Charger } from './Charger';
import { Core } from './Core';
import { RendererComponent } from './types/objects';

export class ChargerPowerer {
	constructor(private core: Core) {}

	private checkForChargerComponentOverlap(
		charger: Charger,
		component: RendererComponent
	) {
		return polygonOverlap(charger.getPolygon(), component.getPolygon());
	}

	searchComponents(charger: Charger) {
		const components = this.core.getRendererComponents();
		let componentMatch: RendererComponent | null = null;

		for (const component of components) {
			if (this.checkForChargerComponentOverlap(charger, component)) {
				componentMatch = component;
				break;
			}
		}

		if (componentMatch !== charger.poweredComponent) {
			charger.poweredComponent = componentMatch;
			charger.needsUpdate = true;
		}
	}
}
