import { ApplyEventHandlers, Events } from './Events';
import { Snapshot } from './History/Snapshot';
import { AutomaticConnectorRecord, Mesh } from './types/mesh';
import { MeshType } from './enums/MeshType';
import { RendererComponent, RendererItem } from './types/objects';
import { Lamp } from './Lamp';
import { HoveredObject, ViewType } from '@/types/creator';
import { Point, Polygon } from '@/3d/core/types/point';
import { Label } from './Label';
import { pick, uniqBy } from 'lodash';
import { Connector } from '@/3d/core/Connector';
import { Charger } from './Charger';
import { ObjectConnector } from '@/3d/core/ObjectConnector';
import { CollisionDetector } from '@/3d/core/CollisionDetector';
import { RendererEvent } from './enums/RendererEvent';
import { ComponentType } from '@/api/ComponentApi';
import { ObjectDuplicator } from './ObjectDuplicator';
import { ChargerPowerer } from './ChargerPowerer';
import { Wall } from './Wall';
import { Node } from './Node';
import { ObjectRemover } from './ObjectRemover';
import { roomHistory } from '@/components/views/creator/renderer/Renderer';
import {
	calculateCentroid,
	calculatePolygonDimensions,
	isPointInsidePolygon,
	roundToHalf,
} from '@/utils/rendererUtils';
import { UUID } from '@/types/types';
import { Vector2 } from 'three';
import CREATOR_CONFIG from '@/configs/creatorConfig';

const editedObjectAttrs: (keyof RendererComponent)[] = [
	'id',
	'componentItemId',
	'angle',
	'pos',
	'windowPosition',
	'groupId',
	'length',
	'width',
];

@ApplyEventHandlers
export class Core {
	private history = roomHistory;
	walls: Wall[] = [];
	labels: Label[] = [];
	nodes: Node[] = [];
	lamps: Lamp[] = [];
	connectors: Connector[] = [];
	chargers: Charger[] = [];
	areaSelection: { verticies: Vector2[]; needsUpdate: boolean } = {
		verticies: [],
		needsUpdate: false,
	};
	private _floorNeedsUpdate = true;
	labelsNeedsUpdate = true;
	selectedGroup: UUID | null = null;
	events = Events.getInstance();
	currentStep = 0;
	viewType = ViewType.RECTANGULAR;
	meshType = MeshType.CLOSED;
	windowMouse = new Vector2(0, 0);
	sceneMouse = new Vector2(0, 0);
	collisionDetector = new CollisionDetector();
	objectConnector = new ObjectConnector(this);
	objectRemover = new ObjectRemover(this);
	objectDuplicator = new ObjectDuplicator(this, this.collisionDetector);
	chargerPowerer = new ChargerPowerer(this);
	height: number = CREATOR_CONFIG.INITIAL_ROOM_HEIGHT;
	componentsSlingLevel: number = CREATOR_CONFIG.INITIAL_SLING_LEVEL;
	customFloorSrc?: string;

	constructor() {}

	get floorNeedsUpdate() {
		return this._floorNeedsUpdate;
	}

	set floorNeedsUpdate(value: boolean) {
		this._floorNeedsUpdate = value;

		if (value) this.labelsNeedsUpdate = true;
	}

	getPolygon(): Polygon {
		return this.nodes
			.map((node) => node.getIntersection())
			.map((pos) => [pos!.x, pos!.y]);
	}

	isPointInside(point: Point) {
		return isPointInsidePolygon(point, this.getPolygon());
	}

	unvisitObjects() {
		for (const obj of this.getRendererObjects()) {
			obj.visited = false;
		}
	}

	getSurfaceArea() {
		const polygon = this.getPolygon();

		let area = 0;

		for (let i = 0; i < polygon.length; i++) {
			const j = (i + 1) % polygon.length;
			area += polygon[i][0] * polygon[j][1];
			area -= polygon[j][0] * polygon[i][1];
		}

		area = Math.abs(area) / 2;
		return area;
	}

	getAllowedActions() {
		return {
			room: !this.currentStep,
			lamps: [1].includes(this.currentStep),
			areaSelection: [1].includes(this.currentStep),
		};
	}

	getSelectedRendererObjects(): RendererComponent[] {
		return this.getRendererComponents().filter((obj) => obj.selected);
	}

	getSelectedObjects() {
		const label = this.labels.find((label) => label.selected);
		const components = this.anyRendererComponentDragging()
			? []
			: this.getRendererComponents()
					.filter((c) => c.selected)
					.map((obj, _, arr) =>
						structuredClone({
							...pick(obj, editedObjectAttrs),
							componentType:
								obj instanceof Lamp
									? ComponentType.LAMP
									: ComponentType.CONNECTOR,
							resizeEnabled:
								obj instanceof Lamp &&
								!obj.connectors.length &&
								arr.length === 1,
							isAutomatic: obj instanceof Connector && obj.isAutomatic,
							copyDisabled:
								obj instanceof Connector &&
								obj.isAutomatic &&
								obj.lamps.filter((l) => l.selected).length <= 1,
						})
					);
		const chargers = this.chargers
			.filter((c) => c.selected)
			.map((charger) => pick(charger, ['id', 'windowPosition']));

		return {
			label: label ? label.id : null,
			components,
			chargers,
		};
	}

	getHoveredObject(): HoveredObject | null {
		const hoveredLamp = this.lamps.find(
			(lamp) => lamp.mouseOver && !lamp.isDragging
		);
		const hoveredConnector = this.connectors.find(
			(connector) => connector.mouseOver && !connector.isDragging
		);

		const hoveredObject = hoveredLamp || hoveredConnector;
		if (!hoveredObject) return null;

		hoveredObject.windowPosition = this.calculatePositionRelativeToWindow(
			hoveredObject.pos
		);

		return {
			componentItemId: hoveredObject.componentItemId,
			windowPosition: {
				x: hoveredObject.windowPosition.x,
				y: hoveredObject.windowPosition.y,
			},
		};
	}

	isObjectInsideRoom(object: RendererItem, pos?: Vector2) {
		if (this.meshType === MeshType.OPENED) return true;

		const corners = object.getAreaPoints(object.length, pos);
		const polygon = this.getPolygon();

		const n = polygon.length;
		let p1x = polygon[0][0],
			p1y = polygon[0][1];

		for (const corner of Object.values(corners)) {
			let inside = false;
			let xIntercept = 0;
			let onEdge = false;

			for (let i = 1; i <= n; i++) {
				const p2x = polygon[i % n][0],
					p2y = polygon[i % n][1];

				if (corner.y > Math.min(p1y, p2y)) {
					if (corner.y <= Math.max(p1y, p2y)) {
						if (corner.x <= Math.max(p1x, p2x)) {
							if (p1y !== p2y) {
								xIntercept =
									((corner.y - p1y) * (p2x - p1x)) / (p2y - p1y) + p1x;
							}

							if (p1x === p2x || corner.x <= xIntercept) {
								inside = !inside;
							}
						}
					}
				}

				const closestPoint = object.getClosestPointOnEdge(
					[p1x, p1y],
					[p2x, p2y],
					corner
				);
				const distance = Math.sqrt(
					(closestPoint.x - corner.x) ** 2 + (closestPoint.y - corner.y) ** 2
				);
				if (distance < Number.EPSILON) {
					onEdge = true;
					break;
				}

				p1x = p2x;
				p1y = p2y;
			}

			if (!inside && !onEdge) return false;
		}

		return true;
	}

	isAnyObjectOutsideRoom() {
		return this.getRendererObjects()
			.map((obj) => this.isObjectInsideRoom(obj))
			.some((isInside) => !isInside);
	}

	getRendererComponents() {
		return [...this.lamps, ...this.connectors];
	}

	getRendererObjects() {
		return [...this.lamps, ...this.connectors, ...this.chargers];
	}

	getCenter() {
		const intersectionPoints: Point[] = this.nodes
			.map((node) => node.getIntersection())
			.filter((point) => point !== null) as Point[];

		const sum = intersectionPoints.reduce(
			(acc, point) => {
				acc.x += point.x;
				acc.y += point.y;
				return acc;
			},
			{ x: 0, y: 0 }
		);

		const centerX = roundToHalf(sum.x / intersectionPoints.length);
		const centerY = roundToHalf(sum.y / intersectionPoints.length);

		return { x: centerX, y: centerY };
	}

	getCentroid() {
		if (this.meshType === MeshType.OPENED) return { x: 0, y: 0 };

		const intersectionPoints: Point[] = this.nodes
			.map((node) => node.getIntersection())
			.filter((point) => point !== null) as unknown as Point[];

		return calculateCentroid(intersectionPoints);
	}

	updateRenderer() {
		this.events.emit(RendererEvent.UPDATE_RENDERER);
	}

	getComponentsGroup(component: RendererComponent) {
		const group = this.getRendererComponents().filter((c) =>
			component.isSameGroup(c)
		);

		return group.length ? group : [component];
	}

	takeSnapshot() {
		const snapshot = new Snapshot(this.save());

		this.history.add(snapshot);
	}

	save(): Mesh {
		const walls = this.walls.map((w) => w.save());
		const nodes = this.nodes.map((n) => n.save());
		const lamps = this.lamps.map((l) => l.save());
		const connectors = this.connectors.map((c) => c.save());
		const chargers = this.chargers.map((c) => c.save());

		const mesh = walls
			.map((w, i) => [w, nodes[i]])
			.flat<any>()
			.concat(lamps)
			.concat(connectors)
			.concat(chargers);

		const automaticConnectors = uniqBy(
			this.lamps
				.filter((lamp) => !!lamp.automaticConnector)
				.map((lamp) => lamp.automaticConnector!),
			'componentItemId'
		).reduce<AutomaticConnectorRecord>((acc, curr) => {
			acc[curr.componentItemId] = curr;
			return acc;
		}, {});

		return {
			type: this.meshType,
			mesh,
			automaticConnectors,
			config: pick(this, ['height', 'componentsSlingLevel', 'customFloorSrc']),
		};
	}

	clear() {
		this.walls = [];
		this.lamps = [];
		this.labels = [];
		this.nodes = [];
		this.connectors = [];

		this.floorNeedsUpdate = true;
		this.collisionDetector = new CollisionDetector();
		this.objectConnector = new ObjectConnector(this);
		this.objectRemover = new ObjectRemover(this);
		this.objectDuplicator = new ObjectDuplicator(this, this.collisionDetector);
	}

	protected handleGroupDragging(posChange: Point, group: RendererComponent[]) {
		for (const component of group) {
			component.pos = component.pos.clone().add(posChange);
		}
	}

	protected anyRendererComponentDragging() {
		return this.getRendererComponents().some((c) => c.isDragging);
	}

	protected getDimensions() {
		const intersectionPoints: Point[] = this.nodes
			.map((node) => node.getIntersection())
			.filter((point) => point !== null) as unknown as Point[];

		const dimesnions = calculatePolygonDimensions(intersectionPoints);
		return dimesnions;
	}

	protected getObjectsInSelectionArea(): RendererItem[] {
		const polygon = this.areaSelection.verticies.map<[number, number]>(
			(point) => [point.x, point.y]
		);

		const objectsInArea = this.getRendererObjects().filter((obj) =>
			obj.isInsideSelect(polygon)
		);
		return objectsInArea;
	}

	protected calculatePositionRelativeToWindow(point: Vector2) {
		return this.windowMouse.clone().add(point.clone().sub(this.sceneMouse));
	}

	protected findNearestNodeWithSameAngle(node: Node, indexOfNode: number) {
		const nodesWithSameAngle = this.nodes.filter((n) => n.angle === node.angle);
		let nearestNode = null;
		let shortestDistance = Infinity;
		const totalNodes = this.nodes.length;

		for (const n of nodesWithSameAngle) {
			const nIdx = this.nodes.indexOf(n);
			if (nIdx === indexOfNode) continue;
			const forwardDistance =
				nIdx > indexOfNode
					? nIdx - indexOfNode
					: nIdx + totalNodes - indexOfNode;
			const backwardDistance =
				indexOfNode > nIdx
					? indexOfNode - nIdx
					: indexOfNode + totalNodes - nIdx;
			const distance = Math.min(forwardDistance, backwardDistance);

			if (distance < shortestDistance && node.areWallsBetweenSkew(n)) {
				shortestDistance = distance;
				nearestNode = n;
			}
		}

		return nearestNode;
	}

	protected detectChargerConnections() {
		for (const charger of this.chargers) {
			this.chargerPowerer.searchComponents(charger);
		}
	}

	protected clickOutside() {
		this.selectedGroup = null;

		for (const label of this.labels) {
			label.selected = false;
		}
		for (const obj of this.getRendererObjects()) {
			if (obj.selected) {
				obj.needsUpdate = true;
				obj.selected = false;
			}
		}
	}

	protected checkIfWholeGroupSelected(components: RendererComponent[]) {
		if (components.length < 2) return false;
		if (!components[0].groupId) return false;

		const group = this.getComponentsGroup(components[0]);
		for (const obj of group) {
			if (!components.includes(obj)) return false;
		}

		return true;
	}
}
