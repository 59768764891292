import { Line } from './Line';
import { Draggable } from './interfaces/Draggable';
import { MouseOver } from './interfaces/MouseOver';
import { UUID } from '@/types/types';
import { Lamp } from '@/3d/core/Lamp';
import { Collidable } from '@/3d/core/interfaces/Collidable';
import { CollisionObject } from '@/3d/core/CollisionDetector';
import { ConnectorType } from '@/api/ComponentApi';
import { Point, Polygon } from './types/point';
import { LineHelped } from './interfaces/LineAssisted';
import { LineHelper } from './HelperLine';
import { Selectable } from './interfaces/Selectable';
import {
	ConnectorObject,
	ObjectTypeName,
	RendererComponent,
	RendererComponentMontageType,
} from './types/objects';
import { roomBuilder } from '@/components/views/creator/renderer/Renderer';
import {
	getAngleToXAxis,
	getRotatedAngle,
	isPointInsidePolygon,
	rotateCornerPoints,
} from '@/utils/rendererUtils';
import { Connectable } from './interfaces/Connectable';
import { ConnectingEdge, SerializedConnectingEdge } from './ConnectingEdge';
import { pick } from 'lodash';
import { Clonable } from './interfaces/Clonable';
import { Vector2 } from 'three';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import polygonOverlap from 'polygon-overlap';

const { GENERIC_OBJECT_DIMENSIONS } = RENDERER_CONFIG;

const copyParams = [
	'componentItemId',
	'seriesId',
	'pos',
	'angle',
	'length',
	'width',
	'height',
	'automaticConnector',
	'isAutomatic',
	'connectorType',
	'montageType',
] as const;

export interface AutomaticConnector {
	componentItemId: UUID;
	seriesId: UUID;
	length: number;
	width: number;
	height: number;
	connectorType: ConnectorType;
	montageType: RendererComponentMontageType;
}

export interface ConnectorConfig {
	id: UUID;
	componentItemId: UUID;
	seriesId: UUID;
	pos: Point;
	length: number;
	width: number;
	height: number;
	angle: number;
	connectorType: ConnectorType;
	montageType?: RendererComponentMontageType;
	lamps: UUID[];
	groupId?: UUID;
	edges?: SerializedConnectingEdge[];
	isAutomatic?: boolean;
	mouseOver?: boolean;
	isDragging?: boolean;
	isShadow?: boolean;
}

export class Connector
	implements
		Draggable,
		MouseOver,
		Collidable,
		LineHelped,
		Connectable,
		Selectable,
		Clonable<Connector>
{
	id: UUID;
	componentItemId: UUID;
	seriesId: UUID;
	groupId?: UUID;
	private _pos!: Vector2;
	angle: number;
	length: number = GENERIC_OBJECT_DIMENSIONS.length;
	height: number = GENERIC_OBJECT_DIMENSIONS.height;
	width: number = GENERIC_OBJECT_DIMENSIONS.width;
	montageType: RendererComponentMontageType;
	connectorType = ConnectorType.L;
	lastPosition!: Vector2;
	isAutomatic: boolean;
	windowPosition?: Vector2;
	lamps: Lamp[] = [];
	lineHelpers: LineHelper[] = [];
	connectionEdges: ConnectingEdge[] = [];
	clickPosition?: Vector2;
	centerClickDiff?: Vector2;
	mouseOver = false;
	isDragging = false;
	private _selected = false;
	isShadow = false;
	isAreaHover = false;
	isSummaryHover = false;
	visited = false;
	needsUpdate = true;

	constructor(config: ConnectorConfig) {
		this.id = config.id;
		this.componentItemId = config.componentItemId;
		this.seriesId = config.seriesId;
		this._pos = new Vector2(config.pos.x, config.pos.y);
		this.height = config.height;
		this.width = config.width;
		this.length = config.length;
		this.angle = config.angle;
		this.connectorType = config.connectorType;
		this.groupId = config.groupId;
		this.isAutomatic = !!config.isAutomatic;
		this.isShadow = !!config.isShadow;
		this.mouseOver = !!config.mouseOver;
		this.isDragging = !!config.isDragging;
		this.montageType = config.montageType || RendererComponentMontageType.SLIGN;

		this.createOrUpdateLineHelpers();

		if (!config.edges) this.createOrUpdateConnectionEdges();
	}

	get selected() {
		return this._selected;
	}

	set selected(selected: boolean) {
		this._selected = selected;
		this.needsUpdate = true;
	}

	get pos() {
		return this._pos;
	}

	set pos(pos: Vector2) {
		this.needsUpdate = true;

		const posChange = pos.clone();
		posChange.sub(this.pos);

		this._pos = pos;
		this.createOrUpdateLineHelpers();
		this.createOrUpdateConnectionEdges({ posChange });
	}

	rotate(axis = this.pos) {
		this.needsUpdate = true;

		this.angle = getRotatedAngle(this.angle);

		this._pos = rotateCornerPoints(
			[this.pos],
			RENDERER_CONFIG.ROTATE_TICK,
			axis.x,
			axis.y
		)[0];

		this.createOrUpdateConnectionEdges({ rotatationAxis: axis });
		this.createOrUpdateLineHelpers();
	}

	createOrUpdateLineHelpers() {
		const vertices = this.getAreaPoints();
		const isObjectConnected = !!this.lamps.length;
		const objectGroupId = this.groupId;

		for (let idx = 0; idx < vertices.length; idx++) {
			const point = vertices[idx];
			const nextPoint = vertices[(idx + 1) % vertices.length];
			const angle = getAngleToXAxis(point, nextPoint);

			const endpoints = {
				start: point,
				end: nextPoint,
			};

			if (!!this.lineHelpers[idx]) {
				const helper = this.lineHelpers[idx];
				Object.assign(helper, {
					angle,
					endpoints,
					isObjectConnected,
					objectGroupId,
				});

				continue;
			}

			this.lineHelpers.push(new LineHelper(angle, endpoints, objectGroupId));
		}
	}

	createOrUpdateConnectionEdges({
		posChange,
		rotatationAxis,
	}: {
		posChange?: Vector2;
		rotatationAxis?: Vector2;
	} = {}) {
		const points = this.getConnectorPoints();

		if (!this.connectionEdges.length) {
			for (const point of points) {
				this.connectionEdges.push(
					new ConnectingEdge({
						id: crypto.randomUUID(),
						component: this,
						centerPoint: {
							x: point.vector.x,
							y: point.vector.y,
						},
						isShortArm: point.isShortArm,
					})
				);
			}

			return;
		}

		if (posChange)
			for (const edge of this.connectionEdges) {
				edge.centerPoint = edge.getUpdatedCenterPoint(posChange);
			}

		if (rotatationAxis)
			for (const edge of this.connectionEdges) {
				edge.handleHostRotation(RENDERER_CONFIG.ROTATE_TICK, rotatationAxis);
			}
	}

	isSameGroup(other: Collidable | Connectable) {
		return !!this.groupId && !!other.groupId && this.groupId === other.groupId;
	}

	updateGroupPosition(posChange: Vector2, groupObjects: RendererComponent[]) {
		for (const object of groupObjects.filter((o) => o.id !== this.id)) {
			object.pos = object.pos.clone().add(posChange);
		}
	}

	connectLamp(lamp: Lamp) {
		this.lamps.push(lamp);
		lamp.connectors.push(this);
	}

	disconnectLamp(lamp: Lamp) {
		this.lamps = this.lamps.filter((l) => {
			if (l.id !== lamp.id) return true;

			for (const edge of this.connectionEdges) {
				if (edge.connectedTo?.component.id === lamp.id)
					edge.connectedTo = undefined;
			}

			lamp.connectors = [];
			lamp.groupId = undefined;

			return false;
		});

		if (!this.lamps.length) this.groupId = undefined;
	}

	getPolygon(): Polygon {
		return this.getAreaPoints(this.length).map((corner) => [
			corner.x,
			corner.y,
		]);
	}

	isInsideSelect(polygon: Polygon) {
		const corners = this.getAreaPoints(this.length);

		for (const corner of Object.values(corners)) {
			if (isPointInsidePolygon(corner, polygon)) {
				return true;
			}
		}

		return polygonOverlap(polygon, this.getPolygon());
	}

	getLinePoints(rotated = false, length = this.length, pos = this.pos) {
		const halfWidth = this.width / 2;
		const halfLength = length / 2;

		switch (this.connectorType) {
			case ConnectorType.L:
				const middlePoint = pos.clone(),
					p1 = pos.clone(),
					p2 = pos.clone();

				p1.x += this.length - halfWidth;
				p2.y += this.length - halfWidth;

				const pointsL = rotated
					? rotateCornerPoints(
							[p1, p2],
							this.angle,
							middlePoint.x,
							middlePoint.y
					  )
					: [p1, p2];

				return [
					new Line(middlePoint, pointsL[0]),
					new Line(middlePoint, pointsL[1]),
				];
			case ConnectorType.T:
				const top = pos.clone(),
					left = pos.clone(),
					right = pos.clone();

				top.y -= halfLength;
				left.x -= halfLength;
				right.x += halfLength;

				const pointsT = rotated
					? rotateCornerPoints([top, left, right], this.angle, pos.x, pos.y)
					: [top, left, right];

				return [
					new Line(pos, pointsT[0]),
					new Line(pos, pointsT[1]),
					new Line(pos, pointsT[2]),
				];
			case ConnectorType.X:
				const top1 = pos.clone(),
					bottom = pos.clone(),
					left1 = pos.clone(),
					right2 = pos.clone();

				top1.y -= halfLength;
				bottom.y += halfLength;
				left1.x -= halfLength;
				right2.x += halfLength;

				const pointsX = rotated
					? rotateCornerPoints(
							[top1, bottom, left1, right2],
							this.angle,
							pos.x,
							pos.y
					  )
					: [top1, bottom, left1, right2];

				return [
					new Line(pos, pointsX[0]),
					new Line(pos, pointsX[2]),
					new Line(pos, pointsX[1]),
					new Line(pos, pointsX[3]),
				];

			case ConnectorType.I:
			case ConnectorType.SQUARE:
				const iCenter = pos.clone(),
					p1i = pos.clone(),
					p2i = pos.clone();

				p1i.x += halfLength;
				p2i.x -= halfLength;

				const pointsI = rotated
					? rotateCornerPoints([p1i, p2i], this.angle, iCenter.x, iCenter.y)
					: [p1i, p2i];

				return [new Line(pointsI[0], pointsI[1])];
		}
	}

	isSkew() {
		return this.angle % 90 !== 0;
	}

	checkIfConnectorsInSameGroup(other: Connector) {
		this.visited = true;
		if (!this.groupId || !other.groupId) return false;

		for (const lamp of this.lamps) {
			if (lamp.visited) continue;
			lamp.visited = true;

			for (const connector of lamp.connectors) {
				if (connector.visited) continue;
				connector.visited = true;

				if (connector === other) return true;

				if (connector.checkIfConnectorsInSameGroup(other)) return true;
			}
		}

		return false;
	}

	setNewGroupId(id = crypto.randomUUID()) {
		if (this.visited) return;
		this.visited = true;
		this.groupId = id;

		for (const lamp of this.lamps) {
			if (lamp.visited) continue;
			lamp.visited = true;
			lamp.groupId = id;

			for (const connector of lamp.connectors) {
				if (connector.visited) continue;

				connector.setNewGroupId(id);
			}
		}
	}

	getAreaPoints(length = this.length, pos = this.pos) {
		const halfWidth = this.width / 2;

		switch (this.connectorType) {
			case ConnectorType.L:
				const [line1L, line2L] = this.getLinePoints(false, length, pos);

				const p1L = new Vector2(line1L.p2.x, line1L.p2.y - halfWidth);
				const p2L = new Vector2(
					line1L.p1.x - halfWidth,
					line1L.p1.y - halfWidth
				);
				const p3L = new Vector2(line2L.p2.x - halfWidth, line2L.p2.y);
				const p4L = new Vector2(line2L.p2.x + halfWidth, line2L.p2.y);
				const p5L = new Vector2(
					line2L.p2.x + halfWidth,
					line2L.p1.y + halfWidth
				);
				const p6L = new Vector2(line1L.p2.x, line1L.p2.y + halfWidth);

				return rotateCornerPoints(
					[p1L, p2L, p3L, p4L, p5L, p6L],
					this.angle,
					line1L.p1.x,
					line1L.p1.y
				);
			case ConnectorType.T:
				const [line1T, line2T, line3T] = this.getLinePoints(false, length, pos);

				const p1T = new Vector2(line1T.p2.x + halfWidth, line1T.p2.y);
				const p2T = new Vector2(line1T.p2.x - halfWidth, line1T.p2.y);
				const p3T = new Vector2(
					line1T.p1.x - halfWidth,
					line1T.p1.y - halfWidth
				);
				const p4T = new Vector2(line2T.p2.x, line2T.p2.y - halfWidth);
				const p5T = new Vector2(line2T.p2.x, line2T.p2.y + halfWidth);
				const p6T = new Vector2(line3T.p2.x, line3T.p2.y + halfWidth);
				const p7T = new Vector2(line3T.p2.x, line3T.p2.y - halfWidth);
				const p8T = new Vector2(
					line3T.p1.x + halfWidth,
					line3T.p1.y - halfWidth
				);

				return rotateCornerPoints(
					[p1T, p2T, p3T, p4T, p5T, p6T, p7T, p8T],
					this.angle,
					line1T.p1.x,
					line1T.p1.y
				);
			case ConnectorType.X:
				const [line1X, line2X, line3X, line4X] = this.getLinePoints(
					false,
					length,
					pos
				);

				const p1X = new Vector2(line1X.p2.x + halfWidth, line1X.p2.y);
				const p2X = new Vector2(line1X.p2.x - halfWidth, line1X.p2.y);
				const p3X = new Vector2(
					line1X.p1.x - halfWidth,
					line1X.p1.y - halfWidth
				);
				const p4X = new Vector2(line2X.p2.x, line2X.p2.y - halfWidth);
				const p5X = new Vector2(line2X.p2.x, line2X.p2.y + halfWidth);
				const p6X = new Vector2(
					line2X.p1.x - halfWidth,
					line2X.p1.y + halfWidth
				);
				const p7X = new Vector2(line3X.p2.x - halfWidth, line3X.p2.y);
				const p8X = new Vector2(line3X.p2.x + halfWidth, line3X.p2.y);
				const p9X = new Vector2(
					line3X.p1.x + halfWidth,
					line3X.p1.y + halfWidth
				);
				const p10X = new Vector2(line4X.p2.x, line4X.p2.y + halfWidth);
				const p11X = new Vector2(line4X.p2.x, line4X.p2.y - halfWidth);
				const p12X = new Vector2(
					line4X.p1.x + halfWidth,
					line4X.p1.y - halfWidth
				);

				return rotateCornerPoints(
					[p1X, p2X, p3X, p4X, p5X, p6X, p7X, p8X, p9X, p10X, p11X, p12X],
					this.angle,
					line1X.p1.x,
					line1X.p1.y
				);
			case ConnectorType.I:
			case ConnectorType.SQUARE:
				const linePoints = this.getLinePoints(false, length, pos);

				const p1I = new Vector2(
					linePoints[0].p1.x,
					linePoints[0].p1.y + halfWidth
				);
				const p2I = new Vector2(
					linePoints[0].p1.x,
					linePoints[0].p1.y - halfWidth
				);
				const p3I = new Vector2(
					linePoints[0].p2.x,
					linePoints[0].p2.y - halfWidth
				);
				const p4I = new Vector2(
					linePoints[0].p2.x,
					linePoints[0].p2.y + halfWidth
				);

				return rotateCornerPoints(
					[p1I, p2I, p3I, p4I],
					this.angle,
					this.pos.x,
					this.pos.y
				);
		}
	}

	getLastValidPosition(polygon: Polygon, pos: Vector2) {
		const corners = this.getAreaPoints(this.length, pos);
		const n = polygon.length;
		const room = roomBuilder.getRoom();

		let minDx = 0;
		let minDy = 0;
		let maxDx = 0;
		let maxDy = 0;
		const epsilon = 5;

		for (const corner of corners) {
			let p1x = polygon[0][0];
			let p1y = polygon[0][1];

			let dx = 0,
				dy = 0;
			let minDistance = Infinity;

			for (let i = 1; i <= n; i++) {
				const p2x = polygon[i % n][0];
				const p2y = polygon[i % n][1];

				const closestPoint = this.getClosestPointOnEdge(
					[p1x, p1y],
					[p2x, p2y],
					corner
				);
				const distance = Math.sqrt(
					(closestPoint.x - corner.x) ** 2 + (closestPoint.y - corner.y) ** 2
				);

				if (distance < minDistance) {
					minDistance = distance;

					const edgeLength = Math.sqrt((p2x - p1x) ** 2 + (p2y - p1y) ** 2);
					const normal = {
						x: (p2y - p1y) / edgeLength,
						y: -(p2x - p1x) / edgeLength,
					};

					const adjustedClosestPoint = {
						x: closestPoint.x + epsilon * normal.x,
						y: closestPoint.y + epsilon * normal.y,
					};

					dx = corner.x - adjustedClosestPoint.x;
					dy = corner.y - adjustedClosestPoint.y;
				}

				p1x = p2x;
				p1y = p2y;
			}

			minDx = Math.min(minDx, dx);
			minDy = Math.min(minDy, dy);
			maxDx = Math.max(maxDx, dx);
			maxDy = Math.max(maxDy, dy);
		}

		const newPosition = pos.clone();
		newPosition.sub(
			new Vector2(minDx < 0 ? minDx : maxDx, minDy < 0 ? minDy : maxDy)
		);

		if (room.isObjectInsideRoom(this, newPosition)) {
			return newPosition;
		} else {
			return false;
		}
	}

	getClosestPointOnEdge(p1: number[], p2: number[], corner: Vector2) {
		const x1 = p1[0],
			y1 = p1[1],
			x2 = p2[0],
			y2 = p2[1],
			x3 = corner.x,
			y3 = corner.y;
		const dx = x2 - x1,
			dy = y2 - y1;
		const t = ((x3 - x1) * dx + (y3 - y1) * dy) / (dx * dx + dy * dy);
		const clampedT = Math.max(0, Math.min(1, t));

		return new Vector2(x1 + clampedT * dx, y1 + clampedT * dy);
	}

	getConnectorPoints(
		pos = this.pos,
		length = this.length
	): {
		vector: Vector2;
		isShortArm?: true;
	}[] {
		const linePoints = this.getLinePoints(true, length, pos);

		switch (this.connectorType) {
			case ConnectorType.L:
				const [l1, l2] = linePoints;
				return [{ vector: l1.p2 }, { vector: l2.p2 }];
			case ConnectorType.T:
				const [t1, t2, t3] = linePoints;
				return [
					{ vector: t1.p2, isShortArm: true },
					{
						vector: t2.p2,
						isShortArm: true,
					},
					{
						vector: t3.p2,
						isShortArm: true,
					},
				];
			case ConnectorType.X:
				const [x1, x2, x3, x4] = linePoints;
				return [
					{
						vector: x1.p2,
						isShortArm: true,
					},
					{
						vector: x2.p2,
						isShortArm: true,
					},
					{
						vector: x3.p2,
						isShortArm: true,
					},
					{
						vector: x4.p2,
						isShortArm: true,
					},
				];
			case ConnectorType.I:
				const [i1] = linePoints;
				return [{ vector: i1.p1 }, { vector: i1.p2 }];

			case ConnectorType.SQUARE:
				const [sx1] = linePoints;

				return [
					{ vector: sx1.p1 },
					{ vector: sx1.p2 },
					{
						vector: rotateCornerPoints([sx1.p1], 90, this.pos.x, this.pos.y)[0],
					},
					{
						vector: rotateCornerPoints([sx1.p2], 90, this.pos.x, this.pos.y)[0],
					},
				];
		}
	}

	getCollisionBox(pos = this.pos) {
		const areaPoints = this.getAreaPoints(this.length, pos);

		switch (this.connectorType) {
			case ConnectorType.L:
				const [p1L, p2L, p3L, p4L, p5L, p6L] = areaPoints;

				return [
					new CollisionObject([
						new Vector2(p1L.x, p1L.y),
						new Vector2(p2L.x, p2L.y),
						new Vector2(p5L.x, p5L.y),
						new Vector2(p6L.x, p6L.y),
					]),
					new CollisionObject([
						new Vector2(p2L.x, p2L.y),
						new Vector2(p3L.x, p3L.y),
						new Vector2(p4L.x, p4L.y),
						new Vector2(p5L.x, p5L.y),
					]),
				];
			case ConnectorType.T:
				const [p1T, p2T, p3T, p4T, p5T, p6T, p7T, p8T] = areaPoints;
				return [
					new CollisionObject([
						new Vector2(p1T.x, p1T.y),
						new Vector2(p2T.x, p2T.y),
						new Vector2(p3T.x, p3T.y),
						new Vector2(p8T.x, p8T.y),
					]),
					new CollisionObject([
						new Vector2(p4T.x, p4T.y),
						new Vector2(p5T.x, p5T.y),
						new Vector2(p6T.x, p6T.y),
						new Vector2(p7T.x, p7T.y),
					]),
				];
			case ConnectorType.X:
				const [p1X, p2X, p3X, p4X, p5X, p6X, p7X, p8X, p9X, p10X, p11X, p12X] =
					areaPoints;
				return [
					new CollisionObject([
						new Vector2(p1X.x, p1X.y),
						new Vector2(p2X.x, p2X.y),
						new Vector2(p7X.x, p7X.y),
						new Vector2(p8X.x, p8X.y),
					]),
					new CollisionObject([
						new Vector2(p4X.x, p4X.y),
						new Vector2(p5X.x, p5X.y),
						new Vector2(p10X.x, p10X.y),
						new Vector2(p11X.x, p11X.y),
					]),
				];
			case ConnectorType.I:
				const [p1, p2, p3, p4] = areaPoints;
				return [
					new CollisionObject([
						new Vector2(p1.x, p1.y),
						new Vector2(p2.x, p2.y),
						new Vector2(p3.x, p3.y),
						new Vector2(p4.x, p4.y),
					]),
				];
			case ConnectorType.SQUARE:
				const [sp1, sp2, sp3, sp4] = areaPoints;
				return [
					new CollisionObject([
						new Vector2(sp1.x, sp1.y),
						new Vector2(sp2.x, sp2.y),
						new Vector2(sp3.x, sp3.y),
						new Vector2(sp4.x, sp4.y),
					]),
				];
		}
	}

	dispose() {
		const room = roomBuilder.getRoom();
		room.connectors = room.connectors.filter((c) => c.id !== this.id);

		for (const edge of this.connectionEdges) {
			edge.disconnectEdges();
		}

		for (const lamp of this.lamps) {
			lamp.connectors = lamp.connectors.filter((c) => c.id !== this.id);
			if (!lamp.connectors.length) lamp.groupId = undefined;
		}
	}

	save(): ConnectorObject {
		return {
			object: ObjectTypeName.CONNECTOR,
			// @ts-ignore
			param: {
				...pick(this, copyParams),
				id: this.id,
				groupId: this.groupId,
				lamps: this.lamps.map((lamp) => lamp.id),
				edges: this.connectionEdges.map((edge) => ({
					id: edge.id,
					centerPoint: edge.centerPoint,
					connectedTo: edge.connectedTo?.id,
					isShortArm: edge.isShortArm,
				})),
				isShadow: false,
				mouseOver: false,
				isDragging: false,
			},
		};
	}

	clone() {
		const params = {
			id: crypto.randomUUID(),
			...pick(this, copyParams),
			lamps: [],
			edges: [],
			isShadow: false,
			mouseOver: false,
			isDragging: false,
		};

		const connector = new Connector(params as ConnectorConfig);

		return connector;
	}
}
