import { MeshType } from '@/3d/core/enums/MeshType';
import { ObjectTypeName } from '@/3d/core/types/objects';
import CREATOR_CONFIG from '@/configs/creatorConfig';
import { RoomShape } from '@/types/creator';

const roomShapes = {
	[RoomShape.SQUARE]: {
		type: MeshType.CLOSED,
		mesh: [
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 0,
						y: 250,
					},
					angle: 90,
					length: 500,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 250,
						y: 500,
					},
					angle: 0,
					length: 500,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 500,
						y: 250,
					},
					angle: 270,
					length: 500,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 250,
						y: 0,
					},
					angle: 180,
					length: 500,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
		],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
	[RoomShape.ANGLED]: {
		type: MeshType.CLOSED,
		mesh: [
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 0,
						y: 200,
					},
					angle: 90,
					length: 400,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 200,
						y: 400,
					},
					angle: 0,
					length: 400,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 400,
						y: 300,
					},
					angle: 270,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 300,
						y: 200,
					},
					angle: 180,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 270,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 200,
						y: 100,
					},
					angle: 270,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 100,
						y: 0,
					},
					angle: 180,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
		],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
	[RoomShape.RHOMB]: {
		type: MeshType.CLOSED,
		mesh: [
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 0,
						y: 200,
					},
					angle: 90,
					length: 400,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 100,
						y: 400,
					},
					angle: 0,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 270,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 200,
						y: 500,
					},
					angle: 90,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 300,
						y: 600,
					},
					angle: 0,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 400,
						y: 400,
					},
					angle: 270,
					length: 400,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 300,
						y: 200,
					},
					angle: 180,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 270,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 200,
						y: 100,
					},
					angle: 270,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
			{
				object: ObjectTypeName.WALL,
				param: {
					pos: {
						x: 100,
						y: 0,
					},
					angle: 180,
					length: 200,
				},
			},
			{
				object: ObjectTypeName.NODE,
				param: {
					angle: 90,
				},
			},
		],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
	[RoomShape.TRUNCATED]: {
		type: MeshType.CLOSED,
		mesh: [
			{
				object: 'wall',
				param: {
					pos: {
						x: 0,
						y: 250,
					},
					length: 500,
					angle: 90,
				},
			},
			{
				object: 'node',
				param: {
					angle: 90,
				},
			},
			{
				object: 'wall',
				param: {
					pos: {
						x: 250,
						y: 500,
					},
					length: 500,
					angle: 0,
				},
			},
			{
				object: 'node',
				param: {
					angle: 90,
				},
			},
			{
				object: 'wall',
				param: {
					pos: {
						x: 500,
						y: 375,
					},
					length: 250,
					angle: 270,
				},
			},
			{
				object: 'node',
				param: {
					angle: 135,
				},
			},
			{
				object: 'wall',
				param: {
					pos: {
						x: 375,
						y: 125,
					},
					length: 353.5533905932738,
					angle: 225,
				},
			},
			{
				object: 'node',
				param: {
					angle: 135,
				},
			},
			{
				object: 'wall',
				param: {
					pos: {
						x: 125,
						y: 1.5308084989341916e-14,
					},
					length: 250,
					angle: 180,
				},
			},
			{
				object: 'node',
				param: {
					angle: 90,
				},
			},
		],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
	[RoomShape.INFINITE]: {
		type: MeshType.OPENED,
		mesh: [],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
	[RoomShape.CUSTOM_FLOOR]: {
		type: MeshType.OPENED,
		mesh: [],
		config: {
			height: CREATOR_CONFIG.INITIAL_ROOM_HEIGHT,
			componentsSlingLevel: CREATOR_CONFIG.INITIAL_SLING_LEVEL,
		},
	},
};

export default roomShapes;
