import { Room } from '../core/Room';
import { MeshManager } from './MeshManager';
import { Label } from '../core/Label';
import { ObjectTypeName } from '../core/types/objects';
import * as THREE from 'three';

const FONT_QUALITY_MULTIPLIER = 10;

const FONT_STYLES = {
	fillStyle: '#121926',
	font: `${14 * FONT_QUALITY_MULTIPLIER}px Inter`,
	textAlign: 'center',
	textBaseline: 'middle',
};
export const FONT_LINE_HEIGHT = 22;

const meshParameters: THREE.MeshBasicMaterialParameters = {
	transparent: true,
};

export class LabelManager extends MeshManager {
	private labelMeshes: Map<Label, THREE.Mesh> = new Map();
	protected textures: THREE.Texture[] = [];

	constructor(
		protected override room: Room,
		protected override meshGroup: THREE.Group
	) {
		super(room, meshGroup);
	}

	update() {
		if (!this.room.labelsNeedsUpdate) return;

		for (const text of this.textures) {
			text.dispose();
		}
		this.textures = [];
		this.removeUnusedMeshes(
			this.labelMeshes,
			new Set(this.room.labels),
			ObjectTypeName.LABEL
		);

		for (const label of this.room.labels) {
			if (!this.labelMeshes.has(label)) {
				this.createLabelMesh(label);
			} else {
				this.updateLabelMesh(label);
			}
		}

		this.room.labelsNeedsUpdate = false;
	}

	anyLabelSelected() {
		return this.room.labels.some((label) => label.selected);
	}

	private createCanvas(label: Label) {
		const { length } = label.wall;

		const text = `<> ${Math.round(length)} cm`;

		const canvas = document.createElement('canvas');
		const width =
				this.calculateLabelWidth(text.length * FONT_QUALITY_MULTIPLIER) *
				FONT_QUALITY_MULTIPLIER,
			height = FONT_LINE_HEIGHT * FONT_QUALITY_MULTIPLIER;

		canvas.width = width;
		canvas.height = height;

		const context = canvas.getContext('2d')!;

		Object.assign(context, FONT_STYLES);

		context.fillText(text, canvas.width / 2, canvas.height / 2);

		if (label.mouseOver || label.selected) {
			context.beginPath();
			context.lineWidth = FONT_QUALITY_MULTIPLIER;
			context.moveTo(0, height);
			context.lineTo(width, height);
			context.stroke();
		}

		return canvas;
	}

	private calculateLabelWidth(length: number) {
		return `<> ${Math.round(length)} cm`.length * FONT_QUALITY_MULTIPLIER;
	}

	protected transformMesh(mesh: THREE.Mesh, label: Label) {
		const { angle, pos } = label.wall;

		mesh.rotateX(-Math.PI / 2);

		const calculated = pos.clone();

		switch (angle) {
			case 0:
				calculated.y += 20;
				mesh.rotateZ(180 * (Math.PI / 180));
				break;
			case 45:
				calculated.y += 15;
				calculated.x -= 15;
				mesh.rotateZ((angle + 90) * (Math.PI / 180));
				break;
			case 90:
				calculated.x -= 20;
				mesh.rotateZ(90 * (Math.PI / 180));
				break;
			case 135:
				calculated.x -= 30;
				mesh.rotateZ((angle - 90) * (Math.PI / 180));
				break;
			case 180:
				calculated.y -= 20;
				break;
			case 225:
				calculated.y -= 15;
				calculated.x += 15;
				mesh.rotateZ((angle + 90) * (Math.PI / 180));
				break;
			case 270:
				calculated.x += 20;
				mesh.rotateZ(270 * (Math.PI / 180));
				break;
			case 315:
				calculated.x += 30;
				mesh.rotateZ((angle - 90) * (Math.PI / 180));
				break;
		}

		mesh.position.x = calculated.x;
		mesh.position.y = 1;
		mesh.position.z = calculated.y;

		label.pos = calculated;

		return mesh;
	}

	private createLabelMesh(label: Label) {
		const { length } = label.wall;

		const canvas = this.createCanvas(label);

		const texture = new THREE.CanvasTexture(canvas);
		this.textures.push(texture);

		const material = new THREE.MeshBasicMaterial({
			map: texture,
			...meshParameters,
		});

		const labelMesh = new THREE.Mesh(
			new THREE.PlaneGeometry(
				canvas.width / FONT_QUALITY_MULTIPLIER,
				canvas.height / FONT_QUALITY_MULTIPLIER,
				10,
				10
			),
			material
		);

		this.transformMesh(labelMesh, label);

		label.length = this.calculateLabelWidth(length);

		this.labelMeshes.set(label, labelMesh);

		this.meshGroup.add(labelMesh);
	}

	private updateLabelMesh(label: Label) {
		const labelMesh = this.labelMeshes.get(label);
		if (!labelMesh) return;

		const { length, angle, pos } = label.wall;

		const canvas = this.createCanvas(label);
		const texture = new THREE.CanvasTexture(canvas);
		this.textures.push(texture);
		const material = new THREE.MeshBasicMaterial({
			map: texture,
			...meshParameters,
		});

		labelMesh.material = material;
		const calculated = pos.clone();

		switch (angle) {
			case 0:
				calculated.y += 20;
				break;
			case 45:
				calculated.y += 15;
				calculated.x -= 15;
				break;
			case 90:
				calculated.x -= 20;
				break;
			case 135:
				calculated.x -= 30;
				break;
			case 180:
				calculated.y -= 20;
				break;
			case 225:
				calculated.y -= 15;
				calculated.x += 15;
				break;
			case 270:
				calculated.x += 20;
				break;
			case 315:
				calculated.x += 30;
				break;
		}

		labelMesh.position.x = calculated.x;
		labelMesh.position.z = calculated.y;

		label.pos = calculated;

		label.length = this.calculateLabelWidth(length);
		this.labelMeshes.set(label, labelMesh);
	}
}
