import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { MaterialFactory, MaterialType } from './MaterialFactory';
import { disposeMaterials } from '@/utils/rendererUtils';
import {
	LineMaterial,
	LineMaterialParameters,
} from 'three/examples/jsm/lines/LineMaterial';
import * as THREE from 'three';
import { omit } from 'lodash';

export type Line = Line2;

export class LineManager {
	lines: Map<string, Line> = new Map();

	constructor(protected group: THREE.Group) {}

	createOrUpdateLine(
		key: string,
		v1: THREE.Vector3,
		v2: THREE.Vector3,
		opts: THREE.LineBasicMaterialParameters
	) {
		let line = this.lines.get(key);

		if (!line) {
			line = this.createLine(v1, v2, opts);
			this.lines.set(key, line);
			this.group.add(line);
		} else {
			this.updateLine(line, v1, v2, opts);
		}
	}

	removeLine(key: string) {
		const line = this.lines.get(key);

		if (line) {
			if (!Array.isArray(line.material) && line.material.userData.timer)
				clearInterval(line.material.userData.timer);

			this.group.remove(line);
			line.geometry.dispose();
			disposeMaterials(line);
			this.lines.delete(key);
		}
	}

	removeLines(paritalKey: string) {
		this.lines.forEach((_, key) => {
			if (key.includes(paritalKey)) this.removeLine(key);
		});
	}

	protected createLine(
		v1: THREE.Vector3,
		v2: THREE.Vector3,
		opts: THREE.LineBasicMaterialParameters | LineMaterialParameters
	): Line {
		const { color } = opts;

		const geometry = new LineGeometry();
		const colors = new THREE.Color(color);

		geometry.setPositions([v1.x, v1.y, v1.z, v2.x, v2.y, v2.z]);
		geometry.setColors([
			colors.r,
			colors.g,
			colors.b,
			colors.r,
			colors.g,
			colors.b,
		]);

		const material = MaterialFactory.getMaterial(MaterialType.LINE, {
			vertexColors: true,
			alphaToCoverage: true,
			...opts,
		}) as LineMaterial;

		const line = new Line2(geometry, material);

		if (opts.userData?.animated) {
			material.userData.timer = setInterval(() => {
				material.dashOffset += 1.0;
				material.needsUpdate = true;
			}, 50);
			line.computeLineDistances();
		}

		return line;
	}

	protected updateLine(
		line: Line,
		v1: THREE.Vector3,
		v2: THREE.Vector3,
		opts: THREE.LineBasicMaterialParameters
	) {
		const colors = new THREE.Color(opts.color);
		line.geometry.setPositions([v1.x, v1.y, v1.z, v2.x, v2.y, v2.z]);
		line.geometry.setColors([
			colors.r,
			colors.g,
			colors.b,
			colors.r,
			colors.g,
			colors.b,
		]);

		if (line.material.userData.timer) {
			clearInterval(line.material.userData.timer);
			line.material.userData.timer = undefined;
		}

		Object.assign(line.material, omit(opts, ['userData', 'color']));
		line.material.color = colors;

		if (opts.userData?.animated && !line.material.userData.timer) {
			line.material.userData.timer = setInterval(() => {
				line.material.dashOffset += 1.0;
				line.material.needsUpdate = true;
			}, 50);
			line.computeLineDistances();
		}
	}
}
