import { BaseRenderer } from './BaseRenderer';
import { MeshType } from '../core/enums/MeshType';
import { ViewType } from '@/types/creator';
import { OrbitControls } from './controls/OrbitControls';
import { FirstPersonControls } from './controls/FirstPersonControls';
import { debounce } from 'lodash';
import { Events } from '../core/Events';
import { RendererEvent } from '../core/enums/RendererEvent';
import RENDERER_CONFIG from '@/configs/rendererConfig';
import CREATOR_CONFIG from '@/configs/creatorConfig';
import gsap from 'gsap';
import * as THREE from 'three';

const RECTANGULAR_CAMERA_INITIAL = [
	0,
	CREATOR_CONFIG.MAX_ROOM_HEIGHT + 50,
	0,
] as const;
const ISOMETRIC_CAMERA_INITIAL = [500, 350, 500] as const;
const ISOMETRIC_CENTER_ADJUSTMENT = 0.2;
const CAMERA_SIZE = CREATOR_CONFIG.MAX_ROOM_HEIGHT + 50;
const CONTROLS_TARGET_Z_MODIFIER = 0.01;
const CONTROLS_MIN_PAN = new THREE.Vector3(
	-(RENDERER_CONFIG.FLOOR_SIDE_LENGTH / 2),
	0,
	-(RENDERER_CONFIG.FLOOR_SIDE_LENGTH / 2)
);
const CONTROLS_MAX_PAN = new THREE.Vector3(
	RENDERER_CONFIG.FLOOR_SIDE_LENGTH / 2,
	0,
	RENDERER_CONFIG.FLOOR_SIDE_LENGTH / 2
);

export class CameraController {
	protected renderer: BaseRenderer;
	protected rotateTimeout: number | null = null;
	mainCamera!: THREE.OrthographicCamera;
	screenshotCamera!: THREE.OrthographicCamera;
	firstPersonCamera!: THREE.PerspectiveCamera;
	controls!: OrbitControls;
	firstPersonControls!: FirstPersonControls;

	constructor(renderer: BaseRenderer) {
		this.renderer = renderer;
	}

	protected createControls() {
		this.controls = new OrbitControls(this.mainCamera, this.renderer.canvas);

		this.controls.maxZoom = RENDERER_CONFIG.CAMERA.MAX_ZOOM.IN;
		this.controls.minZoom = RENDERER_CONFIG.CAMERA.MAX_ZOOM.OUT;
		this.controls.screenSpacePanning =
			RENDERER_CONFIG.CAMERA.CONTROLS.SCREEN_SPACE_PANNING;
		this.controls.panSpeed = RENDERER_CONFIG.CAMERA.CONTROLS.PAN_SPEED;
		this.controls.maxPolarAngle =
			RENDERER_CONFIG.CAMERA.CONTROLS.MAX_POLAR_ANGLE;
		this.controls.autoRotateSpeed =
			RENDERER_CONFIG.CAMERA.CONTROLS.ROTATE_SPEED;
		this.controls.enableRotate =
			this.renderer.room.viewType === ViewType.ISOMETRIC;

		this.controls.addEventListener('change', () => {
			const tempVector = new THREE.Vector3();

			tempVector.copy(this.controls.target);
			this.controls.target.clamp(CONTROLS_MIN_PAN, CONTROLS_MAX_PAN);
			tempVector.sub(this.controls.target);
			this.mainCamera.position.sub(tempVector);
		});

		this.controls.addEventListener(
			'change',
			debounce(() => {
				Events.getInstance().emit(RendererEvent.UPDATE_RENDERER);
			}, 250)
		);

		const center = this.renderer.room.getCentroid();

		this.controls.target.set(
			center.x,
			0,
			center.y - CONTROLS_TARGET_Z_MODIFIER
		);

		this.firstPersonControls = new FirstPersonControls(
			this.firstPersonCamera,
			new THREE.Vector3(
				center.x,
				RENDERER_CONFIG.CAMERA.FIRST_PERSON.POS_Y,
				center.y
			),
			this.renderer.scene
		);

		this.controls.saveState();
	}

	createCamera() {
		const aspect =
			this.renderer.canvas.clientWidth / this.renderer.canvas.clientHeight;
		const cameraProps = [
			-CAMERA_SIZE * aspect,
			CAMERA_SIZE * aspect,
			CAMERA_SIZE,
			-CAMERA_SIZE,
			RENDERER_CONFIG.CAMERA.CONFIG[this.renderer.room.viewType].NEAR,
			RENDERER_CONFIG.CAMERA.CONFIG[this.renderer.room.viewType].FAR,
		];

		this.mainCamera = new THREE.OrthographicCamera(...cameraProps);
		this.screenshotCamera = new THREE.OrthographicCamera(...cameraProps);
		this.firstPersonCamera = new THREE.PerspectiveCamera();
		this.firstPersonCamera.aspect = aspect;

		const center = this.renderer.room.getCentroid();

		for (let camera of [this.mainCamera, this.screenshotCamera]) {
			camera.zoom = RENDERER_CONFIG.CAMERA.ZOOM.SM;
			camera.position.set(center.x, RECTANGULAR_CAMERA_INITIAL[1], center.y);
		}

		this.firstPersonCamera.position.set(0, 50, 0);

		for (let camera of [
			this.mainCamera,
			this.screenshotCamera,
			this.firstPersonCamera,
		]) {
			camera.updateProjectionMatrix();
		}
		this.createControls();
	}

	rotateView(direction: 'left' | 'right') {
		if (this.rotateTimeout) return;

		this.controls.enabled = false;
		this.controls.rotationDirection = direction;
		this.controls.autoRotate = true;

		this.rotateTimeout = setTimeout(() => {
			this.controls.autoRotate = false;
			this.controls.enabled = true;
			this.rotateTimeout = null;
		}, RENDERER_CONFIG.CAMERA.CONTROLS.ROTATE_TIME);
	}

	centerCamera() {
		this.controls.enabled = false;

		const { x, y: z } =
			this.renderer.room.meshType === MeshType.OPENED
				? {
						x: RECTANGULAR_CAMERA_INITIAL[0],
						y: RECTANGULAR_CAMERA_INITIAL[2],
				  }
				: this.renderer.room.getCentroid();

		switch (this.renderer.room.viewType) {
			case ViewType.RECTANGULAR:
				gsap.to(this.controls.target, {
					ease: 'power2.out',
					duration: 1,
					x,
					z: z - CONTROLS_TARGET_Z_MODIFIER,
					onComplete: () => {
						this.controls.enabled = true;
					},
				});

				this.mainCamera.position.y = RECTANGULAR_CAMERA_INITIAL[1];

				gsap.to(this.mainCamera.position, {
					ease: 'power2.out',
					duration: 1,
					x,
					z,
					onComplete: () => {
						this.controls.enabled = true;
					},
				});
				break;
			case ViewType.ISOMETRIC:
				gsap.to(this.controls.target, {
					ease: 'power2.out',
					duration: 1,
					x: x * ISOMETRIC_CENTER_ADJUSTMENT,
					z: x * ISOMETRIC_CENTER_ADJUSTMENT - CONTROLS_TARGET_Z_MODIFIER,
				});

				gsap.to(this.mainCamera.position, {
					ease: 'power2.out',
					duration: 1,
					x: ISOMETRIC_CAMERA_INITIAL[0],
					y: ISOMETRIC_CAMERA_INITIAL[1],
					z: ISOMETRIC_CAMERA_INITIAL[2],
					onComplete: () => {
						this.controls.enabled = true;
					},
				});
				break;
			case ViewType.FIRST_PERSON:
				this.firstPersonCamera.position.set(
					x,
					RENDERER_CONFIG.CAMERA.FIRST_PERSON.POS_Y,
					z
				);
				this.firstPersonControls.translation = new THREE.Vector3(
					x,
					RENDERER_CONFIG.CAMERA.FIRST_PERSON.POS_Y,
					z
				);
				this.firstPersonControls.rotation =
					RENDERER_CONFIG.CAMERA.FIRST_PERSON.INIT_QUATERNION.clone();
				break;
		}
	}

	zoomCamera(zoomLevel: number) {
		gsap.to(this.mainCamera, {
			duration: 0.5,
			ease: 'power1.inOut',
			zoom: zoomLevel,
			onUpdate: () => {
				this.mainCamera.updateProjectionMatrix();
			},
			onComplete: () => {
				this.controls.update();
			},
		});
	}

	changeView(viewType: ViewType) {
		this.controls.enabled = false;

		this.renderer.room.viewType = viewType;
		for (const obj of this.renderer.room.getRendererComponents()) {
			obj.needsUpdate = true;
		}
		this.renderer.room.updateRenderer();

		this.mainCamera.near = RENDERER_CONFIG.CAMERA.CONFIG[viewType].NEAR;
		this.mainCamera.far = RENDERER_CONFIG.CAMERA.CONFIG[viewType].FAR;
		this.controls.enableRotate = viewType === ViewType.ISOMETRIC;

		this.mainCamera.updateProjectionMatrix();

		this.centerCamera();
	}

	handleResize(aspect: number) {
		this.mainCamera.left = -CAMERA_SIZE * aspect;
		this.mainCamera.right = CAMERA_SIZE * aspect;
		this.mainCamera.updateProjectionMatrix();
	}

	prepareScreenshotCamera() {
		const { x, y: z } =
			this.renderer.room.meshType === MeshType.OPENED
				? {
						x: RECTANGULAR_CAMERA_INITIAL[0],
						y: RECTANGULAR_CAMERA_INITIAL[2],
				  }
				: this.renderer.room.getCentroid();

		this.screenshotCamera.position.x = x;
		this.screenshotCamera.position.z = z;
		this.screenshotCamera.lookAt(x, 0, z - CONTROLS_TARGET_Z_MODIFIER);

		this.screenshotCamera.updateProjectionMatrix();
	}

	dispose() {
		this.controls.dispose();
	}
}
