import React, { useEffect } from 'react';
import { Button } from '@/components/partials/button/Button';
import { useNavigate } from 'react-router-dom';
import { setLoading } from '@/store/slices/appSlice';
import { useTypedDispatch } from '@/store/hooks';
import {
	Box,
	Flex,
	Title,
	Image,
	Text,
	useMantineTheme,
	MediaQuery,
} from '@mantine/core';
import { ContactBoxes } from '@/components/partials/contactBoxes/ContactBoxes';
import { useContent } from '@/providers/ContentProvider';
import styles from './ErrorPage.module.scss';

interface Props {
	httpCode: string;
}

const baseSlug = 'errorPage.';

export const ErrorPage: React.FC<Props> = ({ httpCode }) => {
	const { getContent } = useContent();

	const { other } = useMantineTheme();

	useEffect(() => {
		const nav = document.getElementById('navbar'),
			footer = document.querySelector('footer');
		nav?.classList.add('hidden');
		footer?.classList.add('hidden');
		return () => {
			nav?.classList.remove('hidden');
			footer?.classList.remove('hidden');
		};
	}, []);

	const navigate = useNavigate();
	const dispatch = useTypedDispatch();

	const handleNavigate = () => {
		dispatch(setLoading(false));
		navigate('/');
	};

	const errorMessage = (httpCode: string) => {
		switch (httpCode) {
			case '404':
				return getContent(`${baseSlug}errorNotResourceFound`);
			case '':
				return getContent(`${baseSlug}errorNotPageFound`);
			default:
				return getContent(`${baseSlug}errorServer`);
		}
	};

	return (
		<Flex className={`page-wrapper ${styles.container}`} gap="xl">
			<Box maw={528}>
				<Title order={1} mb="lg">
					{httpCode || '404'}
				</Title>
				<Title order={3} mb="sm">
					{getContent(`${baseSlug}subheader`)}
				</Title>
				<Text {...other.fontSizes.md} color={other.textDimmed} mb="md">
					{errorMessage(httpCode)}
				</Text>
				<ContactBoxes />
				<Button
					themes={['primary', 'arrow', 'fullWidth']}
					buttonProps={{
						onClick: handleNavigate,
					}}
				>
					{getContent(`${baseSlug}navigateHomeBtn`)}
				</Button>
			</Box>
			<MediaQuery smallerThan="lg" styles={{ display: 'none' }}>
				<Image src={getContent(`${baseSlug}heroImg`)} maw={700} />
			</MediaQuery>
		</Flex>
	);
};
