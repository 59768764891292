import { BaseRenderer } from './BaseRenderer';
import * as THREE from 'three';

export class LightController {
	private ambientLight!: THREE.AmbientLight;
	protected renderer: BaseRenderer;

	constructor(renderer: BaseRenderer) {
		this.renderer = renderer;
	}

	initLights() {
		this.ambientLight = new THREE.AmbientLight(0xffffff, 1);
		this.renderer.scene.add(this.ambientLight);
	}
}
