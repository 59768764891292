export const QueryKey = {
	attachments: 'attachments',
	content: 'content',
	projects: 'projects',
	componentCategories: 'component-categories',
	components: 'components',
	componentItems: 'component-items',
	parameterCategories: 'parameter-categories',
	parameters: 'parameters',
	users: 'users',
	globalSettings: 'global-settings',
} as const;

export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];
