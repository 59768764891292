import { Collidable } from '@/3d/core/interfaces/Collidable';
import { Point } from '@/3d/core/types/point';
import * as THREE from 'three';

export class CollisionEdge {
	length: number;

	constructor(public p1: THREE.Vector2, public p2: THREE.Vector2) {
		this.length = p1.distanceTo(p2);
	}

	isIntersectingOtherEdge(edge: CollisionEdge) {
		const point = { x: 0, y: 0 };

		const a = this.p1;
		const b = this.p2;
		const e = edge.p1;
		const f = edge.p2;

		const a1 = b.y - a.y;
		const a2 = f.y - e.y;
		const b1 = a.x - b.x;
		const b2 = e.x - f.x;
		const c1 = b.x * a.y - a.x * b.y;
		const c2 = f.x * e.y - e.x * f.y;
		const denom = a1 * b2 - a2 * b1;

		if (!denom) return false;

		point.x = (b1 * c2 - b2 * c1) / denom;
		point.y = (a2 * c1 - a1 * c2) / denom;

		const uc = (f.y - e.y) * (b.x - a.x) - (f.x - e.x) * (b.y - a.y);
		const ua = ((f.x - e.x) * (a.y - e.y) - (f.y - e.y) * (a.x - e.x)) / uc;
		const ub = ((b.x - a.x) * (a.y - e.y) - (b.y - a.y) * (a.x - e.x)) / uc;

		return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
	}
}

export class CollisionObject {
	edges: CollisionEdge[] = [];

	constructor(public vertices: THREE.Vector2[]) {
		if (vertices.length !== 4)
			throw new Error('Invalid number of vertices for a rectangle');

		this.edges = vertices.map(
			(point, idx, arr) =>
				new CollisionEdge(point, arr[(idx + 1) % arr.length]),
			[]
		);
	}

	private getCenter(): THREE.Vector2 {
		let centerX = 0;
		let centerY = 0;

		for (const vertex of this.vertices) {
			centerX += vertex.x;
			centerY += vertex.y;
		}

		return new THREE.Vector2(centerX / 4, centerY / 4);
	}

	private getMBR(): { min: THREE.Vector2; max: THREE.Vector2 } {
		let minX = Infinity;
		let minY = Infinity;
		let maxX = -Infinity;
		let maxY = -Infinity;

		for (const vertex of this.vertices) {
			minX = Math.min(minX, vertex.x);
			minY = Math.min(minY, vertex.y);
			maxX = Math.max(maxX, vertex.x);
			maxY = Math.max(maxY, vertex.y);
		}

		return {
			min: new THREE.Vector2(minX, minY),
			max: new THREE.Vector2(maxX, maxY),
		};
	}

	isAABBCollision(other: CollisionObject) {
		const mbr1 = this.getMBR();
		const mbr2 = other.getMBR();

		const center1 = this.getCenter();
		const center2 = other.getCenter();

		const halfWidth1 = (mbr1.max.x - mbr1.min.x) / 2;
		const halfHeight1 = (mbr1.max.y - mbr1.min.y) / 2;

		const halfWidth2 = (mbr2.max.x - mbr2.min.x) / 2;
		const halfHeight2 = (mbr2.max.y - mbr2.min.y) / 2;

		const deltaX = Math.abs(center1.x - center2.x);
		const deltaY = Math.abs(center1.y - center2.y);

		return (
			deltaX <= halfWidth1 + halfWidth2 && deltaY <= halfHeight1 + halfHeight2
		);
	}

	isShapeCollision(other: CollisionObject) {
		for (const edge1 of this.edges) {
			for (const edge2 of other.edges) {
				if (edge1.isIntersectingOtherEdge(edge2)) {
					return true;
				}
			}
		}

		return false;
	}
}

export class CollisionDetector {
	collidables: Collidable[] = [];

	constructor() {}

	addCollidable(collidable: Collidable) {
		this.collidables.push(collidable);
	}

	removeCollidable(collidable: Collidable) {
		this.collidables = this.collidables.filter((c) => c !== collidable);
	}

	isColliding(obj: Collidable, pos: Point) {
		try {
			if (!obj.getCollisionBox().length) return false;

			for (const collidable of this.collidables) {
				if (collidable === obj) continue;
				if (collidable.isSameGroup && collidable.isSameGroup(obj)) continue;

				for (const box1 of obj.getCollisionBox(pos)) {
					for (const box2 of collidable.getCollisionBox()) {
						if (box1.isAABBCollision(box2)) {
							if (box1.isShapeCollision(box2)) return true;
						}
					}
				}
			}

			return false;
		} catch (error) {
			return false;
		}
	}
}
