import React, { useEffect, useState } from 'react';
import { Button } from '@/components/partials/button/Button';
import {
	LoadingOverlay,
	Title,
	Text,
	useMantineTheme,
	PasswordInput,
	Notification,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useParams } from 'react-router-dom';
import { validatePassword } from '@/utils/utilities';
import { HeroImg } from '@/components/partials/heroImg/HeroImg';
import { AppPaths } from '@/configs/appPaths';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import { SubmitStatus } from '../login/Login';
import UnauthenticatedApi from '@/api/UnauthenticatedApi';
import styles from './SetNewPassword.module.scss';

const initialValues = {
	password: '',
	repeatPassword: '',
};

const baseSlug = 'setPassword.';

export const SetNewPassword: React.FC = () => {
	const { getErrorMessage, getContent } = useContent();

	const { other, spacing } = useMantineTheme();

	const navigate = useNavigate();

	const token = useParams().token as string;

	useEffect(() => {
		if (!token) navigate(`/${AppPaths.LOGIN}`);
	}, [navigate, token]);

	const [status, setStatus] = useState(SubmitStatus.THROTTLE);
	const [errorMessage, setErrorMessage] = useState('');

	const form = useForm({
		initialValues,
		validate: {
			password: (value: string, values: typeof initialValues) => {
				if (value !== values.repeatPassword)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
			repeatPassword: (value: string, values: typeof initialValues) => {
				if (value !== values.password)
					return getErrorMessage(ErrorCode.DIFFERENT_PASSWORDS);
				if (!validatePassword(value))
					return getErrorMessage(ErrorCode.PASSWORD_TOO_WEAK);
				return null;
			},
		},
	});

	const handleSubmit = async () => {
		setStatus(SubmitStatus.LOADING);
		const response = await UnauthenticatedApi.resetPassword(
			token,
			form.values.password
		);
		form.reset();

		if (response === SubmitStatus.SUCCESS) {
			setStatus(SubmitStatus.SUCCESS);
			return;
		}

		setErrorMessage(getErrorMessage(response.message));
		setStatus(SubmitStatus.ERROR);
	};

	return (
		<div className="page-wrapper-unauth">
			<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={status === SubmitStatus.LOADING} />
				<Title ta="center" order={1} mb={6}>
					{getContent(`${baseSlug}header`)}
				</Title>
				<Text
					{...other.fontSizes.xl}
					ta="center"
					color="dark.6"
					mb="xl"
					className="hide-on-mobile"
				>
					{getContent(`${baseSlug}subheader`)}
				</Text>
				{status === SubmitStatus.ERROR && (
					<Notification {...other.notificationProps.danger} mb="lg">
						<Text {...other.fontSizes.md} color="red.5">
							{errorMessage}
						</Text>
					</Notification>
				)}
				{status === SubmitStatus.SUCCESS && (
					<Notification {...other.notificationProps.success} mb="lg">
						<Text {...other.fontSizes.md} color="green.6">
							{getContent(`${baseSlug}success`)}
						</Text>
					</Notification>
				)}
				{status !== SubmitStatus.SUCCESS && (
					<>
						<PasswordInput
							{...form.getInputProps('password')}
							{...other.inputSizes.lg}
							required
							placeholder={getContent(`${baseSlug}inputPasswordPlaceholder`)}
							mb="lg"
						/>
						<PasswordInput
							{...form.getInputProps('repeatPassword')}
							{...other.inputSizes.lg}
							required
							placeholder={getContent(
								`${baseSlug}inputRepeatPasswordPlaceholder`
							)}
							mb="lg"
						/>
						<Button
							themes={['primary', 'arrow', 'fullWidth']}
							buttonProps={{
								type: 'submit',
								style: {
									marginBottom: spacing.lg,
								},
							}}
						>
							{getContent(`${baseSlug}submitBtn`)}
						</Button>
					</>
				)}
				<Text
					{...other.fontSizes.md}
					ta="center"
					color={other.textDimmed}
					mb="lg"
				>
					{getContent(`${baseSlug}navigateLogin`, {
						links: {
							$login: {
								to: `/${AppPaths.LOGIN}`,
								className: 'text-primary',
							},
						},
					})}
				</Text>
			</form>
			<HeroImg src={getContent(`${baseSlug}heroImg`)} />
		</div>
	);
};
