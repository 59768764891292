export enum AppPaths {
	EMAIL_CONFIRMATION = 'email-confirmation/:token',

	LOGIN = 'login',
	REGISTER = 'register',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',
	CONTACT = 'contact',

	PROJECTS = 'projects',
	ARCHIVE = 'archive',
	ACCOUNT = 'account',

	CREATOR = 'creator',
	LAYOUT = 'layout',
	LIGHTS = 'lights',
	ACCESSORIES = 'accessories',
	SUMAMRY = 'summary',
}
