import React, { useMemo } from 'react';
import { Mesh } from '@/3d/core/types/mesh';
import { UUID } from '@/types/types';
import { Affix } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { Project3dView } from '@/components/partials/project3dView/Project3dView';
import { Loading } from '@/components/partials/loading/Loading';
import ProjectApi from '@/api/ProjectApi';

export const FullScreenProjectView: React.FC = () => {
	const projectId = useParams().projectId as UUID;
	const projectQuery = useQuery({
		queryKey: [ProjectApi.queryKey, projectId],
		queryFn: ProjectApi.getOne,
	});

	const mesh = useMemo<Mesh | null>(() => {
		if (!projectQuery.data) return null;

		try {
			return JSON.parse(projectQuery.data.options).renderer.mesh;
		} catch (error) {
			return null;
		}
	}, [projectQuery.data]);

	if (!mesh)
		return (
			<Affix top={0} left={0} w="100dvw" h="100dvh" bg="white">
				<Loading fullHeight />;
			</Affix>
		);

	return (
		<Affix top={0} left={0} w="100dvw" h="100dvh">
			<Project3dView mesh={mesh} />
		</Affix>
	);
};
