import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store/index';
import { StoreKey } from '@/configs/storeKeys';

export type TutorialStep = {
	slug: string;
	enabled: boolean;
};

export type PastTutorialSteps = number[];
export const initialPastTutorialSteps: PastTutorialSteps = [];

export const initialCurrentTutorialStep = 0;

export type TutorialSteps = TutorialStep[];
const tutorialSteps: TutorialSteps = [
	{
		slug: 'roomShapes',
		enabled: true,
	},
	{
		slug: 'roomHeight',
		enabled: true,
	},
	{
		slug: 'roomWall',
		enabled: true,
	},
	{
		slug: 'viewToggler',
		enabled: true,
	},
	{
		slug: 'projectName',
		enabled: true,
	},
	{
		slug: 'viewRotation',
		enabled: false,
	},
	{
		slug: 'lampSeries',
		enabled: false,
	},
	{
		slug: 'lamps',
		enabled: false,
	},
	{
		slug: 'componentParameters',
		enabled: false,
	},
	{
		slug: 'resetBtn',
		enabled: false,
	},
	{
		slug: 'componentBox',
		enabled: false,
	},
];

interface SliceState {
	isTutorialOpened: boolean;
	currentTutorialStep: number;
	pastTutorialSteps: PastTutorialSteps;
	tutorialSteps: TutorialSteps;
}

const initialState: SliceState = {
	isTutorialOpened: false,
	currentTutorialStep: initialCurrentTutorialStep,
	pastTutorialSteps: initialPastTutorialSteps,
	tutorialSteps: [...tutorialSteps],
};

export const tutorialSlice = createSlice({
	name: StoreKey.TUTORIAL,
	initialState,
	reducers: {
		setTutorialCurrentStep: (state, action: PayloadAction<number>) => {
			state.currentTutorialStep = action.payload;
		},
		resetTutorialSteps: (state) => {
			state.tutorialSteps = [...tutorialSteps];
		},
		enableTutorialStep: (
			state,
			{ payload }: PayloadAction<number | number[]>
		) => {
			if (Array.isArray(payload)) {
				payload.forEach((step) => {
					state.tutorialSteps[step].enabled = true;
				});
			} else state.tutorialSteps[payload].enabled = true;
		},
		setTutorialPastSteps: (
			state,
			action: PayloadAction<Partial<PastTutorialSteps>>
		) => {
			Object.assign(state.pastTutorialSteps, action.payload);
		},
		nextTutorialCurrentStep: (state) => {
			state.pastTutorialSteps.push(state.currentTutorialStep);
		},
		setTutorialOpened: (state, { payload }: PayloadAction<boolean>) => {
			state.isTutorialOpened = payload;
		},
		finishTutorial: (state) => {
			state.pastTutorialSteps = state.tutorialSteps.map((_, idx) => idx);
		},
	},
});

export const {
	setTutorialCurrentStep,
	resetTutorialSteps,
	enableTutorialStep,
	setTutorialPastSteps,
	nextTutorialCurrentStep,
	setTutorialOpened,
	finishTutorial,
} = tutorialSlice.actions;

export const selectTutoriaState = (state: RootState) => state.app;
export default tutorialSlice.reducer;
