const APP_CONFIG = {
	QUERY_STALE_TIME: 30_000,
	QUERY_CACHE_TIME: 1_800_000,
	QUERY_RETRY: 2,
	EDIT_LOGOUT_TIME: 6,
	SIZE_PER_PAGE: 6,
	TEXTAREA_LIMIT: 200,
	NAVBAR_HEIGHT: 88,
	INFINITE_SCROLL_PER_PAGE: 3,
	TABLE_PAGE_SIZES: [10, 25, 50],
	MODAL_WIDTH: 528,
	Z_INDEX: {
		NOTIFICATION: 100,
		DRAWER: 104,
		OVERLAY: 50,
		MODAL: 301,
		SCROLL_TOP: 50,
		DRAGGED_PREVIEW: 500,
		TUTORIAL_OVERLAY: 550,
		TUTORIAL_ITEM: 555,
	},
	ROOT_FONT_SIZE: 16,
	BREAKPOINTS: {
		XS: 550,
		SM: 700,
		MD: 900,
		LG: 1000,
		XL: 1200,
		XXL: 1440,
	},
	DEFAULT_LANGUAGE: 'PL',
	DEFAULT_DOCUMENT_TITLE: 'Konfigurator oświetlenia - Lena Lighting',
	DEFAULT_META_DESC:
		'Konfigurator oświetlenia służy do stworzenia projektu linii świetlnej Lena Lighting.',
} as const;

export default APP_CONFIG;
