import { QueryKey } from '@/configs/queryKeys';
import { ComponentCategory } from './ComponentCategoryApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { Parameter } from './ParameterApi';
import { Translations, UUID } from '@/types/types';
import EntityApi from './EntityApi';
import httpClient from './httpClient';

export enum ParameterCategoryType {
	STANDARD,
	FLUX,
	POWER,
	EFFECTIVITY,
	LAMPSHADE_TYPE,
	DIMM_DALI,
	ATTACHMENT_METHOD,
	VERSION,
}

export interface ParameterCategory {
	id: UUID;
	name: string;
	componentCategories: ComponentCategory[];
	parameters: Parameter[];
	type: ParameterCategoryType;
	categoryOrder: number;
	translations: Translations<{
		name: string;
		description: string;
	}>;
	description?: string;
	unit?: string;
	color?: string;
}

class ParameterCategoryApi extends EntityApi<ParameterCategory> {
	constructor() {
		super(QueryKey.parameterCategories);
	}

	async getCategoryParameters({
		queryKey,
	}: QueryFunctionContext<[QueryKey, UUID, QueryKey]>) {
		const response = await httpClient.get<Parameter[]>(
			`${queryKey[0]}/${queryKey[1]}/${queryKey[2]}`
		);

		return response.data;
	}
}

export default new ParameterCategoryApi();
