import { Room } from './Room';
import { Wall } from './Wall';
import { Label } from './Label';
import { Lamp } from './Lamp';
import { Node } from './Node';
import { ApplyEventHandlers, On } from './Events';
import { NativeMouseEvent } from '../renderer/MouseHandler';
import { MouseButtons } from '@/types/types';
import { Connector } from '@/3d/core/Connector';
import { Line } from '@/3d/core/Line';
import { Charger } from './Charger';
import { ConnectorType } from '@/api/ComponentApi';
import { ViewType } from '@/types/creator';
import { RendererEvent } from './enums/RendererEvent';
import { Vector2 } from 'three';
import RENDERER_CONFIG from '@/configs/rendererConfig';

@ApplyEventHandlers
export class MouseEvents {
	private observableWalls: Wall[] = [];
	private observableNodes: Node[] = [];
	private observableLabels: Label[] = [];
	private observableLamps: Lamp[] = [];
	private observableConnectors: Connector[] = [];
	private observableChargers: Charger[] = [];
	private wallMouseOver = false;
	private nodeMouseOver = false;
	private labelMouseOver = false;
	private lampMouseOver = false;
	private connectorMouseOver = false;
	private chargerMouseOver = false;
	isAreaSelecting = false;
	startAreaDragPos: Vector2 = new Vector2(0, 0);
	currentAreaDragPos: Vector2 = new Vector2(0, 0);

	constructor(private room: Room) {
		this.registerObservableArea();
	}

	@On(RendererEvent.UPDATE_OBSERVABLE_AREA)
	registerObservableArea() {
		this.observableWalls = [];
		this.observableNodes = [];
		this.observableLabels = [];
		this.observableLamps = [];
		this.observableConnectors = [];
		this.observableChargers = [];

		for (const wall of this.room.walls) {
			this.observableWalls.push(wall);
		}

		for (const node of this.room.nodes) {
			this.observableNodes.push(node);
		}

		for (const label of this.room.labels) {
			this.observableLabels.push(label);
		}

		for (const lamp of this.room.lamps) {
			this.observableLamps.push(lamp);
		}

		for (const connector of this.room.connectors) {
			this.observableConnectors.push(connector);
		}

		for (const charger of this.room.chargers) {
			this.observableChargers.push(charger);
		}
	}

	@On(RendererEvent.MOUSE_LEAVE_RENDERER)
	mouseLeaveLeaveRenderer() {
		this.wallMouseOver = false;
		this.labelMouseOver = false;
		this.nodeMouseOver = false;
		this.lampMouseOver = false;
		this.connectorMouseOver = false;
	}

	private isMouseInsideRect(
		mouse: Vector2,
		object: Wall | Label | Lamp | Charger
	) {
		if (!!this.currentAreaDragPos.x || !!this.currentAreaDragPos.y) return;

		const width = object.width;
		const length = object.length;

		const linePoints = object.getLinePoints(length);
		const p1 = linePoints.p1;
		const p2 = linePoints.p2;

		const numerator = Math.abs(
			(p2.y - p1.y) * mouse.x -
				(p2.x - p1.x) * mouse.y +
				p2.x * p1.y -
				p2.y * p1.x
		);
		const denominator = Math.sqrt(
			Math.pow(p2.y - p1.y, 2) + Math.pow(p2.x - p1.x, 2)
		);

		const distance = numerator / denominator;

		const insideThickness = distance <= width / 2;

		const dotProduct =
			(mouse.x - p1.x) * (p2.x - p1.x) + (mouse.y - p1.y) * (p2.y - p1.y);
		const wallLengthSquared = Math.pow(length, 2);
		const betweenEndpoints = dotProduct >= 0 && dotProduct <= wallLengthSquared;

		return insideThickness && betweenEndpoints;
	}

	private isMouseInsideCircle(mouse: Vector2, node: Node) {
		const intersection = node.getIntersection();
		const { wallA, wallB } = node.getConnectedWalls();

		if (
			intersection &&
			wallA.length > RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH &&
			wallB.length > RENDERER_CONFIG.WALL_MIN_SPLIT_LENGTH
		) {
			const { x, y } = intersection;
			const dx = mouse.x - x;
			const dy = mouse.y - y;
			const distance = Math.sqrt(dx * dx + dy * dy);

			return distance <= 20;
		}

		return false;
	}

	private isMouseInsideLine(mouse: Vector2, line: Line, thickness = 10) {
		const { p1, p2 } = line;

		const length = line.length;

		const numerator = Math.abs(
			(p2.y - p1.y) * mouse.x -
				(p2.x - p1.x) * mouse.y +
				p2.x * p1.y -
				p2.y * p1.x
		);
		const denominator = Math.sqrt(
			Math.pow(p2.y - p1.y, 2) + Math.pow(p2.x - p1.x, 2)
		);

		const distance = numerator / denominator;

		const insideThickness = distance <= thickness / 2;

		const dotProduct =
			(mouse.x - p1.x) * (p2.x - p1.x) + (mouse.y - p1.y) * (p2.y - p1.y);
		const wallLengthSquared = Math.pow(length, 2);
		const betweenEndpoints = dotProduct >= 0 && dotProduct <= wallLengthSquared;

		return insideThickness && betweenEndpoints;
	}

	private isMouseInsideConnector(mouse: Vector2, connector: Connector) {
		const lines = connector.getLinePoints(true);

		switch (connector.connectorType) {
			case ConnectorType.L:
				return (
					this.isMouseInsideLine(mouse, lines[0], connector.width) ||
					this.isMouseInsideLine(mouse, lines[1], connector.width)
				);

			case ConnectorType.T:
				return (
					this.isMouseInsideLine(mouse, lines[0], connector.width) ||
					this.isMouseInsideLine(mouse, lines[1], connector.width) ||
					this.isMouseInsideLine(mouse, lines[2], connector.width)
				);

			case ConnectorType.X:
				return (
					this.isMouseInsideLine(mouse, lines[0], connector.width) ||
					this.isMouseInsideLine(mouse, lines[1], connector.width) ||
					this.isMouseInsideLine(mouse, lines[2], connector.width) ||
					this.isMouseInsideLine(mouse, lines[3], connector.width)
				);
			case ConnectorType.I:
			case ConnectorType.SQUARE:
				return this.isMouseInsideLine(mouse, lines[0], connector.width);
		}
	}

	private objectDragging(mouse: Vector2, mouseEvent: NativeMouseEvent) {
		for (const wall of this.observableWalls) {
			if (wall.isDragging && wall.mouseOver) {
				this.room.events.emit(
					RendererEvent.WALL_DRAGGING,
					wall,
					mouse,
					mouseEvent
				);
				return true;
			}
		}

		for (const lamp of this.observableLamps) {
			if (lamp.isDragging && lamp.mouseOver) {
				this.room.events.emit(
					RendererEvent.LAMP_DRAGGING,
					lamp,
					mouse,
					mouseEvent
				);
				return true;
			}
		}

		for (const connector of this.observableConnectors) {
			if (connector.isDragging && connector.mouseOver) {
				this.room.events.emit(
					RendererEvent.CONNECTOR_DRAGGING,
					connector,
					mouse,
					mouseEvent
				);
				return true;
			}
		}

		for (const charger of this.observableChargers) {
			if (charger.isDragging && charger.mouseOver) {
				this.room.events.emit(
					RendererEvent.CHARGER_DRAGGING,
					charger,
					mouse,
					mouseEvent
				);
				return true;
			}
		}

		return false;
	}

	mouseUp(mouseEvent: NativeMouseEvent) {
		this.isAreaSelecting = false;

		if (this.room.viewType === ViewType.FIRST_PERSON) {
			document.body.style.cursor = 'initial';
			return;
		}

		for (const wall of this.observableWalls) {
			if (wall.mouseOver) {
				this.room.events.emit(RendererEvent.MOUSE_UP, wall);
				document.body.style.cursor = 'grab';
				return;
			}
		}
		for (const obj of this.room.getRendererObjects()) {
			if (obj.mouseOver) {
				this.room.events.emit(RendererEvent.MOUSE_UP, obj);
				document.body.style.cursor = 'grab';
				return;
			}
		}

		const { areaSelection } = this.room.getAllowedActions();
		if (
			areaSelection &&
			this.currentAreaDragPos.x &&
			this.currentAreaDragPos.y
		) {
			this.room.events.emit(RendererEvent.SELECT_DRAG_END);
			this.currentAreaDragPos = new Vector2(0, 0);
		}
	}

	mouseDown(mouse: Vector2, mouseEvent: NativeMouseEvent) {
		const { room, lamps, areaSelection } = this.room.getAllowedActions();
		const isLeftClick = mouseEvent.button === MouseButtons.LEFT;

		if (this.room.viewType === ViewType.FIRST_PERSON) {
			document.body.style.cursor = 'move';
			return;
		}

		if (room) {
			if (!isLeftClick) return;

			for (const rect of this.observableWalls) {
				if (rect.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						rect,
						mouseEvent,
						mouse
					);
					return;
				}
			}

			for (const lab of this.observableLabels) {
				if (lab.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						lab,
						mouseEvent,
						mouse
					);
					return;
				}
			}

			for (const circle of this.observableNodes) {
				if (circle.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						circle,
						mouseEvent,
						mouse
					);
					circle.mouseOver = false;
					this.nodeMouseOver = false;
					return;
				}
			}
		}

		if (lamps) {
			for (const lamp of this.observableLamps) {
				if (lamp.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						lamp,
						mouseEvent,
						mouse
					);
					return;
				}
			}

			for (const connector of this.observableConnectors) {
				if (connector.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						connector,
						mouseEvent,
						mouse
					);
					return;
				}
			}

			for (const charger of this.observableChargers) {
				if (charger.mouseOver) {
					this.room.events.emit(
						RendererEvent.MOUSE_DOWN,
						charger,
						mouseEvent,
						mouse
					);
					this.lampMouseOver = false;
					return;
				}
			}
		}

		if (areaSelection && isLeftClick) {
			this.startAreaDragPos = mouse;
			this.isAreaSelecting = true;
		}

		this.room.events.emit(RendererEvent.CLICK_ON_EMPTY_SPACE);
	}

	mouseMove(mouse: Vector2, mouseEvent: NativeMouseEvent) {
		if (this.room.viewType !== ViewType.RECTANGULAR) return;

		this.room.sceneMouse = mouse;
		this.room.windowMouse = new Vector2(mouseEvent.clientX, mouseEvent.clientY);

		const { room, lamps, areaSelection } = this.room.getAllowedActions();

		if (this.objectDragging(mouse, mouseEvent)) {
			document.body.style.cursor = 'grabbing';
			return;
		}

		if (room) {
			//wall
			if (!this.nodeMouseOver && !this.labelMouseOver) {
				for (const rect of this.observableWalls) {
					if (this.isMouseInsideRect(mouse, rect)) {
						document.body.style.cursor = 'grab';
						this.wallMouseOver = true;
						if (!rect.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								rect,
								mouseEvent
							);
						}
					} else {
						if (rect.mouseOver) {
							this.wallMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, rect);
						}
					}
				}
			}
			//node
			if (!this.wallMouseOver && !this.labelMouseOver) {
				for (const circle of this.observableNodes) {
					if (this.isMouseInsideCircle(mouse, circle)) {
						document.body.style.cursor = 'pointer';
						this.nodeMouseOver = true;
						if (!circle.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								circle,
								mouseEvent
							);
						}
					} else {
						if (circle.mouseOver) {
							this.nodeMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, circle);
						}
					}
				}
			}

			//label
			if (!this.wallMouseOver && !this.nodeMouseOver) {
				for (const label of this.observableLabels) {
					if (this.isMouseInsideRect(mouse, label)) {
						if (label.wall.angle % 90) {
							document.body.style.cursor = 'initial';
							return;
						}

						document.body.style.cursor = 'pointer';
						this.labelMouseOver = true;
						this.room.labelsNeedsUpdate = true;

						if (!label.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								label,
								mouseEvent
							);
						}
					} else {
						if (label.mouseOver) {
							this.labelMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, label);
						}
					}
				}
			}
		}

		if (areaSelection && this.isAreaSelecting) {
			this.currentAreaDragPos = mouse;

			this.room.events.emit(
				RendererEvent.SELECT_DRAG,
				this.startAreaDragPos,
				this.currentAreaDragPos
			);

			return;
		}

		if (lamps) {
			//lamp
			if (!this.connectorMouseOver && !this.chargerMouseOver) {
				for (const lamp of this.observableLamps) {
					if (this.isMouseInsideRect(mouse, lamp)) {
						this.lampMouseOver = true;
						document.body.style.cursor = 'grab';
						if (!lamp.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								lamp,
								mouseEvent
							);
						}
					} else {
						if (lamp.mouseOver) {
							this.lampMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, lamp);
						}
					}
				}
			}

			//connector
			if (!this.lampMouseOver && !this.chargerMouseOver) {
				for (const connector of this.observableConnectors) {
					if (this.isMouseInsideConnector(mouse, connector)) {
						this.connectorMouseOver = true;
						document.body.style.cursor = 'grab';
						if (!connector.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								connector,
								mouseEvent
							);
						}
					} else {
						if (connector.mouseOver) {
							this.connectorMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, connector);
						}
					}
				}
			}

			//charger
			if (!this.lampMouseOver && !this.connectorMouseOver) {
				for (const charger of this.observableChargers) {
					if (this.isMouseInsideRect(mouse, charger)) {
						this.chargerMouseOver = true;
						document.body.style.cursor = 'grab';
						if (!charger.mouseOver) {
							this.room.events.emit(
								RendererEvent.MOUSE_ENTER,
								charger,
								mouseEvent
							);
						}
					} else {
						if (charger.mouseOver) {
							this.chargerMouseOver = false;
							this.room.events.emit(RendererEvent.MOUSE_LEAVE, charger);
						}
					}
				}
			}
		}
	}
}
