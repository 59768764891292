import { ViewType } from '@/types/creator';
import { Quaternion } from 'three';

const RENDERER_CONFIG = {
	SCENE: {
		BACKGROUND: 0xeef2f6,
	},
	CAMERA: {
		SURFACE_AREA_BREAKPOINT: {
			XS: 50_000,
			SM: 80_000,
			MD: 120_000,
			LG: 400_000,
			XL: 800_000,
		},
		ZOOM: { XS: 1.75, SM: 1.25, MD: 1, LG: 0.75, XL: 0.5 },
		ZOOM_SCRENSHOT: { XS: 2.4, SM: 1.95, MD: 1.6, LG: 1.15, XL: 0.9 },
		ZOOM_TICK: 0.25,
		MAX_ZOOM: {
			IN: 5.5,
			OUT: 0.75,
		},
		CONTROLS: {
			PAN_SPEED: 0.8,
			MAX_POLAR_ANGLE: Math.PI / 2.5,
			SCREEN_SPACE_PANNING: false,
			ROTATE_SPEED: 10,
			ROTATE_TIME: 500,
		},
		FIRST_PERSON: {
			POS_Y: 50,
			// POS_Y: 10,
			INIT_QUATERNION: new Quaternion(
				0.45575657303428563,
				0.005937721073483071,
				-0.003040352607180629,
				0.8900794604194175
			),
		},
		CONFIG: {
			[ViewType.RECTANGULAR]: {
				NEAR: 0.1,
				FAR: 2000,
			},
			[ViewType.ISOMETRIC]: {
				NEAR: -2000,
				FAR: 10_000,
			},
			[ViewType.FIRST_PERSON]: {
				NEAR: -2000,
				FAR: 10_000,
			},
		},
	},
	GRID_SIZE: 20,
	MIN_DIST_GRID: 2.5,
	WALL_THICKNESS: 10,
	WALL_MIN_SPLIT_LENGTH: 100,
	WALL_MIN_LENGTH: 50,
	WALL_MAX_LENGTH: 1_000,
	ROTATE_TICK: 45,
	HISTORY_MAXIMUM_SNAPSHOTS: 10,
	GENERIC_OBJECT_DIMENSIONS: {
		length: 55,
		width: 5,
		height: 6.9,
	},
	CHARGER_DIMENSIONS: {
		length: 20,
		width: 20,
		height: 2.5,
	},
	OBJECT_CONNECT_THRESHOLD: 10,
	OBJECT_DISCONNECT_THRESHOLD: 15,
	FLOOR_SIDE_LENGTH: 7000,
	FLOOR_Y_POS: 0,
	FLOOR_LINES_Y_POSITION: 1,
	FLOOR_LINES_LARGE_Y_POSITION: 1,
	FLOOR_GRID_SIZE: 10000,
	LAMP_DEFAULT_BOTTOM_COLOR: '#f5f6f6',
} as const;

export default RENDERER_CONFIG;
