import { QueryKey } from '@/configs/queryKeys';
import { Attachment, Translations, UUID } from '@/types/types';
import { ComponentCategory } from './ComponentCategoryApi';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ParameterCategory } from './ParameterCategoryApi';
import { DimensionsPresentation } from '@/types/creator';
import { ComponentItem } from './ComponentItemApi';
import { omitBy } from 'lodash';
import EntityApi from './EntityApi';
import httpClient from './httpClient';
import { Parameter } from './ParameterApi';

export enum ConnectorType {
	L,
	T,
	X,
	I,
	SQUARE,
}

export enum ComponentType {
	LAMP,
	CONNECTOR,
	ACCESSORY,
	SLING,
}

export interface Component {
	id: UUID;
	name: string;
	componentCategory: ComponentCategory;
	type: ComponentType;
	componentItemsCount: number;
	connectorType: ConnectorType | null;
	translations: Translations<{
		name: string;
		description: string;
	}>;
	description?: string;
	image?: Attachment;
	isLampConnectable?: boolean;
	defaultComponentItem?: Omit<ComponentItem, 'component'>;
}

export type ParameterWithAvailability = Parameter & { isAvailable: boolean };

export type CalculatedComponentItemsResponse = {
	componentItems: ComponentItem[];
	parameterCategories: (Omit<ParameterCategory, 'parameters'> & {
		parameters: ParameterWithAvailability[];
	})[];
	dimensions: { isAvailable: boolean; value: DimensionsPresentation }[];
};

class ComponentApi extends EntityApi<Component> {
	constructor() {
		super(QueryKey.components);
	}

	async getParametersResult({
		queryKey,
	}: QueryFunctionContext<
		[
			QueryKey,
			UUID | undefined,
			QueryKey,
			UUID[],
			DimensionsPresentation | '',
			UUID?
		]
	>) {
		const params = omitBy(
			{
				selectedParameters: queryKey[3].join(','),
				dimensions: queryKey[4],
				project: queryKey[5],
			},
			(val) => !val
		);

		const response = await httpClient.get<CalculatedComponentItemsResponse>(
			`${queryKey[0]}/${queryKey[1]}/${queryKey[2]}`,
			{
				params,
			}
		);

		return response.data;
	}
}

export default new ComponentApi();
