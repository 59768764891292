import { BaseRenderer } from './BaseRenderer';
import { ApplyEventHandlers, Emit, Events, On } from '../core/Events';
import { WallManager } from './WallManager';
import { LabelManager } from './LabelManager';
import { NodeManager } from './NodeManager';
import { FloorManager } from './FloorManager';
import { LampManager } from './LampManager';
import { Room } from '../core/Room';
import { SelectionAreaRenderer } from '@/3d/renderer/SelectionAreaRenderer';
import { ConnectorManager } from '@/3d/renderer/ConnectorManager';
import { ChargerManager } from './ChargerManager';
import { RendererEvent } from '../core/enums/RendererEvent';
import { ChangeViewPayload, ViewType } from '@/types/creator';
import * as THREE from 'three';

@ApplyEventHandlers
export class RoomRenderer extends BaseRenderer {
	private floorManager: FloorManager;
	private wallManager: WallManager;
	private nodeManager: NodeManager;
	private labelManager: LabelManager;
	private lampManager: LampManager;
	private connectorManager: ConnectorManager;
	private chargerManager: ChargerManager;
	private selectionAreaRenderer: SelectionAreaRenderer;

	constructor(canvas: HTMLCanvasElement, room: Room) {
		super(canvas, room);
		this.floorManager = new FloorManager(room, this.meshGroup);
		this.wallManager = new WallManager(room, this.meshGroup);
		this.nodeManager = new NodeManager(room, this.meshGroup);
		this.labelManager = new LabelManager(room, this.meshGroup);
		this.lampManager = new LampManager(room, this.meshGroup);
		this.connectorManager = new ConnectorManager(room, this.meshGroup);
		this.chargerManager = new ChargerManager(room, this.meshGroup);
		this.selectionAreaRenderer = new SelectionAreaRenderer(
			room,
			this.meshGroup
		);

		this.drawRoom();
	}

	@On(RendererEvent.CHANGE_ROOM_HEIGHT)
	@Emit(RendererEvent.UPDATE_RENDERER)
	changeHeight(height: number) {
		this.changeRoomHeight(height);
	}

	@On(RendererEvent.CHANGE_COMPONENTS_LEVEL)
	@Emit(RendererEvent.UPDATE_RENDERER)
	changeLevel(level: number) {
		for (const object of this.room.getRendererObjects()) {
			object.needsUpdate = true;
		}

		this.room.componentsSlingLevel = level;

		this.chargerManager.setLevel(level);
	}

	@On(RendererEvent.DRAW_CUSTOM_FLOOR)
	drawCustomFloor(src: string) {
		this.floorManager.loadCustomFloor(src);
	}

	@On(RendererEvent.UPDATE_RENDERER)
	drawRoom() {
		if (this.room.viewType === ViewType.FIRST_PERSON) return;

		if (this.room.currentStep === 0) {
			this.floorManager.update();
			this.wallManager.update();
			this.nodeManager.update();
			this.labelManager.update();
		}

		if (
			this.room.currentStep === 1 &&
			this.room.viewType === ViewType.RECTANGULAR
		) {
			this.selectionAreaRenderer.drawSelectionArea();
		}

		this.lampManager.update();
		this.connectorManager.update();
		this.chargerManager.update();
	}

	@On(RendererEvent.CHANGE_VIEW_TYPE)
	private handleViewTypeChange({ viewType }: ChangeViewPayload) {
		this.changeViewType(viewType);

		this.floorManager.update();
		this.nodeManager.update();
		this.wallManager.update();
		this.lampManager.update();
		this.connectorManager.update();
		this.chargerManager.update();
	}

	@On(RendererEvent.ZOOM_CAMERA)
	private handleCameraZoom(zoomLevel: number) {
		this.cameraController.zoomCamera(zoomLevel);
	}

	@On(RendererEvent.RESTART_VIEW)
	private restartView() {
		this.room.viewType = ViewType.RECTANGULAR;
		this.handleCameraCenter();
	}

	@On(RendererEvent.CENTER_CAMERA)
	private handleCameraCenter() {
		this.cameraController.centerCamera();
	}

	@On(RendererEvent.ROTATE_VIEW)
	private handleRotate(direction: 'left' | 'right') {
		this.cameraController.rotateView(direction);
	}

	@On(RendererEvent.RESIZE_RENDERER)
	handleResizeRenderer() {
		this.resizeRenderer();
	}

	takeScreenshot() {
		if (!this.screenshotRenderer) return;

		this.prepareScreenshot();

		const group = this.meshGroup.clone(true);
		this.screenshotScene.add(group);

		this.screenshotRenderer.render(
			this.screenshotScene,
			this.cameraController.screenshotCamera
		);

		this.screenshotScene.traverse((object) => {
			if (!(object instanceof THREE.Mesh)) return;
			this.screenshotScene.remove(object);

			if (object.material) {
				if (Array.isArray(object.material)) {
					for (const material of object.material) {
						material.dispose();
					}
				} else object.material.dispose();
			}
			if (object.geometry) object.geometry.dispose();
		});

		return this.screenshotRenderer.domElement;
	}

	dispose() {
		this.renderer.dispose();
		this.screenshotRenderer?.dispose();
		this.cameraController.dispose();

		const ctx = this.canvas.getContext('webgl2')!;
		ctx.clear(0x000000);

		Events.getInstance().removeAll();
	}
}
