import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import APP_CONFIG from './appConfig';

const CREATOR_CONFIG = {
	MIN_DEVICE_WIDTH_SUPPORTED: APP_CONFIG.BREAKPOINTS.LG,
	MIN_ROOM_HEIGHT: 250,
	MAX_ROOM_HEIGHT: 500,
	MIN_LIGHTS_OFFSET: 20,
	INITIAL_ROOM_HEIGHT: 350,
	INITIAL_SLING_LEVEL: 300,
	ANONYMOUS_PROJECTS_STORAGE_KEY: 'anonymousProjects',
	ANONYMOUS_PROJECTS_MAX_AMOUNT: 20,
	DIMENSIONS_ORDER: 3,
	SAVE_INTERVAL: 10_000,
	MAX_PROJECT_NAME_LENGTH: 30,
	MAX_COMPONENTS_AMOUNT: 150,
	MAX_SLINGS_AMOUNT: 100,
	PARAM_SLIGN_NAME: 'zwieszany',
	ALLOWED_CUSTOM_BACKGROUND_TYPES: [...IMAGE_MIME_TYPE, 'image/vnd.dxf'].join(
		','
	),
	CUSTOM_FLOOR_MAX_FILE_SIZE: 10 * 1024 * 1024,
} as const;

export default CREATOR_CONFIG;
