import React from 'react';
import styles from './Loader.module.scss';
import { Box, BoxProps } from '@mantine/core';

export const Loader: React.FC<{ boxProps?: BoxProps }> = ({ boxProps }) => {
	return (
		<Box className={styles.spinner} w={40} h={40} {...boxProps}>
			<div className={styles.spinnerChase}>
				<div className={styles.chaseDot} />
				<div className={styles.chaseDot} />
				<div className={styles.chaseDot} />
				<div className={styles.chaseDot} />
				<div className={styles.chaseDot} />
				<div className={styles.chaseDot} />
			</div>
		</Box>
	);
};
