import { ComponentItem } from '@/api/ComponentItemApi';
import { ComponentCategory } from '@/api/ComponentCategoryApi';
import { Component, ComponentType } from '@/api/ComponentApi';
import { Optional } from 'utility-types';
import { Project } from '@/api/ProjectApi';
import { Lamp } from '@/3d/core/Lamp';
import { Mesh } from '@/3d/core/types/mesh';
import { UUID } from './types';
import { Charger } from '@/3d/core/Charger';
import { Point } from '@/3d/core/types/point';

export enum RoomShape {
	SQUARE,
	ANGLED,
	RHOMB,
	TRUNCATED,
	INFINITE,
	CUSTOM_FLOOR,
}

export interface CreatorProject
	extends Optional<Omit<Project, 'componentItems' | 'options'>, 'id'> {
	creatorComponentItems: CreatorComponentItem[];
	options: string;
}

export interface CreatorRenderer {
	initialized: boolean;
	viewType: ViewType;
	zoomLevel: number;
	canUndo: boolean;
	mesh: Mesh | null;
}

export type ComponentItemsChanges = Record<UUID, number>;

export interface CreatorOptions {
	currentStep: number;
	refresher: number;
	roomHeight: number;
	lightsLevel: number;
	roomShape: RoomShape | null;
	selectedPredefined: PrefedinedRoom | null;
	selectedComponentCategory: ComponentCategory | null;
	selectedComponent: Component | null;
	draggedComponentItem: {
		componentItem: ComponentItem;
		automaticConnector?: ComponentItem;
	} | null;
	selectedLabel: UUID | null;
	selectedCreatorComponentItems: SelectedComponent[];
	selectedChargers: SelectedCharger[];
	hoveredObject: HoveredObject | null;
	shareDrawerOpened: boolean;
	sendDrawerOpened: boolean;
	saveDrawerOpened: boolean;
	isLampDetailsOpened: boolean;
	isProjectLoading?: boolean;
}

export type SelectedComponent = Pick<
	Lamp,
	| 'id'
	| 'componentItemId'
	| 'angle'
	| 'pos'
	| 'windowPosition'
	| 'automaticConnector'
	| 'groupId'
	| 'length'
	| 'width'
> & {
	componentType: ComponentType;
	resizeEnabled: boolean;
	isAutomatic: boolean;
	copyDisabled: boolean;
};

export type SelectedCharger = Pick<Charger, 'id' | 'windowPosition'>;
export type HoveredObject = {
	componentItemId: UUID;
	windowPosition: Point;
};

export type DimensionsPresentation = `${string}/${string}/${string}`;

export enum ViewType {
	RECTANGULAR,
	ISOMETRIC,
	FIRST_PERSON,
}

export enum LampFilter {
	ALL,
	LED,
	FLUORESCENT,
	INCANDESCENT,
}

export interface PrefedinedRoom {
	id: UUID;
	name: string;
	lampSerie: string;
	background: string;
	lampImg: string;
	mesh: Mesh;
}

export type lampDimesions = {
	width: number;
	height: number;
	length: number;
};

export interface CreatorComponentItem {
	id: UUID;
	componentItemId: UUID;
}

export type AnonymousProject = Pick<
	Project,
	'id' | 'name' | 'projectIndex' | 'createdAt' | 'netPrice' | 'euroNetPrice'
>;

export type ChangeViewPayload = {
	viewType: ViewType;
	zoomLevel: number;
};
