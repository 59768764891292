import React from 'react';
import {
	LoadingOverlay,
	TextInput,
	Title,
	Text,
	useMantineTheme,
	Textarea,
	Notification,
} from '@mantine/core';
import { Button } from '@/components/partials/button/Button';
import { isEmail, useForm } from '@mantine/form';
import { ErrorCode, useContent } from '@/providers/ContentProvider';
import { validatePhoneNumber } from '@/utils/utilities';
import { PhonePrefix } from '@/components/partials/phonePrefix/PhonePrefix';
import { useMutation } from '@tanstack/react-query';
import { HeroImg } from '@/components/partials/heroImg/HeroImg';
import { useAuth } from '@/providers/AuthProvider';
import { omit } from 'lodash';
import CountryList from 'country-list-with-dial-code-and-flag';
import AppApi from '@/api/AppApi';
import styles from './Contact.module.scss';

export interface ContactValues {
	contactPerson: string;
	email: string;
	companyName: string;
	country: string;
	phoneNumber: string;
	content: string;
}

const baseSlug = 'contact.';

export const Contact: React.FC = () => {
	const { user } = useAuth();

	const { getErrorMessage, getContent } = useContent();

	const { other, spacing } = useMantineTheme();

	const form = useForm<ContactValues>({
		initialValues: {
			contactPerson: user?.contactPerson || '',
			email: user?.email || '',
			companyName: user?.companyName || '',
			country: user?.country
				? `${user.country}|${
						CountryList.findOneByCountryCode(user.country)?.dial_code
				  }`
				: '|',
			phoneNumber: user?.phoneNumber.split(' ')[1] || '',
			content: getContent(`${baseSlug}inputContentInitial`),
		},
		validate: {
			phoneNumber: (value: string) =>
				value && !validatePhoneNumber(value)
					? getErrorMessage(ErrorCode.INVALID_PHONE_NUMBER)
					: null,
			email: isEmail(getErrorMessage(ErrorCode.INVALID_EMAIL)),
		},
	});

	const submitMutation = useMutation({ mutationFn: AppApi.contact });

	const prefix = form.values.country.split('|')[1];

	const handleSubmit = async (values: ContactValues) => {
		if (submitMutation.isSuccess) {
			submitMutation.reset();
			form.reset();
			return;
		}

		submitMutation.mutate(
			omit(
				{
					...values,
					phoneNumber: `${prefix ? `${prefix} ` : ''}${values.phoneNumber}`,
				},
				'country'
			)
		);
	};

	return (
		<div className="page-wrapper-unauth">
			<form className={styles.form} onSubmit={form.onSubmit(handleSubmit)}>
				<LoadingOverlay visible={submitMutation.isPending} />
				<Title order={1} mb="lg">
					{getContent(`${baseSlug}header`)}
				</Title>
				{submitMutation.isSuccess ? (
					<Notification {...other.notificationProps.success} mb="lg">
						<Text {...other.fontSizes.md} color="green.6">
							{getContent(`${baseSlug}successInfo`)}
						</Text>
					</Notification>
				) : (
					<>
						<Title order={3} mb="sm">
							{getContent(`${baseSlug}subheader`)}
						</Title>
						<Text {...other.fontSizes.md} color="dark.6" mb="lg">
							{getContent(`${baseSlug}info`)}
						</Text>
					</>
				)}

				<TextInput
					{...form.getInputProps('contactPerson')}
					{...other.inputSizes.lg}
					required
					type="text"
					mb="lg"
					placeholder={getContent(`${baseSlug}inputContactPersonPlaceholder`)}
					readOnly={submitMutation.isSuccess}
				/>
				<TextInput
					{...form.getInputProps('companyName')}
					{...other.inputSizes.lg}
					required
					type="text"
					mb="lg"
					placeholder={getContent(`${baseSlug}inputCompanyNamePlaceholder`)}
					readOnly={submitMutation.isSuccess}
				/>
				<TextInput
					{...form.getInputProps('email')}
					{...other.inputSizes.lg}
					required
					type="email"
					mb="lg"
					placeholder={getContent(`${baseSlug}inputEmailPlaceholder`)}
					readOnly={submitMutation.isSuccess}
				/>
				<TextInput
					{...form.getInputProps('phoneNumber')}
					{...other.inputSizes.lg}
					required
					type="tel"
					icon={!!user ? <PhonePrefix prefix={prefix} /> : undefined}
					iconWidth={74}
					mb="lg"
					placeholder={getContent(`${baseSlug}inputPhoneNumberPlaceholder`)}
					readOnly={submitMutation.isSuccess}
				/>
				<Textarea
					{...form.getInputProps('content')}
					{...other.inputSizes.lg}
					required
					mb="lg"
					placeholder={getContent(`${baseSlug}inputContentPlaceholder`)}
					readOnly={submitMutation.isSuccess}
				/>

				<Button
					themes={['primary', 'arrow', 'fullWidth']}
					disabled={submitMutation.isSuccess}
					buttonProps={{
						type: 'submit',
						style: {
							marginBottom: spacing.lg,
						},
					}}
				>
					{getContent(`${baseSlug}submitBtn`)}
				</Button>
			</form>
			<HeroImg src={getContent(`${baseSlug}heroImg`)} />
		</div>
	);
};
