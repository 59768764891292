import { configureStore } from '@reduxjs/toolkit';
import { StoreKey } from '@/configs/storeKeys';
import appReducer from './slices/appSlice';
import creatorReducer from './slices/creatorSlice';
import tutorialReducer from './slices/tutorialSlice';

export const store = configureStore({
	reducer: {
		app: appReducer,
		creator: creatorReducer,
		tutorial: tutorialReducer,
	},
	devTools: import.meta.env.DEV,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredPaths: [
					`${StoreKey.CREATOR}.updatedAt`,
					`${StoreKey.CREATOR}.options.selectedCreatorComponentItems`,
				],
			},
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
