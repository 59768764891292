import { ContactValues } from '@/components/views/unauthenticated/contact/Contact';
import { Attachment } from '@/types/types';
import { QueryKey } from '@/configs/queryKeys';
import httpClient from './httpClient';

class AppApi {
	async contact(values: Omit<ContactValues, 'country'>) {
		const response = await httpClient.post<{ sent: true }>('contact', values);
		return response.data;
	}

	async createAttachment(file: File | Blob) {
		const attachmentBody = new FormData();
		attachmentBody.append('file', file);

		const response = await httpClient.post<Attachment>(
			QueryKey.attachments,
			attachmentBody
		);

		return response.data;
	}

	async convertFile(file: File | Blob) {
		const attachmentBody = new FormData();
		attachmentBody.append('file', file);

		const response = await httpClient.post<Attachment>(
			`${QueryKey.attachments}/convert`,
			attachmentBody
		);

		return response.data;
	}
}

export default new AppApi();
