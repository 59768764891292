import React, { useMemo } from 'react';
import {
	Badge,
	Flex,
	Title,
	useMantineTheme,
	Menu,
	Text,
	Grid,
	rem,
	Center,
	LoadingOverlay,
	Box,
} from '@mantine/core';
import { createColumnHelper } from '@tanstack/react-table';
import { SendProjectDrawer } from '@/components/partials/sendProjectDrawer/SendProjectDrawer';
import { ShareProjectDrawer } from '@/components/partials/shareProjectDrawer/ShareProjectDrawer';
import {
	formatDateForPresentation,
	formatPrice,
	isDefaultLanguage,
	projectStatusColor,
} from '@/utils/utilities';
import { useContent } from '@/providers/ContentProvider';
import { useDisclosure, useFullscreen, useMediaQuery } from '@mantine/hooks';
import { Button } from '@/components/partials/button/Button';
import { Link, useParams } from 'react-router-dom';
import { ClientTable } from '@/components/partials/tables/ClientTable';
import { AppPaths } from '@/configs/appPaths';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApiFormattedResponseData, Currency, UUID } from '@/types/types';
import { Loading } from '@/components/partials/loading/Loading';
import { Loader } from '@/components/partials/loading/partials/Loader';
import { useAuth } from '@/providers/AuthProvider';
import { CreatorSliceState } from '@/store/slices/creatorSlice';
import { Project3dView } from '@/components/partials/project3dView/Project3dView';
import ProjectApi, {
	Project as IProject,
	ProjectComponentItem,
	ProjectStatus,
} from '@/api/ProjectApi';
import downloadFile from 'js-file-download';
import ArrowLeftIcon from '@/assets/icons/arrowLeft.svg?react';
import SearchIcon from '@/assets/icons/search.svg?react';
import CloseIcon from '@/assets/icons/close.svg?react';
import ArchiveIcon from '@/assets/icons/archive.svg?react';
import EditIcon from '@/assets/icons/edit.svg?react';
import ArrowDownIcon from '@/assets/icons/arrowDown.svg?react';
import DownloadIcon from '@/assets/icons/download.svg?react';
import SendIcon from '@/assets/icons/send.svg?react';
import ShareIcon from '@/assets/icons/share.svg?react';
import APP_CONFIG from '@/configs/appConfig';
import styles from './Project.module.scss';

const columnHelper = createColumnHelper<ProjectComponentItem>();

const baseSlug = 'project.';

export const Project: React.FC = () => {
	const queryClient = useQueryClient();

	const user = useAuth().user;

	const { getContent, getEntityText, language } = useContent();
	const isDefault = isDefaultLanguage(language);

	const priceLocaleOpts = useMemo(
		() => (isDefault ? undefined : { currency: Currency.EUR }),
		[isDefault]
	);

	const mobileBreakpoint = useMediaQuery(
		`(max-width: ${rem(APP_CONFIG.BREAKPOINTS.MD)})`
	);
	const theme = useMantineTheme();

	const projectId = useParams().projectId as UUID;
	const projectQuery = useQuery({
		queryKey: [ProjectApi.queryKey, projectId],
		queryFn: ProjectApi.getOne,
		refetchOnMount: 'always',
		initialData: () =>
			queryClient
				.getQueriesData<ApiFormattedResponseData<IProject>>({
					queryKey: [ProjectApi.queryKey],
				})
				.reduce<IProject[]>(
					(prev, current) =>
						current[1]?.data ? [...prev, ...current[1].data] : prev,
					[]
				)
				.find((proj) => proj.id === projectId),
	});

	const sliceState = useMemo<CreatorSliceState | null>(() => {
		if (!projectQuery.data) return null;
		try {
			return JSON.parse(projectQuery.data.options);
		} catch (error) {
			return null;
		}
	}, [projectQuery.data]);

	const isUserOwner = projectQuery.data?.user?.id === user?.id;

	const { ref, toggle, fullscreen } = useFullscreen();

	const [shareOpened, { open: openShare, close: closeShare }] =
		useDisclosure(false);
	const [sendOpened, { open: openSend, close: closeSend }] =
		useDisclosure(false);
	const openDrawer = (type: 'share' | 'send') => () =>
		type === 'share' ? openShare() : openSend();

	const color = projectStatusColor(theme, projectQuery.data?.status);

	const getPdfMutation = useMutation({
		mutationFn: ProjectApi.getPdf,
		onSuccess: (file) => {
			downloadFile(
				file,
				`${getContent('downloadedFileName', {
					vars: {
						$name: projectQuery.data!.name,
						$date: formatDateForPresentation(projectQuery.data!.updatedAt),
					},
				})}.pdf`
			);
		},
	});
	const handleDownload = () => getPdfMutation.mutate(projectId);

	const columns = useMemo(
		() => [
			columnHelper.accessor('componentItemData.image', {
				header: getContent(`${baseSlug}columnComponentImage`),
				enableSorting: false,
				cell: (ctx) =>
					ctx.getValue() ? (
						<img
							className={styles.itemThumbnail}
							src={ctx.getValue() as unknown as string}
						/>
					) : null,
			}),
			columnHelper.accessor('componentItemData.componentIndex', {
				header: getContent(`${baseSlug}columnComponentIndex`),
				sortingFn: 'basic',
			}),
			columnHelper.accessor('componentItemData', {
				header: getContent(`${baseSlug}columnComponentName`),
				sortingFn: 'basic',
				cell: (ctx) => getEntityText(ctx.getValue(), 'name'),
			}),
			columnHelper.accessor('quantity', {
				header: getContent(`${baseSlug}columnQuantity`),
				sortingFn: 'basic',
			}),
			columnHelper.accessor(isDefault ? 'netPrice' : 'euroNetPrice', {
				id: 'total',
				header: getContent(`${baseSlug}columnTotal`),
				cell: (ctx) =>
					user ? formatPrice(ctx.getValue() / 100, priceLocaleOpts) : '-',
				sortingFn: 'basic',
				meta: {
					style: {
						textAlign: 'right',
						marginLeft: 'auto',
					},
				},
			}),
		],
		[priceLocaleOpts]
	);

	if (!projectQuery.data) return <Loading />;

	return (
		<div className="page-wrapper-auth">
			<Flex align="center" gap="md" mb="lg">
				<Button
					isLink
					to={`/${AppPaths.PROJECTS}`}
					themes={['iconOnly', 'transparent', 'fit']}
					buttonProps={{
						type: 'button',
					}}
				>
					<ArrowLeftIcon />
				</Button>
				<Title order={2}>{getContent(`${baseSlug}header`)}</Title>
			</Flex>
			<Flex
				gap={{ base: 'lg', sm: 'xl' }}
				direction={{ base: 'column', md: 'row' }}
				mb="xl"
			>
				<Grid className={styles.summary} gutter={0}>
					<Grid.Col span={12}>
						<Flex
							gap="lg"
							className={styles.box}
							direction={{ base: 'column', sm: 'row' }}
							align="center"
						>
							<Title order={3}>{projectQuery.data.name}</Title>
							<Menu>
								<Menu.Target>
									<button className={styles.actionButton}>
										<LoadingOverlay
											visible={getPdfMutation.isPending}
											loader={
												<Loader
													boxProps={{
														w: 20,
														h: 20,
													}}
												/>
											}
										/>
										{getContent('projects.actionsPlaceholder')}
										<ArrowDownIcon />
									</button>
								</Menu.Target>
								<Menu.Dropdown>
									{projectQuery.data.status === ProjectStatus.NOT_SENT &&
										isUserOwner && (
											<Link
												to={`/${AppPaths.CREATOR}/${projectQuery.data.id}/${AppPaths.LAYOUT}`}
												className="decoration-none "
											>
												<Menu.Item icon={<EditIcon />}>
													{getContent('projects.actionsEdit')}
												</Menu.Item>
											</Link>
										)}
									{projectQuery.data.status === ProjectStatus.NOT_SENT &&
										isUserOwner && (
											<Menu.Item icon={<ArchiveIcon />}>
												{getContent('projects.actionsArchive')}
											</Menu.Item>
										)}
									{projectQuery.data.status === ProjectStatus.ARCHIVED &&
										isUserOwner && (
											<Menu.Item icon={<ArchiveIcon />}>
												{getContent('projects.actionsRestore')}
											</Menu.Item>
										)}
									{projectQuery.data.status !== ProjectStatus.SENT &&
										isUserOwner && <Menu.Divider />}
									<Menu.Item icon={<DownloadIcon />} onClick={handleDownload}>
										{getContent('projects.actionsDownload')}
									</Menu.Item>
									{projectQuery.data.status === ProjectStatus.NOT_SENT &&
										isUserOwner && (
											<Menu.Item
												onClick={openDrawer('send')}
												icon={<SendIcon />}
											>
												{getContent('projects.actionsSend')}
											</Menu.Item>
										)}
									{projectQuery.data.status !== ProjectStatus.ARCHIVED && (
										<Menu.Item
											onClick={openDrawer('share')}
											icon={<ShareIcon />}
										>
											{getContent('projects.actionsShare')}
										</Menu.Item>
									)}
								</Menu.Dropdown>
							</Menu>
						</Flex>
					</Grid.Col>

					{!!user && (
						<>
							<Grid.Col
								span={12}
								style={{
									borderTop: `1px solid ${theme.other.borderColor}`,
								}}
							>
								<div className={styles.box}>
									<Text
										{...theme.other.fontSizes.md}
										color={theme.other.textDimmed}
										mb="xs"
									>
										{user.email}
									</Text>
									<Title order={3}>{user.contactPerson}</Title>
								</div>
							</Grid.Col>
						</>
					)}
					<Grid.Col
						span={6}
						md={4}
						style={{
							borderRight: mobileBreakpoint
								? 0
								: `1px solid ${theme.other.borderColor}`,
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelProjectIndex`)}
							</Text>
							<Title order={3}>{projectQuery.data.projectIndex}</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={6}
						md={4}
						style={{
							borderRight: mobileBreakpoint
								? 0
								: `1px solid ${theme.other.borderColor}`,
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelPrice`)}
							</Text>
							<Title order={3}>
								{user
									? formatPrice(
											(isDefault
												? projectQuery.data.netPrice
												: projectQuery.data.euroNetPrice) / 100,
											priceLocaleOpts
									  )
									: '-'}
							</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={12}
						md={4}
						style={{
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelStatus`)}
							</Text>
							<Title order={3}>
								<Badge h="xl" styles={{ root: { backgroundColor: color } }}>
									{getContent(`projectStatus${projectQuery.data.status}`)}
								</Badge>
							</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={6}
						style={{
							borderRight: `1px solid ${theme.other.borderColor}`,
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelCreatedAt`)}
							</Text>
							<Title order={3}>
								{formatDateForPresentation(projectQuery.data.createdAt)}
							</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={6}
						style={{
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelUpdatedAt`)}
							</Text>
							<Title order={3}>
								{formatDateForPresentation(projectQuery.data.updatedAt)}
							</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={6}
						style={{
							borderRight: `1px solid ${theme.other.borderColor}`,
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelRoomHeight`)}
							</Text>
							<Title order={3}>{sliceState?.options.roomHeight} cm</Title>
						</div>
					</Grid.Col>
					<Grid.Col
						span={6}
						style={{
							borderTop: `1px solid ${theme.other.borderColor}`,
						}}
					>
						<div className={styles.box}>
							<Text
								{...theme.other.fontSizes.md}
								color={theme.other.textDimmed}
								mb="xs"
							>
								{getContent(`${baseSlug}labelLightsLevel`)}
							</Text>
							<Title order={3}>{sliceState?.options.lightsLevel} cm</Title>
						</div>
					</Grid.Col>
				</Grid>

				<Box className={styles.preview} ref={ref}>
					{sliceState?.renderer.mesh ? (
						<>
							<Project3dView
								mesh={sliceState.renderer.mesh}
								boxProps={
									fullscreen
										? undefined
										: {
												h: 450,
										  }
								}
							/>
							<Button
								themes={['iconOnly']}
								buttonProps={{
									type: 'button',
									className: styles.button,
									onClick: toggle,
								}}
							>
								{fullscreen ? <CloseIcon /> : <SearchIcon />}
							</Button>
						</>
					) : (
						<Center h="100%">
							<Title order={3}>
								{getContent(`${baseSlug}previewUnavailable`)}
							</Title>
						</Center>
					)}
				</Box>
			</Flex>
			<ClientTable
				emptyView={
					<Title order={2} my="xl">
						{getContent(`${baseSlug}emptyComponents`, {
							links: {
								$creator: {
									to: `/${AppPaths.CREATOR}/${projectQuery.data.id}/${AppPaths.LAYOUT}`,
								},
							},
						})}
					</Title>
				}
				reactTableProps={{
					columns,
					data: projectQuery.data.projectComponentItems.concat(
						projectQuery.data.accessories
					),
					enableSorting: true,
				}}
				pagination={false}
			/>
			<ShareProjectDrawer
				project={projectQuery.data}
				opened={shareOpened}
				close={closeShare}
			/>
			<SendProjectDrawer
				project={projectQuery.data}
				opened={sendOpened}
				close={closeSend}
			/>
		</div>
	);
};
